<template>
  <div>
    <div style="background: #fff; position: fixed; z-index: 50; padding: 0px 20px; width: 100%; text-align:center;  box-shadow:rgba(0, 0, 0, 0.2) 2px 4px 20px;">
      <div class="d-flex justify-content-between">
        <div>
          <div @click="$router.go(-1)" style="padding-top:20px; padding-left: 10px; float: left; text-align:left; padding-right:25px; cursor:pointer;">
              <span class="fa fa-chevron-left" style="font-size:20px;"></span>
              <span style="font-size: 20px; padding-left: 5px;">Back</span>
          </div>
          <div style="padding-top: 16px; padding-bottom: 16px; padding-left: 10px; float: left; text-align:left;">
            <h3 style="margin-top:3px; font-size: 23px;">
              {{convention.project}}
            </h3>
          </div>
        </div>
        <div style="padding-top: 16px;">
          <button class="btn btn-default" @click="projectSettings"><font-awesome-icon icon="cog" style="font-size: 25px;"/></button>
        </div>
      </div>
    </div>
    <div class="col-md-2 bg-light" style="position:fixed; bottom:0px; top:125px; border-right: 1px solid #f1f1f1; border-top: 1px solid #f1f1f1; -webkit-box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1); box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);">
      <ul class="nav flex-column pt-4 pb-1">
        <li class="nav-item">
          <a class="nav-link px-0" style="cursor:pointer;" @click="viewKOL('KOLs')">
            <div class="d-flex bd-highlight">
              <h6 class="p-2 m-0 text-uppercase">
                KOLs
              </h6>
            </div>
          </a>
        </li>
      </ul>
      <ul class="nav flex-column pt-0 pb-1">
        <li class="nav-item">
          <a class="nav-link px-0" style="cursor:pointer;">
            <div class="d-flex bd-highlight justify-content-between">
              <h6 class="p-2 m-0 text-uppercase">Heatmap</h6>
              <font-awesome-icon icon="plus" style="margin-top:8px;" @click="addHeatmap"/>
            </div>
          </a>
          <ul class="nav pl-4">
            <li v-for="(item, index) in heatmapTables" :key="index" class="nav-item py-2 pr-3 d-flex align-items-center" style="width: 100%; cursor:pointer;" @click="showSpreadsheet(item)">
              <h6 class="mb-0 font-weight-normal">{{item.name}}</h6>
            </li>
          </ul>
        </li>
      </ul>
      <ul class="nav flex-column pt-0 pb-4">
        <li class="nav-item">
          <a class="nav-link px-0" style="cursor:pointer;" @click="viewClientAccess('Client Access')">
            <div class="d-flex bd-highlight">
              <h6 class="p-2 m-0 text-uppercase">
                Client Access
              </h6>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <main role="main" class="col-md-7 ml-sm-auto col-lg-10 ml-0" style="position: fixed; padding-left: 0px; padding-right: 0px; bottom:0px; border-top: 1px solid #f1f1f1; top: 125px; left: 0px; right: 0px; background-color: #f4f5f7; overflow-y:auto;">
      <Component :is="showComponent" :type="type" :projectId="projectId" />
    </main>
    <div v-if="showModal">
      <heatmap-modal @close="closeModal" @saved="showTables" :title="title" :project="convention.project" :editMode="editMode"/>
    </div>
  </div>
</template>
<script>
// import { http } from '@/config/base-url'
import ConventionService from '@/services/convention.service'
import KOLs from '@/components/3x/KOLs.vue'
import HeatmapModal from '@/components/3x/HeatmapModal.vue'
import Spreadsheet from '@/components/3x/Spreadsheet.vue'
export default {
  props: ['project'],
  components: {
    KOLs,
    HeatmapModal,
    Spreadsheet
  },
  data () {
    return {
      isDraft: false,
      showModal: false,
      items: [],
      biblio: {},
      title: '',
      editMode: false,
      showDropdown: false,
      showComponent: 'KOLs',
      type: 'KOLs',
      convention: {},
      showdropdown: true,
      clientData: [],
      formId: '',
      formData: {},
      projectId: '',
      heatmapTables: []
    }
  },
  methods: {
    getConvention: function () {
      ConventionService.getConvention(this.$route.params.pid).then((response) => {
        console.log(response)
        this.convention = response
      })
    },
    projectSettings: function () {
      this.$router.push({ name: 'profile-settings', params: { id: this.$route.params.id, pid: this.$route.params.pid } })
    },
    closeModal: function () {
      this.showModal = false
    },
    viewKOL: function (type) {
      this.showComponent = 'KOLs'
      this.type = type
      this.projectId = this.$route.params.pid
    },
    addHeatmap: function () {
      this.editMode = false
      this.showModal = true
      this.title = 'Upload Heatmap Data'
    },
    showTables: function () {
      this.showModal = false
      ConventionService.getHeatmapTables(this.$route.params.id).then((response) => {
        console.log(response)
        this.heatmapTables = response
      })
    },
    showSpreadsheet: function (item) {
      this.showComponent = 'Spreadsheet'
      this.type = item
      this.projectId = this.$route.params.id
    }
  },
  mounted () {
    this.getConvention()
    this.showTables()
  },
  created () {
  }
}
</script>
<style scoped>
.file-select > .select-button {
  margin: 10px;
  cursor: pointer;
  font-size: 25px;
}

.file-select > input[type="file"] {
  display: none;
}
</style>
