<template>
  <div>
    <div class="d-flex justify-content-between" style="background: #fff; position: fixed; z-index: 50; padding: 20px 25px; width: 100%; text-align:center; box-shadow:rgba(0, 0, 0, 0.2) 2px 4px 20px;">
      <div class="btn-group">
        <a class="btn btn-secondary" v-bind:class="{ active: isAllForms}" style="text-transform: uppercase; padding: 10px 15px; color:#fff; cursor:pointer;" @click="allForms">All Forms</a>
      </div>
      <div class="w-25">
        <input type="text" class="form-control form-control-lg" v-model="formSearch" placeholder="Search Forms..."/>
      </div>
      <div class="btn-group">
        <a class="btn btn-primary btn" style="text-transform: uppercase; font-weight: bold; padding: 10px 15px; color:#fff; cursor: pointer;" @click="addForm">Add New Form</a>
        <!-- <a v-if="hasTemplates" class="btn btn-info btn" style="text-transform: uppercase; font-weight: bold; padding: 10px 15px; color:#fff; cursor: pointer;" @click="addEventFromTemplate">Add Event From Template</a> -->
      </div>
    </div>
    <!-- Events -->
    <div style="padding-top: 88px;">
      <div style="position: fixed; top: 142px; width: 100%;">
        <table class="table" style="background-color: #fff; margin-bottom: 0px;">
          <thead>
            <tr>
              <th width="70%">Form</th>
              <th width="10%">Category</th>
              <th width="10%">Status</th>
              <th width="10%"></th>
            </tr>
          </thead>
        </table>
        <div style="position: fixed; top: 192px; left: 0px; right: 0px; bottom: 0px; padding-top: 0px; padding-bottom: 0px; background-color: #FFF; overflow-y:auto;">
          <table class="table table-striped">
            <tbody>
              <tr v-for="(item, index) in filteredForms" :key="index">
                <td width="70%" style="padding-top: 24px;">{{item.form_name}}</td>
                <td width="10%" style="padding-top: 24px;">{{item.category}}</td>
                <td width="10%" style="padding-top: 24px;">
                  <change-status :item="item" :key-index="index" v-on:status-changed="saveItemStatus(item, index)"></change-status>
                </td>
                <td width="10%" style="padding-top: 24px;">
                  <div class="btn-group">
                    <button class="btn btn-primary btn-sm" @click="editForm(item)">Edit</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-center p-3">
            <!-- <button class="btn btn-primary" v-if="hasMoreItemsToShow" @click="showMoreItems()">Show more</button> -->
          </div>
        </div>
      </div>
    </div>
    <div v-if="showFormModal">
      <form-modal @close="closeFormModal" @save="saveForm" :item="formItem" :title="formTitle" :type="type"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FormModal from '@/components/requests/modals/FormModal.vue'
import ChangeStatus from '@/components/requests/helpers/ChangeStatus.vue'
import formService from '@/services/form.service'
import moment from 'moment'
// import { forEach } from 'lodash'
export default {
  name: 'request-froms-builder',
  components: {
    FormModal,
    ChangeStatus
  },
  data () {
    return {
      formSearch: '',
      isMyForms: false,
      isAllForms: false,
      showFormModal: false,
      formTitle: '',
      formItem: {},
      type: '',
      hasTemplates: false
    }
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        console.log(value)
        return moment(String(value)).format('MM/DD/YYYY')
      }
    }
  },
  computed: {
    ...mapState({ forms: state => state.forms.forms }),
    filteredForms: function () {
      return this.forms
        .filter((form) => {
          return form.form_name.toLowerCase().match(this.formSearch)
        })
    }
  },
  methods: {
    myForms: function () {
      this.isMyForms = !this.isMyForms
      this.isAllForms = false
      this.$store.dispatch('forms/getMyForms')
    },
    allForms: function () {
      this.isAllForms = !this.isAllForms
      this.isMyForms = false
      this.$store.dispatch('forms/getForms')
    },
    // viewForm: function (id) {
    //   this.$router.push({ name: 'eventDetail', params: { id: id } })
    // },
    addForm: function () {
      this.showFormModal = true
      this.formTitle = 'Add New Form'
      this.type = 'Form'
    },
    // addEventFromTemplate: function () {
    //   this.showEventModal = true
    //   this.eventTitle = 'Add New Event'
    //   this.type = 'Template'
    // },
    editForm: function (item) {
      this.showFormModal = true
      this.formTitle = 'Edit Form'
      this.formItem = item
    },
    closeFormModal: function () {
      this.showFormModal = false
      this.formItem = {}
      this.$store.dispatch('forms/getForms')
    },
    saveForm: function (item) {
      this.showFormModal = false
      // if (item.type === 'add') {
      //   this.events.push(item)
      // } else {
      //   forEach(this.events, (items) => {
      //     if (items.id === item.id) {
      //       items = item
      //     }
      //   })
      //   this.eventItem = {}
      // }
    },
    saveItemStatus: function (item, index) {
      formService.saveForm(item).then((response) => {
        console.log(response)
      })
    }
  },
  created () {
    this.isAllForms = true
    this.myForms = false
    this.$store.dispatch('forms/getForms')
    // eventService.getEventTemplates().then((response) => {
    //   console.log(response)
    //   if (response.length === 0) {
    //     this.hasTemplates = false
    //   } else {
    //     this.hasTemplates = true
    //   }
    // })
  }
}
</script>

<style scoped>

</style>
