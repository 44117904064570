<template>
  <div class="login__settings">
    <div class="login">
      <div class="login__header">
        <img class="header__logo" src="@/assets/simpson-logo.svg">
      </div>
      <div class="login__main" @click="MicrosoftLogin">
        <img class="main__logo" src="@/assets/ms-signin-light.svg">
      </div>
      <!-- <div class="login__button"></div> -->
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
// import axios from 'axios'
import AuthService from '@/services/auth.service'
import GraphService from '@/services/graph.service'
export default {
  name: 'login',
  data () {
    return {
      username: '',
      password: '',
      submitted: false,
      user: null,
      userInfo: null,
      apiCallFailed: false,
      loginFailed: false
    }
  },
  methods: {
    ...mapActions('auth', ['login', 'goToRoute']),
    handleSubmit (e) {
      this.submitted = true
      const { username, password } = this
      // let formValid = false
      const forms = document.getElementsByClassName('needs-validation')
      Array.prototype.filter.call(forms, function (form) {
        if (form.checkValidity() === false) {
          e.preventDefault()
          e.stopPropagation()
        }
        form.classList.add('was-validated')
      })
      if (username && password) {
        const authData = {
          username: username,
          password: password
        }
        this.login(authData)
      }
    },
    callAPI () {
      this.apiCallFailed = false
      this.authService.getToken().then(token => {
        console.log(token)
        this.graphService.getUserInfo(token.accessToken).then(data => {
          this.userInfo = data
          localStorage.setItem('muser', JSON.stringify(this.userInfo))
          const roles = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).roles : ''
          if (roles.includes('admin') || roles.includes('finance')) {
            this.$router.push('/projects')
          } else {
            this.$router.push('/applications')
          }
        }, error => {
          console.error(error)
          this.apiCallFailed = true
        })
      }, error => {
        console.error(error)
        this.apiCallFailed = true
      })
    },
    MicrosoftLogin: function () {
      this.loginFailed = false
      this.authService.login().then(user => {
        if (user) {
          this.user = user
          const authData = {
            username: user
          }
          this.login(authData)
          this.callAPI()
        } else {
          this.loginFailed = true
        }
      })
    }
  },
  computed: {
    ...mapState('auth', ['errorStatus', 'errorMessage'])
  },
  mounted () {
    if (this.$route.params.id) {
      console.log(this.$route.params.id)
      const id = this.$route.params.id
      this.$router.push(`aad-callback/${id}`)
    } else {
      console.log('No parameter')
    }
    this.authService = new AuthService()
    this.graphService = new GraphService()
  }
}
</script>

<style scoped>
.login__settings {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login {
  width: 320px;
  height: 150px;
  border-radius: 4px;
}

.login__header {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header__logo {
  height: 120px;
  margin-top: -120px;
}

.header__text {
  margin: 15px 0;
}

.login__main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(150px - 50px);
}

.main__logo {
  cursor: pointer;
}

</style>
