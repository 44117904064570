<template>
<div>
    <div v-if="itemIndex != keyIndex" @click="changeStatus(keyIndex)">
        <span class="mr-2 badge" :class="{'badge-success': item.status == 1 , 'badge-danger': item.status == 0 }" style="cursor:pointer;">{{getStatusName(item.status)}}</span>
    </div>
    <div v-else>
        <select
        class="form-control shadow-none"
        v-model="status"
        v-focus
        @keyup.esc="cancelEdit"
        @blur="cancelEdit"
        @change="saveStatus(status, keyIndex); $emit('status-changed')"
        >
        <option value="1">Active</option>
        <option value="0">Inactive</option>
        </select>
    </div>
</div>
</template>

<script>
export default {
  name: 'ContractorChangeStatus',
  props: ['item', 'keyIndex'],
  data () {
    return {
      status: true,
      itemIndex: -1
    }
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.focus()
      }
    }
  },
  methods: {
    changeStatus: function (index) {
      // this.status = this.items[index].status;
      this.status = this.item.status
      this.itemIndex = index
    },
    cancelEdit: function () {
      this.itemIndex = -1
    },
    saveStatus: function (item, index) {
      // this.items[index].status = JSON.parse(item);
      this.item.status = JSON.parse(item)
      this.itemIndex = -1
    },
    getStatusName: function (item) {
      const formattedItem = JSON.parse(item)
      if (formattedItem === 1) {
        return 'Active'
      } else {
        return 'Inactive'
      }
    }
  }
}
</script>

<style scoped>
</style>
