<template>
  <div>
    <!-- <div class="d-flex justify-content-between align-items-center" style="background: #fff; position: fixed; z-index: 50; padding: 20px 25px; width: 100%; text-align:center; box-shadow:rgba(0, 0, 0, 0.2) 2px 4px 20px;">
      <div class="col-md-5 d-flex justify-content-start" style="padding-left: 0px; margin-top: 5px;">
        <button class="btn btn-secondary" style="text-transform: uppercase; padding: 10px 15px; color:#fff; cursor:pointer;" @click="goBack">Back</button>
        <h2 class="ml-4">Charlotte Cunningham-Rundles</h2>
      </div>
      <span class="mr-2 badge badge-success" style="font-size: 20px;">Active</span>
    </div> -->
    <!-- <div class="d-flex justify-content-start align-items-center" style="background: #fff; position: fixed; z-index: 50; padding: 20px 25px; width: 100%; text-align:center; box-shadow:rgba(0, 0, 0, 0.2) 2px 4px 20px;">
      <button class="btn btn-secondary" style="text-transform: uppercase; padding: 10px 15px; color:#fff; cursor:pointer;" @click="goBack">Back</button>
      <h2 class="ml-4">Charlotte Cunningham-Rundles</h2>
      <span class="mr-2 badge badge-success" style="font-size: 20px;">Active</span>
    </div> -->
    <div style="padding: 25px 0px 0px 0px; bottom: 0; width: 100%;">
      <nav class="col-md-3 d-none d-md-block bg-light sidebar">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0);" @click="goBack">Back</a></li>
            <li class="breadcrumb-item active" aria-current="page">Charlotte Cunningham-Rundles</li>
          </ol>
        </nav>
        <div style="padding: 0px 25px 15px 25px;">
          <div class="d-flex justify-content-between align-items-center">
            <span class="text-muted">123456</span>
            <span class="badge badge-success">Active</span>
          </div>
          <div class="kol-profile text-center">
              <div class="profile-image" style="background-image: url('https://employee.simpsonhealthcare.com/takeda/4534-104/kols/7.jpg')"></div>
              <h4 class="mt-3">Charlotte Cunningham-Rundles</h4>
          </div>
        </div>
        <div class="sidebar-sticky">
          <ul class="nav flex-column pt-0 pb-4">
            <li class="nav-item">
              <a class="nav-link" style="cursor:pointer;" @click="viewContact('Contact Info')">
                <div class="d-flex bd-highlight">
                  <h6 class="p-2 m-0 text-uppercase">
                    Contact Info
                  </h6>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" style="cursor:pointer;" @click="viewHCPDetails('HCP Details')">
                <div class="d-flex bd-highlight">
                  <h6 class="p-2 m-0 text-uppercase">
                    HCP Details
                  </h6>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" style="cursor:pointer;" @click="viewOpenPayments('Open Payments')">
                <div class="d-flex bd-highlight">
                  <h6 class="p-2 m-0 text-uppercase">
                    Open Payments
                  </h6>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" style="cursor:pointer;" v-on:click="toggleBiblio">
                <div class="d-flex bd-highlight justify-content-between">
                  <h6 class="p-2 m-0 text-uppercase">Bibliometric Info</h6>
                  <font-awesome-icon v-if="showdropdown == false" icon="chevron-right" style="margin-top:8px;"/>
                  <font-awesome-icon v-if="showdropdown == true" icon="chevron-down" style="margin-top:8px;"/>
                </div>
              </a>
              <ul class="nav pl-4" v-if="showdropdown">
                <li class="nav-item py-2 pr-3 d-flex justify-content-between align-items-center" style="width: 100%;" @click="viewBibliometric('Academic Appointments')">
                  <h6 class="mb-0 font-weight-normal">Academic Appointments</h6>
                  <span class="badge badge-secondary">0</span>
                </li>
                <li class="nav-item py-2 pr-3 d-flex justify-content-between align-items-center" style="width: 100%;">
                  <h6 class="mb-0 font-weight-normal">Clinical Interests</h6>
                  <span class="badge badge-secondary">0</span>
                </li>
                <li class="nav-item py-2 pr-3 d-flex justify-content-between align-items-center" style="width: 100%;" @click="viewClinicalTrial('Clinical Trials')">
                  <h6 class="mb-0 font-weight-normal">Clinical Trials</h6>
                  <span class="badge badge-secondary">0</span>
                </li>
                <li class="nav-item py-2 pr-3 d-flex justify-content-between align-items-center" style="width: 100%;">
                  <h6 class="mb-0 font-weight-normal">Conference Activities</h6>
                  <span class="badge badge-secondary">0</span>
                </li>
                <li class="nav-item py-2 pr-3 d-flex justify-content-between align-items-center" style="width: 100%;">
                  <h6 class="mb-0 font-weight-normal">Editorial Boards</h6>
                  <span class="badge badge-secondary">0</span>
                </li>
                <li class="nav-item py-2 pr-3 d-flex justify-content-between align-items-center" style="width: 100%;">
                  <h6 class="mb-0 font-weight-normal">Institutional Restrictions</h6>
                  <span class="badge badge-secondary">0</span>
                </li>
                <li class="nav-item py-2 pr-3 d-flex justify-content-between align-items-center" style="width: 100%;">
                  <h6 class="mb-0 font-weight-normal">Involvement in Professional Committees and Societies</h6>
                  <span class="badge badge-secondary">0</span>
                </li>
                <li class="nav-item py-2 pr-3 d-flex justify-content-between align-items-center" style="width: 100%;">
                  <h6 class="mb-0 font-weight-normal">Medical Education</h6>
                  <span class="badge badge-secondary">0</span>
                </li>
                <li class="nav-item py-2 pr-3 d-flex justify-content-between align-items-center" style="width: 100%;">
                  <h6 class="mb-0 font-weight-normal">Notable Activities</h6>
                  <span class="badge badge-secondary">0</span>
                </li>
                <li class="nav-item py-2 pr-3 d-flex justify-content-between align-items-center" style="width: 100%;">
                  <h6 class="mb-0 font-weight-normal">Other Pharmaceutical Industry Experience</h6>
                  <span class="badge badge-secondary">0</span>
                </li>
                <li class="nav-item py-2 pr-3 d-flex justify-content-between align-items-center" style="width: 100%;">
                  <h6 class="mb-0 font-weight-normal">Professional Interests</h6>
                  <span class="badge badge-secondary">0</span>
                </li>
                <li class="nav-item py-2 pr-3 d-flex justify-content-between align-items-center" style="width: 100%;">
                  <h6 class="mb-0 font-weight-normal">Professional Training</h6>
                  <span class="badge badge-secondary">0</span>
                </li>
                <li class="nav-item py-2 pr-3 d-flex justify-content-between align-items-center" style="width: 100%;" @click="viewPublication('Publications')">
                  <h6 class="mb-0 font-weight-normal">Publications</h6>
                  <span class="badge badge-secondary">0</span>
                </li>
                <li class="nav-item py-2 pr-3 d-flex justify-content-between align-items-center" style="width: 100%;">
                  <h6 class="mb-0 font-weight-normal">Research Interests</h6>
                  <span class="badge badge-secondary">0</span>
                </li>
                <li class="nav-item py-2 pr-3 d-flex justify-content-between align-items-center" style="width: 100%;">
                  <h6 class="mb-0 font-weight-normal">Society/Foundation Affiliations</h6>
                  <span class="badge badge-secondary">0</span>
                </li>
                <li class="nav-item py-2 pr-3 d-flex justify-content-between align-items-center" style="width: 100%;">
                  <h6 class="mb-0 font-weight-normal">Textbooks</h6>
                  <span class="badge badge-secondary">0</span>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" style="cursor:pointer;" @click="viewEngagementPlan('Engagement Plan')">
                <div class="d-flex bd-highlight">
                  <h6 class="p-2 m-0 text-uppercase">
                    Engagement Plan
                  </h6>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" style="cursor:pointer;" @click="toggleProjects">
                <div class="d-flex bd-highlight justify-content-between">
                  <h6 class="p-2 m-0 text-uppercase">Projects</h6>
                  <font-awesome-icon v-if="showProjectDropdown == false" icon="chevron-right" style="margin-top:8px;"/>
                  <font-awesome-icon v-if="showProjectDropdown == true" icon="chevron-down" style="margin-top:8px;"/>
                </div>
              </a>
              <ul class="nav pl-4" v-if="showProjectDropdown">
                <li class="nav-item py-2 pr-3 d-flex align-items-center" style="width: 100%; cursor:pointer;" @click="viewProject('Takeda 4534-104 KOL Eng and Monitoring')">
                  <h6 class="mb-0 font-weight-normal">Takeda 4534-104 KOL Eng and Monitoring</h6>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <main role="main" class="col-md-7 ml-sm-auto col-lg-9 ml-0" style="position: fixed; top: 55px; left: 0px; right: 0px; bottom: 0px; background-color: #f4f5f7; overflow-y:auto;">
      <Component :is="showComponent" :type="type" :kolId="kolId" :name="name" :projectId="projectId" />
    </main>
    <!-- <div v-for="item in myfilteredList" :key="item.id" class="col-sm-6 col-md-4 col-lg-3" style="padding-top: 15px;">
            <article v-if="!item.is_archived" class="info-box" style="width: 100%; text-align:left; box-shadow:rgba(0, 0, 0, 0.2) 2px 4px 20px;">
              <div class="card mb-3 w-100">
                <div class="card-body">
                  <div class="d-flex justify-content-between mb-3 h-50">
                    <div class="text-uppercase">
                      <span class="badge" v-bind:class="getBadgeType(item.project_status)">{{item.project_status}}</span>
                    </div>
                    <span v-if="!filterUserProjects(item.user_projects)" @click="starProject(item)" class="far fa-star" style="font-size: 22px;"></span>
                    <font-awesome-icon v-if="filterUserProjects(item.user_projects)" @click="unStarProject(item)" icon="star" style="font-size: 22px; color: goldenrod;"/>
                  </div>
                  <h5 class="card-title" style="height: 100px;">{{item.name}}</h5>
                </div>
                <div class="card-footer" v-if="hasAccess">
                  <div class="d-flex justify-content-between">
                    <span class="badge badge-dark text-uppercase" style="cursor:pointer;" @click="archiveProject(item)">Archive</span>
                    <span class="badge badge-primary text-uppercase" style="cursor:pointer;" data-toggle="modal" data-target="#projectModal" data-backdrop="static" data-keyboard="false" @click="editProject(item)">Edit</span>
                  </div>
                </div>
              </div>
            </article>
        </div> -->
  </div>
</template>

<script>
// import { mapState } from 'vuex'
// import { forEach } from 'lodash'
import ContactInfo from '@/components/profiles/ContactInfo.vue'
import HCPDetails from '@/components/profiles/HCPDetails.vue'
import BibliometricInfo from '@/components/profiles/BibliometricInfo.vue'
import Publications from '@/components/profiles/Publications.vue'
import ClinicalTrials from '@/components/profiles/ClinicalTrials.vue'
import OpenPayments from '@/components/profiles/open-payments/OpenPayments.vue'
import EngagementPlan from '@/components/profiles/EngagementPlan.vue'
import ProjectInfo from '@/components/profiles/projects/ProjectInfo.vue'
export default {
  name: 'kolprofile',
  components: {
    ContactInfo,
    HCPDetails,
    BibliometricInfo,
    Publications,
    ClinicalTrials,
    OpenPayments,
    EngagementPlan,
    ProjectInfo
  },
  data () {
    return {
      projectTypeOptions: [],
      kolSearch: '',
      projectType: 'All Specialties',
      showdropdown: false,
      showProjectDropdown: false,
      showComponent: '',
      type: '',
      kolId: '',
      name: '',
      projectId: ''
    }
  },
  methods: {
    goBack: function () {
      this.$router.go(-1)
    },
    toggleBiblio: function () {
      this.showdropdown = !this.showdropdown
    },
    toggleProjects: function () {
      this.showProjectDropdown = !this.showProjectDropdown
    },
    viewContact: function (type) {
      this.showComponent = 'ContactInfo'
      this.type = type
      this.kolId = this.$route.params.id
    },
    viewHCPDetails: function (type) {
      this.showComponent = 'HCPDetails'
      this.type = type
      this.kolId = this.$route.params.id
    },
    viewEngagementPlan: function (type) {
      this.showComponent = 'EngagementPlan'
      this.type = type
      this.kolId = this.$route.params.id
    },
    viewBibliometric: function (type) {
      this.showComponent = 'BibliometricInfo'
      this.type = type
      this.kolId = this.$route.params.id
    },
    viewPublication: function (type) {
      this.showComponent = 'Publications'
      this.type = type
      this.kolId = this.$route.params.id
    },
    viewClinicalTrial: function (type) {
      this.showComponent = 'ClinicalTrials'
      this.type = type
      this.kolId = this.$route.params.id
    },
    viewOpenPayments: function (type) {
      this.showComponent = 'OpenPayments'
      this.type = type
      this.kolId = this.$route.params.id
      this.name = 'Charlotte Cunningham-Rundles'
    },
    viewProject: function (type) {
      this.showComponent = 'ProjectInfo'
      this.type = type
      this.kolId = this.$route.params.kolid
      this.projectId = this.$route.params.id
    }
  },
  created () {
  }
}
</script>

<style scoped>
/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 55px;
  bottom: 0;
  left: 0;
  z-index: 0; /* Behind the navbar */
  padding: 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  position: relative;
  top: 0px;
  bottom: 0;
  height: calc(100vh - 365px);
  padding-top: 0px;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.profile-image {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  background-size: cover;
  display: inline-block;
}

.btype:nth-child(odd) {
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
}

.btype:nth-child(even) {
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
}

.btype:nth-child(4n) {
    border-right: 1px solid #ccc;
}
</style>
