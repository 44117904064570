import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalendarAlt, faMapMarkerAlt, faChevronRight, faChevronDown, faUser, faPercentage, faExternalLinkAlt, faArrowLeft, faChevronUp, faCog, faSave, faPaperclip, faPencilRuler, faInfoCircle, faImage, faUserClock, faFileAlt, faEdit, faTrash, faStar, faPlus, faPlusCircle, faArchive, faSearch, faCloudDownloadAlt, faCloudUploadAlt, faCheck, faTimes, faComments, faCommentAlt, faClock, faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import datePicker from 'vue-bootstrap-datetimepicker'
import JQuery from 'jquery'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import Multiselect from 'vue-multiselect'
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import '@fortawesome/fontawesome-free/css/solid.css'
import '@fortawesome/fontawesome-free/css/regular.css'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import VueCoreVideoPlayer from 'vue-core-video-player'
import VuePlyr from 'vue-plyr'
// import VueLoading from 'vuejs-loading-plugin'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
// import 'vue-plyr/dist/vue-plyr.css'
import UsdFormat from './filters/usd_format'
import Percentage from './filters/percentage'
import TotalPayments from './filters/total_payments'
// import Inputmask from 'inputmask'
import VueMask from 'v-mask'
import VueFriendlyIframe from 'vue-friendly-iframe'

Vue.filter('usd_format', UsdFormat)
Vue.filter('percentage', Percentage)
Vue.filter('totalPayments', TotalPayments)
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

JQuery.extend(true, JQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle'
  }
})

// Vue.directive('input-mask', {
//   bind: function (el) {
//     new Inputmask().mask(el)
//   }
// })

library.add(faCalendarAlt, faMapMarkerAlt, faChevronRight, faCloudDownloadAlt, faCloudUploadAlt, faChevronDown, faUser, faPercentage, faExternalLinkAlt, faArrowLeft, faChevronUp, faCog, faSave, faPaperclip, faPencilRuler, faInfoCircle, faImage,
  faUserClock, faFileAlt, faEdit, faTrash, faStar, faPlus, faPlusCircle, faArchive, faSearch, faCheck, faTimes, faComments,
  faClock, faCommentAlt, faLongArrowAltLeft)
Vue.component('multiselect', Multiselect)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(datePicker)
Vue.use(Loading)
Vue.use(VueCoreVideoPlayer)
Vue.use(VuePlyr)
Vue.use(VueMask)
// Vue.use(VueLoading)
Vue.use(VueTimepicker)
Vue.use(VueFriendlyIframe)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
