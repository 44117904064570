<template>
  <div>
    <nav class="col-md-2 d-none d-md-block bg-light sidebar">
      <div class="sidebar-sticky">
        <div style="padding: 25px 25px 15px 25px;">
          <h3 style="font-size:1.7rem;">Marketing</h3>
        </div>
        <ul class="nav flex-column">
          <li class="nav-item" :class="{ 'active': view == 'projects' }">
            <a class="nav-link" style="cursor:pointer;" v-on:click="showView('projects')">
              <div class="d-flex bd-highlight">
                <h6 class="p-2 m-0 text-uppercase">
                  Projects
                </h6>
              </div>
            </a>
          </li>
          <!-- <li class="nav-item" :class="{ 'active': view == 'budgets' }">
            <a class="nav-link" style="cursor:pointer;" v-on:click="showView('budgets')">
              <div class="d-flex bd-highlight">
                <h6 class="p-2 m-0 text-uppercase">
                  Budgets
                </h6>
              </div>
            </a>
          </li> -->
        </ul>
      </div>
    </nav>
    <main role="main" class="col-md-8 ml-sm-auto col-lg-10 px-0 ml-0" style="position: fixed; top: 55px; left: 0px; right: 0px; bottom: 0px; padding-top: 0px; padding-bottom: 25px; background-color: #f4f5f7; overflow-y:auto;">
      <div v-if="view === 'projects'">
        <div class="d-flex justify-content-between" style="background: #fff; position: absolute; z-index: 50; padding: 20px 25px; width: 100%; right: 0; text-align:center; box-shadow:rgba(0, 0, 0, 0.2) 2px 4px 20px;">
          <div class="w-25">
            <input type="text" class="form-control form-control-lg" v-model="projectSearch" placeholder="Search Project..."/>
          </div>
          <div>
            <div class="btn-group">
              <!-- <a class="btn btn-warning btn" style="text-transform: uppercase; font-size: 14px; font-weight: bold; padding: 10px 15px; color:#fff;"
              data-toggle="modal" data-target="#userModal" data-backdrop="static" data-keyboard="false"><font-awesome-icon icon="archive"/> Archived Projects</a> -->
              <a class="btn btn-primary btn" style="text-transform: uppercase; font-size: 14px; font-weight: bold; padding: 10px 15px; color:#fff;"
            data-toggle="modal" data-target="#projectModal" data-backdrop="static" data-keyboard="false" @click="addProject"><font-awesome-icon icon="plus"/> Add Project</a>
            </div>
          </div>
        </div>
        <div style="padding: 0px 25px; padding-top: 25px; position:absolute; top: 100px; bottom: 0; width: 100%; overflow-y:auto;">
          <div class="row">
            <div v-for="item in myfilteredList" :key="item.id" class="col-4" style="padding-top: 15px;">
                <article class="info-box" style="width: 100%; text-align:left; box-shadow:rgba(0, 0, 0, 0.2) 2px 4px 20px;">
                  <div class="card mb-3 w-100" :class="getMarketingStatus(item.marketing_status)">
                    <div class="card-body" :class="{'mkt-status': item.project_status != 'Marketing'}">
                      <div class="d-flex justify-content-between mb-3 h-50" v-if="item.project_status == 'Marketing'">
                        <div class="text-uppercase">
                          <span class="badge" v-bind:class="getBadgeType(item.project_status)">{{item.project_status}}</span>
                        </div>
                      </div>
                      <h5 class="card-title" style="height: 100px;">{{item.name}}</h5>
                    </div>
                    <div class="card-footer" v-if="item.project_status == 'Marketing'">
                      <div class="d-flex justify-content-between">
                        <span class="badge badge-dark text-uppercase" style="cursor:pointer;" @click="archiveProject(item)">Archive</span>
                        <span class="badge badge-primary text-uppercase" style="cursor:pointer;" data-toggle="modal" data-target="#projectModal" data-backdrop="static" data-keyboard="false" @click="editProject(item)">Edit</span>
                      </div>
                    </div>
                  </div>
                </article>
            </div>
          </div>
          <div class="d-flex justify-content-center p-3">
            <button class="btn btn-primary" v-if="hasMoreItemsToShow" @click="showMoreItems()">Show more</button>
          </div>
        </div>
      </div>
      <div v-if="view === 'budgets'">
          <div style="padding: 20px 25px;">
            <a class="btn btn-primary btn" style="text-transform: uppercase; font-size: 14px; font-weight: bold; padding: 10px 15px; color:#fff;"
            data-toggle="modal" data-target="#proposalModal" data-backdrop="static" data-keyboard="false" @click="addProposal"><font-awesome-icon icon="plus"/> Add Budget</a>
          </div>
          <div style="padding: 25px;">
            <table class="table" style="background:#fff;">
              <thead>
                <tr>
                  <th>Client</th>
                  <th>Rate</th>
                  <th>Proposal</th>
                  <th>Out of Pockets</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in proposalData" :key="index">
                  <td width="15%">{{item.client}}</td>
                  <td width="15%">{{item.rate_id}}</td>
                  <td width="25%">{{item.proposal}}</td>
                  <td width="25%">{{item.out_of_pocket_template}}</td>
                  <td width="10%">
                    <change-status style="padding-top: 5px;" :item="item" :key-index="index" v-on:status-changed="saveItemStatus(item, index)"></change-status>
                  </td>
                  <td width="10%">
                    <a :href="getURL(item.id)"><font-awesome-icon icon="cloud-download-alt" style="margin-top: 10px;"></font-awesome-icon></a>&nbsp;&nbsp;
                    <font-awesome-icon icon="edit" style="margin-top: 10px;" data-toggle="modal" data-target="#proposalModal" data-backdrop="static" data-keyboard="false" @click="editProposal(item)"></font-awesome-icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
      </div>
    </main>
    <!-- Project MODAL -->
    <div class="modal" tabindex="-1" role="dialog" id="projectModal">
      <div class="modal-dialog" role="document" style="max-width: 675px;">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{projectTitle}} Project</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form novalidate>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="client">Client</label>
                  <multiselect :options="clients" :show-labels="false" placeholder="Select client" v-model="inputForm.client"></multiselect>
                </div>
                <div class="form-group col-md-6">
                  <label for="projectid">Project #</label>
                  <input type="text" class="form-control" placeholder="Project #" v-model="inputForm.project_id" :disabled="editMode">
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="projectname">Project Name</label>
                  <input type="text" class="form-control" id="projectname" placeholder="Project Name" v-model="inputForm.name">
                </div>
              </div>
              <div class="form-row" v-if="hasAccess && editMode">
                <div class="col-md-6 form-group">
                  <label>Project Status</label>
                  <multiselect :options="projectStatus" :show-labels="false" placeholder="Select project status" v-model="inputForm.project_status"></multiselect>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close">Close</button>
            <button type="button" class="btn btn-primary" @click="saveProject(inputForm)">Save project</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Project MODAL -->

    <!-- Proposal MODAL -->
    <div class="modal" tabindex="-1" role="dialog" id="proposalModal">
      <div class="modal-dialog" role="document" style="max-width: 675px;">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">New Proposal</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form novalidate>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="client">Client</label>
                  <multiselect :options="clients" :show-labels="false" placeholder="Select client" v-model="inputForm.client"></multiselect>
                </div>
                <div class="form-group col-md-6">
                  <label for="ratecard">Rate Card</label>
                  <multiselect :options="rates" :show-labels="false" placeholder="Select rate card" v-model="inputForm.rate_id"></multiselect>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="proposal">Proposal</label>
                  <multiselect :options="proposals" :show-labels="false" placeholder="Select proposal" v-model="inputForm.proposal"></multiselect>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="proposal">Out of Pocket</label>
                  <multiselect :options="oop" :show-labels="false" placeholder="Select Out of Pocket" v-model="inputForm.out_of_pocket_template"></multiselect>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="proposal"># of Simpson Employees</label>
                  <input type="number" class="form-control" placeholder="# of Simpson Employees" v-model="inputForm.num_employees">
                </div>
                <div class="form-group col-md-6">
                  <label for="proposal"># of nights staying</label>
                  <input type="number" class="form-control" placeholder="# of nights staying" v-model="inputForm.num_nights">
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="proposal"># of days staying</label>
                  <input type="number" class="form-control" placeholder="# of days staying" v-model="inputForm.num_days">
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close">Close</button>
            <button type="button" class="btn btn-primary" @click="saveProposal()">Save Proposal</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Proposal MODAL -->
  </div>
</template>

<script>
import JQuery from 'jquery'
// import moment from 'moment'
import ProjectService from '@/services/project.service'
import SettingService from '@/services/setting.service'
import { includes, orderBy } from 'lodash'

export default {
  name: 'Marketing',
  data () {
    return {
      allItems: [],
      myItems: [],
      finalItems: [],
      pagesShown: 1,
      pageSize: 24,
      projectSearch: '',
      projectTypeOptions: ['All Projects', 'In Progress', 'Internal', 'Need PO', 'Not Kicked', 'On Hold'],
      projectType: 'All Projects',
      user: JSON.parse(localStorage.getItem('user')),
      projectStatus: ['In Progress', 'Marketing'],
      clients: [],
      inputForm: {},
      projectTitle: 'Add',
      editMode: false,
      view: 'projects',
      rates: [],
      proposals: [],
      oop: [],
      proposalData: {}

    }
  },
  computed: {
    myfilteredList: function () {
      const arr = this.finalItems.filter((item) => {
        if (this.projectType !== 'All Projects') {
          return item.project_status === this.projectType
        } else {
          return item
        }
      }).filter((item) => {
        return item.name.toLowerCase().match(this.projectSearch.toLowerCase())
      })

      return this.pageSize ? arr.slice(0, this.pageSize * this.pagesShown) : arr
    },
    hasMoreItemsToShow: function () {
      const arr = this.finalItems.filter((item) => {
        if (this.projectType !== 'All Projects') {
          return item.project_status === this.projectType
        } else {
          return item
        }
      }).filter((item) => {
        return item.name.toLowerCase().match(this.projectSearch)
      })

      return this.pagesShown < arr.length / this.pageSize
    },
    hasAccess: function () {
      console.log(includes(this.user.roles, 'admin'))
      const roles = this.user.roles
      if (includes(roles, 'admin') || includes(roles, 'finance')) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    getAllProjects: function () {
      this.busy = true
      ProjectService.getAllMarketingProjects().then((response) => {
        const allProjects = response
        let myItems = []
        let allItems = []
        allItems = orderBy(allProjects, ['project_id'], ['desc'])
        const finalItems = myItems.concat(allItems)
        this.finalItems = finalItems
        console.log(this.finalItems)
      })

      SettingService.getActiveProjectStatus().then((response) => {
        // this.projectStatus = response.project_status
        this.projectTypeOptions = response.project_type
        this.projectTypeOptions.unshift('All Projects')
      })

      SettingService.getActiveClients().then((response) => {
        this.clients = response.clients
      })
    },

    getProposals: function () {
      // ProposalService.getData('all-proposals').then((response) => {
      //   console.log(response)
      //   this.proposalData = response
      // })

      // ProposalService.getData('rate-card').then((response) => {
      //   console.log(response)
      //   this.rates = response
      // })

      // ProposalService.getData('proposal-templates').then((response) => {
      //   console.log(response)
      //   this.proposals = response
      // })

      // ProposalService.getData('out-of-pocket-templates').then((response) => {
      //   console.log(response);
      //   this.oop = response;
      // })
    },
    showMoreItems: function () {
      this.pagesShown = this.pagesShown + 1
    },
    getBadgeType: function (type) {
      return {
        'badge-info': (type === 'Internal'),
        'badge-success': (type === 'In Progress'),
        'badge-warning': (type === 'Marketing'),
        'badge-primary': (includes(['Completed', 'Need PO', 'Not Kicked', 'On Hold'], type))
      }
    },
    getMarketingStatus: function (status) {
      return {
        'archived': (status === 'Marketing Archived'),
        'in-progress': (status === 'In Progress')
      }
    },
    saveProject: function () {
      console.log(this.editMode)
      if (!this.editMode) {
        this.inputForm.project_status = 'Marketing'
      }
      if (this.editMode && this.inputForm.project_status === 'In Progress') {
        this.inputForm.marketing_status = this.inputForm.project_status
      }
      console.log(this.inputForm)
      ProjectService.saveMarketingData(this.inputForm).then((response) => {
        if (response.id) {
          this.inputForm.id = response.id
          this.finalItems.push(this.inputForm)
          this.finalItems = orderBy(this.finalItems, ['project_id'], ['desc'])
        }
        JQuery('#projectModal').modal('hide')
      })
    },
    archiveProject: function (item) {
      item.is_archived = true
      item.marketing_status = 'Marketing Archived'
      item.project_status = 'Archived'

      ProjectService.saveMarketingData(item).then((response) => {
        console.log(response)
      })
    },
    close: function () {
      this.inputForm = {}
      this.showModal = false
    },
    editProject: function (item) {
      this.inputForm = item
      this.editMode = true
      this.projectTitle = 'Edit'
      console.log(this.inputForm)
    },
    addProject: function () {
      this.inputForm = {}
      this.editMode = false
      this.projectTitle = 'Add'
    },
    showView: function (view) {
      this.view = view
    },
    addProposal: function () {
      console.log('clicked')
    },
    saveProposal: function () {
      console.log(this.inputForm)
      // let type = ''
      // if (this.inputForm.id) {
      //   type = 'update'
      // } else {
      //   type = 'new'
      // }
      // ProposalService.saveData(type, this.inputForm).then((response) => {
      //   console.log(response)
      //   JQuery('#proposalModal').modal('hide')
      // })
    },
    saveItemStatus: function (item, index) {
      // ProposalService.saveData('update', item).then((response) => {
      //   console.log(response)
      // })
    },
    editProposal: function (item) {
      this.inputForm = item
    },
    getURL: function (id) {
      return `http://localhost:5000/v2/proposals/budget/${id}`
    }
  },
  mounted () {
    this.getAllProjects()
    this.getProposals()
  }
}
</script>

<style scoped>
/* Rounded sliders */

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 18px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #009370;
}

input:focus+.slider {
    box-shadow: 0 0 1px #009370;
}

input:checked+.slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.archived {
  background: white url('../assets/archived.png') no-repeat center center;
  background-size: 200px auto;
}

.in-progress {
  background: white url('../assets/in-progress.png') no-repeat center center;
  background-size: 200px auto;
}

.mkt-status {
  height: 237px;
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 54px;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  bottom: 0;
  height: 100%;
  padding-top: 0px;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
  padding: 0px 16px;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #fff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

.active {
  background: #009370;
}

.active .nav-link,
.active .nav-link:hover {
  color: #fff;
}
</style>
