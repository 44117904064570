<template>
  <div class="col-sm-12 col-md-12 pb-2">
    <h5 v-if="searches.general == true" class="section-title text-center">List of General Payments in {{ options.year }}</h5>
    <h5 v-else-if="searches.nature == true && searches.company == false" class="section-title text-center">List of General Payments in {{ options.year }} in category {{ options.nature_of }}</h5>
    <h5 v-else-if="searches.company == true && searches.nature == false" class="section-title text-center">List of General Payments in {{ options.year }} received from {{ options.company }}</h5>
    <h5 v-else-if="searches.company == true && searches.nature == true" class="section-title text-center">List of General Payments in {{ options.year }} in category {{ options.nature_of }} received from {{ options.company }}</h5>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Company Making Payments</th>
          <th>Nature of Payment</th>
          <th>Date</th>
          <th style="text-align:right;">Total Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(payment, index) in sorted_general_payments" :key="index">
          <td>{{ payment.company }}</td>
          <td>{{ payment.nature_of_payment }}</td>
          <td>{{ payment.date_of_payment }}</td>
          <td align="right">${{ payment.amount | usd_format }}</td>
        </tr>
        <tr>
          <td colspan="3" align="right" class="font-weight-bold">Total ({{ numPayments }} payments)</td>
          <td align="right" class="font-weight-bold">${{ total | usd_format }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import axios from 'axios'
import { orderBy, filter, forEach } from 'lodash'
import moment from 'moment'
export default {
  props: [
    'id',
    'sent_options',
    'year'
  ],
  data () {
    return {
      physician_id: null,
      options: {},
      searches: {},
      general_payments: [],
      total: null,
      numPayments: null
    }
  },
  mounted () {
    // Assign options
    this.options = this.sent_options
    this.getGeneralPayments(this.year)
    // Assign search filters
    // this.searches = this.search_filters
  },
  computed: {
    loadPhysicianId () {
      return this.id
    },
    sorted_general_payments () {
      return orderBy(this.general_payments, 'amount', ['desc', 'asc'])
    }
  },
  watch: {
    // loadPhysicianId () {
    //   this.physician_id = this.id

    //   //
    //   this.onPageLoad()
    // }
  },
  methods: {
    // onPageLoad () {
    //   let query = ''
    //   switch (this.options.year) {
    //     case '2018':
    //       query = 'xrap-xhey'
    //       break
    //     case '2017':
    //       query = 'trby-32sz'
    //       break
    //     case '2016':
    //       query = 'daqx-kcwf'
    //       break
    //     case '2015':
    //       query = 'rqr8-e3gy'
    //       break
    //   }

    //   this.getGeneralPayments(query)
    // },
    getGeneralPayments (year, company, nature) {
      // Clear array before search
      // this.total = item.general_payments_total
      // this.numPayments = item.num
      this.general_payments = []
      // this.finalTotal = this.total
      // this.numOfPayments = this.numPayments

      axios(`https://openpaymentsdata.cms.gov/resource/${year.query}.json?physician_profile_id=${this.id}`, {
        headers: {
          'X-App-Token': 'YhHPfcbvU5yPjTVFQzRraM41w'
        }
      }).then(res => {
        res.data.forEach((item) => {
          let payment = ''

          if (item.nature_of_payment_or_transfer_of_value === 'Compensation for services other than consulting, including serving as faculty or as a speaker at a venue other than a continuing education program') {
            payment = 'Services other than consulting'
          } else if (item.nature_of_payment_or_transfer_of_value === 'Compensation for serving as faculty or as a speaker for an unaccredited and non-certified continuing education program.') {
            payment = 'Faculty/speaker at an unnacredited/non-certified program'
          } else {
            payment = item.nature_of_payment_or_transfer_of_value
          }

          let generalObj = {
            company: item.applicable_manufacturer_or_applicable_gpo_making_payment_name,
            nature_of_payment: payment,
            date_of_payment: moment(item.date_of_payment).format('MM/DD/YYYY'),
            amount: parseFloat(item.total_amount_of_payment_usdollars)
          }
          this.general_payments.push(generalObj)
          this.getTotal()
          if (company) {
            this.general_payments = filter(this.general_payments, { 'company': company })
            this.getTotal()
          }

          if (nature) {
            this.general_payments = filter(this.general_payments, { 'nature_of_payment': nature })
            this.getTotal()
          }
          console.log(this.general_payments)
        })
      }).catch(error => {
        console.log(error)
      })
    },
    getTotal: function () {
      let total = 0.00
      forEach(this.general_payments, function (item) {
        total += item.amount
      })
      this.total = parseFloat(total)
      this.numPayments = this.general_payments.length
    },
    // ---------- From Parent
    // searchGeneralPayments (query) {
    //   if (this.options.company !== 'All Companies' && this.options.nature_of === 'All Nature of Payments') {
    //     let queryCall = this.$http.get(`https://openpaymentsdata.cms.gov/resource/` + query + `.json?applicable_manufacturer_or_applicable_gpo_making_payment_name=`+ this.options.company + `&physician_profile_id=` + this.physician_id)

    //     this.getQueryResults(queryCall)

    //   } else if (this.options.company !== 'All Companies' && this.options.nature_of !== 'All Nature of Payments') {
    //     let queryCall = this.$http.get(`https://openpaymentsdata.cms.gov/resource/` + query + `.json?applicable_manufacturer_or_applicable_gpo_making_payment_name=`+ this.options.company + `&nature_of_payment_or_transfer_of_value=` + this.options.nature_of + `&physician_profile_id=` + this.physician_id)

    //     this.getQueryResults(queryCall)

    //   } else if (this.options.company === 'All Companies' && this.options.nature_of !== 'All Nature of Payments' || this.options.company === 'All Companies' && this.options.nature_of === 'All Nature of Payments') {
    //     let queryCall = this.$http.get(`https://openpaymentsdata.cms.gov/resource/` + query + `.json?nature_of_payment_or_transfer_of_value=` + this.options.nature_of + `&physician_profile_id=` + this.physician_id)

    //     this.getQueryResults(queryCall)
    //   }
    // },
    getQueryResults (queryCall) {
      // Clear array before search
      this.general_payments = []

      queryCall
        .then(res => {
          res.data.forEach((item) => {
            let payment = ''

            if (item.nature_of_payment_or_transfer_of_value === 'Compensation for services other than consulting, including serving as faculty or as a speaker at a venue other than a continuing education program') {
              payment = 'Services other than consulting'
            } else if (item.nature_of_payment_or_transfer_of_value === 'Compensation for serving as faculty or as a speaker for an unaccredited and non-certified continuing education program.') {
              payment = 'Faculty/speaker at an unnacredited/non-certified program'
            } else {
              payment = item.nature_of_payment_or_transfer_of_value
            }

            let generalObj = {
              company: item.applicable_manufacturer_or_applicable_gpo_making_payment_name,
              nature_of_payment: payment,
              date_of_payment: moment(item.date_of_payment).format('M/D/YYYY'),
              amount: parseFloat(item.total_amount_of_payment_usdollars)
            }
            this.general_payments.push(generalObj)
          })
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>
<style scoped>
</style>
