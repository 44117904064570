<template>
  <div class="d-flex justify-content-center px-5" style="padding:45px 25px 25px 25px;">
    <!-- <Description :kid="this.kid" :type="type" /> -->
    <div class="card w-75">
      <div class="card-header d-flex justify-content-between align-items-center">
        <div>
          <h4>{{type}}</h4>
          <p class="text-muted mb-0" style="font-size: 0.85em;">Last updated: 7/1/2020 2:57 PM by Simpson Employee</p>
        </div>
        <button v-if="items.length == 0" class="btn btn-sm btn-primary" @click="addInfo">Add Clinical Trial</button>
      </div>
      <div class="card-body p-0">
        <table class="table mb-0">
          <tr v-if="items.length == 0">
            <td colspan="3" class="text-center">
              No Information Available
            </td>
          </tr>
          <tr v-for="item in items" :key="item.id">
            <td width="80%">
              <!-- <div v-html="item.description"></div> -->
              <ul>
                <li v-for="(bib, index) in item.description" :key="index" v-html="bib"></li>
              </ul>
            </td>
            <td><span class="badge badge-success" :class="{'badge-success': item.publish_type === 'Published' , 'badge-warning': item.publish_type === 'Draft' }">{{item.publish_type}}</span></td>
            <td>
              <div class="d-flex justify-content-between pt-1" v-if="!item.draft_id">
                <font-awesome-icon class="text-primary" icon="edit" style="font-size: 18px;" @click="editBiblio(item)" />
                <font-awesome-icon v-if="item.publish_type !== 'Draft'" class="text-warning" icon="pencil-ruler" style="font-size: 18px;" @click="addDraft(item)" />
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div v-if="showModal">
      <clinical-trial-modal @close="closeInfoModal" @saved="saveBiblio" :title="title" :editMode="editMode" :type="type" :kolId="kolId"/>
    </div>
  </div>
</template>
<script>
import ClinicalTrialModal from '@/components/profiles/ClinicalTrialModal.vue'
export default {
  props: ['type', 'kolId'],
  components: {
    ClinicalTrialModal
  },
  data () {
    return {
      isDraft: false,
      showModal: false,
      items: [],
      biblio: {},
      title: '',
      editMode: false
    }
  },
  methods: {
    getBibliometrics: function (id, type) {
      console.log(id, type)
    },
    addInfo: function () {
      this.title = 'Add Clinical Trials'
      this.editMode = false
      this.showModal = true
    },
    closeInfoModal: function () {
      this.showModal = false
    },
    saveBiblio: function (data) {
      // data.description = JSON.parse(data.description)
      console.log('Saved Data', data)
      this.items.push(data)
      this.showModal = false
    }
  },
  created () {
    this.getBibliometrics(this.kolId, this.type)
  }
}
</script>
