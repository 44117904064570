<template>
  <div>
    <div class="d-flex justify-content-between">
      <h4>Email Responses</h4>
      <div class="btn-group">
        <button class="btn btn-warning text-white" @click="addCalendarInvite">Add Calendar Invite</button>
        <button class="btn btn-primary" @click="addEmailResponse">Add Email Response</button>
      </div>
    </div>
    <div style="padding-top: 15px;" ref="emailResponses">
      <table class="table table-striped" style="background: #fff;">
        <tr>
          <th>Email Name</th>
          <th>Type</th>
          <th>Schedule</th>
          <th></th>
        </tr>
        <tr v-for="item in emailResponse" :key="item.id">
          <td>{{item.email_name}}</td>
          <td>{{item.email_type}}</td>
          <td>{{item.schedule}}</td>
          <td>
            <div class="d-flex justify-content-between" style="font-size: 18px;">
              <span class="badge badge-success" style="cursor:pointer;" @click="sendEmailManually(item)">Send Manually</span>
              <span class="badge badge-info" style="cursor:pointer;" @click="editEmailResponse(item)">Edit</span>
              <span class="badge badge-danger" style="cursor:pointer;" @click="deleteEmailResponse(item)">Delete</span>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div style="padding-top: 15px;" ref="calendarInvites">
      <h4>Calendar Invites</h4>
      <table class="table table-striped" style="background: #fff;">
        <tr>
          <th>Subject</th>
          <th>Location</th>
          <th>Start Date</th>
          <th>Start Time</th>
          <th></th>
        </tr>
        <tr v-for="item in calendarInvite" :key="item.id">
          <td>{{item.subject}}</td>
          <td>{{item.location}}</td>
          <td>{{item.start_date | formatDate}}</td>
          <td>{{item.start_time | formatTime}}</td>
          <td>
            <div class="d-flex justify-content-end" style="font-size: 18px;">
              <span class="badge badge-info" style="cursor:pointer; margin-right: 10px;" @click="editCalendarInvite(item)">Edit</span>
              <span class="badge badge-danger" style="cursor:pointer;" @click="deleteCalendarInvite(item)">Delete</span>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div v-if="showEmailResponseModal">
      <email-response-modal @close="closeEmailResponseModal" @saved="savedEmailResponse" :eventId="eventId" :title="emailModalTitle" :inputForm="inputForm" />
    </div>
    <div v-if="showCalendarInviteModal">
      <calendar-invite-modal @close="closeCalendarInviteModal" @saved="savedCalendarInvite" :eventId="eventId" :title="calendarTitle" :inputForm="inputForm" />
    </div>
  </div>
</template>

<script>
import eventService from '@/services/event.service'
import EmailResponseModal from '@/components/events/modals/EmailResponseModal.vue'
import CalendarInviteModal from '@/components/events/modals/CalendarInviteModal.vue'
import moment from 'moment'
import { forEach } from 'lodash'
export default {
  name: 'emailResponses',
  components: {
    EmailResponseModal,
    CalendarInviteModal
  },
  data () {
    return {
      view: '',
      showEmailResponseModal: false,
      emailModalTitle: '',
      showRegistrantModal: false,
      registrantName: '',
      eventName: '',
      eventId: this.$route.params.id,
      emailResponse: [],
      inputForm: {},
      showCalendarInviteModal: false,
      calendarTitle: '',
      calendarInvite: []
    }
  },
  computed: {
  },
  methods: {
    addEmailResponse: function () {
      this.inputForm = {
        event_id: this.$route.params.id,
        cc_address: null,
        bcc_address: null,
        num_days: null,
        email_type: null,
        schedule: null,
        email_name: null,
        subject: null,
        from_address: null,
        attachments: []
      }
      console.log(this.inputForm)
      this.emailModalTitle = 'New Email Response'
      this.showEmailResponseModal = true
    },
    editEmailResponse: function (item) {
      console.log(item)
      this.inputForm = item
      this.emailModalTitle = 'Edit Email Response'
      this.showEmailResponseModal = true
    },
    sendEmailManually: function (item) {
      eventService.sendEmailManually(item.id).then((response) => {
        console.log(response)
        console.log('Email Sent Manually')
      })
    },
    deleteEmailResponse: function (item) {
      console.log(item)
      eventService.deleteResponseEmail(item.id).then((response) => {
        console.log(response)
        const emailId = item.id
        var removeIndex = this.emailResponse.map(function (item) { return item.id }).indexOf(emailId)
        this.emailResponse.splice(removeIndex, 1)

        forEach(item.attachments, (value) => {
          var formData = new FormData()
          formData.append('id', value.id)
          formData.append('file_name', value.file_name)
          eventService.removeEmailAttachment(formData).then((response) => {
            console.log(response)
          })
        })
        console.log('Email Response Deleted!')
      })
    },
    closeEmailResponseModal: function () {
      this.showEmailResponseModal = false
    },
    savedEmailResponse: function (itemList) {
      console.log(itemList)
      if (itemList.attachments.length === 0) {
        console.log(itemList.attachments)
        eventService.getEmailAttachments(itemList.id).then((response) => {
          itemList.attachments = response
        })
      }

      var index = this.emailResponse.map(function (item) { return item.id }).indexOf(itemList.id)
      if (index === -1) {
        this.emailResponse.push(itemList)
      } else {
        this.emailResponse[index] = itemList
      }
      this.showEmailResponseModal = false
    },
    emailResponses: function () {
      let loader = this.$loading.show({
        container: this.$refs.emailResponses,
        loader: 'dots'
      })
      eventService.getEmailResponses(this.$route.params.id).then((response) => {
        this.emailResponse = response
        console.log(response)
        loader.hide()
      })
    },
    calendarInvites: function () {
      let loader = this.$loading.show({
        container: this.$refs.calendarInvites,
        loader: 'dots'
      })
      eventService.getCalendarInvites(this.$route.params.id).then((response) => {
        this.calendarInvite = response
        console.log(response)
        loader.hide()
      })
    },
    editCalendarInvite: function (item) {
      console.log(item)
      this.inputForm = item
      this.calendarTitle = 'Edit Calendar Invite'
      this.showCalendarInviteModal = true
    },
    addCalendarInvite: function () {
      this.inputForm = {}
      this.calendarTitle = 'Add Calendar Invite'
      this.showCalendarInviteModal = true
    },
    deleteCalendarInvite: function (item) {
      console.log(item)
      eventService.deleteCalendarInvite(item.id).then((response) => {
        console.log(response)
        const calId = item.id
        var removeIndex = this.calendarInvite.map(function (item) { return item.id }).indexOf(calId)
        this.calendarInvite.splice(removeIndex, 1)
        console.log('Calendar Invite Deleted!')
      })
    },
    closeCalendarInviteModal: function () {
      this.showCalendarInviteModal = false
    },
    savedCalendarInvite: function (itemList) {
      console.log(itemList)
      var index = this.calendarInvite.map(function (item) { return item.id }).indexOf(itemList.id)
      if (index === -1) {
        this.calendarInvite.push(itemList)
      } else {
        this.calendarInvite[index] = itemList
      }
      this.showCalendarInviteModal = false
    }
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
      }
    },
    formatTime: function (value) {
      if (value) {
        console.log(value)
        return moment(String(value), 'HH:mm:ss').format('h:mm A')
      }
    }
  },
  created () {
    // this.getDetails()
    this.emailResponses()
    this.calendarInvites()
  }
}
</script>

<style scoped>
h3 {
  display: inline-block;
  padding-left: 120px;
  padding-top: 5px;
}
/*
 * Sidebar
 */
.sidebar {
  position: fixed;
  top: 142px;
  bottom: 0;
  width: 200px;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}
.sidebar-sticky {
  position: relative;
  top: 0;
  bottom: 0;
  height: 100%;
  padding-top: 0px;
  background: #fff;
  border-right: 1px solid #ccc;
  scrollbar-width: none;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}
.notes-section {
  position: fixed;
  top: 145px;
  left: 200px;
  right: 0px;
  bottom: 0px;
  padding-top: 0px;
  padding-bottom: 25px;
  background-color: #f4f5f7;
  overflow-y:auto;
}
::-webkit-scrollbar {
  display:none;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}
.sidebar .nav-link {
  font-weight: 500;
  color: #333;
  padding: 0px 16px;
}
.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}
.sidebar .nav-link.active {
  color: #fff;
}
.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}
.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}
.sidebar .nav-link {
  padding-top: 5px;
  padding-bottom: 5px;
}
.sidebar .nav-link.active {
  color: #FFF;
  background: #006AB6;
}

</style>
