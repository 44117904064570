<template>
  <div>
    <div class="d-flex justify-content-between">
      <h4>Vendor Access</h4>
      <button class="btn btn-primary" @click="addVendor">Add Vendor</button>
    </div>
    <div class="col-6" style="padding-left: 0px; margin-top: 15px;">
      <multiselect :options="options" :show-labels="false" :multiple="true" track-by="name" label="name" placeholder="Search Vendors" v-model="vendor" @select="onSelect"></multiselect>
    </div>
    <div class="row">
      <div v-for="(item, index) in vendors" :key="index" class="col-4">
        <div class="card" style="margin-top: 25px;">
          <div class="card-body">
            <div class="d-flex justifiy-content-start">
              <div class="circle">
                <span class="initials">{{item.initials}}</span>
              </div>
              <h4 style="margin-left: 15px;">{{item.name}}<br><small>{{item.email}}</small></h4>
            </div>
          </div>
          <div class="card-footer">
            <span class="badge badge-pill badge-danger" @click="deleteVendorAccess(item, index)">Delete</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showVendorModal">
      <vendor-modal @close="closeVendorModal" :event="eventData"/>
    </div>
  </div>
</template>

<script>
import eventService from '@/services/event.service'
import VendorModal from '@/components/events/modals/VendorModal.vue'
export default {
  name: 'eventVendors',
  props: ['eventData'],
  components: {
    VendorModal
  },
  data () {
    return {
      vendor: [],
      vendors: [],
      options: [],
      showVendorModal: false
    }
  },
  computed: {
  },
  methods: {
    getVendors: function () {
      eventService.getVendors().then((response) => {
        this.options = response
      })
    },
    getVendorAccess: function (id) {
      eventService.getVendorAccess(id).then((response) => {
        this.vendors = response
      })
    },
    onSelect: function (item) {
      item.initials = this.getAccessInitials(item)
      item.event_id = this.$route.params.id
      eventService.saveVendorAccess(item).then((response) => {
        item.id = response.id
        this.vendors.push(item)
      })
    },
    addVendor: function () {
      this.showVendorModal = true
    },
    closeVendorModal: function () {
      this.showVendorModal = false
      this.getVendors()
    },
    getAccessInitials: function (item) {
      console.log(item)
      const first = item.first_name.substring(0, 1)
      const last = item.last_name.substring(0, 1)
      return first + last
    },
    deleteVendorAccess: function (item, index) {
      console.log(item)
      console.log(index)
      eventService.deleteVendorAccess(item.id).then((response) => {
        console.log(response)
        this.vendors.splice(index, 1)
      })
    }
  },
  mounted () {
    this.getVendors()
    this.getVendorAccess(this.$route.params.id)
  }
}
</script>

<style scoped>
.circle {
  background-color: #333;
  border-radius: 50%;
  height: 60px;
  text-align: center;
  width: 60px;
}

.initials {
  font-size: calc(60px / 2);
  /* 50% of parent */
  line-height: 1;
  position: relative;
  top: calc(60px / 4);
  /* 25% of parent */
  color: #FFF;
}
</style>
