<template>
  <div>
    <div class="d-flex justify-content-between" style="background: #fff; position: fixed; z-index: 50; padding: 20px 25px; width: 100%; text-align:center; box-shadow:rgba(0, 0, 0, 0.2) 2px 4px 20px;">
      <div class="d-flex">
        <button class="btn btn-secondary" @click="goBack">
          <font-awesome-icon icon="long-arrow-alt-left" style="font-size: 22px;"/>
        </button>
        <h3 style="margin-top:5px; margin-left: 15px;">Archived Projects</h3>
      </div>
      <div class="w-25">
        <input type="text" class="form-control form-control-lg" v-model="projectSearch" placeholder="Search Project..."/>
      </div>
    </div>
    <div style="padding: 0px 25px; padding-top: 25px; position:fixed; top: 140px; bottom: 0; width: 100%; overflow-y:auto;">
      <ul class="list-group">
        <li class="list-group-item" v-for="item in myfilteredList" :key="item.id">
          <div class="d-flex justify-content-between" v-if="item.is_archived">
            <h5 style="margin-bottom: 0px; padding: 5px 0px;">{{item.name}} <small v-if="item.marketing_status == 'Marketing Archived'"><span class="badge badge-warning">Marketing</span></small></h5>
            <button class="btn btn-info" @click="unarchiveProject(item)"><font-awesome-icon icon="archive"/> Unarchive</button>
          </div>
        </li>
      </ul>
      <div class="d-flex justify-content-center p-3">
        <button class="btn btn-primary" v-if="hasMoreItemsToShow" @click="showMoreItems()">Show more</button>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from 'moment'
import ProjectService from '@/services/project.service'
import { includes } from 'lodash'

export default {
  name: 'Archived',
  data () {
    return {
      allItems: [],
      myItems: [],
      finalItems: [],
      pagesShown: 1,
      pageSize: 24,
      projectSearch: '',
      projectTypeOptions: [],
      projectStatus: [],
      clients: [],
      inputForm: {
        modules: []
      },
      projectType: 'All Projects',
      editMode: false,
      editProjectName: false,
      newprojectname: '',
      showModal: false,
      projectTitle: 'Add',
      user: JSON.parse(localStorage.getItem('user'))
    }
  },
  computed: {
    myfilteredList: function () {
      const arr = this.finalItems.filter((item) => {
        if (this.projectType !== 'All Projects') {
          return item.project_status === this.projectType
        } else {
          return item
        }
      }).filter((item) => {
        return item.name.toLowerCase().match(this.projectSearch.toLowerCase())
      })
      return this.pageSize ? arr.slice(0, this.pageSize * this.pagesShown) : arr
    },
    hasMoreItemsToShow: function () {
      const arr = this.finalItems.filter((item) => {
        if (this.projectType !== 'All Projects') {
          return item.project_status === this.projectType
        } else {
          return item
        }
      }).filter((item) => {
        return item.name.toLowerCase().match(this.projectSearch)
      })

      return this.pagesShown < arr.length / this.pageSize
    },
    hasAccess: function () {
      const roles = this.user.roles
      if (includes(roles, 'admin') || includes(roles, 'finance')) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    goBack: function () {
      this.$router.go(-1)
    },
    getArchivedProjects: function () {
      this.busy = true
      ProjectService.getArchivedProjects().then((response) => {
        this.finalItems = response
        console.log(this.finalItems)
      })
    },
    unarchiveProject: function (item) {
      if (item.marketing_status === 'Marketing Archived') {
        item.project_status = 'Marketing'
      } else {
        item.project_status = 'In Progress'
      }
      item.marketing_status = null
      item.is_archived = false
      ProjectService.saveMarketingData(item).then((response) => {
        console.log(response)
      })
    },
    showMoreItems: function () {
      this.pagesShown = this.pagesShown + 1
    }
  },
  mounted () {
    this.getArchivedProjects()
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
/* Rounded sliders */

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 18px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #009370;
}

input:focus+.slider {
    box-shadow: 0 0 1px #009370;
}

input:checked+.slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
