<template>
  <div>
    <div class="d-flex justify-content-between" style="background-color: #f4f5f7; padding: 10px; border-radius: 5px;">
      <h4 style="margin-top: 5px; margin-bottom: 0px;">Registration Info</h4>
      <div class="d-flex justify-content-between">
        <button v-if="!editInfo" class="btn btn-primary" @click="editRegistrationInfo">Edit</button>
        <div v-else class="d-flex justify-content-between">
          <button class="btn btn-success" style="margin-right: 15px;" @click="saveRegistrationInfo">Save</button>
          <button class="btn btn-secondary" @click="cancelRegistrationInfo">Cancel</button>
        </div>
      </div>
    </div>
    <div v-show="showError" style="padding: 15px 15px 0px 15px;">
      <div class="alert alert-danger" role="alert">
        <font-awesome-icon icon="exclamation-triangle" style="font-size: 20px;"></font-awesome-icon><span style="padding-left: 15px;">There are errors prohibiting this registration from being saved!</span>
      </div>
    </div>
    <form style="padding: 0px 15px 15px 15px;" class="needs-validation" novalidate>
      <div class="row" style="padding-top: 15px;">
        <div v-for="item in jsonForm" :key="item.id" v-bind:class="{'col-12': item.columnSize == 12, 'col-6': item.columnSize == 6 }">
          <!---------- Input ---------->
          <div class="form-group" v-if="item.type == 'input'">
            <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
            <input :type="item.inputType" class="form-control" :class="checkFormField(item)" @change="checkItem(item)" :disabled="!editInfo" :placeholder="item.placeholderText" v-model="inputForm[item.vModel]">
            <div class="invalid-feedback">{{item.error}}</div>
          </div>
          <!---------- Textarea ---------->
          <div class="form-group" v-if="item.type == 'textarea'">
            <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
            <textarea cols="30" rows="2" class="form-control" :class="checkFormField(item)" @change="checkItem(item)" :disabled="!editInfo" :placeholder="item.placeholderText" v-model="inputForm[item.vModel]"></textarea>
            <div class="invalid-feedback">{{item.error}}</div>
          </div>
          <!---------- Dropdown ---------->
          <div v-if="item.type == 'select'">
            <div class="form-group">
              <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
              <select class="form-control" v-model="inputForm[item.vModel]" :class="checkFormField(item)" :disabled="!editInfo" @change="checkItem(item)">
                <option value="">{{item.placeholderText}}</option>
                <option v-for="(choice, index) in item.choices" :key="index">
                  {{ choice }}
                </option>
              </select>
              <div class="invalid-feedback">{{item.error}}</div>
            </div>
            <!-- Other -->
            <div v-if="item.other === 'Yes'" class="form-group">
              <textarea :name="['other-']+item.id" :id="['other-']+item.classNme" cols="30" rows="1" class="form-control" v-model="inputForm[item.vModel]"></textarea>
            </div>
          </div>
          <!---------- Checkbox ---------->
          <div v-if="item.type == 'checkbox'">
            <div class="form-group" v-if="item.display === 'standard'">
              <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label> <span class="text-info" v-if="item.limit === 'Yes'"> (Select up to {{item.limitAllowed}})</span>
              <span class="text-info" v-else> (Select all that apply)</span>
              <div class="clearfix"></div>
              <div class="form-check" v-for="(choice, index) in item.choices" :key="index">
                <input class="form-check-input" type="checkbox" :value="choice" v-model="inputForm[item.vModel]" :disabled="!editInfo" :class="checkFormField(item)" @change="checkItem(item)">
                <label class="form-check-label">
                  {{ choice }}
                </label>
                <div v-if="item.choices.length == index + 1" class="invalid-feedback">{{item.error}}</div>
              </div>
            </div>

            <div class="form-group" v-else>
              <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label> <span class="text-info" v-if="item.limit === 'Yes'"> (Select up to {{item.limitAllowed}})</span>
              <div class="clearfix"></div>
              <div class="form-check form-check-inline" v-for="(choice, index) in item.choices" :key="index">
                <input class="form-check-input" type="checkbox" :value="choice" v-model="inputForm[item.vModel]" :disabled="!editInfo" :class="checkFormField(item)" @change="checkItem(item)">
                <label class="form-check-label">
                  {{ choice }}
                </label>
              </div>
              <div style="margin-left: 0px;" class="invalid-feedback">{{item.error}}</div>
            </div>

            <!-- Other -->
            <div v-if="item.other === 'Yes'" class="form-group">
              <textarea :name="['other-']+item.id" :id="['other-']+item.className" cols="30" rows="1" class="form-control" v-model="inputForm[item.vModel]"></textarea>
            </div>
          </div>
          <!---------- Radio ---------->
          <div v-if="item.type == 'radio'">
            <div class="form-group" v-if="item.display === 'standard'">
              <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
              <div class="clearfix"></div>
              <div class="form-check" v-for="(choice, index) in item.choices" :key="index">
                <input class="form-check-input" type="radio" :value="choice" v-model="inputForm[item.vModel]" :disabled="!editInfo" :class="checkFormField(item)" @change="checkItem(item)">
                <label class="form-check-label">
                  {{ choice }}
                </label>
                <div v-if="item.choices.length == index + 1" class="invalid-feedback">{{item.error}}</div>
              </div>
            </div>

            <div class="form-group" v-else>
              <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
              <div class="clearfix"></div>
              <div class="form-check form-check-inline" v-for="(choice, index) in item.choices" :key="index">
                <input class="form-check-input" type="radio" :value="choice" v-model="inputForm[item.vModel]" :disabled="!editInfo" :class="checkFormField(item)" @change="checkItem(item)">
                <label class="form-check-label">
                  {{ choice }}
                </label>
                <div v-if="item.choices.length == index + 1" class="invalid-feedback">{{item.error}}</div>
              </div>
            </div>

            <!-- Other -->
            <div v-if="item.other === 'Yes'" class="form-group">
              <textarea :name="['other-']+item.id" :id="['other-']+item.className" cols="30" rows="1" class="form-control" v-model="inputForm[item.vModel]"></textarea>
            </div>
          </div>
          <!---------- Switch ---------->
          <div v-if="item.type == 'switch'">
            <div class="form-group">
              <label>{{item.labelText}}</label><br />
              <label class="switch">
                <input type="checkbox" v-model="inputForm[item.vModel]" :disabled="!editInfo">
                <div class="slider round"></div>
              </label>
            </div>

          </div>
          <!---------- Date ---------->
          <div v-if="item.type == 'date'">
            <div class="form-group">
              <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
              <b-form-datepicker today-button reset-button close-button locale="en" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" :bootstrap-styling="true" :clear-button="true" :disabled="!editInfo" v-model="inputForm[item.vModel]" :class="checkFormField(item)" @input="checkItem(item)" :placeholder="item.placeholderText"></b-form-datepicker>
              <div class="invalid-feedback">{{item.error}}</div>
            </div>
          </div>
          <!---------- Time ---------->
          <div v-if="item.type == 'time'">
            <div class="form-group">
              <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label><br>
              <b-form-timepicker format="hh:mm A" input-width="100%" :disabled="!editInfo" v-model="inputForm[item.vModel]" :class="checkFormField(item)" @input="checkItem(item)" :placeholder="item.placeholderText" now-button reset-button></b-form-timepicker>
              <div class="invalid-feedback">{{item.error}}</div>
            </div>
          </div>
          <!---------- Clearfix ---------->
          <div v-if="item.type == 'clearfix'">
            <div class="clearfix"></div>
          </div>

          <!---------- US Address ---------->
          <div v-if="item.type == 'us-address'">
            <div class="row">
              <div class="form-group col-6" v-for="(item, index) in item.customFields" :key="index">
                <span class="text-danger" v-show="item.required">* </span><label>{{item.name}}</label>
                <select v-if="item.name == 'State'" class="form-control" :class="checkFormField(item)" :disabled="!editInfo" @change="checkItem(item)" v-model="inputForm[item.vModel]">
                  <option value="">Select {{item.name}}</option>
                  <option v-for="(choice, index) in item.values" :key="index">
                    {{ choice }}
                  </option>
                </select>
                <input v-else :type="item.inputType" class="form-control" :class="checkFormField(item)" :disabled="!editInfo" @change="checkItem(item)" :placeholder="item.name" v-model="inputForm[item.vModel]">
                <div class="invalid-feedback">{{item.error}}</div>
              </div>
            </div>
          </div>

          <!---------- General, Hotel, Flights, Meals ---------->
          <div v-if="item.type == 'general' || item.type == 'hotel' || item.type == 'flights' || item.type == 'meals'">
            <div class="row py-3">
              <div class="col-12">
                <h5>{{titleCase(item.type)}}</h5>
              </div>
            </div>
            <div class="row">
              <div class="form-group" v-bind:class="{'col-12': item.columnSize == 'col-12', 'col-6': item.columnSize == 'col-6'}" v-for="(item, index) in item.customFields" :key="index">
                <span class="text-danger" v-show="item.required">* </span><label>{{item.name}}</label>
                <div v-if="item.inputType == 'radio'" >
                  <div class="form-check form-check-inline" v-for="(choice, index) in item.choices" :key="index">
                    <input class="form-check-input" type="radio" :value="choice" v-model="inputForm[item.vModel]" :disabled="!editInfo" :class="checkFormField(item)" @change="checkItem(item)">
                    <label class="form-check-label">
                      {{ choice }}
                    </label>
                  </div>
                </div>
                <input v-else-if="item.inputType == 'birth-date'" type="text" v-mask="'##/##/####'" class="form-control" :disabled="!editInfo" v-model="inputForm[item.vModel]" :class="checkFormField(item)" @change="checkItem(item)" :placeholder="item.placeholderText">
                <!-- <datepicker v-else-if="item.inputType == 'date'" format="MM/dd/yyyy" :bootstrap-styling="true" :clear-button="true" :disabled="!editInfo" v-model="inputForm[item.vModel]" :class="checkFormField(item)" @change="checkItem(item)" :placeholder="item.placeholderText"></datepicker> -->
                <b-form-datepicker v-else-if="item.inputType == 'date'" today-button reset-button close-button locale="en" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" :bootstrap-styling="true" :clear-button="true" :disabled="!editInfo" v-model="inputForm[item.vModel]" :class="checkFormField(item)" @input="checkItem(item)" :placeholder="item.placeholderText"></b-form-datepicker>
                <!-- <vue-timepicker v-else-if="item.inputType == 'time'" format="hh:mm A" input-class="form-control time-background" input-width="100%" :disabled="!editInfo" v-model="inputForm[item.vModel]" :class="checkFormField(item)" @change="checkItem(item)" :placeholder="item.placeholderText"></vue-timepicker> -->
                <b-form-timepicker v-else-if="item.inputType == 'time'" format="hh:mm A" input-width="100%" :disabled="!editInfo" v-model="inputForm[item.vModel]" :class="checkFormField(item)" @input="checkItem(item)" :placeholder="item.placeholderText" now-button reset-button></b-form-timepicker>
                <textarea v-else-if="item.inputType == 'textarea'" cols="30" rows="2" class="form-control" :class="checkFormField(item)" :disabled="!editInfo" v-model="inputForm[item.vModel]" @change="checkItem(item)" :placeholder="item.placeholderText"></textarea>
                <input v-else-if="item.clearfix == true" :type="item.inputType" class="form-control w-50-clearfix" :disabled="!editInfo" :class="checkFormField(item)" @change="checkItem(item)" v-model="inputForm[item.vModel]" :placeholder="item.name">
                <input v-else :type="item.inputType" class="form-control" :class="checkFormField(item)" :disabled="!editInfo" @change="checkItem(item)" v-model="inputForm[item.vModel]" :placeholder="item.name">
                <div class="invalid-feedback">{{item.error}}</div>
              </div>
            </div>
          </div>

          <!---------- Contact ---------->
          <div v-if="item.type == 'contact'">
            <div class="row py-3">
              <div class="col-12">
                <h5>{{titleCase(item.type)}}</h5>
              </div>
            </div>
            <div class="row">
              <div class="form-group" v-bind:class="{'col-12': item.columnSize == 'col-12', 'col-6': item.columnSize == 'col-6'}" v-for="(item, index) in item.customFields" :key="index">
                <span class="text-danger" v-show="item.required">* </span><label>{{item.name}}</label>
                <input v-if="item.inputType == 'tel'" :type="item.inputType" v-mask="'+1 (###) ###-####'" class="form-control" :disabled="!editInfo" :class="checkFormField(item)" @change="checkItem(item)" :placeholder="item.name" v-model="inputForm[item.vModel]">
                <input v-else :type="item.inputType" class="form-control" :class="checkFormField(item)" @change="checkItem(item)" :disabled="!editInfo" :placeholder="item.name" v-model="inputForm[item.vModel]">
                <div class="invalid-feedback">{{item.error}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import eventService from '@/services/event.service'
// import Datepicker from 'vuejs-datepicker'
// import VueTimepicker from 'vue2-timepicker'
import moment from 'moment'
import { forEach } from 'lodash'
// import eventService from '@/services/event.service'

export default {
  name: 'GeneratedFormEdit',
  components: {
    // Datepicker,
    // VueTimepicker
  },
  props: ['registrantId'],
  data () {
    return {
      jsonForm: {},
      inputForm: {},
      valid: false,
      inputBlured: false,
      formChecked: false,
      formArray: [],
      editInfo: false,
      registrantInfo: {},
      birth_date: '',
      showError: false
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    titleCase: function (str) {
      str = str.toLowerCase().split(' ')
      for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
      }
      return str.join(' ')
    },
    editRegistrationInfo: function () {
      this.editInfo = true
    },
    customDateFormatter: function (date) {
      return moment(date).format('MM/DD/YYYY')
    },
    saveRegistrationInfo: function () {
      this.validate()
      if (this.valid) {
        console.log(this.inputForm)
        this.registrantInfo.reg_form = JSON.stringify(this.inputForm)
        this.registrantInfo.reg_date = moment(this.registrantInfo.reg_date).format('YYYY-MM-DD')
        eventService.saveRegistrant(this.registrantInfo).then((response) => {
          this.editInfo = false
        })
      }
    },
    isDate: function (date) {
      const _regExp = new RegExp('^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$')
      return _regExp.test(date)
    },
    cancelRegistrationInfo: function () {
      this.validate()
      if (this.valid) {
        this.editInfo = false
      }
    },
    validate: function () {
      console.log(this.formArray)
      this.inputBlured = true
      console.log('Validate', this.formArray)
      if (this.formArray.length === 0) {
        this.valid = true
        this.showError = false
      } else {
        this.valid = false
        this.showError = true
      }
    },
    checkFormField: function (item) {
      if ((this.inputForm[item.vModel] === '' || this.inputForm[item.vModel].length === 0) && item.required) {
        return {
          'is-invalid': (this.inputForm[item.vModel] === '' || this.inputForm[item.vModel].length === 0) && this.inputBlured && item.required
        }
      } else {
        return {
          'is-valid': (this.inputForm[item.vModel] !== '' || this.inputForm[item.vModel].length !== 0) && this.editInfo !== false
        }
      }
    },
    checkItem: function (item) {
      console.log(this.inputForm[item.vModel])
      let value = this.inputForm[item.vModel]
      if (value === '') {
        this.formArray.push(item.vModel)
      } else if ((this.inputForm[item.vModel] !== '' || this.inputForm[item.vModel] !== 0) && item.required) {
        const index = this.formArray.indexOf(item.vModel)
        this.formArray.splice(index, 1)
      }
      console.log('Check', this.formArray)
    },
    maskData: function (str) {
      return str.split('').map((e, i) => i ? '*' : e).join('')
    },
    getData: function () {
      eventService.getRegistrant(this.registrantId).then((response) => {
        console.log(response)
        this.registrantInfo = response
        this.inputForm = response.reg_form
        this.jsonForm = response.form_builder
        const items = response.form_builder
        const requiredFields = []
        // const reg = response.reg_form
        forEach(this.inputForm, (value, key) => {
          forEach(items, field => {
            if (field.customFields) {
              field.customFields.forEach(subfield => {
                if (subfield.required && subfield.vModel === key) {
                  if (value === '') {
                    requiredFields.push(key)
                  }
                }
              })
            } else {
              if (field.vModel) {
                if (field.required && field.vModel === key) {
                  if (value === '') {
                    requiredFields.push(key)
                  }
                }
              }
            }
          })
        })
        this.formArray = requiredFields
        console.log(this.formArray)
        // const data = {}
      //   forEach(items, field => {
      //     if (field.customFields) {
      //       field.customFields.forEach(subfield => {
      //         if (subfield.type === 'checkbox') {
      //           data[subfield.vModel] = []
      //         } else {
      //           data[subfield.vModel] = ''
      //         }
      //         if (subfield.required) {
      //           this.formArray.push(subfield.vModel)
      //         }
      //       })
      //     } else {
      //       if (field.vModel) {
      //         if (field.type === 'checkbox') {
      //           data[field.vModel] = []
      //         } else {
      //           data[field.vModel] = ''
      //         }
      //         if (field.required) {
      //           this.formArray.push(field.vModel)
      //         }
      //       }
      //     }
      //   })
      })
    }
  },
  created () {
    this.getData()
  }
}
</script>
<style scoped>
.w-50-clearfix {
  width: calc(50% - 15px) !important;
}
/*--------------------------------------------------
  Switch
--------------------------------------------------*/
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 18px;
}

.switch input { display: none }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #009370;
}

input:focus+.slider {
  box-shadow: 0 0 1px #009370;
}

input:checked+.slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

.slider.round { border-radius: 34px }

.slider.round:before { border-radius: 50% }

/* input[type="text"], textarea {
outline: none;
box-shadow:none !important;
border:1px solid #ccc;
} */

.remove-border {
  border-left: 0px !important;
}
.input-group-prepend {
  padding: 10px;
  background-color: #fff;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
</style>
