<template>
  <div>
    <!-- Start of Header -->
    <div style="background: #fff; position: fixed; z-index: 50; padding: 20px; width: 100%; text-align:center;  box-shadow:rgba(0, 0, 0, 0.2) 2px 4px 20px;">
      <div class="cleafix float-left" style="padding-top: 10px; padding-left: 10px; text-align:left;">
        <div @click="$router.go(-1)" style="padding-top:5px; padding-left: 10px; float: left; text-align:left; padding-right:25px; cursor:pointer;">
            <span class="fa fa-chevron-left" style="font-size:20px;"></span>
            <span style="font-size: 20px; padding-left: 5px;">Back</span>
        </div>
        <div style="padding-left: 10px; float: left; text-align:left;">
          <h3 style="margin-top:0px;">
            Mobile Apps
          </h3>
        </div>
      </div>
      <div class="float-right" style="padding-top: 5px; padding-right: 10px; text-align:right;">
          <div class="btn-group">
              <a class="btn btn-primary btn" style="text-transform: uppercase; font-size: 14px; font-weight: bold; padding: 10px 15px; color:#fff;"
              data-toggle="modal" data-target="#userModal" data-backdrop="static" data-keyboard="false"><span class="fa fa-plus"></span> Add Mobile App</a>
          </div>
      </div>
    </div> <!-- End of Header -->
  </div>
</template>

<script>
// import SettingService from '@/services/setting.service'
// import AppCard from '@/components/AppCard.vue'
export default {
  name: 'app-name',
  // components: {
  //   'AppCard': AppCard
  // },
  data () {
    return {
    }
  },
  methods: { },
  mounted () { }
}
</script>

<style scoped>
/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 54px;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  bottom: 0;
  height: 100%;
  padding-top: 0px;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
  padding: 0px 16px;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #fff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

</style>
