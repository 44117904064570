<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-dialog" role="document" style="max-width: 750px;">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: 0px !important;">
            <h5 class="modal-title">{{title}}</h5>
            <button class="close" v-on:click="close">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body" style="padding: 0px;">
            <form novalidate>
              <div class="row" style="padding-left: 15px; padding-right: 15px;">
                <div class="col-6 form-group">
                  <label>Client</label>
                  <input type="text" v-model="inputForm.client" class="form-control" placeholder="Client" disabled>
                </div>
              </div>
              <div class="row" style="padding-left: 15px; padding-right: 15px;">
                <div class="col form-group">
                  <label>First Name</label>
                  <input type="text" v-model="inputForm.first_name" class="form-control" placeholder="First Name">
                </div>
                <div class="col form-group">
                  <label>Last Name</label>
                  <input type="text" v-model="inputForm.last_name" class="form-control" placeholder="Last Name">
                </div>
              </div>
              <div class="row" style="padding-left: 15px; padding-right: 15px;">
                <div class="col form-group">
                  <label>Email</label>
                  <input type="email" v-model="inputForm.email" class="form-control" placeholder="Email">
                </div>
                <div class="col form-group">
                  <label>Phone</label>
                  <input type="text" v-model="inputForm.phone" class="form-control" placeholder="Phone">
                </div>
              </div>
              <div v-if="hasAccess().includes('dev-admin')">
                <div class="row" style="padding-left: 15px; padding-right: 15px;">
                  <div class="col form-group">
                    <label>3ConneX Project ID (Profiles)</label>
                    <input type="email" v-model="inputForm.project_id" class="form-control" placeholder="Project ID">
                  </div>
                  <div class="col form-group">
                    <label>Access Type</label>
                    <input type="text" v-model="inputForm.access_type" class="form-control" placeholder="Access Type">
                  </div>
                </div>
                <div class="row" style="padding-left: 15px; padding-right: 15px;">
                  <div class="col form-group">
                    <label>3ConneX Modules</label>
                    <div class="row">
                      <div class="col">
                        <label for="ee">Experts</label><br />
                        <label class="switch">
                            <input type="checkbox" value="Experts" id="ee" v-model="experts">
                            <div class="slider round"></div>
                        </label>
                      </div>
                      <div class="col">
                        <label for="heatmap">Heatmap</label><br />
                        <label class="switch">
                            <input type="checkbox" value="Heatmap" id="heatmap" v-model="heatmap">
                            <div class="slider round"></div>
                        </label>
                      </div>
                      <div class="col">
                        <label for="congress">Congress</label><br />
                        <label class="switch">
                          <input type="checkbox" value="Congress" id="congress" v-model="congress">
                          <div class="slider round"></div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" style="padding-left: 15px; padding-right: 15px;">
                  <div class="col form-group">
                    <label>Has Cross Brand Access?</label><br />
                    <label class="switch">
                        <input type="checkbox" id="cb" v-model="inputForm.has_cross_brands_access">
                        <div class="slider round"></div>
                    </label>
                  </div>
                </div>
                <div v-if="inputForm.has_cross_brands_access && inputForm.cross_brands">
                  <b-form-group style="padding-left: 15px;"
                    label="Which Brands should they have access to?"
                    v-slot="{ ariaDescribedby }"
                  >
                    <b-form-checkbox-group
                      v-model="inputForm.cross_brands"
                      :options="crossbrands"
                      :aria-describedby="ariaDescribedby"
                      switches
                      stacked
                    ></b-form-checkbox-group>
                  </b-form-group>
                </div>
              </div>
            </form>
            <div class="p-3 d-flex justify-content-end">
              <div class="p-1 d-flex">
                <button class="btn btn-primary mr-3" @click="saveUser">Save</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import SettingService from '@/services/setting.service'
export default {
  name: 'ClientModal',
  props: ['title', 'client', 'user'],
  data () {
    return {
      inputForm: {
        first_name: null,
        last_name: null,
        client: null,
        email: null,
        phone: null,
        access_type: null,
        project_id: null,
        connex_modules: [],
        is_active: true,
        has_cross_brands_access: false,
        cross_brands: []
      },
      congress: false,
      heatmap: false,
      experts: false,
      crossbrands: []
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    saveUser: function () {
      this.inputForm.connex_modules = []
      if (this.experts) {
        this.inputForm.connex_modules.push('Experts')
      }
      if (this.heatmap) {
        this.inputForm.connex_modules.push('Heatmap')
      }
      if (this.congress) {
        this.inputForm.connex_modules.push('Congress')
      }

      console.log(this.inputForm)
      if (!this.inputForm.phone) {
        this.inputForm.phone = null
      }

      if (!this.inputForm.has_cross_brands_access) {
        this.inputForm.has_cross_brands_access = false
      }

      if (!this.inputForm.cross_brands) {
        this.inputForm.cross_brands = null
      }

      if (!this.inputForm.project_id) {
        this.inputForm.project_id = null
      }
      if (!this.inputForm.access_type) {
        this.inputForm.access_type = null
      }
      this.inputForm.is_active = true
      // console.log(this.inputForm)
      SettingService.saveClient(this.inputForm).then((response) => {
        console.log(response)
        if (response.id) {
          this.inputForm.id = response.id
          this.inputForm.type = 'add'
        } else {
          this.inputForm.type = 'edit'
        }
        this.inputForm.name = this.inputForm.first_name + ' ' + this.inputForm.last_name
        this.$emit('showUsers', this.inputForm)
      })
    },
    hasAccess: function () {
      // console.log(JSON.parse(localStorage.getItem('user')).roles)
      return JSON.parse(localStorage.getItem('user')).roles
    },
    getCrossBrands: function() {
      SettingService.getCrossBrands(this.client).then((res) => {
        console.log(res)
        this.crossbrands = res
      })
    }
  },
  mounted () {
    this.getCrossBrands()
    if (this.user.type === 'edit') {
      console.log(this.user)
      this.inputForm = this.user
      if(this.inputForm.connex_modules !== null) {
        if (this.inputForm.connex_modules.includes('Experts')) {
          this.experts = true
        }

        if (this.inputForm.connex_modules.includes('Heatmap')) {
          this.heatmap = true
        }

        if (this.inputForm.connex_modules.includes('Congress')) {
          this.congress = true
        }
      }
      
    } else {
      this.inputForm = {}
      this.inputForm.client = this.client
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>

.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.document-editor {
    border: 1px solid var(--ck-color-base-border);
    border-radius: var(--ck-border-radius);
}

.close:focus {
  outline: none;
}

/* Rounded sliders */

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 18px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #009370;
}

input:focus+.slider {
    box-shadow: 0 0 1px #009370;
}

input:checked+.slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
