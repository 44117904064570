<template>
  <div>
    <div style="background-color:#006AB6; padding: 10px 15px; color: #fff;">
      <h4 style="margin-bottom: 0px;">Field Settings</h4>
    </div>
    <div style="padding: 15px;">
      <div class="text-info" style="padding-bottom: 25px;">
        <h4>{{item.labelText}}</h4>
      </div>
      <!---------- Column Width ---------->
      <div class="edit-options" v-if="item.type !== 'button' && item.type !== 'us-address' && item.type !== 'contact' && item.type !== 'image' && item.type !== 'word' && item.type !== 'general' && item.type !== 'hotel' && item.type !== 'flights' && item.type !== 'meals'">
        <div class="form-group">
          <label style="font-weight:500;">Column Size</label>
          <div class="btn-group btn-group-toggle" style="width: 100%;">
            <label class="btn btn-outline-primary" :class="{ 'active': item.columnSize == 6}">
              <input type="radio" value="6" v-model="item.columnSize"> Medium
            </label>
            <label class="btn btn-outline-primary" :class="{ 'active': item.columnSize == 12}">
              <input type="radio" value="12" v-model="item.columnSize"> Large
            </label>
          </div>
        </div>
      </div>
      <!---------- Image Width ---------->
      <div class="edit-options" v-if="item.type === 'image'">
        <div class="form-group">
          <label style="font-weight:500;">Image Width</label>
          <div class="btn-group btn-group-toggle" style="width: 100%;">
            <label class="btn btn-outline-primary" :class="{ 'active': item.imageWidth == ''}">
              <input type="radio" value="" v-model="item.imageWidth"> Original width
            </label>
            <label class="btn btn-outline-primary" :class="{ 'active': item.imageWidth == '100%'}">
              <input type="radio" value="100%" v-model="item.imageWidth"> 100%
            </label>
          </div>
        </div>
      </div>
      <!---------- Label ---------->
      <div class="edit-options" v-if="item.type !== 'us-address' && item.type !== 'contact' && item.type !== 'image' && item.type !== 'word' && item.type !== 'general' && item.type !== 'hotel' && item.type !== 'flights' && item.type !== 'meals'">
        <div class="form-group" v-if="item.type !== 'button'">
          <label class="text-danger" style="font-weight:500;">Label</label>
          <input class="form-control" :placeholder="item.labelText" v-model="item.labelText"/>
        </div>
        <!-- <div v-if="item.type !== 'button' && item.type !== 'switch'" class="form-group" style="padding-bottom: 15px">
          <div class="row">
            <div class="col-md-6">Enable label</div>
            <div class="col-md-6">
              <label class="switch">
                <input type="checkbox" v-model="item.label">
                <div class="slider round"></div>
              </label>
            </div>
          </div>
        </div> -->
      </div>
      <!---------- Input Type ---------->
      <div class="edit-options" v-if="item.type === 'input'">
        <div class="form-group">
          <label style="font-weight:500;">Input Type</label>
          <select class="form-control" v-model="item.inputType">
            <option value="">Select Input Type...</option>
            <option value="text">Text</option>
            <option value="email">E-mail</option>
          </select>
        </div>
      </div>
      <!---------- Placeholder ---------->

      <div class="edit-options" v-if="item.type === 'input' || item.type === 'textarea' || item.type === 'select' || item.type === 'date' || item.type === 'time'">
        <hr>
        <div class="d-flex justify-content-between">
            <label style="font-weight: 500;">Enable placeholder</label>
            <div class="form-group" style="margin-bottom: 0px; margin-top: 5px;">
              <label class="switch">
                <input type="checkbox" v-model="item.placeholder">
                <div class="slider round"></div>
              </label>
            </div>
        </div>
        <div class="form-group">
          <transition name="fade">
            <div v-if="item.placeholder">
              <label style="font-weight: 500;">Placeholder Text</label>
              <input class="form-control" v-model="item.placeholderText"/>
            </div>
          </transition>
        </div>
        <hr>
      </div>
      <!---------- Required ---------->
      <div class="edit-options" v-if="item.type !== 'button' && item.type !== 'us-address' && item.type !== 'contact' && item.type !== 'image' && item.type !== 'word' && item.type !== 'switch' && item.type !== 'general' && item.type !== 'hotel' && item.type !== 'flights' && item.type !== 'meals'">
        <div class="d-flex justify-content-between">
          <label style="font-weight: 500;">Is field required?</label>
          <div class="form-group" style="margin-bottom: 0px;">
            <label class="switch">
              <input type="checkbox" v-model="item.required">
              <div class="slider round"></div>
            </label>
          </div>
        </div>
      </div>
      <div class="edit-options" v-if="item.customFields">
        <label style="font-weight: 500;">Which fields are required?</label>
        <div class="d-flex justify-content-between" v-for="(value, index) in item.customFields" :key="index">
          <label>{{value.name}}</label>
          <div class="form-group" style="margin-bottom: 0px;">
            <label class="switch">
              <input type="checkbox" v-model="value.required">
              <div class="slider round"></div>
            </label>
          </div>
        </div>
        <hr>
      </div>
      <!---------- Label Text Change ----------->
      <div class="edit-options" v-if="item.type === 'meals'">
        <div class="d-flex justify-content-between">
          <div class="form-group" style="margin-bottom: 0px;">
            <label style="font-weight: 500;">Change XXX to day</label>
            <select class="form-control" v-model="xxxToDay" @change="changeText(item)">
              <option value="">Select day...</option>
              <option value="Sunday">Sunday</option>
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednesday">Wednesday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
              <option value="Saturday">Saturday</option>
            </select>
          </div>
        </div>
      </div>
      <!---------- Name and ID Attributes ---------->
      <div class="edit-options" v-if="item.type !== 'us-address' && item.type !== 'button' && item.type !== 'contact' && item.type !== 'image' && item.type !== 'word' && item.type !== 'general' && item.type !== 'hotel' && item.type !== 'flights' && item.type !== 'meals'">
        <div class="form-group">
          <label class="text-danger" style="font-weight: 500;">Excel Column Name<br><small class="text-info">Used to capture value<br>Maximum 3 words</small></label>
          <input class="form-control" v-model="item.classAttr" id="newAttr" data-max-words="3" @blur="doneAttribute()"/>
        </div>
        <div class="form-group">
          <!-- <label style="font-weight: 500;">Attribute Name</label> -->
          <input hidden class="form-control" v-model="item.className" disabled/>
        </div>
        <div class="form-group">
          <!-- <label style="font-weight: 500;">Attribute ID</label> -->
          <input hidden class="form-control" v-model="item.classId" disabled/>
        </div>
      </div>
      <!---------- Max Length for Single Input and Textarea ---------->
      <div class="edit-options" v-if="item.type === 'input' || item.type === 'textarea'" style="padding-bottom: 15px">
        <div class="form-group">
          <label style="font-weight: 500;">Max Length</label>
          <input type="text" class="form-control" v-model="item.maxLength">
        </div>
      </div>
      <!---------- Display options for Checkbox and Radio ---------->
      <div class="edit-options" v-if="item.type === 'checkbox' || item.type === 'radio'">
        <div class="form-group">
          <label style="font-weight: 500;">Display</label>
          <div class="btn-group btn-group-toggle" style="width: 100%;">
            <label class="btn btn-outline-primary" :class="{ 'active': item.display == 'standard'}">
              <input type="radio" value="standard" v-model="item.display"> Standard
            </label>
            <label class="btn btn-outline-primary" :class="{ 'active': item.display == 'inline'}">
              <input type="radio" value="inline" v-model="item.display"> Inline
            </label>
          </div>
        </div>
      </div>
      <!---------- Choices for Dropdown, Checkbox, and Radio ---------->
      <div class="edit-options" v-if="item.type === 'checkbox' || item.type === 'radio'|| item.type === 'select'">
        <div class="form-group" style="padding-bottom: 10px; margin-bottom: 0px;">
          <label style="font-weight: 500;">Choices</label>
          <div class="d-flex justify-content-between" style="margin-bottom: 10px;" >
            <input type="text" class="form-control" v-model="newChoice">
            <button class="btn text-primary" @click.prevent="addChoice(newChoice)" style="font-size: 25px; margin-top: -15px;">&#x2b;</button>
          </div>
          <div class="d-flex justify-content-between" style="margin-bottom: 10px;" v-for="(choice, index) in item.choices" :key="index">
            <input type="text" class="form-control" v-model="item.choices[index]">
            <button class="btn text-danger" @click.prevent="deleteChoice(index)" style="font-size: 18px; margin-top: -5px;" v-show="choice !== 'Other'">&#x2715;</button>
          </div>
        </div>
        <!-- Limit Allowed Selection for Checkbox -->
        <div class="form-group" v-if="item.type === 'checkbox'">
          <label style="font-weight: 500;">Would you like to limit the number of selections?</label>
          <div class="btn-group btn-group-toggle" style="width: 100%;">
            <label class="btn btn-outline-primary" :class="{ 'active': item.limit == 'Yes'}">
              <input type="radio" value="Yes" v-model="item.limit"> Yes
            </label>
            <label class="btn btn-outline-primary" :class="{ 'active': item.limit == 'No'}">
              <input type="radio" value="No" v-model="item.limit" @click="removeLimitAllowed"> No
            </label>
          </div>

          <transition name="fade">
            <div style="padding-top: 15px" v-if="item.limit === 'Yes'">
              <label style="font-weight: 500;">Enter max selections allowed:</label>
              <input class="form-control" v-model="item.limitAllowed" style="width: 25%" />
            </div>
          </transition>
        </div>
      </div>
      <!---------- Other Option ---------->
      <div class="edit-options" v-if="item.type === 'checkbox' || item.type === 'radio'|| item.type === 'select'">
        <div class="form-group">
          <label style="font-weight: 500;">Would you like to add an 'Other' option?</label>
          <div class="btn-group btn-group-toggle" style="width: 100%;">
            <label class="btn btn-outline-primary" :class="{ 'active': item.other == 'Yes'}">
              <input type="radio" value="Yes" v-model="item.other" @click="addOther(item.other)"> Yes
            </label>
            <label class="btn btn-outline-primary" :class="{ 'active': item.other == 'No'}">
              <input type="radio" value="No" v-model="item.other" @click="removeOther"> No
            </label>
          </div>
        </div>
      </div>
      <!---------- Button ---------->
      <div v-if="item.type === 'button'" class="edit-options" style="padding-bottom: 15px">
        <div class="form-group">
          <label>Button Text</label>
          <input class="form-control" v-model="item.buttonText" />
        </div>
        <div class="form-group">
          <label style="margin-right: 10px">Button Size</label>

          <div class="btn-group btn-group-toggle" style="width: 100%;">
            <label class="btn btn-outline-primary" :class="{ 'active': item.buttonSize == 'sm'}">
              <input type="radio" value="sm" v-model="item.buttonSize"> Small
            </label>
            <label class="btn btn-outline-primary" :class="{ 'active': item.buttonSize == 'md'}">
              <input type="radio" value="md" v-model="item.buttonSize"> Medium
            </label>
            <label class="btn btn-outline-primary" :class="{ 'active': item.buttonSize == 'lg'}">
              <input type="radio" value="lg" v-model="item.buttonSize"> Large
            </label>
          </div>
        </div>
        <div class="form-group">
          <label>Button Color</label>
            <select class="form-control" v-model="item.buttonType">
              <option value="">Select button color...</option>
              <option value="default">No Color</option>
              <option value="primary">Blue</option>
              <option value="success">Green</option>
              <option value="danger">Red</option>
              <option value="warning">Orange</option>
              <option value="info">Light Blue</option>
            </select>
        </div>
      </div>

      <!---------- Image ---------->
      <div v-if="item.type === 'image'" class="edit-options" style="padding-bottom: 15px">
        <div class="my-8">
          <div v-if="item.imageFile != '468x60'" class="d-flex justify-content-end" style="margin-top: -10px; margin-right: -10px; font-size: 20px; cursor:pointer;">
            <font-awesome-icon icon="times" class="text-danger" @click="removeHeaderImage(item)"></font-awesome-icon>
          </div>
          <img :src="item.imageSource" width="280"/>
          <label for="file" style="cursor: pointer; margin-top: 15px;">
            <figure>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
              >
                <path
                  class="path1"
                  d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                ></path>
              </svg>
            </figure>
            <span class="upload-caption">{{hasImage ? "Replace" : "Click to upload"}}</span>
          </label>
          <input type="file" id="file" ref="file" @change="uploadFile()"/>
        </div>
        <div class="form-group">
          <label>Alt Text</label>
          <input class="form-control" v-model="item.imageAltText" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { forEach } from 'lodash'
export default {
  props: ['item'],
  name: 'FormItemEdit',
  data () {
    return {
      newChoice: '',
      newAttr: this.item.newAttr,
      hasImage: false,
      headerImage: '',
      xxxToDay: ''
    }
  },
  watch: {
    newAttr: function () {
      document.querySelectorAll('input[data-max-words]').forEach(input => {
        // Remember the word limit for the current input
        const maxWords = parseInt(input.getAttribute('data-max-words') || 0)
        // Add an eventlistener to test for key inputs
        input.addEventListener('keydown', e => {
          const target = e.currentTarget
          // Split the text in the input and get the current number of words
          const words = target.value.split(/\s+/).length
          // If the word count is > than the max amount and a space is pressed
          // Don't allow for the space to be inserted
          if (!target.getAttribute('data-announce')) {
          // Note: this is a shorthand if statement
          // If the first two tests fail allow the key to be inserted
          // Otherwise we prevent the default from happening
            words >= maxWords && e.keyCode === 32 && e.preventDefault()
          }
          // else
          // words >= maxWords && e.keyCode == 32 && (e.preventDefault() || alert('Word Limit Reached'))
        })
      })
    }
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.focus()
      }
    }
  },
  methods: {
    doneAttribute () {
      // Get temporary Attribute Name/ID
      let str = this.item.classAttr
      // Make lowercase and place spaces with underscores
      str = str.replace(/\s+/g, '_').toLowerCase()
      // Assign Attribute Name/ID to data
      // this.item.classAttr = this.newAttr
      this.item.className = str
      this.item.classId = str
      this.item.vModel = str
      // Clear temporary Attribute
      // this.newAttr = ''
    },
    deleteChoice (index) {
      this.item.choices.splice(index, 1)
    },
    addChoice (val) {
      this.item.choices.push(val)
      this.newChoice = ''
    },
    addOther (val) {
      this.item.choices.push('Other')
    },
    removeOther () {
      this.item.choices.pop()
    },
    removeLimitAllowed () {
      this.item.limitAllowed = ''
    },
    requireAllFields (items) {
      items.forEach(function (item, i) {
        item.required = !item.required
      })
    },
    changeText (items) {
      console.log(items)
      forEach(items.customFields, (item) => {
        if (item.inputType === 'radio') {
          item.name = `Will you be attending ${this.xxxToDay} night dinner?`
        }
      })
    },
    uploadFile: function () {
      const item = this.item
      const file = this.$refs.file.files[0]
      if (file) {
        this.hasImage = !this.hasImage
      }
      const formData = new FormData()
      formData.append('file', file)

      axios.post('https://assets.simpsonhealthcare.com/events/upload-header.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function (response) {
        console.log(response)
        const res = response.data
        item.imageSource = res.imageSource
        item.imageFile = res.imageFile
        console.log(item)
        console.log('SUCCESS!!')
      }).catch(function () {
        console.log('FAILURE!!')
      })
      this.item = item
    },
    removeHeaderImage: function (image) {
      const item = this.item
      const formData = new FormData()
      formData.append('file', `images/${image.imageFile}`)
      axios.post('https://assets.simpsonhealthcare.com/events/delete-images.php', formData).then(function (response) {
        item.imageFile = '468x60'
        item.imageSource = 'https://via.placeholder.com/600x130?text=Header+Image'
        item.imageAltText = ''
      }).catch(function () {
        console.log('FAILURE!!')
      })
    }
  }
}
</script>
<style>
/* Image Uploaded */
#fileInput {
  display: none;
}

.img-preview {
  width: 280px;
}
</style>
<style scoped>
/* image Uploaded */
.my-8>input {
  display: none;
}
.my-8 {
  text-align:center;
  border: 1px dashed #ccc;
  padding: 15px;
  margin-bottom: 25px;
}
/*--------------------------------------------------
  Switch
--------------------------------------------------*/
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 18px;
}

.switch input { display: none }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #009370;
}

input:focus+.slider {
  box-shadow: 0 0 1px #009370;
}

input:checked+.slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

.slider.round { border-radius: 34px }

.slider.round:before { border-radius: 50% }

</style>
