A<template>
  <div></div>
</template>

<script>
// import { mapActions } from 'vuex'
import AuthService from '@/services/auth.service'
export default {
  name: 'aadcallback',
  data () {
    return {
      user: {}
    }
  },
  methods: {
    // ...mapActions('auth', ['login', 'goToRoute'])
    manageCallback: function () {
      if (!this.user) {
        console.log('Something went wrong with login!')
      }
    }
  },
  mounted () {
    // if (this.$route.params.id) {
    //   console.log(this.$route.params.id)
    //   this.goToRoute(this.$route.params.id)
    // } else {
    //   console.log('No parameter')
    // }
    this.authService = new AuthService()
    this.manageCallback()
  }
}
</script>

<style scoped>
</style>
