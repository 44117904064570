<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-dialog" role="document" style="max-width: 750px;">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: 0px !important;">
            <h5 class="modal-title">{{title}}</h5>
            <button class="close" @click="close">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body" style="padding: 0px;">
            <div class="card p-3">
              <div v-if="formError" class="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>Error!</strong> Database Table Name is required!
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="formError = false">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <p>To upload heatmap data make sure data is saved as CSV. Also, make sure the first row has the column names, and each column has a maximum of 3 words.</p>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>
                    Table Name<br>
                    <small>Max 3 words</small>
                  </label>
                  <input class="form-control" id="newAttr" data-max-words="3" placeholder="Table Name" v-model="table_name">
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="d-flex justify-content-center align-items-center" style="border: 1px dashed #ccc; height: 200px; width: 350px;">
                    <div class="file-select">
                      <button class="btn btn-sm btn-default shadow-none" @click="uploadFile"><font-awesome-icon icon="cloud-upload-alt"/> Click to upload file</button>
                      <input type="file" ref="file" @change="handleFileChange">
                    </div>
                  </div>
                </div>
                <div class="col" v-if="uploadedFile">
                  <label><strong>{{fileUpload.file_name}}</strong></label><br>
                  <label>File Type:</label> <strong>{{fileUpload.type}}</strong><br>
                  <label>File Size:</label> <strong>{{fileUpload.size}}</strong><br>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">Close</button>
            <button type="button" class="btn btn-success" @click="saveUpload">Save</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { http } from '@/config/base-url'
export default {
  name: 'HeatmapModal',
  components: { },
  props: ['title', 'type', 'project', 'editMode'],
  data () {
    return {
      biblio_type: this.type,
      editor: false,
      description: '',
      biblioId: '',
      formError: false,
      table_name: '',
      fileUpload: {},
      uploadedFile: false
    }
  },
  watch: {
    newAttr: function () {
      document.querySelectorAll('input[data-max-words]').forEach(input => {
        // Remember the word limit for the current input
        const maxWords = parseInt(input.getAttribute('data-max-words') || 0)
        // Add an eventlistener to test for key inputs
        input.addEventListener('keydown', e => {
          const target = e.currentTarget
          // Split the text in the input and get the current number of words
          const words = target.value.split(/\s+/).length
          // If the word count is > than the max amount and a space is pressed
          // Don't allow for the space to be inserted
          if (!target.getAttribute('data-announce')) {
          // Note: this is a shorthand if statement
          // If the first two tests fail allow the key to be inserted
          // Otherwise we prevent the default from happening
            words >= maxWords && e.keyCode === 32 && e.preventDefault()
          }
          // else
          // words >= maxWords && e.keyCode == 32 && (e.preventDefault() || alert('Word Limit Reached'))
        })
      })
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    uploadFile: function () {
      this.uploadedFile = false
      this.$refs.file.click()
      console.log('clicked')
    },
    handleFileChange: function (e) {
      const file = e.target.files[0]
      console.log('File', file)
      // const item = {}
      // item.file = file
      // item.file_name = file.name
      var ext = file.name.substr(file.name.lastIndexOf('.') + 1)
      this.fileUpload.file_name = file.name
      this.fileUpload.size = this.formatBytes(file.size)
      this.fileUpload.type = ext
      this.fileUpload.file = file
      this.uploadedFile = true
      // this.attachments.push(item)
      // if (this.inputForm.id) {
      //   var formData = new FormData()
      //   formData.append('id', this.inputForm.id)
      //   formData.append('file[]', item.file)
      //   eventService.uploadFile(formData).then((response) => {
      //     console.log(response)
      //   })
      // }
      // console.log(this.attachments)
    },
    formatBytes: function (bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes'
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    },
    saveUpload: function () {
      const client = this.project.split(' ')[0]
      var formData = new FormData()
      formData.append('extension', this.fileUpload.ext)
      formData.append('size', this.fileUpload.size)
      formData.append('name', this.fileUpload.file_name)
      formData.append('file', this.fileUpload.file)
      formData.append('table_name', this.table_name.trim())
      formData.append('client', client)
      formData.append('project_id', this.$route.params.id)
      return http.post(`v2/conventions/read-file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => {
        console.log(res)
        this.$emit('saved')
      }).catch((error) => {
        console.log(error)
      })
    }
  },
  created () {
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.multiselect.form-control {
  padding: 0;
}

.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.document-editor {
    border: 1px solid var(--ck-color-base-border);
    border-radius: var(--ck-border-radius);
}

.close:focus {
  outline: none;
}

.mce-notification {
  display: none !important;
}

.file-select > .select-button {
  margin: 10px;
  cursor: pointer;
  font-size: 25px;
}

.file-select > input[type="file"] {
  display: none;
}
</style>
