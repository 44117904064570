<template>
<div>
  <div style="overflow-y:auto; overflow-x:hidden; height: calc(100vh - 100px);">
  <div class="row" style="padding-bottom: 25px;" ref="resultsCard">
    <div class="col-9">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Dashboard</h5>
          <div class="row" style="text-align:center;">
            <div class="col">
              <h6 class="card-title text-uppercase" style="margin-bottom: 0px;">URL</h6>
              <small><a :href="'https://surveys.mybeliefmap.com/'+publicId" target="_blank">https://surveys.mybeliefmap.com/{{publicId}}</a></small>
            </div>
            <div class="col">
              <h6 class="card-title text-uppercase" style="margin-bottom: 0px;">Total Responses</h6>
              <div><span>{{dashboardResults.total_responses}}</span></div>
            </div>
            <div class="col">
              <h6 class="card-title text-uppercase" style="margin-bottom: 0px;">Survey Status</h6>
              <div><span :class="{ 'badge badge-success': status, 'badge badge-danger': !status }">{{getSurveyStatus()}}</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="card">
        <div class="card-body">
          <h6 class="card-title text-uppercase">Behavior Stage</h6>
            <div style="text-align:center;">
              <h2>{{overall_results.behavior}}</h2>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" style="padding-bottom: 25px;">
    <div class="col-3">
      <div class="card">
        <div class="card-body">
          <h6 class="card-title text-uppercase">Risk Beliefs</h6>
          <div style="text-align:center;">
            <h2>{{overall_results.risk}}</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="card">
        <div class="card-body">
          <h6 class="card-title text-uppercase">Consequence Beliefs</h6>
          <div style="text-align:center;">
            <h2>{{overall_results.consequence}}</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="card">
        <div class="card-body">
          <h6 class="card-title text-uppercase">Barrier Beliefs</h6>
          <div style="text-align:center;">
            <h2>{{overall_results.barrier}}</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="card">
        <div class="card-body">
          <h6 class="card-title text-uppercase">Benefit Beliefs</h6>
          <div style="text-align:center;">
            <h2>{{overall_results.benefit}}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card" v-if="hasArchetypes">
    <div class="card-body">
      <h6 class="card-title text-uppercase">Archetypes</h6>
      <div style="padding-top: 25px;">
        <div class="row" style="padding-bottom: 25px;">
          <div class="col-2" style="text-align:center; border-right: 1px solid #ccc;">
            <img src="@/assets/archetypes/caregiver.png" alt="caregiver" width="100">
            <h2>{{checkArchetype('caregiver', overall_results.archetypes)}}</h2>
          </div>
          <div class="col-2" style="text-align:center; border-right: 1px solid #ccc;">
            <img src="@/assets/archetypes/creator.png" alt="creator" width="100">
            <h2>{{checkArchetype('creator', overall_results.archetypes)}}</h2>
          </div>
          <div class="col-2" style="text-align:center; border-right: 1px solid #ccc;">
            <img src="@/assets/archetypes/everyperson.png" alt="everyperson" width="100">
            <h2>{{checkArchetype('everyperson', overall_results.archetypes)}}</h2>
          </div>
          <div class="col-2" style="text-align:center; border-right: 1px solid #ccc;">
            <img src="@/assets/archetypes/explorer.png" alt="explorer" width="100">
            <h2>{{checkArchetype('explorer', overall_results.archetypes)}}</h2>
          </div>
          <div class="col-2" style="text-align:center; border-right: 1px solid #ccc;">
            <img src="@/assets/archetypes/hero.png" alt="hero" width="100">
            <h2>{{checkArchetype('hero', overall_results.archetypes)}}</h2>
          </div>
          <div class="col-2" style="text-align:center;">
            <img src="@/assets/archetypes/innocent.png" alt="innocent" width="100">
            <h2>{{checkArchetype('innocent', overall_results.archetypes)}}</h2>
          </div>
        </div>
        <div class="row" style="border-top: 1px solid #ccc; padding-top: 25px;">
          <div class="col-2" style="text-align:center; border-right: 1px solid #ccc;">
            <img src="@/assets/archetypes/jester.png" alt="jester" width="100">
            <h2>{{checkArchetype('jester', overall_results.archetypes)}}</h2>
          </div>
          <div class="col-2" style="text-align:center; border-right: 1px solid #ccc;">
            <img src="@/assets/archetypes/lover.png" alt="lover" width="100">
            <h2>{{checkArchetype('lover', overall_results.archetypes)}}</h2>
          </div>
          <div class="col-2" style="text-align:center; border-right: 1px solid #ccc;">
            <img src="@/assets/archetypes/magician.png" alt="magician" width="100">
            <h2>{{checkArchetype('magician', overall_results.archetypes)}}</h2>
          </div>
          <div class="col-2" style="text-align:center; border-right: 1px solid #ccc;">
            <img src="@/assets/archetypes/revolutionary.png" alt="revolutionary" width="100">
            <h2>{{checkArchetype('revolutionary', overall_results.archetypes)}}</h2>
          </div>
          <div class="col-2" style="text-align:center; border-right: 1px solid #ccc;">
            <img src="@/assets/archetypes/ruler.png" alt="ruler" width="100">
            <h2>{{checkArchetype('ruler', overall_results.archetypes)}}</h2>
          </div>
          <div class="col-2" style="text-align:center;">
            <img src="@/assets/archetypes/sage.png" alt="sage" width="100">
            <h2>{{checkArchetype('sage', overall_results.archetypes)}}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
</template>

<script>
// import moment from 'moment'
import BeliefMapService from '@/services/beliefmap.service'
import * as _ from 'lodash'

export default {
  name: 'SurveyDashboardView',
  props: ['surveyId', 'publicId', 'status'],
  data () {
    return {
      dashboardResults: {},
      overall_results: {},
      hasArchetypes: false
    }
  },
  methods: {
    getSurvey: function () {
      BeliefMapService.getSurvey(this.surveyId).then((response) => {
        console.log(response)
        this.hasArchetypes = response.has_archetypes
      })
    },

    getDashboardResults: function () {
      console.log(this.publicId)
      let loader = this.$loading.show({
        container: this.$refs.resultsCard,
        loader: 'dots'
      })
      BeliefMapService.getSurveyDashboard(this.surveyId).then((response) => {
        this.dashboardResults = response
        this.overall_results = response.overall_results
        console.log(this.dashboardResults)
        loader.hide()
      })
    },

    checkArchetype: function (type, archetypes) {
      let total = 0
      _.forEach(archetypes, (item) => {
        if (item === type) {
          total += 1
        }
      })
      return total
    },
    getSurveyStatus: function () {
      if (this.status) {
        return 'Active'
      } else {
        return 'Inactive'
      }
    }
  },
  mounted () {
    this.getSurvey()
    this.getDashboardResults()
  }
}
</script>

<style scoped>

</style>
