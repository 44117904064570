<template>
<div>
  <b-tabs>
    <b-tab :title="tabtitles[0]" class="tab-content-container">
      <div style="padding-top: 25px;">
        <div class="form-group">
          <editor id="editor" api-key="xmy55lj0kciq6f2f0cfrnrnj4qjbs8e5qge7gjk8rr1j4692" style="height: 300px; border:none;" :init="options" v-model="introduction"></editor>
          <!-- <textarea class="form-control" v-model="introduction" rows="10" placeholder="Enter introduction..."></textarea> -->
        </div>
        <button class="btn btn-primary float-right" v-on:click="saveIntroduction">Save Introduction</button>
      </div>
    </b-tab>
    <b-tab :title="tabtitles[1]" class="tab-content-container">
      <survey-questions :id="surveyId" placeholder="Add screener question and press enter" :published="published"
                        :questions="screenerQuestions" type="screener" category-type="screener-question" answer-type="custom">
      </survey-questions>
    </b-tab>
    <b-tab :title="tabtitles[2]" class="tab-content-container">
      <survey-questions :id="surveyId" placeholder="Add behavior question and press enter" :published="published"
                        :questions="behaviorQuestions" type="behavior" category-type="behavior-stage" answer-type="default">
      </survey-questions>
    </b-tab>
    <b-tab :title="tabtitles[3]" class="tab-content-container">
      <survey-questions :id="surveyId" placeholder="Add risk question and press enter" :published="published"
                        :questions="riskQuestions" type="risk" category-type="risk-belief" answer-type="default">
      </survey-questions>
    </b-tab>
    <b-tab :title="tabtitles[4]" class="tab-content-container">
      <survey-questions :id="surveyId" placeholder="Add consequence question and press enter" :published="published"
                        :questions="consequenceQuestions" type="consequence" category-type="consequence-belief" answer-type="default">
      </survey-questions>
    </b-tab>
    <b-tab :title="tabtitles[5]" class="tab-content-container">
      <survey-questions :id="surveyId" placeholder="Add barrier question and press enter" :published="published"
                        :questions="barrierQuestions" type="barrier" category-type="barrier-belief" answer-type="default">
      </survey-questions>
    </b-tab>
    <b-tab :title="tabtitles[6]" class="tab-content-container">
      <survey-questions :id="surveyId" placeholder="Add benefit question and press enter" :published="published"
                        :questions="benefitQuestions" type="benefit" category-type="benefit-belief" answer-type="default">
      </survey-questions>
    </b-tab>
  </b-tabs>
</div>
</template>

<script>
// import moment from 'moment'
import Editor from '@tinymce/tinymce-vue'
import BeliefMapService from '@/services/beliefmap.service'
import SurveyQuestions from '@/components/beliefmap/SurveyQuestions.vue'
import * as _ from 'lodash'

export default {
  name: 'SurveyQuestionsView',
  components: {
    'editor': Editor,
    'SurveyQuestions': SurveyQuestions
  },
  props: ['surveyId', 'published'],
  data () {
    return {
      tabtitles: ['Introduction', 'Screener Questions', 'Behavior Stage', 'Risk Beliefs', 'Consequence Beliefs', 'Barrier Beliefs', 'Benefit Beliefs'],
      survey: {},
      introduction: null,
      screener_question: null,
      screener_answer: null,
      screener: {},
      screenerQuestions: [],
      behaviorQuestions: [],
      riskQuestions: [],
      consequenceQuestions: [],
      barrierQuestions: [],
      benefitQuestions: [],
      screenerQuestionAnswers: [],
      screenerEdit: -1,
      screenerAnswerEdit: -1,
      showQuestion: false,
      showAnswer: false,
      answer: null,
      options: {
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar: 'bold italic strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist | link'
      }
    }
  },
  methods: {
    getSurvey: function (id) {
      BeliefMapService.getSurvey(id).then((response) => {
        this.survey = response
        this.introduction = this.survey.introduction
      })
    },
    getSurveyQuestions: function (id) {
      BeliefMapService.getAllSurveyQuestions(id).then((response) => {
        _.forEach(response, (el, index) => {
          if (el.type === 'screener') {
            this.screenerQuestions.push(el)
          }
          if (el.type === 'behavior') {
            this.behaviorQuestions.push(el)
          }
          if (el.type === 'risk') {
            this.riskQuestions.push(el)
          }
          if (el.type === 'consequence') {
            this.consequenceQuestions.push(el)
          }
          if (el.type === 'barrier') {
            this.barrierQuestions.push(el)
          }
          if (el.type === 'benefit') {
            this.benefitQuestions.push(el)
          }
        })
      })
    },
    saveIntroduction: function () {
      this.survey.introduction = this.introduction
      console.log(this.survey)
      BeliefMapService.saveSurvey(this.survey).then((response) => {
        let data = response.data
        console.log(data)
      })
    },
    addScreener: function () {
      let newScreenerQuestion = {
        survey_id: this.surveyId,
        type: 'screener',
        category_type: 'screener-question',
        question: this.screener_question,
        question_type: null,
        answer_type: 'custom',
        answers: []
      }
      BeliefMapService.saveQuestion(newScreenerQuestion).then((response) => {
        newScreenerQuestion.question_id = response.id
        this.screenerQuestions.push(newScreenerQuestion)
        this.screener_question = null
      })
    },
    editScreenerQuestion: function (index) {
      this.screener = this.screenerQuestions[index]
      this.screenerEdit = index
      this.showQuestion = !this.showQuestion
    },
    saveScreenerQuestion: function (item) {
      this.showQuestion = !this.showQuestion
      this.screenerEdit = -1

      console.log(item)
      BeliefMapService.saveQuestion(item)
    },

    addQuestionAnswer: function () {
      this.screener.answers.push(this.screener_answer)
      this.screener_answer = null
    },
    editQuestionAnswer: function (index) {
      this.answer = this.screener.answers[index]
      this.screenerAnswerEdit = index
    },
    saveQuestionAnswer: function (item, index) {
      this.screener.answers[index] = item
      this.screenerAnswerEdit = -1
    }
  },
  mounted () {
    this.getSurvey(this.surveyId)
    this.getSurveyQuestions(this.surveyId)
  }
}
</script>

<style scoped>
  input.form-control {
    border:none;
    padding-left: 0px;
  }
</style>
