<template>
  <div style="padding: 25px;">
    <nav class="col-md-2 d-none d-md-block bg-light sidebar">
      <div class="sidebar-sticky">
        <div style="padding: 25px 25px 15px 25px;">
          <h3 style="font-size:1.7rem;">Video Library</h3>
        </div>
        <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link" style="cursor:pointer;" v-on:click="showView('webex')">
              <div class="d-flex bd-highlight">
                <h6 class="p-2 m-0 text-uppercase">
                  Cisco Webex
                </h6>
              </div>
            </a>
            <ul class="nav flex-column">
              <li class="nav-item pl-3">
                <a class="nav-link text-muted" style="cursor:pointer;" v-on:click="showView('webex')">
                  <div class="d-flex bd-highlight">
                    <h6 class="p-2 m-0">
                      Mac OS
                    </h6>
                  </div>
                </a>
              </li>
              <li class="nav-item pl-3">
                <a class="nav-link text-muted" style="cursor:pointer;" v-on:click="showView('webex')">
                  <div class="d-flex bd-highlight">
                    <h6 class="p-2 m-0">
                      Windows 10
                    </h6>
                  </div>
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link" style="cursor:pointer;" v-on:click="showView('webex')">
              <div class="d-flex bd-highlight">
                <h6 class="p-2 m-0 text-uppercase">
                  Simpson Events
                </h6>
              </div>
            </a>
            <ul class="nav flex-column">
              <li class="nav-item pl-3">
                <a class="nav-link text-muted" style="cursor:pointer;" v-on:click="showView('webex')">
                  <div class="d-flex bd-highlight">
                    <h6 class="p-2 m-0">
                      General
                    </h6>
                  </div>
                </a>
              </li>
              <li class="nav-item pl-3">
                <a class="nav-link text-muted" style="cursor:pointer;" v-on:click="showView('webex')">
                  <div class="d-flex bd-highlight">
                    <h6 class="p-2 m-0">
                      Form Builder
                    </h6>
                  </div>
                </a>
              </li>
              <li class="nav-item pl-3">
                <a class="nav-link text-muted" style="cursor:pointer;" v-on:click="showView('webex')">
                  <div class="d-flex bd-highlight">
                    <h6 class="p-2 m-0">
                      Email Responses
                    </h6>
                  </div>
                </a>
              </li>
              <li class="nav-item pl-3">
                <a class="nav-link text-muted" style="cursor:pointer;" v-on:click="showView('webex')">
                  <div class="d-flex bd-highlight">
                    <h6 class="p-2 m-0">
                      Event Templates
                    </h6>
                  </div>
                </a>
              </li>
              <li class="nav-item pl-3">
                <a class="nav-link text-muted" style="cursor:pointer;" v-on:click="showView('webex')">
                  <div class="d-flex bd-highlight">
                    <h6 class="p-2 m-0">
                      Registrants
                    </h6>
                  </div>
                </a>
              </li>
              <li class="nav-item pl-3">
                <a class="nav-link text-muted" style="cursor:pointer;" v-on:click="showView('webex')">
                  <div class="d-flex bd-highlight">
                    <h6 class="p-2 m-0">
                      Event URL
                    </h6>
                  </div>
                </a>
              </li>
              <li class="nav-item pl-3">
                <a class="nav-link text-muted" style="cursor:pointer;" v-on:click="showView('webex')">
                  <div class="d-flex bd-highlight">
                    <h6 class="p-2 m-0">
                      Vendor Access
                    </h6>
                  </div>
                </a>
              </li>
              <li class="nav-item pl-3">
                <a class="nav-link text-muted" style="cursor:pointer;" v-on:click="showView('webex')">
                  <div class="d-flex bd-highlight">
                    <h6 class="p-2 m-0">
                      Public Access
                    </h6>
                  </div>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
    <main role="main" class="col-md-8 ml-sm-auto col-lg-10" style="position: fixed; top: 55px; left: 0px; right: 0px; bottom: 0px; padding-top: 0px; padding-bottom: 25px; background-color: #f4f5f7; overflow-y:auto;">
      <div class="row">
        <section class="col-md-9 py-3">
          <div class="video">
            <vue-plyr ref="plyr">
              <video :src="video" autoplay="true"></video>
            </vue-plyr>
          </div>
        </section>
        <section class="col-md-3 pl-0 py-3">
          <ul class="nav flex-column">
            <li class="nav-item" v-for="(item, index) in videos" :key="index">
              <a class="nav-link p-0" style="cursor:pointer;" @click="showVideo(item)">
                <div class="d-flex justify-content-start" style="padding: 5px;">
                  <img :src="item.img" width="150px">
                  <p class="pl-2" style="font-size: 14px; font-wight: 500;">{{item.title}}</p>
                </div>
              </a>
            </li>
          </ul>
        </section>
      </div>
      <!-- <div style="padding: 0px;">
        <div v-if="view === 'webex'">
          <div class="bg-light d-none d-md-block p-0" style="bottom: 0; position: fixed; top: 54px;">
            <div class="sidebar-sticky" style="border-right: 1px solid #ccc;">
              <ul class="nav flex-column">
                <li class="nav-item" v-for="(item, index) in videos" :key="index">
                  <a class="nav-link" style="cursor:pointer;" @click="showVideo(item)">
                    <div style="padding: 5px;">
                      <img :src="item.img" width="400">
                      <h5>{{item.title}}</h5>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-8 ml-sm-auto px-4 py-4">
            <h4>{{title}}</h4>
            <vue-plyr ref="plyr">
              <video :src="video" autoplay="true"></video>
            </vue-plyr>
          </div>
        </div>
      </div> -->
    </main>
  </div>
</template>

<script>
export default {
  name: 'video-library',
  data () {
    return {
      view: 'webex',
      videos: [
        { src: 'https://assets.simpsonhealthcare.com/video-library/webex/mac-os-desktop-install.mp4', title: 'Mac OS Desktop Install', img: 'https://assets.simpsonhealthcare.com/video-library/webex/mac-os-desktop-install.png' },
        { src: 'https://assets.simpsonhealthcare.com/video-library/webex/mac-os-browser-login.mp4', title: 'Mac OS Browser Login', img: 'https://assets.simpsonhealthcare.com/video-library/webex/mac-os-desktop-install.png' },
        { src: 'https://assets.simpsonhealthcare.com/video-library/webex/mac-os-outlook-webex-signature.mp4', title: 'Mac OS Outlook WebEx Signature', img: 'https://assets.simpsonhealthcare.com/video-library/webex/mac-os-outlook-webex-signature.png' },
        { src: 'https://assets.simpsonhealthcare.com/video-library/webex/mac-os-screen-recording-camera-permissions.mp4', title: 'Mac OS Screen Recording & Camera Permissions', img: 'https://assets.simpsonhealthcare.com/video-library/webex/mac-os-screen-recording-camera-permissions.png' },
        { src: 'https://assets.simpsonhealthcare.com/video-library/webex/windows-desktop-install.mp4', title: 'Windows Desktop Install', img: 'https://assets.simpsonhealthcare.com/video-library/webex/windows-desktop-install.png' },
        { src: 'https://assets.simpsonhealthcare.com/video-library/webex/windows-browser-login.mp4', title: 'Windows Browser Login', img: 'https://assets.simpsonhealthcare.com/video-library/webex/windows-desktop-install.png' },
        { src: 'https://assets.simpsonhealthcare.com/video-library/webex/windows-outlook-webex-signature.mp4', title: 'Windows Outlook WebEx Signature', img: 'https://assets.simpsonhealthcare.com/video-library/webex/windows-outlook-webex-signature.png' }
      ],
      video: { src: 'https://assets.simpsonhealthcare.com/video-library/webex/mac-os-desktop-install.mp4', title: 'Mac OS Desktop Install', img: 'https://assets.simpsonhealthcare.com/video-library/webex/mac-os-desktop-install.png' },
      title: ''
    }
  },
  methods: {
    showView: function (view) {
      this.view = view
    },
    showVideo: function (item) {
      this.video = item.src
      this.title = item.title
      this.$refs.plyr.player.play()
    }
  },
  mounted () { }
}
</script>

<style scoped>
/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 54px;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  bottom: 0;
  height: 100%;
  padding-top: 0px;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
  padding: 0px 16px;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #fff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

.active {
  background: #009370;
}

.active .nav-link,
.active .nav-link:hover {
  color: #fff;
}

.employee-active {
  background-color: #006AB6;
  color: #fff;
}

.employee-active h5,
.employee-active .text-muted {
  color: #fff !important;
}

/* Rounded sliders */

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 18px;
    margin-left: 15px;
    margin-top: 4px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #009370;
}

input:focus+.slider {
    box-shadow: 0 0 1px #009370;
}

input:checked+.slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.video {
  padding-top: 5px;
}

</style>
