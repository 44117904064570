<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-dialog" role="document" style="max-width: 750px;">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: 0px !important;">
            <h5 class="modal-title">{{title}}</h5>
            <button class="close" v-on:click="close">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body" style="padding: 0px;">
            <div class="card">
              <b-form @submit.stop.prevent="saveEvent">
                <div style="padding: 15px;">
                  <div class="row" v-if="type == 'Template'">
                    <div class="col">
                      <div class="form-group">
                        <span class="text-danger">* </span><label>Event Template</label>
                        <multiselect :class="{ 'invalid': isInvalid(inputForm.template), 'valid': isValid(inputForm.template) }" :options="templates" track-by="template_name" label="template_name" :allow-empty="false" :show-labels="false" @input="checkItem('template')" placeholder="Select Template" v-model="inputForm.template"></multiselect>
                        <label class="invalid" v-if="isInvalid(inputForm.template)">Field is required.</label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <span class="text-danger">* </span><label>Event Name</label>
                      <b-input type="text" class="form-control" v-model="inputForm.event_name" placeholder="Event Name" @change="checkItem('event_name')" :state="validation('event_name')"></b-input>
                      <b-form-invalid-feedback :state="validation('event_name')">
                        Field is required.
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                  <div class="row" style="padding-top: 15px;">
                    <div class="col">
                      <span class="text-danger">* </span><label>Project</label>
                      <multiselect :class="{ 'invalid': isInvalid(inputForm.project), 'valid': isValid(inputForm.project) }" :options="projects" :show-labels="false" :allow-empty="false" @input="checkItem('project')" placeholder="Select Project" v-model="inputForm.project"></multiselect>
                      <label class="invalid" v-if="isInvalid(inputForm.project)">Field is required.</label>
                    </div>
                    <div class="col">
                      <span class="text-danger">* </span><label>Project Manager</label>
                      <multiselect :class="{ 'invalid': isInvalid(inputForm.project_manager), 'valid': isValid(inputForm.project_manager) }" :options="projectManagers" :show-labels="false" @input="checkItem('project_manager')" placeholder="Select Project Manager" v-model="inputForm.project_manager"></multiselect>
                      <label class="invalid" v-if="isInvalid(inputForm.project_manager)">Field is required.</label>
                    </div>
                  </div>
                  <div class="row" style="padding-top: 15px;">
                    <div class="col-6">
                      <span class="text-danger">* </span><label>Start Date</label>
                      <b-form-datepicker today-button reset-button close-button locale="en" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" @input="checkItem('start_date')" :state="validation('start_date')" v-model="inputForm.start_date" placeholder="Start Date"></b-form-datepicker>
                      <b-form-invalid-feedback :state="validation('start_date')">
                        Field is required.
                      </b-form-invalid-feedback>
                    </div>
                    <div class="col-6">
                      <span class="text-danger">* </span><label>End Date</label>
                      <b-form-datepicker today-button reset-button close-button locale="en" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" @input="checkItem('end_date')" :state="validation('end_date')" v-model="inputForm.end_date" placeholder="End Date"></b-form-datepicker>
                      <b-form-invalid-feedback :state="validation('start_date')">
                        Field is required.
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                  <div class="row" style="padding-top: 15px;">
                    <div class="col-6">
                      <label>Cutoff Date</label>
                      <b-form-datepicker today-button reset-button close-button locale="en" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" v-model="inputForm.cutoff_date" placeholder="Cutoff Date"></b-form-datepicker>
                    </div>
                    <div class="col-6">
                      <label>Registration Limit</label>
                      <input type="text" class="form-control" placeholder="Registration Limit" v-model="inputForm.registration_limit">
                    </div>
                  </div>
                  <!-- <div class="row" style="padding-top: 15px; padding-bottom: 15px;">
                    <div class="col">
                      <label>Notes</label>
                      <tinymce-editor api-key="xmy55lj0kciq6f2f0cfrnrnj4qjbs8e5qge7gjk8rr1j4692" style="height: 100px; border:none;" :init="options" v-model="inputForm.notes"></tinymce-editor>
                    </div>
                  </div> -->
                  <div class="p-3 d-flex justify-content-end">
                    <div class="p-1 d-flex">
                      <button type="submit" class="btn btn-primary mr-3">Save</button>
                      <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close">Cancel</button>
                    </div>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import eventService from '@/services/event.service'
// import Editor from '@tinymce/tinymce-vue'
// import Datepicker from 'vuejs-datepicker'
import { forEach } from 'lodash'
import moment from 'moment'
export default {
  name: 'EventModal',
  components: {
    // Datepicker,
    // 'tinymce-editor': Editor
  },
  props: ['title', 'item', 'type'],
  data () {
    return {
      projects: [],
      projectManagers: [],
      templates: [],
      start: '',
      end: '',
      cutoff: '',
      inputForm: {},
      formArray: [],
      required: true,
      // options: {
      //   menubar: false,
      //   plugins: [
      //     'advlist autolink lists link image charmap print preview anchor',
      //     'searchreplace visualblocks code fullscreen',
      //     'insertdatetime media table paste code help wordcount'
      //   ],
      //   toolbar: 'bold italic strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist | link'
      // },
      formSubmitted: false,
      formValid: false,
      value: []
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    isInvalid (name) {
      if (this.formSubmitted) {
        if (name === null || name === '') {
          return true
        } else {
          return false
        }
      }
    },
    isValid (name) {
      if (this.formSubmitted) {
        return name !== null
      }
    },
    validation (name) {
      if (this.formSubmitted) {
        if (this.inputForm[name] === null || this.inputForm[name].length === 0) {
          return false
        } else {
          return true
        }
      } else {
        return null
      }
    },
    getProjects: function () {
      eventService.getProjects().then((response) => {
        const res = response
        const items = []
        forEach(res, (item) => {
          items.push(item.name)
        })
        this.projects = items
      })
    },
    getEventTemplates: function () {
      eventService.getEventTemplates().then((response) => {
        console.log(response)
        this.templates = response
      })
    },
    getProjectManagers: function () {
      eventService.getProjectManagers().then((response) => {
        this.projectManagers = response.users
      })
    },
    checkItem: function (item) {
      console.log(this.inputForm[item])
      if (this.inputForm[item] !== '' || this.inputForm[item] !== 0) {
        const index = this.formArray.indexOf(item)
        this.formArray.splice(index, 1)
      }
      console.log('Check', this.formArray)
    },
    validateEvent: function () {
      this.formSubmitted = true
      console.log(this.formArray)
      if (this.formArray.length === 0) {
        this.formValid = true
      } else {
        this.formValid = false
      }
      console.log(this.formValid)
    },
    saveEvent: function () {
      this.validateEvent()
      if (this.formValid) {
        this.inputForm.start_date = this.inputForm.start_date ? this.formatDate(this.inputForm.start_date) : null
        this.inputForm.end_date = this.inputForm.end_date ? this.formatDate(this.inputForm.end_date) : null
        this.inputForm.cutoff_date = this.inputForm.cutoff_date ? this.formatDate(this.inputForm.cutoff_date) : null
        this.inputForm.event_name = this.inputForm.event_name ? this.inputForm.event_name : null
        this.inputForm.project = this.inputForm.project ? this.inputForm.project : null
        this.inputForm.project_manager = this.inputForm.project_manager ? this.inputForm.project_manager : null
        this.inputForm.registration_limit = this.inputForm.registration_limit ? this.inputForm.registration_limit : null
        // this.inputForm.notes = this.inputForm.notes ? this.inputForm.notes : null
        this.inputForm.template = this.inputForm.template ? this.inputForm.template : null
        this.inputForm.type = this.type
        console.log(this.inputForm)
        eventService.saveEvent(this.inputForm).then((response) => {
          console.log(response)
          if (response.id) {
            this.inputForm.id = response.id
            this.inputForm.status = true
            this.inputForm.type = 'add'
          } else {
            this.inputForm.type = 'update'
          }
          this.$emit('save', this.inputForm)
        })
      }
    },
    formatDate: function (date) {
      return moment(date).format('YYYY-MM-DD')
    },
    formatStringDate: function (date) {
      const _date = new Date()
      _date.setYear(date.slice(0, 4))
      _date.setMonth(parseInt(date.slice(5, 7)) - 1)
      _date.setDate(date.slice(8, 10))
      return _date
    }
  },
  mounted () {
    if (this.item.id) {
      this.inputForm = this.item
      console.log(this.inputForm)
    } else {
      this.inputForm = {
        event_name: null,
        project: null,
        project_manager: null,
        start_date: null,
        end_date: null,
        template: null
      }
      this.formArray = ['event_name', 'project', 'project_manager', 'start_date', 'end_date']
      if (this.type === 'Template') {
        this.formArray.unshift('template')
      }
      console.log(this.formArray)
    }
    this.getProjects()
    this.getProjectManagers()
    this.getEventTemplates()
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.vdp-datepicker input.form-control {
  background-color:#FFF;
}
</style>
<style scoped>
.invalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 12.8px;
  color: #dc3545;
}

.invalid .multiselect__tags {
  border-color: #dc3545!important;
}

.valid .multiselect__tags {
  border-color: #28a745!important;
}

.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.document-editor {
    border: 1px solid var(--ck-color-base-border);
    border-radius: var(--ck-border-radius);
}

.close:focus {
  outline: none;
}

.mce-notification {
  display: none !important;
}

.file-select > .select-button {
  margin: 10px;
  cursor: pointer;
  font-size: 25px;
}

.file-select > input[type="file"] {
  display: none;
}
</style>
