<template>
  <div id="app">
    <nav v-if="showMenu" class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="navbar-brand">Simpson Healthcare</div>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item" v-if="hasAccess().includes('admin') || hasAccess().includes('finance')">
            <router-link to="/projects" class="nav-link" active-class="active">Projects</router-link>
          </li>
          <li class="nav-item" v-if="hasAccess().includes('admin') || hasAccess().includes('finance') || hasAccess().includes('marketing')">
            <router-link to="/marketing" class="nav-link" active-class="active">Marketing</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/events" class="nav-link" active-class="active">Events</router-link>
          </li>
          <li class="nav-item" v-if="hasAccess().includes('admin') || hasAccess().includes('finance')">
            <router-link to="/reports" class="nav-link" active-class="active">Reports</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/applications" class="nav-link" active-class="active">Applications</router-link>
          </li>
          <li class="nav-item" v-if="hasAccess().includes('admin')">
            <router-link to="/settings" class="nav-link" active-class="active">Settings</router-link>
          </li>
          <li class="nav-item" v-if="hasAccess().includes('dev-admin')">
            <router-link to="/3x" class="nav-link" active-class="active">3ConneX</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/beliefmap-surveys" class="nav-link" active-class="active">BeliefMap</router-link>
          </li>
          <li class="nav-item" v-if="hasAccess().includes('dev-admin')">
            <router-link to="/clients" class="nav-link" active-class="active">Clients</router-link>
          </li>
          <li class="nav-item" v-if="hasAccess().includes('dev-admin')">
            <router-link to="/request-forms" class="nav-link" active-class="active">Requests<sup>BETA</sup></router-link>
          </li>
          <li class="nav-item" v-if="hasAccess().includes('dev-admin')">
            <router-link to="/graphics" class="nav-link" active-class="active">Emails</router-link>
          </li>
        </ul>
        <ul class="navbar-nav flex-row ml-md-auto d-none d-md-flex right-dropdown">
          <li class="nav-item">
            <a :href="'https://employee.simpsonhealthcare.com/login.php?username='+getEmail" target="_blank" class="nav-link" active-class="active">Employee Portal <font-awesome-icon icon="external-link-alt"/></a>
            <!-- <router-link to="/employee-portal" class="nav-link" active-class="active">Portal</router-link> -->
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <div class="float-left" style="margin-top: -10px;">
                <div class="circle">
                  <span class="initials">{{getAccessInitials()}}</span>
                </div>
              </div>
              <div class="float-left" style="padding-left: 10px;">
                <h6>{{getAccess()}}</h6>
              </div>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
              <div v-if="hasAccess().includes('dev-admin')">
                <router-link to="/request-forms-builder" class="dropdown-item">Request Form Builder</router-link>
              </div>
              <div v-if="hasAccess().includes('dev-admin')">
                <router-link to="/kols" class="dropdown-item">KOLs</router-link>
              </div>
              <div v-if="hasAccess().includes('dev-admin')">
                <router-link to="/video-library" class="dropdown-item">Library</router-link>
              </div>
              <div v-if="hasAccess().includes('dev-admin')">
                <router-link to="/employees" class="dropdown-item">Employees</router-link>
              </div>
              <div v-if="hasAccess().includes('dev-admin')">
                <router-link to="/temps" class="dropdown-item">Temps</router-link>
              </div>
              <a href="javascript:void(0);" @click="onLogout" class="dropdown-item">Logout</a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <router-view/>
  </div>
</template>

<script>
// import { mapActions } from 'vuex'
import AuthService from '@/services/auth.service'
// import GraphService from '@/services/graph.service'
export default {
  name: 'app',
  computed: {
    showMenu: function () {
      return (this.$route.name !== 'login' && this.$route.name !== 'aadcallback')
    },
    getEmail: function () {
      return JSON.parse(localStorage.getItem('user')).email
    }
  },
  methods: {
    // ...mapActions('auth', ['logout']),
    onLogout: function () {
      // this.logout()
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('muser')
      // this.$router.replace('/')
      this.authService.logout()
    },
    searchItem: function () {
      this.showSearchModal = true
    },
    closeSearchModal: function () {
      this.showSearchModal = false
    },
    getAccess: function () {
      return JSON.parse(localStorage.getItem('user')).name
    },
    getAccessInitials: function () {
      let first = JSON.parse(localStorage.getItem('user')).first_name.substring(0, 1)
      let last = JSON.parse(localStorage.getItem('user')).last_name.substring(0, 1)
      return first + last
    },
    hasAccess: function () {
      // console.log(JSON.parse(localStorage.getItem('user')).roles)
      if(JSON.parse(localStorage.getItem('user')).roles) {
        return JSON.parse(localStorage.getItem('user')).roles
      }
    }
  },
  mounted () {
    this.authService = new AuthService()
    // this.graphService = new GraphService()
  }
}
</script>

<style scoped>
.navbar {
  padding: 0 1rem;
}
.bg-light {
  background-color: #006AB6 !important;
}
.navbar-light .navbar-brand,
.navbar-light .navbar-brand:hover {
  color: #fff;
  padding: 12px;
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
  color: #fff;
  background-color: #009370;
  padding: 15px;
}

.navbar-light .right-dropdown .active>.nav-link,
.navbar-light .right-dropdown .nav-link.active,
.navbar-light .right-dropdown .nav-link.show,
.navbar-light .right-dropdown .show>.nav-link {
  color: #fff;
  background-color: transparent;
  padding: 15px;
}

.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:visited{
  color: #fff;
  padding: 15px;
}

.circle {
  background-color: #333;
  border-radius: 50%;
  height: 40px;
  text-align: center;
  width: 40px;
}

.initials {
  font-size: calc(40px / 2);
  /* 50% of parent */
  line-height: 1;
  position: relative;
  top: calc(40px / 4);
  /* 25% of parent */
  color: #FFF;
}
</style>
