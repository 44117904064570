<template>
  <div class="d-flex justify-content-center px-5" style="padding:45px 25px 25px 25px;">
    <!-- <Description :kid="this.kid" :type="type" /> -->
    <div class="card w-75">
      <div class="card-header d-flex justify-content-between align-items-center">
        <div>
          <h4>{{type}}</h4>
          <p class="text-muted mb-0" style="font-size: 0.85em;">Last updated: 7/1/2020 2:57 PM by Simpson Employee</p>
        </div>
        <button v-if="!editMode" class="btn btn-sm btn-warning" @click="editInfo">Edit</button>
        <div v-else class="btn-group">
          <button class="btn btn-sm btn-success" @click="save">Save</button>
          <button class="btn btn-sm btn-default" @click="cancel">Cancel</button>
        </div>
      </div>
      <div class="card-body p-5">
        <div class="row">
          <div class="col">
            <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">NPI</label>
            <p v-if="!editMode" style="font-size: 16px;">{{inputForm.npi}}</p>
            <div v-else class="pb-2">
              <input type="text" class="form-control w-25" placeholder="NPI Number" v-model="inputForm.npi">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">Primary Specialty</label>
            <p v-if="!editMode" style="font-size: 16px;">{{inputForm.primary_specialty}}</p>
            <div v-else class="pb-2">
              <input type="text" class="form-control" placeholder="Primary Specialty" v-model="inputForm.primary_specialty">
            </div>
          </div>
          <div class="col">
            <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">Secondary Specialty</label>
            <p v-if="!editMode" style="font-size: 16px;">{{inputForm.secondary_specialty}}</p>
            <div v-else class="pb-2">
              <input type="text" class="form-control" placeholder="Secondary Specialty" v-model="inputForm.secondary_specialty">
            </div>
          </div>
          <div class="col">
            <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">Tertiary Specialty</label>
            <p v-if="!editMode" style="font-size: 16px;">{{inputForm.tertiary_specialty}}</p>
            <div v-else class="pb-2">
              <input type="text" class="form-control" placeholder="Tertiary_specailty" v-model="inputForm.tertiary_specialty">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">Position</label>
            <p v-if="!editMode" style="font-size: 16px;">{{inputForm.position}}</p>
            <div v-else class="pb-2">
              <input type="text" class="form-control" placeholder="Position" v-model="inputForm.position">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">Affiliation</label>
            <p v-if="!editMode" style="font-size: 16px;">{{inputForm.affiliation}}</p>
            <div v-else class="pb-2">
              <input type="text" class="form-control" placeholder="Affiliation" v-model="inputForm.affiliation">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">Focus</label>
            <p v-if="!editMode" style="font-size: 16px;">{{inputForm.focus}}</p>
            <div v-else class="pb-2">
              <input type="text" class="form-control" placeholder="Focus" v-model="inputForm.focus">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['type', 'kolId'],
  components: {},
  data () {
    return {
      isDraft: false,
      showModal: false,
      items: [],
      biblio: {},
      title: '',
      editMode: false,
      inputForm: {
        npi: '123456',
        primary_specialty: 'Pulmonologist',
        secondary_specialty: 'Asthma',
        tertiary_specialty: 'Neurologist',
        position: 'Position Detail',
        affiliation: 'Affiliation Detail',
        focus: 'Focus Detail'
      }
    }
  },
  methods: {
    getBibliometrics: function (id, type) {
      console.log(id, type)
    },
    editInfo: function () {
      this.editMode = true
    },
    save: function () {
      this.editMode = false
    },
    cancel: function () {
      this.editMode = false
    }
  },
  created () {
    this.getBibliometrics(this.kolId, this.type)
  }
}
</script>
