import { http } from '@/config/base-url'
import authHeader from '@/config/auth-header'

// const API = 'http://localhost/~abeamin/events/api/index.php'
// const API = 'http://localhost:5000/v2'

export default {

  getEvents () {
    return http.get(`v2/events/`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  getEvent (id) {
    return http.get(`v2/events/${id}`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  saveEvent (item) {
    if (item.id) {
      return http.put(`v2/events/${item.id}`, item, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    } else {
      return http.post(`v2/events/`, item, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    }
  },

  saveTemplate (item) {
    return http.post(`v2/events/templates`, item, {
      headers: authHeader()
    }).then((res) => {
      return res.data
    }).catch((error) => {
      console.log(error)
    })
  },

  getEventTemplates () {
    return http.get(`v2/events/templates`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  getEventForm (id) {
    return http.get(`v2/events/form/${id}`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  saveEventForm (item) {
    if (item.id) {
      return http.put(`v2/events/form/${item.id}`, item, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    } else {
      return http.post(`v2/events/form`, item, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    }
  },

  getEventRegistrants (id) {
    return http.get(`v2/events/${id}/registrants`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  getRegistrant (id) {
    return http.get(`v2/events/registrants/${id}`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  getRegistrantNotes (id) {
    return http.get(`v2/events/registrants/${id}/notes`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  saveRegistrant (item) {
    if (item.id) {
      return http.put(`v2/events/registrant/${item.id}`, item, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    } else {
      return http.post(`v2/events/registrant`, item, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    }
  },

  getProjects () {
    return http.get(`v2/projects/active-projects`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  getProjectManagers () {
    return http.get(`v2/users/employees/active`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  getEmailResponses (id) {
    return http.get(`v2/events/${id}/email-responses`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  saveEmailResponse (item) {
    if (item.id) {
      return http.put(`v2/events/${item.event_id}/email/${item.id}`, item, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    } else {
      return http.post(`v2/events/${item.event_id}/email`, item, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    }
  },

  getCalendarInvites (id) {
    return http.get(`v2/events/${id}/calendar-invites`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  saveCalendarInvite (item) {
    if (item.id) {
      return http.put(`v2/events/${item.event_id}/calendar-invite/${item.id}`, item, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    } else {
      return http.post(`v2/events/${item.event_id}/calendar-invite`, item, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    }
  },

  deleteCalendarInvite (id) {
    return http.delete(`v2/events/calendar-invite/${id}`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  // updateEmailResponse (id, emailResponse) {
  //   return http.put(`events/${id}/email/response`, emailResponse, {
  //     headers: authHeader()
  //   }).then((res) => {
  //     const items = res.data
  //     return items
  //   }).catch((error) => {
  //     console.log(error)
  //   })
  // },

  getEmailAttachments (id) {
    return http.get(`v2/events/email/${id}/attachments`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  uploadFile (formData) {
    return http.post('https://assets.simpsonhealthcare.com/events/upload-attachments.php', formData, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  removeEmailAttachment (formData) {
    return http.post('https://assets.simpsonhealthcare.com/events/delete-attachments.php', formData, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  sendEmailManually (id) {
    return http.get(`v2/events/email/${id}/send-manually`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  sendRegistrantEmailManually (regId, emailId) {
    return http.get(`v2/events/email/${regId}/${emailId}/send-manually`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  deleteResponseEmail (id) {
    return http.delete(`v2/events/email/${id}`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  getActiveEmployees () {
    return http.get(`v2/events/employees/active`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  saveVendor (item) {
    if (item.id) {
      return http.put(`v2/events/vendors/${item.id}`, item, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    } else {
      return http.post(`v2/events/vendors`, item, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    }
  },

  saveVendorAccess (item) {
    return http.post(`v2/events/vendors-access`, item, {
      headers: authHeader()
    }).then((res) => {
      return res.data
    }).catch((error) => {
      console.log(error)
    })
  },

  getVendors () {
    return http.get(`v2/events/vendors`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  getVendorAccess (id) {
    return http.get(`v2/events/vendor-access/${id}`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  deleteVendorAccess (id) {
    return http.delete(`v2/events/vendor-access/${id}`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  deleteRegistrant (id) {
    return http.delete(`v2/events/registrant/${id}`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  getEventURL (id) {
    return http.get(`v2/events/${id}/link`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },
  saveLink (item) {
    console.log(item)
    if (item.id) {
      return http.put(`v2/events/link/${item.id}`, item, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    } else {
      return http.post(`v2/events/link`, item, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    }
  }
}
