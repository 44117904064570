import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login.vue'
// import Users from '@/views/Users.vue'
import Employees from '@/views/Employees.vue'
import Temps from '@/views/Temps.vue'
import EmployeePortal from '@/views/EmployeePortal.vue'
import Projects from '@/views/Projects.vue'
import Archived from '@/views/Archived.vue'
import Marketing from '@/views/Marketing.vue'
import Applications from '@/views/Applications.vue'
import Settings from '@/views/Settings.vue'
import Connex from '@/views/3x.vue'
import Convention from '@/components/3x/interactions/Convention.vue'
// import AuthService from '@/services/auth.service'
import AadCallback from '@/views/AadCallback.vue'
// import LoginRedirect from '@/views/LoginRedirect.vue'
import Reports from '@/views/Reports.vue'
import MobileApps from '@/views/MobileApps.vue'
import AppName from '@/views/AppName.vue'
import VideoLibrary from '@/views/VideoLibrary.vue'
import Events from '@/views/Events.vue'
import EventDetail from '@/views/EventDetail.vue'
import FormBuilder from '@/components/events/form-builder/FormBuilder.vue'
import Registrants from '@/components/events/Registrants.vue'
import EmailResponses from '@/components/events/EmailResponses.vue'
import EventURL from '@/components/events/EventURL.vue'
import EventVendors from '@/components/events/EventVendors.vue'
import KOLs from '@/views/KOLs.vue'
import KOLProfile from '@/views/KOLProfile.vue'
import Profiles from '@/components/3x/Profiles.vue'
import ProfileSettings from '@/components/3x/ProfileSettings.vue'
import KOLDetails from '@/components/3x/KOLDetails.vue'
import ContractorAccess from '@/views/ContractorAccess.vue'
import BeliefMap from '@/views/BeliefMap.vue'
import BeliefMapSurvey from '@/views/BeliefMapSurvey.vue'
import Clients from '@/views/Clients.vue'
import Graphics from '@/views/Graphics.vue'
import RequestForms from '@/views/RequestForms.vue'
import RequestFormsBuilder from '@/views/RequestFormsBuilder.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/aad-callback',
    name: 'aadcallback',
    component: AadCallback
  },
  {
    path: '/projects',
    name: 'projects',
    component: Projects,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/archived',
    name: 'archived',
    component: Archived,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/marketing',
    name: 'marketing',
    component: Marketing,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/employees',
    name: 'employees',
    component: Employees,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/temps',
    name: 'temps',
    component: Temps,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reports',
    name: 'reports',
    component: Reports,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/employee-portal',
    name: 'employee-portal',
    component: EmployeePortal,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/applications',
    name: 'applications',
    component: Applications,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/request-forms',
    name: 'request-forms',
    component: RequestForms,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/request-forms-builder',
    name: 'request-forms-builder',
    component: RequestFormsBuilder,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings/mobile-apps',
    name: 'mobile-apps',
    component: MobileApps,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings/mobile-apps/:name',
    name: 'app-name',
    component: AppName,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings/contractor-access',
    name: 'contractor-access',
    component: ContractorAccess,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/3x',
    name: '3connex',
    component: Connex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/3x/:id/conventions/:cid',
    name: 'convention',
    component: Convention,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/3x/:id/profiles/:pid',
    name: 'profiles',
    component: Profiles,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/3x/:id/profiles/:pid/kols/:kolid',
    name: 'kol',
    component: KOLDetails,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/3x/:id/profiles/:pid/settings',
    name: 'profile-settings',
    component: ProfileSettings,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/video-library',
    name: 'video-library',
    component: VideoLibrary,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/beliefmap-surveys',
    name: 'beliefmap',
    component: BeliefMap,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/beliefmap-surveys/:id',
    name: 'beliefmap-survey',
    component: BeliefMapSurvey,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/events',
    name: 'events',
    component: Events,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/clients',
    name: 'clients',
    component: Clients,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/graphics',
    name: 'graphics',
    component: Graphics,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/events/:id',
    name: 'eventDetail',
    component: EventDetail,
    children: [
      {
        path: 'form-builder',
        component: FormBuilder,
        name: 'form-builder'
      },
      {
        path: 'email-responses',
        component: EmailResponses,
        name: 'email-responses'
      },
      {
        path: 'registrants',
        component: Registrants,
        name: 'registrants'
      },
      {
        path: 'event-url',
        component: EventURL,
        name: 'event-url'
      },
      {
        path: 'vendor-access',
        component: EventVendors,
        name: 'vendor-access'
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/kols',
    name: 'kols',
    component: KOLs,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/kols/:id',
    name: 'kolprofile',
    component: KOLProfile,
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// const openRoutes = ['login'];
router.beforeEach((to, from, next) => {
  // let authService = new AuthService()
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // if (authService.isLoggedIn()) {
    //   // console.log(to.path)
    //   // authService.getToken().then(token => {
    //   //   // console.log('Expires: ' + token.expiresOn)
    //   // })
    //   next()
    // } else {
    next()
    // }
  } else {
    next()
  }
})

export default router
