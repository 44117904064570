<template>
  <div>
      <form>
        <div class="p-3 row">
          <div v-for="item in jsonForm" :key="item.id" v-bind:class="{'col-12': item.columnSize == 12, 'col-6': item.columnSize == 6 }">
            <!---------- Input ---------->
            <div class="form-group" v-if="item.type == 'input'">
              <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
              <input :type="item.inputType" class="form-control" :placeholder="item.placeholderText">
            </div>
            <!---------- Textarea ---------->
            <div class="form-group" v-if="item.type == 'textarea'">
              <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
              <textarea cols="30" rows="2" class="form-control" :placeholder="item.placeholderText"></textarea>
            </div>
            <!---------- Dropdown ---------->
            <div v-if="item.type == 'select'">
              <div class="form-group">
                <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
                <select class="form-control">
                  <option value="">{{item.placeholderText}}</option>
                  <option v-for="(choice, index) in item.choices" :key="index">
                    {{ choice }}
                  </option>
                </select>
              </div>
              <!-- Other -->
              <div v-if="item.other === 'Yes'" class="form-group">
                <textarea :name="['other-']+item.id" :id="['other-']+item.classNme" cols="30" rows="1" class="form-control"></textarea>
              </div>
            </div>
            <!---------- Project Dropdown ---------->
            <div v-if="item.type == 'project-select'">
              <div class="form-group">
                <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
                <multiselect :options="projects" :show-labels="false" :allow-empty="false" :placeholder="item.placeholderText"></multiselect>
              </div>
              <!-- Other -->
              <div v-if="item.other === 'Yes'" class="form-group">
                <textarea :name="['other-']+item.id" :id="['other-']+item.classNme" cols="30" rows="1" class="form-control"></textarea>
              </div>
            </div>
            <!---------- Employee Dropdown ---------->
            <div v-if="item.type == 'employee-select'">
              <div class="form-group">
                <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
                <multiselect :options="projectManagers" :show-labels="false" :allow-empty="false" :placeholder="item.placeholderText"></multiselect>
              </div>
              <!-- Other -->
              <div v-if="item.other === 'Yes'" class="form-group">
                <textarea :name="['other-']+item.id" :id="['other-']+item.classNme" cols="30" rows="1" class="form-control"></textarea>
              </div>
            </div>
            <!---------- Checkbox ---------->
            <div v-if="item.type == 'checkbox'">
              <div class="form-group" v-if="item.display === 'standard'">
                <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label> <span class="text-info" v-if="item.limit === 'Yes'"> (Select up to {{item.limitAllowed}})</span>
                <span class="text-info" v-else> (Select all that apply)</span>
                <div class="clearfix"></div>
                <div class="form-check" v-for="(choice, index) in item.choices" :key="index">
                  <input class="form-check-input" type="checkbox">
                  <label class="form-check-label">
                    {{ choice }}
                  </label>
                </div>
              </div>

              <div class="form-group" v-else>
                <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label> <span class="text-info" v-if="item.limit === 'Yes'"> (Select up to {{item.limitAllowed}})</span>
                <div class="clearfix"></div>
                <div class="form-check form-check-inline" v-for="(choice, index) in item.choices" :key="index">
                  <input class="form-check-input" type="checkbox">
                  <label class="form-check-label">
                    {{ choice }}
                  </label>
                </div>
              </div>

              <!-- Other -->
              <div v-if="item.other === 'Yes'" class="form-group">
                <textarea :name="['other-']+item.id" :id="['other-']+item.className" cols="30" rows="1" class="form-control"></textarea>
              </div>
            </div>
            <!---------- Radio ---------->
            <div v-if="item.type == 'radio'">
              <div class="form-group" v-if="item.display === 'standard'">
                <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
                <div class="clearfix"></div>
                <div class="form-check" v-for="(choice, index) in item.choices" :key="index">
                  <input class="form-check-input" type="radio">
                  <label class="form-check-label">
                    {{ choice }}
                  </label>
                </div>
              </div>

              <div class="form-group" v-else>
                <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
                <div class="clearfix"></div>
                <div class="form-check form-check-inline" v-for="(choice, index) in item.choices" :key="index">
                  <input class="form-check-input" type="radio">
                  <label class="form-check-label">
                    {{ choice }}
                  </label>
                </div>
              </div>

              <!-- Other -->
              <div v-if="item.other === 'Yes'" class="form-group">
                <textarea :name="['other-']+item.id" :id="['other-']+item.className" cols="30" rows="1" class="form-control"></textarea>
              </div>
            </div>
            <!---------- Switch ---------->
            <div v-if="item.type == 'switch'">
              <div class="form-group">
                <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label><br />
                <label class="switch">
                  <input type="checkbox">
                  <div class="slider round"></div>
                </label>
              </div>
            </div>
            <!---------- Date ---------->
            <div v-if="item.type == 'date'">
              <div class="form-group">
                <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
                <b-form-datepicker today-button reset-button close-button locale="en" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" :placeholder="item.placeholderText"></b-form-datepicker>
                <!-- <datepicker format="MM/dd/yyyy" :bootstrap-styling="true" :clear-button="true" :placeholder="item.placeholderText"></datepicker> -->
              </div>
            </div>
            <!---------- Time ---------->
            <div v-if="item.type == 'time'">
              <div class="form-group">
                <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label><br>
                <b-form-timepicker :placeholder="item.placeholderText" now-button reset-button></b-form-timepicker>
                <!-- <vue-timepicker format="hh:mm A" input-class="form-control time-background" input-width="100%" :placeholder="item.placeholderText"></vue-timepicker> -->
              </div>
            </div>
            <!---------- Button ---------->
            <div v-if="item.type == 'button'">
              <div class="form-group">
                <button class="btn" :class="[ 'btn-' + item.buttonSize, 'btn-' + item.buttonType ]">{{item.buttonText}}</button>
              </div>
            </div>
            <!---------- Clearfix ---------->
            <div v-if="item.type == 'clearfix'">
              <div class="clearfix"></div>
            </div>

            <!---------- US Address ---------->
            <div v-if="item.type == 'us-address'">
              <div class="row">
                <div class="form-group col-6" v-for="(item, index) in item.customFields" :key="index">
                  <span class="text-danger" v-show="item.required">* </span><label>{{item.name}}</label>
                  <select v-if="item.name == 'State'" class="form-control">
                    <option value="">Select {{item.name}}</option>
                    <option v-for="(choice, index) in item.values" :key="index">
                      {{ choice }}
                    </option>
                  </select>
                  <input v-else :type="item.inputType" class="form-control" :placeholder="item.name">
                </div>
              </div>
            </div>

            <!---------- Contact ---------->
            <div v-if="item.type == 'contact'">
              <div class="row py-3">
                <div class="col-12">
                  <h5>{{titleCase(item.type)}}</h5>
                </div>
              </div>
              <div class="row">
                <div class="form-group" v-bind:class="{'col-12': item.columnSize == 'col-12', 'col-6': item.columnSize == 'col-6'}" v-for="(item, index) in item.customFields" :key="index">
                  <span class="text-danger" v-show="item.required">* </span><label>{{item.name}}</label>
                  <input :type="item.inputType" class="form-control" :placeholder="item.name">
                </div>
              </div>
            </div>

            <!---------- General, Hotel, Flights, Meals ---------->
            <div v-if="item.type == 'general' || item.type == 'hotel' || item.type == 'flights' || item.type == 'meals'">
              <div class="row py-3">
                <div class="col-12">
                  <h5>{{titleCase(item.type)}}</h5>
                </div>
              </div>
              <div class="row">
                <div class="form-group" v-bind:class="{'col-12': item.columnSize == 'col-12', 'col-6': item.columnSize == 'col-6'}" v-for="(item, index) in item.customFields" :key="index">
                  <span class="text-danger" v-show="item.required">* </span><label>{{item.name}}</label>
                  <div v-if="item.inputType == 'radio'" >
                    <div class="form-check form-check-inline" v-for="(choice, index) in item.choices" :key="index">
                      <input class="form-check-input" type="radio">
                      <label class="form-check-label">
                        {{ choice }}
                      </label>
                    </div>
                  </div>
                  <input v-else-if="item.inputType == 'birth-date'" class="form-control" :placeholder="item.placeholderText">
                  <b-form-datepicker v-else-if="item.inputType == 'date'" today-button reset-button close-button locale="en" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" :placeholder="item.placeholderText"></b-form-datepicker>
                  <b-form-timepicker v-else-if="item.inputType == 'time'" :placeholder="item.placeholderText" now-button reset-button></b-form-timepicker>
                  <!-- <datepicker v-else-if="item.inputType == 'date'" format="MM/dd/yyyy" :bootstrap-styling="true" :clear-button="true" :placeholder="item.placeholderText"></datepicker>
                  <vue-timepicker v-else-if="item.inputType == 'time'" format="hh:mm A" input-class="form-control time-background" input-width="100%" :placeholder="item.placeholderText"></vue-timepicker> -->
                  <textarea v-else-if="item.inputType == 'textarea'" cols="30" rows="2" class="form-control" :placeholder="item.placeholderText"></textarea>
                  <input v-else-if="item.clearfix" :type="item.inputType" class="form-control w-50-clearfix" :placeholder="item.name">
                  <input v-else :type="item.inputType" class="form-control" :placeholder="item.name">
                </div>
              </div>
            </div>

            <!---------- Header Image ---------->
            <div v-if="item.type == 'image'" style="margin-bottom: 35px;">
              <div class="row">
                <div class="col-12">
                  <img :src="item.imageSource" :alt="item.imageAltText" :width="item.imageWidth" class="img-fluid">
                </div>
              </div>
            </div>

            <!---------- Cover Letter ---------->
            <div v-if="item.type == 'word'" style="margin-bottom: 35px;">
              <div class="row">
                <div class="col-12">
                  <div v-html="item.textValue"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
  </div>
</template>
<script>
// import Datepicker from 'vuejs-datepicker'
// import VueTimepicker from 'vue2-timepicker'
import eventService from '@/services/event.service'
import { forEach } from 'lodash'
export default {
  name: 'GenerateForm',
  components: {
    // Datepicker,
    // VueTimepicker
  },
  props: ['jsonForm'],
  data () {
    return {
      projects: [],
      projectManagers: []
    }
  },
  methods: {
    titleCase: function (str) {
      str = str.toLowerCase().split(' ')
      for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
      }
      return str.join(' ')
    },
    getProjects: function () {
      eventService.getProjects().then((response) => {
        const res = response
        const items = []
        forEach(res, (item) => {
          items.push(item.name)
        })
        this.projects = items
        console.log(this.projects)
      })
    },
    getProjectManagers: function () {
      eventService.getProjectManagers().then((response) => {
        this.projectManagers = response.users
      })
    }
  },
  mounted() {
    this.getProjects()
    this.getProjectManagers()
  }
}
</script>
<style scoped>
.w-50-clearfix {
  width: calc(50% - 15px) !important;
}
/*--------------------------------------------------
  Switch
--------------------------------------------------*/
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 18px;
}

.switch input { display: none }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #009370;
}

input:focus+.slider {
  box-shadow: 0 0 1px #009370;
}

input:checked+.slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

.slider.round { border-radius: 34px }

.slider.round:before { border-radius: 50% }

input[type="text"], textarea {
outline: none;
box-shadow:none !important;
border:1px solid #ccc;
}

.remove-border {
  border-left: 0px !important;
}
.input-group-prepend {
  padding: 10px;
  background-color: #fff;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
</style>
