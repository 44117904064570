import { http } from '@/config/base-url'
import authHeader from '@/config/auth-header'

export default {
  getEmployeeReport (employee, start, end) {
    return http.get(`v2/reports/${employee}/${start}/${end}`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },
  getDepartmentReport (department, start, end) {
    return http.get(`v2/reports/department/${department}/${start}/${end}`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },
  getDepartmentReportByEmployee (department, start, end) {
    return http.get(`v2/reports/department/employee/${department}/${start}/${end}`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },
  getClientReport (client, start, end) {
    return http.get(`v2/reports/client/${client}/${start}/${end}`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },
  getProjectReport (project, start, end) {
    const proj = {
      project: project
    }
    return http.post(`v2/reports/project/${start}/${end}`, proj, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },
  getProjectReportByEmployee (project, start, end) {
    const proj = {
      project: project
    }
    return http.post(`v2/reports/project/employee/${start}/${end}`, proj, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },
  getStatusReport (start, end) {
    return http.get(`v2/reports/status/${start}/${end}`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },
  getPayrollReport (start, end) {
    return http.get(`v2/reports/payroll/${start}/${end}`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  }
}
