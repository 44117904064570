<template>
<div style="background-color: #f4f5f7; position: fixed; height: 100%; width: 100%;">
    <div style="padding: 25px;">
      <div class="clearfix" style="padding-bottom: 25px;">
        <div class="float-left" style="padding-top: 10px; text-align:left;">
          <h3 style="margin-top:0px;">BeliefMap Surveys</h3>
        </div>
        <div class="float-right" style="padding-top: 10px; text-align:right;">
            <a class="btn btn-primary text-uppercase" style="color:#fff;" data-toggle="modal" data-backdrop="static" data-keyboard="false" v-on:click="addSurvey()">Add Survey</a>
        </div>
      </div>
      <div class="card" style="height: 513px;">
        <div class="card-header" style="border-bottom: 0px; background: #fff;">
          <div class="d-flex justify-content-sm-end">
            <div class="form-group mx-sm-6 mb-0">
              <input type="text" v-model="search" class="form-control shadow-none" placeholder="Search">
            </div>
          </div>
        </div>
        <div class="card-body" style="padding:0px; border-left: 0px; border-right: 0px; border-bottom: 0px;" ref="tableCard">
          <table class="table table-striped table-bordered" style="margin-bottom: 0px; border-left: 0px; border-right: 0px;">
            <thead class="thead-light">
              <tr>
                <th width="82%">Survey</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in sortedItems" :key="item.survey_id">
                <td>{{item.name}}</td>
                <td>
                  <button type="button" class="btn btn-sm btn-outline-primary mr-3" data-toggle="modal" data-backdrop="static" data-keyboard="false" v-on:click="editSurvey(item)">Edit</button>
                  <!-- <router-link :to="{ path: 'surveys', params: { surveyId: item.survey_id}}" class="btn btn-sm btn-outline-success mr-3">View Details</router-link> -->
                  <button type="button" class="btn btn-sm btn-outline-success mr-3" v-on:click="viewDetails(item)">View</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer" style="background: #fff; border-top: 0px;">
          <div class="d-flex justify-content-between">
            <div style="padding-top: 8px;">Showing {{getCurrentRows()}} of {{itemsLength}} rows</div>
            <nav>
              <ul class="pagination" style="margin-bottom: 0px;">
                <li class="page-item" v-bind:class="{ disabled: currentPage === 1 }"><button class="page-link" @click="prevPage">Previous</button></li>
                <li class="page-item" v-for="(n, index) in totalPages" :key="index" v-bind:class="{ active: currentPage === n}"><button class="page-link" @click="goToPage(n)">{{n}}</button></li>
                <li class="page-item" v-bind:class="{ disabled: currentPage === totalPages }"><button class="page-link" @click="nextPage">Next</button></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal">
        <survey-modal @close="closeModal" @item="updateSurveys" :survey="surveyItem"/>
    </div>
</div>
</template>

<script>
// import JQuery from 'jquery'
// import moment from 'moment'
import BeliefMapService from '@/services/beliefmap.service'
import SurveyModal from '@/components/beliefmap/SurveyModal.vue'
import { forEach } from 'lodash'

export default {
  name: 'beliefmap',
  components: {
    SurveyModal
  },
  data () {
    return {
      showDetails: false,
      id: '',
      titleName: '',
      activeItem: 'introduction',
      surveys: [],
      surveyItem: {},
      showModal: false,
      showResultsModal: false,
      pageSize: 6,
      currentPage: 1,
      totalPages: 0,
      isCurrentPage: false,
      itemsLength: 0,
      search: ''
    }
  },
  methods: {
    getSurveys () {
      let loader = this.$loading.show({
        container: this.$refs.tableCard,
        loader: 'dots'
      })
      BeliefMapService.getSurveys().then((response) => {
        this.surveys = response
        console.log(response)
        this.itemsLength = this.surveys.length
        this.totalPages = Math.round(this.surveys.length / this.pageSize)
        loader.hide()
      })
    },
    addSurvey () {
      this.showModal = true
      this.surveyItem = {
        name: 'New Survey',
        type: 'new'
      }
    },
    editSurvey (item) {
      console.log(item)
      this.showModal = true
      item.type = 'edit'
      this.surveyItem = item
    },
    closeModal () {
      this.showModal = false
    },
    updateSurveys (item) {
      this.showModal = false
      this.getSurveys()
      // if (item.type === 'add') {
      //   this.surveys.push(item.survey)
      //   this.itemsLength = this.surveys.length
      // } else {
      //   let index = this.surveys.findIndex(s => s.survey_id === item.survey.survey_id)
      //   this.surveys[index] = item.survey
      // }
    },
    viewDetails (item) {
      this.$router.push({ path: `/beliefmap-surveys/${item.survey_id}` })
    },
    nextPage: function () {
      if ((this.currentPage * this.pageSize) < this.surveys.length) {
        this.currentPage++
      }
    },
    prevPage: function () {
      if (this.currentPage > 1) {
        this.currentPage--
      }
    },
    goToPage: function (n) {
      this.currentPage = n
    },
    getCurrentRows: function () {
      let lastentry = 0
      let firstentry = 0
      if (this.currentPage === this.totalPages) {
        const lastentryTemp = this.currentPage * this.pageSize
        firstentry = (lastentryTemp - this.pageSize) + 1
        lastentry = this.itemsLength
      } else {
        lastentry = this.currentPage * this.pageSize
        firstentry = (lastentry - this.pageSize) + 1
      }
      return firstentry + ' to ' + lastentry
    },
    getRows: function (surveys) {
      let surveyData = []
      forEach(surveys, (item) => {
        if (item.name.toLowerCase().includes(this.search.toLowerCase())) {
          surveyData.push(item)
        }
      })
      return surveyData.filter((row, index) => {
        let start = (this.currentPage - 1) * this.pageSize
        let end = this.currentPage * this.pageSize
        if (surveyData.length <= this.pageSize) {
          this.totalPages = 1
        } else {
          this.totalPages = Math.round(surveyData.length / this.pageSize)
        }
        this.getCurrentRows()
        this.itemsLength = surveyData.length

        if (index >= start && index < end) {
          return true
        }
      })
    }
  },
  computed: {
    // sortedItems:function() {
    //   return this.surveys.filter((row, index) => {
    //     let start = (this.currentPage-1)*this.pageSize;
    //     let end = this.currentPage*this.pageSize;
    //     if(index >= start && index < end) return true;
    //   }).filter((item) => {
    //     return item.name.toLowerCase().includes(this.search.toLowerCase());
    //   });
    // }
    sortedItems: function () {
      return this.getRows(this.surveys)
    }
  },
  mounted () {
    this.getSurveys()
  }
}
</script>

<style scoped>

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 64px;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 28px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

/*
 * Content
 */

[role="main"] {
  padding-top: 133px; /* Space for fixed navbar */
}

@media (min-width: 768px) {
  [role="main"] {
    padding-top: 0px; /* Space for fixed navbar */
  }
}

.nav-link {
    cursor:pointer;
}

.btn:focus,.btn:active, .page-link {
   outline: none !important;
   box-shadow: none;
}
</style>
