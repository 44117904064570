<template>
  <span>
   <button v-if="column == '12'" class="btn btn-sm text-success" style="margin-right: 30px"><strong>100%</strong></button>
    <button v-if="column == '6'" class="btn btn-sm text-warning" style="margin-right: 30px"><strong>50%</strong></button>
  </span>
</template>
<script>
export default {
  props: ['column']
}
</script>
<style scoped>
button { cursor:default; background-color: transparent; }
button:hover { color: #3c763d }
</style>
