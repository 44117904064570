<template>
  <div style="padding: 25px;">
    <div class="row">
      <div class="col-4" style="padding-bottom: 30px;">
        <div class="card shadow p-3 mb-5 bg-white rounded">
          <div class="card-body">
            <div class="row">
              <div class="col-3" style="padding-right: 0px;">
                <img src="https://imgs.simpsonhealthcare.com/simpson/apps/timesheet-icon.png" alt="Simpson Timesheet Icon" width="75" style="border-radius: 12px;">
              </div>
              <div class="col-9" style="padding-left: 15px;">
                <h5 class="card-title">Simpson Timesheet<br><small>v. 1.4.0 (Mac)</small><br><small>v. 1.4.1 (Windows 10)</small></h5>
              </div>
            </div>
            <p class="card-text" style="padding-top: 15px;">Tool which allows time-tracking of projects, submission of individual timesheets, reporting, and supervisor approvals.</p>
            <div class="d-flex justify-content-between">
              <a href="https://assets.simpsonhealthcare.com/simpson/timesheet/desktop/latest/simpson-timesheet-1.4.0-mac.dmg"><img src="https://imgs.simpsonhealthcare.com/simpson/apps/download-for-mac.png" alt="Download for MacOS image" width="150"></a>
              <a href="https://assets.simpsonhealthcare.com/simpson/timesheet/desktop/latest/simpson-timesheet-1.4.1-win.exe"><img src="https://imgs.simpsonhealthcare.com/simpson/apps/download-for-windows.png" alt="Download for Windows image" width="150"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Applications',
  data () {
    return {
    }
  },
  methods: { },
  mounted () { }
}
</script>

<style scoped>
</style>
