import { http } from '@/config/base-url'
import authHeader from '@/config/auth-header'

export default {

  getActiveClients () {
    return http.get('v2/settings/client-names/active', {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  getActiveProjectStatus () {
    return http.get('v2/settings/project_status/active', {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  getActiveStaffPositions () {
    return http.get('v2/settings/staff-positions/active', {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  getData (type) {
    return http.get(`v2/settings/${type}`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  getCrossBrands (client) {
    return http.get(`v2/users/cross-brands/${client}`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  saveData (type, item) {
    if (item.id) {
      return http.put(`v2/settings/${type}/${item.id}`, item, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    } else {
      return http.post(`v2/settings/${type}`, item, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    }
  },
  getClients (client) {
    return http.get(`v1/users/clients/${client}`, {
      headers: authHeader()
    }).then((res) => {
      return res.data
    }).catch((error) => {
      console.log(error)
    })
  },

  saveClient (data) {
    if (data.id) {
      return http.put(`v1/users/clients/${data.id}`, data, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    } else {
      return http.post('v1/users/clients', data, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    }
  }
}
