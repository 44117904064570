import { http } from '@/config/base-url'
import authHeader from '@/config/auth-header'

export default {

  getSurveys () {
    return http.get('v2/beliefmap/surveys', {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  getSurvey (id) {
    return http.get(`v2/beliefmap/surveys/${id}`, {
      headers: authHeader()
    }).then((res) => {
      const item = res.data
      return item
    }).catch((error) => {
      console.log(error)
    })
  },

  saveSurvey (survey) {
    if (survey.survey_id) {
      return http.put(`v2/beliefmap/surveys/${survey.survey_id}`, survey, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    } else {
      return http.post(`v2/beliefmap/surveys`, survey, {
        headers: authHeader()
      }).then((res) => {
        return res
      }).catch((error) => {
        console.log(error)
      })
    }
  },

  publishSurvey (id) {
    return http.get(`v2/beliefmap/surveys/${id}/publish`, {
      headers: authHeader()
    }).then((res) => {
      return res
    }).catch((error) => {
      console.log(error)
    })
  },

  getAllSurveyQuestions (id) {
    return http.get(`v2/beliefmap/surveys/${id}/questions`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  saveQuestion (question) {
    if (question.question_id) {
      return http.put(`v2/beliefmap/questions/${question.question_id}`, question, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    } else {
      return http.post('v2/beliefmap/questions', question, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    }
  },

  deleteQuestion (id) {
    return http.delete(`v2/beliefmap/questions/${id}`, {
      headers: authHeader()
    }).then((res) => {
      return res.data
    }).catch((error) => {
      console.log(error)
    })
  },

  deleteQuestionAnswers (id) {
    return http.delete(`v2/beliefmap/answers/${id}`, {
      headers: authHeader()
    }).then((res) => {
      return res.data
    }).catch((error) => {
      console.log(error)
    })
  },

  deleteAllQuestionAnswers (id) {
    http.delete(`v2/beliefmap/questions/${id}/answers`, {
      headers: authHeader()
    }).then((res) => {
      console(res.data)
    }).catch((error) => {
      console.log(error)
    })
  },

  downloadSurveyResults (surveyId) {
    return http.get(`v2/beliefmap/surveys/${surveyId}/download-results`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  getSurveySummaryResults (surveyId) {
    return http.get(`v2/beliefmap/surveys/${surveyId}/summary-results`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  getSurveyDashboard (surveyId) {
    return http.get(`v2/beliefmap/surveys/${surveyId}/dashboard-results`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  getSurveyParticipantResults (surveyId) {
    return http.get(`v2/beliefmap/surveys/${surveyId}/participant-results`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },

  saveQuestionAnswers (answer) {
    if (answer.answer_id) {
      return http.put(`v2/beliefmap/answers/${answer.answer_id}`, answer, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    } else {
      return http.post('v2/beliefmap/answers', answer, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    }
  },

  getArchetypes () {
    return http.get(`v2/beliefmap/archetypes`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  }

}
