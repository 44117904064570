export default (n) => {
  if (n.length > 1) {
    let newArray = n.split('')
    let paymentArray = newArray.map(Number)
    let payTotal = paymentArray.reduce((a, b) => a + b, 0)

    return payTotal
  } else {
    return n
  }
}
