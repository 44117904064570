<template>
  <div style="padding: 25px;">
    <nav class="col-md-2 d-none d-md-block bg-light sidebar">
      <div class="sidebar-sticky">
        <div style="padding: 25px 25px 15px 25px;">
          <h3 style="font-size:1.7rem;">Reports</h3>
        </div>
        <ul class="nav flex-column">
          <li class="nav-item" :class="{ 'active': view == 'employee' }">
            <a class="nav-link" style="cursor:pointer;" v-on:click="showView('employee')">
              <div class="d-flex bd-highlight">
                <h6 class="p-2 m-0 text-uppercase">
                  Employee
                </h6>
              </div>
            </a>
          </li>
          <li class="nav-item" :class="{ 'active': view == 'projects' }">
            <a class="nav-link" style="cursor:pointer;" v-on:click="showView('projects')">
              <div class="d-flex bd-highlight">
                <h6 class="p-2 m-0 text-uppercase">
                  Projects
                </h6>
              </div>
            </a>
          </li>
          <li class="nav-item" :class="{ 'active': view == 'department' }">
            <a class="nav-link" style="cursor:pointer;" v-on:click="showView('department')">
              <div class="d-flex bd-highlight">
                <h6 class="p-2 m-0 text-uppercase">
                  Department
                </h6>
              </div>
            </a>
          </li>
          <li class="nav-item" :class="{ 'active': view == 'clients' }">
            <a class="nav-link" style="cursor:pointer;" v-on:click="showView('clients')">
              <div class="d-flex bd-highlight">
                <h6 class="p-2 m-0 text-uppercase">
                  Clients
                </h6>
              </div>
            </a>
          </li>
          <li class="nav-item" :class="{ 'active': view == 'company' }">
            <a class="nav-link" style="cursor:pointer;" v-on:click="showView('company')">
              <div class="d-flex bd-highlight">
                <h6 class="p-2 m-0 text-uppercase">
                  Company
                </h6>
              </div>
            </a>
          </li>
          <li class="nav-item" :class="{ 'active': view == 'custom' }">
            <a class="nav-link" style="cursor:pointer;" v-on:click="showView('custom')">
              <div class="d-flex bd-highlight">
                <h6 class="p-2 m-0 text-uppercase">
                  Custom
                </h6>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </nav>
    <main role="main" class="col-md-8 ml-sm-auto col-lg-10 px-0 ml-0" style="position: fixed; top: 55px; left: 0px; right: 0px; bottom: 0px; padding-top: 0px; padding-bottom: 25px; background-color: #f4f5f7; overflow-y:auto;">
      <div style="padding: 0px;" class="col-md-12">
        <div v-if="view === 'employee'">
          <div class="bg-light d-none d-md-block p-0" style="bottom: 0; position: fixed; top: 54px;">
            <div style="padding: 15px; border-right: 1px solid #ccc;">
              <input type="search" class="form-control" v-model="employeeSearch" placeholder="Search Employee">
            </div>
            <div class="sidebar-sticky" style="border-right: 1px solid #ccc;">
              <ul class="nav flex-column">
                <li class="nav-item" v-for="item in activeEmployees" :key="item.id">
                    <a class="nav-link" style="cursor:pointer;" v-bind:class="{ 'employee-active': selected == item.id }" v-on:click="showEmployee(item)">
                      <div class="d-flex bd-highlight">
                        <div class="p-2">
                          <img :src="item.img" width="55" height="55" class="rounded-circle"/>
                        </div>
                        <h5 class="p-2 m-1">
                          {{item.name}}<br />
                          <small class="text-muted">{{item.email}}</small>
                        </h5>
                      </div>
                    </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-8 ml-sm-auto px-0 ml-0">
            <div class="col-md-11 d-flex justify-content-end p-0 mt-4">
              <date-range-picker v-model="dateRange" opens="left" :ranges="ranges" @update="getDetailsForWeek">
                <div slot="input" slot-scope="picker" style="min-width: 350px;">
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </div>
              </date-range-picker>
            </div>
            <div class="card col-md-11 mt-2 p-0">
              <div class="card-header" style="background-color: #fff; border-bottom: 1px solid #ccc;">
                <div class="d-flex justify-content-between">
                  <h4 style="margin-bottom: 0px;">{{employee}}</h4>
                  <a class="btn btn-primary" :href="downloadEmployeeReport(employee)"><font-awesome-icon icon="cloud-download-alt" style="font-size: 16px;"/> Download</a>
                </div>
              </div>
              <div class="card-body" ref="employeeCard" style="padding: 0px;">
                <!-- <input type="search" class="form-control" style="width: 100%; border: none;" v-model="employeeSearch" placeholder="Search Project..."> -->
                <div class="table-card" style="height: 400px; overflow-y: auto;">
                  <div style="padding: 0px; border: 0px;">
                    <table class="table table-striped" style="margin-bottom: 0px;">
                        <tr v-for="item in employeeReport.timesheets" :key="item.project_name">
                          <td width="90%" :class="{'pending': item.status == 2}">{{item.project_name}}</td>
                          <td width="10%" :class="{'pending': item.status == 2}" style="text-align: right;">{{item.total_time}}</td>
                        </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card-footer text-muted" style="padding: 0px;">
                <table class="table" style="margin-bottom: 0px; font-weight: bold;">
                  <tr>
                    <td width="90%" style="text-align: right; border-top:none;">Total</td>
                    <td width="10%" style="text-align: right; border-top:none;">{{employeeReport.final_time}}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div v-if="view === 'projects'">
          <div class="bg-light d-none d-md-block p-0" style="bottom: 0; position: fixed; top: 54px;">
            <div style="padding: 15px; border-right: 1px solid #ccc;">
              <input type="search" class="form-control" v-model="projectSearch" placeholder="Search Project">
            </div>
            <div class="sidebar-sticky" style="border-right: 1px solid #ccc;">
              <ul class="nav flex-column" style="width: 400px;">
                <li class="nav-item" v-for="item in activeProjects" :key="item.id">
                    <a class="nav-link" style="cursor:pointer;" v-bind:class="{ 'employee-active': selected == item.id }" v-on:click="showProject(item)">
                      <div class="d-flex bd-highlight">
                        <h5 class="p-2 m-1">
                          {{item.name}}<br />
                        </h5>
                      </div>
                    </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-8 ml-sm-auto px-0 ml-0">
            <div class="col-md-11 d-flex justify-content-between p-0 mt-4">
              <multiselect class="w-25" :options="projectStatus" :show-labels="false" placeholder="Select view..." v-model="projectView" @select="showProjectView"></multiselect>
              <date-range-picker v-model="dateRange" opens="left" :ranges="ranges" @update="getProjectDetailsForWeek">
                <div slot="input" slot-scope="picker" style="min-width: 350px;">
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </div>
              </date-range-picker>
            </div>
            <div class="card col-md-11 mt-2 p-0">
              <div class="card-header" style="background-color: #fff; border-bottom: 1px solid #ccc;">
                <div class="d-flex justify-content-between">
                  <h4 style="margin-bottom: 0px;">{{project}}</h4>
                  <a v-if="projectView == 'Department'" class="btn btn-primary" :href="downloadProjectReport(project)"><font-awesome-icon icon="cloud-download-alt" style="font-size: 16px;"/> Download</a>
                </div>
              </div>
              <div class="card-body" ref="projectCard" style="padding: 0px;">
                <div class="table-card" style="height: 400px; overflow-y: auto;">
                  <div style="padding: 0px; border: 0px;">
                    <ul class="list-group">
                      <li class="list-group-item" v-for="(item, index) in projectReport.timesheets" :key="index">
                        <div v-if="projectView == 'Department'" >
                          <div class="d-flex justify-content-between">
                            <div style="color: #006AB6; cursor:pointer;" @click="showProjectDetails(index)">
                              <font-awesome-icon :icon="getProjectIcon(item.department)" style="font-size: 16px;"/>&nbsp;
                              <span style="font-weight: 600;">{{item.department}}</span>
                            </div>
                            <span style="font-weight: 600;">{{item.total_time}}</span>
                          </div>
                          <div v-if="details && projectIndex == index" style="padding: 15px 0px;">
                            <table class="table table-striped table-bordered" style="margin-bottom: 0px;">
                              <tr>
                                <th>Employee</th>
                                <th style="text-align:right;">Hours</th>
                              </tr>
                              <tr v-for="(employee, index) in item.employees" :key="index">
                                <td :class="{'pending': item.status == 2}" width="90%">{{employee.employee}}</td>
                                <td :class="{'pending': item.status == 2}" width="10%" style="text-align: right;">{{employee.total_time}}</td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div v-if="projectView == 'Employee'" class="d-flex justify-content-between">
                          <span :class="{'pending': item.status == 2}" style="font-weight: 600;">{{item.employees}}</span>
                          <span :class="{'pending': item.status == 2}" style="font-weight: 600;">{{item.total_time}}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="card-footer text-muted" style="padding: 0px;">
                <table class="table" style="margin-bottom: 0px; font-weight: bold;">
                  <tr>
                    <td width="90%" style="text-align: right; border-top:none;">Total</td>
                    <td width="10%" style="text-align: right; border-top:none;">{{projectReport.final_time}}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div v-if="view === 'department'">
          <div class="bg-light d-none d-md-block p-0" style="bottom: 0; position: fixed; top: 54px;border-right: 1px solid #ccc;">
            <div class="sidebar-sticky">
              <ul class="nav flex-column">
                <li class="nav-item" v-for="item in departments" :key="item.id">
                    <a class="nav-link" style="cursor:pointer;" v-bind:class="{ 'employee-active': selected == item.id }" v-on:click="showDepartment(item)">
                      <div class="d-flex bd-highlight">
                        <h5 class="p-2 m-1">
                          {{item.name}}<br />
                        </h5>
                      </div>
                    </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-8 ml-sm-auto px-0 ml-0">
            <div class="col-md-11 d-flex justify-content-between p-0 mt-4">
              <multiselect class="w-25" :options="departmentStatus" :show-labels="false" placeholder="Select view..." v-model="departmentView" @select="showDepartmentView"></multiselect>
              <date-range-picker v-model="dateRange" opens="left" :ranges="ranges" @update="getDepartmentDetailsForWeek">
                <div slot="input" slot-scope="picker" style="min-width: 350px;">
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </div>
              </date-range-picker>
            </div>
            <div class="card col-md-11 mt-2 p-0">
              <div class="card-header" style="background-color: #fff; border-bottom: 1px solid #ccc;">
                <div class="d-flex justify-content-between">
                  <h4 style="margin-bottom: 0px;">{{department}}</h4>
                  <a class="btn btn-primary" :href="downloadDepartmentReport(department)"><font-awesome-icon icon="cloud-download-alt" style="font-size: 16px;"/> Download</a>
                </div>
              </div>
              <div class="card-body" ref="departmentCard" style="padding: 0px;">
                <div class="table-card" style="height: 400px; overflow-y: auto;">
                  <div style="padding: 0px; border: 0px;">
                    <ul class="list-group">
                      <li class="list-group-item" v-for="(item, index) in departmentReport.timesheets" :key="index">
                        <div v-if="departmentView == 'Project'">
                          <div class="d-flex justify-content-between">
                            <div style="color: #006AB6; cursor:pointer;" @click="showDetails(index)">
                              <font-awesome-icon :icon="getDepartmentIcon(index)" style="font-size: 16px;"/>&nbsp;
                              <span style="font-weight: 600;">{{item.project_name}}</span>
                            </div>
                            <span style="font-weight: 600;">{{item.total_time}}</span>
                          </div>
                          <div v-if="details && departmentIndex == index" style="padding: 15px 0px;">
                            <table class="table table-striped table-bordered" style="margin-bottom: 0px;">
                              <tr>
                                <th>Employee</th>
                                <th style="text-align:right;">Hours</th>
                              </tr>
                              <tr v-for="(employee, index) in item.employees" :key="index">
                                <td width="90%">{{employee.employee}}</td>
                                <td width="10%" style="text-align: right;">{{employee.total_time}}</td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div v-if="departmentView == 'Employee'">
                          <div class="d-flex justify-content-between">
                            <div style="color: #006AB6; cursor:pointer;" @click="showDetails(index)">
                              <font-awesome-icon :icon="getDepartmentIcon(index)" style="font-size: 16px;"/>&nbsp;
                              <span style="font-weight: 600;">{{item.employee}}</span>
                            </div>
                            <span style="font-weight: 600;">{{item.total_time}}</span>
                          </div>
                          <div v-if="details && departmentIndex == index" style="padding: 15px 0px;">
                            <table class="table table-striped table-bordered" style="margin-bottom: 0px;">
                              <tr>
                                <th>Project</th>
                                <th style="text-align:right;">Hours</th>
                              </tr>
                              <tr v-for="(project, index) in item.projects" :key="index">
                                <td width="90%" :class="{'pending': item.status == 2}">{{project.project_name}}</td>
                                <td width="10%" :class="{'pending': item.status == 2}" style="text-align: right;">{{project.total_time}}</td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="card-footer text-muted" style="padding: 0px;">
                <table class="table" style="margin-bottom: 0px; font-weight: bold;">
                  <tr>
                    <td width="90%" style="text-align: right; border-top:none;">Total</td>
                    <td width="10%" style="text-align: right; border-top:none;">{{departmentReport.final_time}}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div v-if="view === 'clients'">
          <div class="bg-light d-none d-md-block p-0" style="bottom: 0; position: fixed; top: 54px;">
            <div style="padding: 15px; border-right: 1px solid #ccc;">
              <input type="search" class="form-control" v-model="clientSearch" placeholder="Search Client">
            </div>
            <div class="sidebar-sticky" style="border-right: 1px solid #ccc;">
              <ul class="nav flex-column">
                <li class="nav-item" v-for="item in activeClients" :key="item">
                    <a class="nav-link" style="cursor:pointer;" v-bind:class="{ 'employee-active': selected == item }" v-on:click="showClient(item)">
                      <div class="d-flex bd-highlight">
                        <h5 class="p-2 m-1">
                          {{item}}<br />
                        </h5>
                      </div>
                    </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-8 ml-sm-auto px-0 ml-0">
            <div class="col-md-11 d-flex justify-content-end p-0 mt-4">
              <date-range-picker v-model="dateRange" opens="left" :ranges="ranges" @update="getClientDetailsForWeek">
                <div slot="input" slot-scope="picker" style="min-width: 350px;">
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </div>
              </date-range-picker>
            </div>
            <div class="card col-md-11 mt-2 p-0">
              <div class="card-header" style="background-color: #fff; border-bottom: 1px solid #ccc;">
                <div class="d-flex justify-content-between">
                  <h4 style="margin-bottom: 0px;">{{client}}</h4>
                </div>
              </div>
              <div class="card-body" ref="clientCard" style="padding: 0px;">
                <div class="table-card" style="height: 400px; overflow-y: auto;">
                  <div style="padding: 0px; border: 0px;">
                    <ul class="list-group">
                      <li class="list-group-item" v-for="item in clientReport.timesheets" :key="item.project_name">
                        <div class="d-flex justify-content-between">
                          <div style="color: #006AB6; cursor:pointer;" @click="showClientDetails(item.project_name)">
                            <font-awesome-icon :icon="getIcon(item.project_name)" style="font-size: 16px;"/>&nbsp;
                            <span style="font-weight: 600;">{{item.project_name}}</span>
                          </div>
                          <span style="font-weight: 600;">{{item.total_time}}</span>
                        </div>
                        <div v-if="details && clientIndex == item.project_name" style="padding: 15px 0px;">
                          <table class="table table-striped table-bordered" style="margin-bottom: 0px;">
                            <tr>
                              <th>Department</th>
                              <th style="text-align:right;">Hours</th>
                            </tr>
                            <tr v-for="(department, index) in item.departments" :key="index">
                              <td width="90%">{{department.department}}</td>
                              <td width="10%" style="text-align: right;">{{department.total_time}}</td>
                            </tr>
                          </table>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="card-footer text-muted" style="padding: 0px;">
                <table class="table" style="margin-bottom: 0px; font-weight: bold;">
                  <tr>
                    <td width="90%" style="text-align: right; border-top:none;">Total</td>
                    <td width="10%" style="text-align: right; border-top:none;">{{clientReport.final_time}}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div v-if="view === 'company'">
          <div class="bg-light d-none d-md-block p-0" style="bottom: 0; position: fixed; top: 54px;border-right: 1px solid #ccc;">
            <div class="sidebar-sticky">
              <ul class="nav flex-column" style="width: 400px;">
                <li class="nav-item" v-for="item in companies" :key="item">
                    <a class="nav-link" style="cursor:pointer;" v-bind:class="{ 'employee-active': companySelected == item }" v-on:click="showCompanyItem(item)">
                      <div class="d-flex bd-highlight">
                        <h5 class="p-2 m-1">
                          {{item}}<br />
                        </h5>
                      </div>
                    </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-8 ml-sm-auto px-0 ml-0">
            <div class="col-md-11 d-flex justify-content-end p-0 mt-4">
              <date-range-picker v-model="dateRange" opens="left" :ranges="ranges" @update="getCompanyDetailsForWeek">
                <div slot="input" slot-scope="picker" style="min-width: 350px;">
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </div>
              </date-range-picker>
            </div>
            <div class="card col-md-11 mt-2 p-0">
              <div class="card-header" style="background-color: #fff; border-bottom: 1px solid #ccc;">
                <div class="d-flex justify-content-between">
                  <h4 style="margin-bottom: 0px;">{{company}}</h4>
                  <a class="btn btn-primary" :href="downloadCompanyReport(company)"><font-awesome-icon icon="cloud-download-alt" style="font-size: 16px;"/> Download</a>
                </div>
              </div>
              <div class="card-body" style="padding: 0px;" ref="companyCard">
                <div class="table-card" style="height: 400px; overflow-y: auto;" >
                  <div style="padding: 0px; border: 0px;" v-if="company == 'Payroll'">
                    <ul class="list-group">
                      <li class="list-group-item">
                        <table class="table table-striped table-bordered" style="margin-bottom: 0px;">
                          <tr>
                            <th>Employee</th>
                            <th>Task Date</th>
                            <th>Project</th>
                            <th>TCP</th>
                            <th>Total Time</th>
                          </tr>
                          <tr v-for="(item, index) in companyReport.timesheets" :key="index">
                            <td width="20%" :class="{'pending': item.status == 2}">{{item.employee}}</td>
                            <td width="5%" :class="{'pending': item.status == 2}">{{item.task_date}}</td>
                            <td width="65%" :class="{'pending': item.status == 2}">{{item.project_name}}</td>
                            <td width="2%" :class="{'pending': item.status == 2}">{{item.tcp}}</td>
                            <td width="8%" :class="{'pending': item.status == 2}" style="text-align: right;">{{item.total_time}}</td>
                          </tr>
                        </table>
                      </li>
                    </ul>
                  </div>
                  <div style="padding: 0px; border: 0px;" v-if="company == 'Timesheet Status'">
                    <ul class="list-group">
                      <li class="list-group-item" v-for="item in companyReport.timesheets" :key="item.employee">
                        <div class="d-flex justify-content-between">
                          <div style="color: #006AB6;">
                            <font-awesome-icon :icon="getIcon(item.project_name)" style="font-size: 16px;"/>&nbsp;
                            <span style="font-weight: 600;">{{item.employee}}</span>
                          </div>
                        </div>
                        <div style="padding: 15px 0px;">
                          <table class="table table-striped table-bordered" style="margin-bottom: 0px;">
                            <tr>
                              <th>Week</th>
                              <th style="text-align:right;">Status</th>
                            </tr>
                            <tr v-for="(svalue, index) in item.status" :key="index">
                              <td width="80%">{{svalue.start_date}} - {{svalue.end_date}}</td>
                              <td width="20%" style="text-align: right;" :class="{ 'text-primary': svalue.status == 'Approved', 'text-success': svalue.status == 'Waiting Approval', 'text-danger': svalue.status == 'Not Submitted'}">{{svalue.status}}</td>
                            </tr>
                          </table>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div v-if="company != 'Timesheet Status'" class="card-footer text-muted" style="padding: 0px;">
                <table class="table" style="margin-bottom: 0px; font-weight: bold;">
                  <tr>
                    <td width="90%" style="text-align: right; border-top:none;">Total</td>
                    <td width="10%" style="text-align: right; border-top:none;">{{companyReport.final_time}}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div v-if="view === 'custom'">
          <div class="card shadow p-3 m-5 bg-white rounded w-50">
            <date-range-picker v-model="dateRange" opens="center" :ranges="ranges" @update="getClientDetailsForWeek">
              <div slot="input" slot-scope="picker" style="min-width: 350px;">
                {{ picker.startDate | date }} - {{ picker.endDate | date }}
              </div>
            </date-range-picker>
            <div style="padding-top: 15px;">
              <multiselect :options="customEmployees" :show-labels="false" :multiple="true" placeholder="Select employee..." v-model="custom.users"></multiselect>
            </div>
            <div class="d-flex justify-content-start" style="padding-top: 15px; padding-bottom: 15px;">
              <label>Show Notes</label>
              <label class="switch">
                <input type="checkbox" v-model="custom.show_notes">
                <div class="slider round"></div>
              </label>
            </div>
            <button class="btn btn-primary" @click="downloadCustomReport(custom)"><font-awesome-icon icon="cloud-download-alt" style="font-size: 16px;"/> Download Custom Report</button>
          </div>
        </div>

      </div>
    </main>
  </div>
</template>

<script>
import moment from 'moment'
import EmployeeService from '@/services/employee.service'
import SettingService from '@/services/setting.service'
import ReportService from '@/services/report.service'
import ProjectService from '@/services/project.service'
import { forEach } from 'lodash'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { subMonths, startOfMonth, startOfYear, endOfYear, subYears, startOfWeek, endOfWeek, endOfMonth } from 'date-fns'

export default {
  name: 'Reports',
  components: { DateRangePicker },
  data () {
    return {
      projectStatus: ['Department', 'Employee'],
      projectView: 'Department',
      departmentStatus: ['Project', 'Employee'],
      departmentView: 'Project',
      companies: ['Payroll', 'Timesheet Status'],
      company: '',
      employees: [],
      employeeReport: {},
      departmentReport: {},
      clientReport: {},
      projectReport: {},
      companyReport: {},
      employee: '',
      department: '',
      departments: [],
      client: '',
      clients: [],
      project: '',
      projects: [],
      view: 'employee',
      customEmployees: [],
      selected: '',
      companySelected: '',
      details: false,
      departmentIndex: '',
      projectIndex: '',
      custom: {},
      dateRange: {
        startDate: moment().subtract(2, 'week').startOf('week'),
        endDate: moment().subtract(1, 'week').endOf('week')
      },
      ranges: {
        'Today': [new Date(), new Date()],
        'Yesterday': [new Date(new Date().setDate(new Date().getDate() - 1)), new Date(new Date().setDate(new Date().getDate() - 1))],
        'This Week': [new Date(new Date().setDate(new Date().getDate() - new Date().getDay())), new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 6))],
        'Last week': [new Date(new Date().setDate(new Date().getDate() - new Date().getDay() - 7)), new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 6 - 7))],
        'Last 2 weeks': [new Date(new Date().setDate(new Date().getDate() - new Date().getDay() - 14)), new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 6 - 7))],
        'This month': [startOfWeek(startOfMonth(new Date())), endOfWeek(endOfMonth(new Date()))],
        'Last month': [startOfWeek(startOfMonth(subMonths(new Date(), 1))), endOfWeek(endOfMonth(subMonths(new Date(), 1)))],
        'Last 6 months': [startOfWeek(startOfMonth(subMonths(new Date(), 6))), endOfWeek(new Date())],
        'This year': [startOfWeek(startOfYear(new Date())), endOfWeek(endOfYear(new Date()))],
        'Last year': [startOfWeek(startOfYear(subYears(new Date(), 1))), endOfWeek(endOfYear(subYears(new Date(), 1)))]
      },
      employeeSearch: '',
      projectSearch: '',
      clientSearch: ''
    }
  },
  computed: {
    activeEmployees: function () {
      return this.employees.filter((employee) => {
        return employee.is_active === true
      }).filter((employee) => {
        return employee.name.toLowerCase().match(this.employeeSearch)
      })
    },
    activeProjects: function () {
      return this.projects.filter((project) => {
        return project.name.toLowerCase().match(this.projectSearch)
      })
    },
    activeClients: function () {
      return this.clients.filter((client) => {
        return client.toLowerCase().match(this.clientSearch)
      })
    }
  },
  methods: {
    getIcon: function (index) {
      if (this.details && this.clientIndex === index) {
        return 'chevron-down'
      } else {
        return 'chevron-right'
      }
    },
    getProjectIcon: function (index) {
      if (this.details && this.projectIndex === index) {
        return 'chevron-down'
      } else {
        return 'chevron-right'
      }
    },
    getDepartmentIcon: function (index) {
      if (this.details && this.departmentIndex === index) {
        return 'chevron-down'
      } else {
        return 'chevron-right'
      }
    },
    showView: function (view) {
      this.view = view
    },
    getAllUsers: function () {
      EmployeeService.getAllEmployees().then((response) => {
        // console.log(response)
        const [users] = [response.users]
        const allUsers = []
        forEach(users, (item) => {
          item.img = `https://imgs.simpsonhealthcare.com/simpson/employees/${item.name.replace(/\s+/g, '-').toLowerCase()}.gif`
          if (item.is_active) {
            allUsers.push(item.name)
          }
        })
        this.customEmployees = allUsers
        this.employees = users
      })
    },
    getDepartments: function () {
      SettingService.getData('departments').then((response) => {
        this.departments = response
      })
    },
    getClients: function () {
      SettingService.getData('clients').then((response) => {
        // console.log(response)
        this.clients = response
      })
    },
    getProjects: function () {
      ProjectService.getAllProjectReprots().then((response) => {
        // console.log(response)
        this.projects = response
      })
    },
    showEmployee: function (item) {
      this.userSelected = true
      this.selected = item.id
      this.employee = item.name
      this.getDetailsForWeek()
    },
    showDepartment: function (item) {
      this.userSelected = true
      this.selected = item.id
      this.department = item.name
      this.departmentView = 'Project'
      this.getDepartmentDetailsForWeek()
    },
    showClient: function (item) {
      this.userSelected = true
      this.selected = item
      this.client = item
      this.getClientDetailsForWeek()
    },
    showCompanyItem: function (item) {
      this.userSelected = true
      this.companySelected = item
      this.company = item
      this.getCompanyDetailsForWeek(item)
    },
    showProject: function (item) {
      this.userSelected = true
      this.selected = item.id
      this.project = item.name
      this.projectView = 'Department'
      this.getProjectDetailsForWeek()
    },
    onDateRangeChanged: function (picker) {
      this.dateRange = picker.startDate + ' - ' + picker.endDate
    },
    getDetailsForWeek: function () {
      console.log(this.dateRange)
      let loader = this.$loading.show({
        container: this.$refs.employeeCard,
        loader: 'dots'
      })
      ReportService.getEmployeeReport(this.employee, moment(this.dateRange.startDate).format('YYYY-MM-DD'), moment(this.dateRange.endDate).format('YYYY-MM-DD')).then((response) => {
        // console.log(response)
        this.employeeReport = response
        loader.hide()
      })
    },
    getDepartmentDetailsForWeek: function () {
      // console.log(this.dateRange)
      let loader = this.$loading.show({
        container: this.$refs.departmentCard,
        loader: 'dots'
      })
      ReportService.getDepartmentReport(this.department, moment(this.dateRange.startDate).format('YYYY-MM-DD'), moment(this.dateRange.endDate).format('YYYY-MM-DD')).then((response) => {
        // console.log(response)
        this.departmentReport = response
        loader.hide()
      })
    },
    getDepartmentDetailsForWeekByEmployee: function () {
      // console.log(this.dateRange)
      let loader = this.$loading.show({
        container: this.$refs.departmentCard,
        loader: 'dots'
      })
      ReportService.getDepartmentReportByEmployee(this.department, moment(this.dateRange.startDate).format('YYYY-MM-DD'), moment(this.dateRange.endDate).format('YYYY-MM-DD')).then((response) => {
        // console.log(response)
        this.departmentReport = response
        loader.hide()
      })
    },
    getClientDetailsForWeek: function () {
      console.log(this.dateRange)
      // ReportService.getEmployeeReport(this.employee, moment(this.dateRange.startDate).format('YYYY-MM-DD'), moment(this.dateRange.endDate).format('YYYY-MM-DD')).then((response) => {
      //   console.log(response)
      //   this.employeeReport = response
      // })

      let loader = this.$loading.show({
        container: this.$refs.clientCard,
        loader: 'dots'
      })
      ReportService.getClientReport(this.client, moment(this.dateRange.startDate).format('YYYY-MM-DD'), moment(this.dateRange.endDate).format('YYYY-MM-DD')).then((response) => {
        console.log(response)
        this.clientReport = response
        loader.hide()
      })
    },
    getProjectDetailsForWeek: function () {
      console.log(this.dateRange)
      // ReportService.getEmployeeReport(this.employee, moment(this.dateRange.startDate).format('YYYY-MM-DD'), moment(this.dateRange.endDate).format('YYYY-MM-DD')).then((response) => {
      //   console.log(response)
      //   this.employeeReport = response
      // })
      let loader = this.$loading.show({
        container: this.$refs.projectCard,
        loader: 'dots'
      })
      ReportService.getProjectReport(this.project, moment(this.dateRange.startDate).format('YYYY-MM-DD'), moment(this.dateRange.endDate).format('YYYY-MM-DD')).then((response) => {
        console.log(response)
        this.projectReport = response
        loader.hide()
      })
    },
    getProjectDetailsForWeekByEmployee: function () {
      console.log(this.dateRange)
      // ReportService.getEmployeeReport(this.employee, moment(this.dateRange.startDate).format('YYYY-MM-DD'), moment(this.dateRange.endDate).format('YYYY-MM-DD')).then((response) => {
      //   console.log(response)
      //   this.employeeReport = response
      // })
      let loader = this.$loading.show({
        container: this.$refs.projectCard,
        loader: 'dots'
      })
      ReportService.getProjectReportByEmployee(this.project, moment(this.dateRange.startDate).format('YYYY-MM-DD'), moment(this.dateRange.endDate).format('YYYY-MM-DD')).then((response) => {
        console.log(response)
        this.projectReport = response
        loader.hide()
      })
    },
    getCompanyDetailsForWeek: function () {
      console.log(this.dateRange)
      // ReportService.getEmployeeReport(this.employee, moment(this.dateRange.startDate).format('YYYY-MM-DD'), moment(this.dateRange.endDate).format('YYYY-MM-DD')).then((response) => {
      //   console.log(response)
      //   this.employeeReport = response
      // })
      let loader = this.$loading.show({
        container: this.$refs.companyCard,
        loader: 'dots'
      })
      if (this.company === 'Payroll') {
        ReportService.getPayrollReport(moment(this.dateRange.startDate).format('YYYY-MM-DD'), moment(this.dateRange.endDate).format('YYYY-MM-DD')).then((response) => {
          console.log(response)
          this.companyReport = response
          loader.hide()
        })
      } else {
        ReportService.getStatusReport(moment(this.dateRange.startDate).format('YYYY-MM-DD'), moment(this.dateRange.endDate).format('YYYY-MM-DD')).then((response) => {
          console.log(response)
          this.companyReport = response
          loader.hide()
        })
      }
    },
    showDetails: function (index) {
      this.details = !this.details
      this.departmentIndex = index
    },
    showClientDetails: function (index) {
      this.details = !this.details
      this.clientIndex = index
    },
    showProjectDetails: function (index) {
      this.details = !this.details
      this.projectIndex = index
    },
    downloadEmployeeReport: function (employee) {
      return `https://simpsonapi.com/v1/timesheets/employee/${employee}/download/${moment(this.dateRange.startDate).format('YYYY-MM-DD')}/${moment(this.dateRange.endDate).format('YYYY-MM-DD')}`
    },
    downloadDepartmentReport: function (department) {
      if (this.departmentView === 'Employee') {
        return `https://simpsonapi.com/v1/timesheets/department/${department}/employees/download/${moment(this.dateRange.startDate).format('YYYY-MM-DD')}/${moment(this.dateRange.endDate).format('YYYY-MM-DD')}`
      } else {
        return `https://simpsonapi.com/v1/timesheets/department/${department}/download/${moment(this.dateRange.startDate).format('YYYY-MM-DD')}/${moment(this.dateRange.endDate).format('YYYY-MM-DD')}`
      }
    },
    downloadProjectReport: function (project) {
      return `https://simpsonapi.com/v1/timesheets/project/${project}/download/${moment(this.dateRange.startDate).format('YYYY-MM-DD')}/${moment(this.dateRange.endDate).format('YYYY-MM-DD')}`
    },
    showProjectView: function (view) {
      this.projectReport = {}
      if (view === 'Employee') {
        this.getProjectDetailsForWeekByEmployee()
      } else {
        this.getProjectDetailsForWeek()
      }
    },
    showDepartmentView: function (view) {
      this.departmentReport = {}
      if (view === 'Employee') {
        this.getDepartmentDetailsForWeekByEmployee()
      } else {
        this.getDepartmentDetailsForWeek()
      }
    },
    downloadCustomReport: function (item) {
      item.start = moment(this.dateRange.startDate).format('YYYY-MM-DD')
      item.end = moment(this.dateRange.endDate).format('YYYY-MM-DD')
      console.log(item)
      this.openWindowWithPost('https://assets.simpsonhealthcare.com/simpson/reports/timesheet-yearly-report.php', item)
    },
    downloadCompanyReport: function (company) {
      if (company === 'Payroll') {
        return `https://simpsonapi.com/v1/timesheets/employee/${company.toLowerCase()}/download/${moment(this.dateRange.startDate).format('YYYY-MM-DD')}/${moment(this.dateRange.endDate).format('YYYY-MM-DD')}`
      } else {
        return `https://simpsonapi.com/v1/timesheets/employee/status/download/${moment(this.dateRange.startDate).format('YYYY-MM-DD')}/${moment(this.dateRange.endDate).format('YYYY-MM-DD')}`
      }
    },
    openWindowWithPost: function (url, data) {
      var form = document.createElement('form')
      form.target = '_self'
      form.method = 'POST'
      form.action = url
      form.style.display = 'none'
      for (var key in data) {
        var input = document.createElement('input')
        input.type = 'hidden'
        input.name = key
        input.value = data[key]
        form.appendChild(input)
      }
      document.body.appendChild(form)
      form.submit()
      document.body.removeChild(form)
    }
  },
  filters: {
    date: function (value) {
      return moment(value).format('MMMM DD, YYYY')
    }
  },
  mounted () {
    console.log(this.ranges)
    this.getAllUsers()
    this.getDepartments()
    this.getClients()
    this.getProjects()
  }
}
</script>
<style>
.calendars-container {
  width: 75%;
}
</style>
<style scoped>
/*
 * Sidebar
 */

.pending {
  color: red;
}

.sidebar {
  position: fixed;
  top: 54px;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  position: relative;
  top: 54px;
  bottom: 0;
  height: calc(100vh - 120px);
  padding-top: 0px;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
  padding: 0px 16px;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #fff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

.active {
  background: #009370;
}

.active .nav-link,
.active .nav-link:hover {
  color: #fff;
}

.employee-active {
  background-color: #006AB6;
  color: #fff;
}

.employee-active h5,
.employee-active .text-muted {
  color: #fff !important;
}

/* Rounded sliders */

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 18px;
    margin-left: 15px;
    margin-top: 4px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #009370;
}

input:focus+.slider {
    box-shadow: 0 0 1px #009370;
}

input:checked+.slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
