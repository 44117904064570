function fieldSettings (el, elIndex) {
  if (el.type === 'input') {
    return {
      type: el.type,
      inputType: el.inputType,
      id: elIndex,
      columnSize: el.columnSize,
      label: true,
      labelText: el.name,
      placeholder: false,
      placeholderText: '',
      required: false,
      classAttr: '',
      className: '',
      classId: '',
      minLength: '2',
      maxLength: '50',
      vModel: '',
      error: 'This field is required'
    }
  } else if (el.type === 'section') {
    return {
      type: el.type,
      inputType: el.inputType,
      id: elIndex,
      columnSize: el.columnSize,
      labelText: 'Section Header',
      placeholder: false,
      required: false,
      classAttr: '',
      className: '',
      classId: '',
      minLength: '2',
      maxLength: '50',
      vModel: ''
    }
  } else if (el.type === 'select') {
    return {
      type: el.type,
      inputType: el.inputType,
      id: elIndex,
      columnSize: el.columnSize,
      label: true,
      labelText: el.name,
      placeholder: false,
      placeholderText: '',
      required: false,
      classAttr: '',
      className: '',
      classId: '',
      choices: ['Choice 1', 'Choice 2'],
      other: 'No',
      vModel: '',
      error: 'This field is required'
    }
  } else if (el.type === 'checkbox') {
    return {
      type: el.type,
      id: elIndex,
      columnSize: el.columnSize,
      label: true,
      labelText: el.name,
      required: false,
      classAttr: '',
      className: '',
      classId: '',
      choices: ['Choice 1', 'Choice 2'],
      displayTypes: ['standard', 'inline'],
      display: el.display,
      limit: 'No',
      limitAllowed: '',
      maxSelect: '',
      other: 'No',
      vModel: '',
      error: 'This field is required'
    }
  } else if (el.type === 'radio') {
    return {
      type: el.type,
      id: elIndex,
      columnSize: el.columnSize,
      label: true,
      labelText: el.name,
      required: false,
      classAttr: '',
      className: '',
      classId: '',
      choices: ['Choice 1', 'Choice 2'],
      displayTypes: ['standard', 'inline'],
      display: el.display,
      other: 'No',
      vModel: '',
      error: 'This field is required'
    }
  } else if (el.type === 'switch') {
    return {
      type: el.type,
      id: elIndex,
      columnSize: el.columnSize,
      label: true,
      labelText: el.name,
      classAttr: '',
      className: '',
      classId: '',
      vModel: ''
    }
  }
}

export default fieldSettings
