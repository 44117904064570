<template>
  <div class="d-flex justify-content-center px-4" style="padding:45px 25px 25px 25px;">
    <!-- <Description :kid="this.kid" :type="type" /> -->
    <div class="card w-75">
      <div class="card-header d-flex justify-content-between align-items-center">
        <div>
          <h4>{{type.section_header}}</h4>
          <p class="text-muted mb-0" style="font-size: 0.85em;">Last updated: 7/1/2020 2:57 PM by Simpson Employee</p>
        </div>
        <button @click="editForm" class="btn btn-sm btn-warning">Edit Form</button>
      </div>
      <div class="card-body p-4">
        <GenerateFormEdit :formData="clientData"/>
      </div>
    </div>
  </div>
</template>
<script>
import GenerateFormEdit from '@/components/3x/form-builder/GenerateFormEdit.vue'
export default {
  props: ['type', 'formId', 'formData'],
  components: {
    GenerateFormEdit
  },
  data () {
    return {
      isDraft: false,
      showModal: false,
      items: [],
      biblio: {},
      title: '',
      editMode: false,
      inputForm: {},
      clientData: {}
    }
  },
  watch: {
    formData: {
      handler: 'updateData',
      immediate: true
    }
  },
  methods: {
    editForm: function () {
      console.log(this.formData)
      this.$emit('edit', this.formData)
    },
    updateData: function () {
      this.clientData = this.formData
    }
  },
  mounted () {
    this.updateData()
  },
  created () {
    console.log(this.type, this.formId)
    this.updateData()
  }
}
</script>
