<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-dialog" role="document" style="max-width: 800px;">
        <div class="modal-content" style="height: 100%; border:none;">
          <div class="modal-header">
            <h5 class="modal-title cleafix float-left">{{survey.name}}</h5>
            <button type="button" class="close float-right" v-on:click="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Client</label>
                    <multiselect :options="clients" :show-labels="false" :allow-empty="false" placeholder="Select Client" v-model="surveyItem.client"></multiselect>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Project</label>
                    <multiselect :options="projects" :show-labels="false" :allow-empty="false" placeholder="Select Project" v-model="surveyItem.project"></multiselect>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label style="margin-bottom: 0;">Survey Name</label>
                    <small class="form-text text-muted" style="margin-bottom: 0.5rem;">
                      This name will appear on the acutal survey.
                    </small>
                    <input type="text" class="form-control shadow-none" placeholder="Survey Name" v-model="surveyItem.name">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Will this survey have archetypes?</label><br />
                    <label class="switch">
                      <input type="checkbox" id="hasArchetypes" v-model="surveyItem.has_archetypes">
                      <div class="slider round"></div>
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="float-right">
                    <button type="button" class="btn btn-default btn-sm" v-on:click="close">Cancel</button>
                    <button type="button" class="btn btn-primary btn-sm" v-on:click="saveSurvey(surveyItem)">Save Survey</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import BeliefMapService from '@/services/beliefmap.service'
import ProjectService from '@/services/project.service'
import SettingService from '@/services/setting.service'
import { forEach, sortBy } from 'lodash'
export default {
  name: 'SurveyModal',
  props: ['modalVisible', 'survey'],
  data () {
    return {
      surveyItem: {
        client: null,
        project: null,
        name: null,
        has_archetypes: false
      },
      projects: [],
      clients: []
    }
  },
  methods: {
    saveSurvey (item) {
      console.log(item)
      BeliefMapService.saveSurvey(item).then((response) => {
        let result = {}
        result.type = response.type
        if (response.type === 'add') {
          item.survey_id = response.id
        }
        result.survey = item
        console.log(result)
        this.$emit('item', result)
      })
    },
    close () {
      this.$emit('close')
    },
    showSurvey () {
      if (this.survey.survey_id) {
        this.surveyItem = this.survey
      }
    },
    getProjects () {
      ProjectService.getAllProjects().then((response) => {
        const res = response
        const items = []
        forEach(res, (item) => {
          items.push(item.name)
        })
        this.projects = sortBy(items)
      })
    },
    getClients () {
      SettingService.getActiveClients().then((response) => {
        this.clients = response.clients
      })
    }
  },
  mounted () {
    this.getProjects()
    this.getClients()
    this.showSurvey()
  }
}
</script>

<style scoped>

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

/* Rounded sliders */

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 18px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #009370;
}

input:focus+.slider {
    box-shadow: 0 0 1px #009370;
}

input:checked+.slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
