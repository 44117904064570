<template>
  <div>
    <!-- <Description :kid="this.kid" :type="type" /> -->
    <div style="position: fixed; right: 25px; top: 130px;" class="text-success">
      <font-awesome-icon icon="save" style="font-size: 18px; cursor: pointer;" @click="saveData" />
    </div>
    <div ref="xspreadsheet" id="xapp"></div>
  </div>
</template>
<script>
import ConventionService from '@/services/convention.service'
import Spreadsheet from 'x-data-spreadsheet'
import { forEach } from 'lodash'
export default {
  props: ['type', 'projectId'],
  components: {},
  data () {
    return {
      isDraft: false,
      showModal: false,
      items: [],
      biblio: {},
      title: '',
      editMode: false,
      grid: {}
    }
  },
  methods: {
    getBibliometrics: function (id, type) {
      console.log(id, type)
    },
    saveData: function () {
      console.log(this.type)
      console.log(this.grid.getData()[0].rows)
      const data = this.grid.getData()[0].rows
      const items = {
        table_name: this.type.table_name,
        rows: []
      }
      const finalItems = []
      // Get Column Names
      let cols = data[0]
      let colNames = []
      forEach(cols, (v, k) => {
        forEach(v, (i, j) => {
          colNames.push(i.text)
        })
      })
      console.log(colNames)

      let rows = data
      delete rows[0]
      forEach(rows, (item, index) => {
        console.log(item)
        forEach(item, (v, k) => {
          let rowNames = []
          forEach(v, (i, j) => {
            rowNames.push(i.text)
          })
          var result = {}
          forEach(colNames, (key, i) => {
            result[key] = rowNames[i]
          })
          finalItems.push(result)
        })
      })
      items.rows = finalItems
      console.log(items)
      ConventionService.saveHeatmapTableData(items).then((response) => {
        console.log(response)
      })
    }
  },
  mounted () {
    // const s = new Spreadsheet('#xapp')
    // new Spreadsheet(this.$refs.xspreadsheet)
    //   .loadData({}) // load data
    //   .change(data => {
    //     // save data to db
    //   })
    this.grid = new Spreadsheet(this.$refs.xspreadsheet)
    // let div = document.createElement('div')
    // div.classList.add('x-spreadsheet-toolbar-btn', 'custom')
    // div.innerHTML = '<font-awesome-icon icon="save" />'
    // console.log(div)
    // let el = document.querySelector('div.x-spreadsheet-toolbar-btns')
    // console.log(el)
    // el.append(div)
    // console.log(el)
    ConventionService.getHeatmapTableData(this.type.id).then((response) => {
      console.log(response)
      var out = []
      var o = { name: response.name, rows: {}, freeze: 'A2', styles: [ { font: { bold: true } } ] }
      forEach(response.data, (item, index) => {
        var cells = {}
        forEach(item, function (c, j) {
          if (index === 0) {
            cells[j] = ({ text: c, style: index })
          } else {
            cells[j] = ({ text: c })
          }
        })
        o.rows[index] = { cells: cells }
      })
      out.push(o)
      this.grid.loadData(out)
    })
  },
  created () {
    this.getBibliometrics(this.projectId, this.type)
  }
}
</script>

<style>
#xapp {
  width: 100%;
  height: calc(100vh - 240px);
}

.x-spreadsheet-toolbar {
  width: 100% !important;
}

.x-spreadsheet-sheet {
  width: 100% !important;
  height: calc(100vh - 170px);
}

.x-spreadsheet-bottombar {
  display: none;
}
</style>
