<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-dialog" role="document" style="max-width: 750px;">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: 0px !important;">
            <h5 class="modal-title">{{title}}</h5>
            <button class="close" v-on:click="close">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body" style="padding: 0px;">
            <div class="card">
              <b-form @submit.stop.prevent="saveTemplate">
                <div style="padding: 15px;">
                  <div class="row">
                    <div class="col">
                      <div class="form-group">
                        <label>Event ID</label>
                        <input type="text" class="form-control w-25" v-model="inputForm.event_id" placeholder="Event ID" disabled>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="form-group">
                        <label>Template Name</label>
                        <b-form-input type="text" class="form-control" :state="nameState('template_name')" @change="checkItem('template_name')" v-model="inputForm.template_name" placeholder="Template Name"></b-form-input>
                        <b-form-invalid-feedback :state="nameState('template_name')">
                          This field is required.
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="padding-top: 15px;">
                    <div class="col">
                      <label>Use Event Form?</label><br>
                      <label class="switch">
                        <input type="checkbox" v-model="inputForm.form_builder">
                        <div class="slider round"></div>
                      </label>
                    </div>
                  </div>
                  <div class="row" style="padding-top: 15px;">
                    <div class="col">
                      <label>Use Email Responses?</label><br>
                      <label class="switch">
                        <input type="checkbox" v-model="inputForm.email_responses">
                        <div class="slider round"></div>
                      </label>
                    </div>
                  </div>
                  <div class="p-3 d-flex justify-content-end">
                    <div class="p-1 d-flex">
                      <button type="submit" class="btn btn-primary mr-3">Save</button>
                      <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close">Cancel</button>
                    </div>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import eventService from '@/services/event.service'
export default {
  name: 'EventTemplateModal',
  components: {},
  props: ['title', 'item'],
  data () {
    return {
      start: '',
      end: '',
      cutoff: '',
      inputForm: {
        event_id: null,
        template_name: null,
        form_builder: false,
        email_responses: false
      },
      formArray: [],
      formSubmitted: false,
      formValid: false,
      required: true
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    saveTemplate: function () {
      this.validateTemplate()
      if (this.formValid) {
        console.log(this.inputForm)
        eventService.saveTemplate(this.inputForm).then((response) => {
          console.log(response)
          this.$emit('close')
        })
      }
    },
    checkItem: function (item) {
      console.log(this.inputForm[item])
      if (this.inputForm[item] !== '' || this.inputForm[item] !== 0) {
        const index = this.formArray.indexOf(item)
        this.formArray.splice(index, 1)
      }
      console.log('Check', this.formArray)
    },
    validateTemplate: function () {
      this.formSubmitted = true
      console.log(this.formArray)
      if (this.formArray.length === 0) {
        this.formValid = true
      } else {
        this.formValid = false
      }
      console.log(this.formValid)
    },
    nameState: function (name) {
      if (this.formSubmitted) {
        if (this.inputForm[name] === null || this.inputForm[name].length === 0) {
          return false
        } else {
          return true
        }
      } else {
        return null
      }
    }
  },
  mounted () {
    console.log(this.item)
    if (this.item.id) {
      this.inputForm.event_id = this.item.id
    } else {
      this.inputForm = {}
    }
    this.formArray = ['template_name']
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.vdp-datepicker input.form-control {
  background-color:#FFF;
}
</style>
<style scoped>

.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.document-editor {
    border: 1px solid var(--ck-color-base-border);
    border-radius: var(--ck-border-radius);
}

.close:focus {
  outline: none;
}

.mce-notification {
  display: none !important;
}

.file-select > .select-button {
  margin: 10px;
  cursor: pointer;
  font-size: 25px;
}

.file-select > input[type="file"] {
  display: none;
}

/* Rounded sliders */

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 18px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #009370;
}

input:focus+.slider {
    box-shadow: 0 0 1px #009370;
}

input:checked+.slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
