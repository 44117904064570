<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-dialog" role="document" style="max-width: 750px;">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: 0px !important;">
            <h5 class="modal-title">Add Vendor</h5>
            <button class="close" v-on:click="close">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body" style="padding: 0px;">
            <div class="card">
              <div style="padding: 15px; background: #f6f6f7;">
                <div style="padding: 0px 15px 15px 0px; background: #f6f6f7;">
                  <div class="row">
                    <div class="col-6">
                      <label>First Name</label>
                      <input type="text" class="form-control" v-model="inputForm.first_name">
                    </div>
                    <div class="col-6">
                      <label>Last Name</label>
                       <input type="text" class="form-control" v-model="inputForm.last_name">
                    </div>
                    <div class="col-12">
                      <label>Email</label>
                      <input type="text" class="form-control" v-model="inputForm.email">
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-3 d-flex" style="background: #f6f6f7;">
                <button class="btn btn-primary mr-3" @click.stop.prevent="save">Save Vendor</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import eventService from '@/services/event.service'
export default {
  name: 'VendorModal',
  data () {
    return {
      inputForm: {}
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    save: function () {
      eventService.saveVendor(this.inputForm).then((response) => {
        this.$emit('close')
      })
    }
  }
}
</script>
<style scoped>

.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.document-editor {
    border: 1px solid var(--ck-color-base-border);
    border-radius: var(--ck-border-radius);
}

.close:focus {
  outline: none;
}

.mce-notification {
  display: none !important;
}

.file-select > .select-button {
  margin: 10px;
  cursor: pointer;
  font-size: 25px;
}

.file-select > input[type="file"] {
  display: none;
}
</style>
