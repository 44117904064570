<template>
  <div>
    <form style="padding: 0px 15px 15px 15px;" class="needs-validation" novalidate>
      <div class="row">
        <div v-for="item in jsonForm" :key="item.id" v-bind:class="{'col-12': item.columnSize == 12, 'col-6': item.columnSize == 6 }">
          <!---------- Input ---------->
          <div class="form-group" v-if="item.type == 'input'">
            <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
            <input :type="item.inputType" class="form-control" :class="checkFormField(item)" @change="checkItem(item)" :disabled="!editInfo" :placeholder="item.placeholderText" v-model="inputForm[item.vModel]">
            <div class="invalid-feedback">{{item.error}}</div>
          </div>
          <!---------- Dropdown ---------->
          <div v-if="item.type == 'select'">
            <div class="form-group">
              <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
              <select class="form-control" v-model="inputForm[item.vModel]" :class="checkFormField(item)" :disabled="!editInfo" @change="checkItem(item)">
                <option value="">{{item.placeholderText}}</option>
                <option v-for="(choice, index) in item.choices" :key="index">
                  {{ choice }}
                </option>
              </select>
              <div class="invalid-feedback">{{item.error}}</div>
            </div>
            <!-- Other -->
            <div v-if="item.other === 'Yes'" class="form-group">
              <textarea :name="['other-']+item.id" :id="['other-']+item.classNme" cols="30" rows="1" class="form-control" v-model="inputForm[item.vModel]"></textarea>
            </div>
          </div>
          <!---------- Checkbox ---------->
          <div v-if="item.type == 'checkbox'">
            <div class="form-group" v-if="item.display === 'standard'">
              <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label> <span class="text-info" v-if="item.limit === 'Yes'"> (Select up to {{item.limitAllowed}})</span>
              <span class="text-info" v-else> (Select all that apply)</span>
              <div class="clearfix"></div>
              <div class="form-check" v-for="(choice, index) in item.choices" :key="index">
                <input class="form-check-input" type="checkbox" :value="choice" v-model="inputForm[item.vModel]" :disabled="!editInfo" :class="checkFormField(item)" @change="checkItem(item)">
                <label class="form-check-label">
                  {{ choice }}
                </label>
                <div v-if="item.choices.length == index + 1" class="invalid-feedback">{{item.error}}</div>
              </div>
            </div>

            <div class="form-group" v-else>
              <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label> <span class="text-info" v-if="item.limit === 'Yes'"> (Select up to {{item.limitAllowed}})</span>
              <div class="clearfix"></div>
              <div class="form-check form-check-inline" v-for="(choice, index) in item.choices" :key="index">
                <input class="form-check-input" type="checkbox" :value="choice" v-model="inputForm[item.vModel]" :disabled="!editInfo" :class="checkFormField(item)" @change="checkItem(item)">
                <label class="form-check-label">
                  {{ choice }}
                </label>
              </div>
              <div style="margin-left: 0px;" class="invalid-feedback">{{item.error}}</div>
            </div>

            <!-- Other -->
            <div v-if="item.other === 'Yes'" class="form-group">
              <textarea :name="['other-']+item.id" :id="['other-']+item.className" cols="30" rows="1" class="form-control" v-model="inputForm[item.vModel]"></textarea>
            </div>
          </div>
          <!---------- Radio ---------->
          <div v-if="item.type == 'radio'">
            <div class="form-group" v-if="item.display === 'standard'">
              <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
              <div class="clearfix"></div>
              <div class="form-check" v-for="(choice, index) in item.choices" :key="index">
                <input class="form-check-input" type="radio" :value="choice" v-model="inputForm[item.vModel]" :disabled="!editInfo" :class="checkFormField(item)" @change="checkItem(item)">
                <label class="form-check-label">
                  {{ choice }}
                </label>
                <div v-if="item.choices.length == index + 1" class="invalid-feedback">{{item.error}}</div>
              </div>
            </div>

            <div class="form-group" v-else>
              <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
              <div class="clearfix"></div>
              <div class="form-check form-check-inline" v-for="(choice, index) in item.choices" :key="index">
                <input class="form-check-input" type="radio" :value="choice" v-model="inputForm[item.vModel]" :disabled="!editInfo" :class="checkFormField(item)" @change="checkItem(item)">
                <label class="form-check-label">
                  {{ choice }}
                </label>
                <div v-if="item.choices.length == index + 1" class="invalid-feedback">{{item.error}}</div>
              </div>
            </div>

            <!-- Other -->
            <div v-if="item.other === 'Yes'" class="form-group">
              <textarea :name="['other-']+item.id" :id="['other-']+item.className" cols="30" rows="1" class="form-control" v-model="inputForm[item.vModel]"></textarea>
            </div>
          </div>
          <!---------- Switch ---------->
          <div v-if="item.type == 'switch'">
            <div class="form-group">
              <label>{{item.labelText}}</label><br />
              <label class="switch">
                <input type="checkbox" v-model="inputForm[item.vModel]" :disabled="!editInfo">
                <div class="slider round"></div>
              </label>
            </div>

          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import eventService from '@/services/event.service'
import moment from 'moment'
// import { forEach } from 'lodash'
// import eventService from '@/services/event.service'

export default {
  name: 'GeneratedFormEdit',
  components: {
  },
  props: ['formData'],
  data () {
    return {
      jsonForm: [],
      inputForm: {},
      valid: false,
      inputBlured: false,
      formChecked: false,
      formArray: [],
      editInfo: false,
      registrantInfo: {}
    }
  },
  watch: {
    formData: {
      handler: 'getData',
      immediate: true
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    editRegistrationInfo: function () {
      this.editInfo = true
    },
    customDateFormatter: function (date) {
      return moment(date).format('MM/DD/YYYY')
    },
    saveRegistrationInfo: function () {
      this.validate()
      if (this.valid) {
        console.log(this.inputForm)
        this.registrantInfo.reg_form = JSON.stringify(this.inputForm)
        this.registrantInfo.reg_date = moment(this.registrantInfo.reg_date).format('YYYY-MM-DD')
        eventService.saveRegistrant(this.registrantInfo).then((response) => {
          this.editInfo = false
        })
      }
    },
    isDate: function (date) {
      const _regExp = new RegExp('^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$')
      return _regExp.test(date)
    },
    cancelRegistrationInfo: function () {
      this.validate()
      if (this.valid) {
        this.editInfo = false
      }
    },
    validate: function () {
      this.inputBlured = true
      console.log('Validate', this.formArray)
      if (this.formArray.length === 0) {
        this.valid = true
      }
    },
    checkFormField: function (item) {
      if ((this.inputForm[item.vModel] === '') && item.required) {
        return {
          'is-invalid': (this.inputForm[item.vModel] === '') && this.inputBlured && item.required
        }
      } else {
        return {
          'is-valid': (this.inputForm[item.vModel] !== '') && this.editInfo !== false
        }
      }
    },
    checkItem: function (item) {
      const value = this.inputForm[item.vModel]
      if (value === '') {
        this.formArray.push(item.vModel)
      } else if ((this.inputForm[item.vModel] !== '' || this.inputForm[item.vModel] !== 0) && item.required) {
        const index = this.formArray.indexOf(item.vModel)
        this.formArray.splice(index, 1)
      }
      console.log('Check', this.formArray)
    },
    getData: function () {
      this.jsonForm = []
      this.inputForm = {}
      // console.log(this.formData)
      this.jsonForm = JSON.parse(this.formData.form_elements)
      this.jsonForm.shift()
      console.log(this.jsonForm)
      this.inputForm = JSON.parse(this.formData.form_elements)
      // this.jsonForm = response.form_builder
      // const items = this.formData
      // console.log('Items', items)
    }
  },
  created () {
    this.getData()
  }
}
</script>
<style scoped>
/*--------------------------------------------------
  Switch
--------------------------------------------------*/
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 18px;
}

.switch input { display: none }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #009370;
}

input:focus+.slider {
  box-shadow: 0 0 1px #009370;
}

input:checked+.slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

.slider.round { border-radius: 34px }

.slider.round:before { border-radius: 50% }

/* input[type="text"], textarea {
outline: none;
box-shadow:none !important;
border:1px solid #ccc;
} */

.remove-border {
  border-left: 0px !important;
}
.input-group-prepend {
  padding: 10px;
  background-color: #fff;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
</style>
