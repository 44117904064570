<template>
  <div>
    <input v-if="Object.keys(selectedItem).length === 0" ref="dropdowninput" v-model.trim="inputValue" class="form-control shadow-none text-primary" style="width:584px; border:none; background:transparent;" type="text" />
    <div v-else class="dropdown-selected">
      <div class="badge badge-primary" style="padding: 5px 10px;">{{ selectedItem.name }}<font-awesome-icon icon="times" @click="resetSelection" style="margin-left: 10px; cursor:pointer;"></font-awesome-icon></div>
    </div>
    <div v-show="inputValue && apiLoaded" class="dropdown-list">
      <div @click="selectItem(item)" v-show="itemVisible(item)" v-for="item in itemList" :key="item.email" class="dropdown-item">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { http } from '@/config/base-url'
import authHeader from '@/config/auth-header'
export default {
  props: ['email'],
  data () {
    return {
      selectedItem: {},
      inputValue: '',
      itemList: [],
      apiLoaded: false
    }
  },
  mounted () {
    this.getList()
    if (this.email) {
      http.get(`v2/events/employees/${this.email}`, {
        headers: authHeader()
      }).then((res) => {
        this.selectedItem = res.data
      }).catch((error) => {
        console.log(error)
      })
    }
  },
  methods: {
    resetSelection () {
      this.selectedItem = {}
      this.$nextTick(() => this.$refs.dropdowninput.focus())
      this.$emit('on-item-reset')
    },
    selectItem (theItem) {
      this.selectedItem = theItem
      this.inputValue = ''
      this.$emit('on-item-selected', theItem)
      console.log(theItem)
    },
    itemVisible (item) {
      let currentName = item.name.toLowerCase()
      let currentInput = this.inputValue.toLowerCase()
      return currentName.includes(currentInput)
    },
    getList () {
      http.get('v2/users/employees', {
        headers: authHeader()
      }).then((res) => {
        this.itemList = res.data.users
        console.log(this.itemList)
        this.apiLoaded = true
      }).catch((error) => {
        console.log(error)
      })
      // axios.get(this.apiUrl).then(response => {
      //   this.itemList = response.data
      //   this.apiLoaded = true
      // })
    }
  }
}
</script>

<style>
.dropdown-input, .dropdown-selected{
  width: 584px;
  padding: 6px 12px;
  border: 1px solid transparent;
  background: transparent;
  line-height: 1.5em;
  outline: none;
  border-radius: 8px;
}
.dropdown-input:focus{
  background: #fff;
  border-color: #e2e8f0;
}
.dropdown-input::placeholder{
  opacity: 0.7;
}
.dropdown-selected{
  font-weight: bold;
}
.dropdown-list{
  position: absolute;
  z-index: 1000;
  width: 584px;
  max-height: 200px;
  margin-top: 4px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}
.dropdown-item{
  display: flex;
  width: 100%;
  padding: 11px 16px;
  cursor: pointer;
}
.dropdown-item:hover{
  background: transparent;
}
.dropdown-item-flag{
  max-width: 24px;
  max-height: 18px;
  margin: auto 12px auto 0px;
}
</style>
