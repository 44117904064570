<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-dialog" role="document" style="max-width: 750px;">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: 0px !important;">
            <h5 class="modal-title">{{title}}</h5>
            <button class="close" v-on:click="close">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body" style="padding: 0px;">
            <div class="card">
              <div style="padding: 15px; background: #f6f6f7;">
                <div v-if="formError" class="alert alert-danger alert-dismissible fade show" role="alert">
                  <strong>Submission Error!</strong> The following fields must not be empty: Email Type, Schedule, Email Name, From Address, and Subject
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="dismissAlert">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="row">
                  <div class="col">
                    <p>This is <strong>only</strong> for Confirmation Emails!! You can only insert "Add to Calendar" text to "Registration Confirmation" email types.</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div style="padding: 15px 0px 0px 0px;">
                      <label>Subject<br><small>The title of the calendar event</small></label>
                      <input type="text" class="form-control shadow-none text-primary" placeholder="Subject" v-model="inputForm.subject">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div style="padding: 15px 0px 0px 0px;">
                      <label>Location<br><small>Location of the event</small></label>
                      <input type="text" class="form-control shadow-none text-primary" placeholder="Location" v-model="inputForm.location">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div style="padding: 15px 0px 0px 0px;">
                      <label>Start Date</label>
                      <b-form-datepicker today-button reset-button close-button locale="en" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" v-model="inputForm.start_date" placeholder="Start Date"></b-form-datepicker>
                    </div>
                  </div>
                  <div class="col-6">
                    <div style="padding: 15px 0px 0px 0px;">
                      <label>Start Time</label>
                      <b-form-timepicker now-button reset-button v-model="inputForm.start_time" placeholder="Start Time"></b-form-timepicker>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div style="padding: 15px 0px 0px 0px;">
                      <label>End Date</label>
                      <b-form-datepicker today-button reset-button close-button locale="en" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" v-model="inputForm.end_date" placeholder="End Date"></b-form-datepicker>
                    </div>
                  </div>
                  <div class="col-6">
                    <div style="padding: 15px 0px 0px 0px;">
                      <label>End Time</label>
                      <b-form-timepicker now-button reset-button v-model="inputForm.end_time" placeholder="End Time"></b-form-timepicker>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div style="padding: 15px 0px 0px 0px;">
                      <label>Description</label>
                      <textarea rows="5" class="form-control shadow-none text-primary" placeholder="Enter calendar event description here..." v-model="inputForm.description"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div style="background: #fff;">
                <div class="p-3 d-flex justify-content-end">
                  <div class="p-1 d-flex">
                    <button type="submit" class="btn btn-primary mr-3" @click="saveCalendarInvite">Save "Add To Calendar" Invite</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import Editor from '@tinymce/tinymce-vue'
import eventService from '@/services/event.service'
// import DropdownList from '@/components/events/DropdownList.vue'
// import VueTagsInput from '@johmun/vue-tags-input'
// import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
// import { forEach } from 'lodash'
export default {
  name: 'CalendarInviteModal',
  components: {
    // DropdownList
    // VueBootstrapTypeahead
    // VueTagsInput
  },
  props: ['eventId', 'title', 'inputForm'],
  data () {
    return {
      formError: false
    }
  },
  computed: {
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    saveCalendarInvite: function (event) {
      if (this.inputForm.subject === null || this.inputForm.location === null || this.inputForm.start_date === null || this.inputForm.start_time === null || this.inputForm.end_date === null || this.inputForm.end_time === '' || this.inputForm.description === '') {
        this.formError = true
        event.preventDefault()
        event.stopPropagation()
      } else {
        this.formError = false
        console.log(this.inputForm)
        eventService.saveCalendarInvite(this.inputForm).then((response) => {
          if (response.id) {
            this.inputForm.id = response.id
          }
        })
        this.$emit('saved', this.inputForm)
      }
    }
  },
  mounted () {
    console.log(this.inputForm)
    this.inputForm.event_id = this.eventId
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.invalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 90%;
  color: #dc3545;
}

.invalid .multiselect__tags {
  border-color: #dc3545!important;
}

.valid .multiselect__tags {
  border-color: #28a745!important;
}

.vue-tags-input {
  background-color: transparent !important;
  max-width: 660px !important;
}

.vue-tags-input .ti-new-tag-input {
  background: transparent;
  line-height: 1.4;
  font-size: 16px;
}

.vue-tags-input .ti-input {
  border: none;
}

.vue-tags-input .ti-tag {
  position: relative;
  background: #007bff;
  color: #ffffff;
  padding: 4px 6px;
  margin: 4px 2px 2px 2px;
}
</style>
<style scoped>
.multiselect.form-control {
  padding: 0;
}

.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.document-editor {
    border: 1px solid var(--ck-color-base-border);
    border-radius: var(--ck-border-radius);
}

.close:focus {
  outline: none;
}

.mce-notification {
  display: none !important;
}

.file-select > .select-button {
  margin: 10px;
  cursor: pointer;
  font-size: 25px;
}

.file-select > input[type="file"] {
  display: none;
}
</style>
