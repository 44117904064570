<template>
  <div class="d-flex justify-content-center px-5" style="padding:45px 25px 25px 25px;">
    <!-- <Description :kid="this.kid" :type="type" /> -->
    <div class="card w-100">
      <div class="card-header d-flex justify-content-between align-items-center">
        <div>
          <h4>{{type}}</h4>
          <p class="text-muted mb-0" style="font-size: 0.85em;">Last updated: 7/1/2020 2:57 PM by Simpson Employee</p>
        </div>
        <button class="btn btn-sm btn-primary" @click="addInfo"><font-awesome-icon icon="plus"/> Add</button>
      </div>
      <div class="card-body p-0">
          <div class="table table-striped mb-0">
            <thead>
              <tr>
                <th width="15%">Client</th>
                <th>Year</th>
                <th>Congress</th>
                <th width="15%">Start Date</th>
                <th width="35%">Activity</th>
                <th width="15%">Type</th>
                <th width="25%">Engagement Status</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in engagementPlan" :key="index">
                <td>{{item.client}}</td>
                <td>{{item.year}}</td>
                <td>{{item.congress}}</td>
                <td>{{getDate(item.start_date)}}</td>
                <td>{{item.activity}}</td>
                <td>{{item.activity_type}}</td>
                <td>{{item.engagement_status}}</td>
                <td><span class="badge badge-success">{{showStatus(item.is_active)}}</span></td>
                <td><font-awesome-icon icon="edit" @click="editInfo(item)" /></td>
              </tr>
            </tbody>
          </div>
      </div>
    </div>
    <div v-if="showModal">
      <engagement-plan-modal @close="closeInfoModal" @save="save" :data="data" :title="title" :editMode="editMode" :kolId="kolId"/>
    </div>
  </div>
</template>
<script>
import EngagementPlanModal from '@/components/profiles/EngagementPlanModal.vue'
import moment from 'moment'
import { find } from 'lodash'
export default {
  props: ['type', 'kolId'],
  components: {
    EngagementPlanModal
  },
  data () {
    return {
      isDraft: false,
      showModal: false,
      items: [],
      biblio: {},
      title: '',
      editMode: false,
      engagementPlan: [
        {
          id: '1342435',
          kol_id: '123',
          client: 'Takeda',
          year: '2018',
          congress: 'CIS',
          start_date: '2018-04-26',
          end_date: '2018-04-26',
          activity: '2018 CIS KOL Engagement',
          activity_type: '1:1 Engagement',
          engagement_status: 'Completed',
          is_active: true
        }
      ],
      data: {}
    }
  },
  methods: {
    getBibliometrics: function (id, type) {
      console.log(id, type)
    },
    addInfo: function () {
      this.title = 'Add Engagement Plan'
      this.editMode = false
      this.data = {}
      this.showModal = true
    },
    closeInfoModal: function () {
      this.showModal = false
    },
    editInfo: function (item) {
      this.title = 'Edit Engagement Plan'
      this.editMode = true
      this.data = item
      this.showModal = true
    },
    save: function (item) {
      this.editMode = false
      if (find(this.engagementPlan, { 'id': item.id })) {
        let object = this.engagementPlan.find(x => x.id === item.id)
        let index = this.engagementPlan.indexOf(object)
        this.engagementPlan[index] = item
      } else {
        this.engagementPlan.push(item)
      }
      this.showModal = false
    },
    cancel: function () {
      this.editMode = false
    },
    showStatus: function (status) {
      if (status) {
        return 'Acitve'
      } else {
        return 'Inactive'
      }
    },
    getDate: function (date) {
      return moment(date).format('MM/DD/YYYY')
    }
  },
  created () {
    this.getBibliometrics(this.kolId, this.type)
  }
}
</script>
