import axios from 'axios'
import jwtDecode from 'jwt-decode'
import router from '@/router'

const state = {
  token: null,
  user: null,
  errorStatus: false,
  errorMessage: null
}

const actions = {
  login ({ commit }, authData) {
    console.log(authData)
    const userToken = btoa(`${authData.username}:S1mps@n!`)
    console.log(userToken)
    axios.get('https://simpsonapi.com/v2/users/microsoft-login', {
      headers: {
        Authorization: `Basic ${userToken}`
      }
    }).then((res) => {
      console.log(res)
      // window.location.replace(res.data.url)
      console.log(jwtDecode(res.data.token))
      const userData = jwtDecode(res.data.token)
      const userObject = {
        public_id: userData.public_id,
        email: userData.email,
        name: userData.name,
        roles: userData.roles,
        first_name: userData.first_name,
        last_name: userData.last_name,
        type: userData.type
      }
      commit('authUser', {
        token: res.data.token,
        user: userObject
      })
      console.log(userObject)
      localStorage.setItem('token', res.data.token)
      localStorage.setItem('user', JSON.stringify(userObject))
      // router.push('/projects')
    }).catch((error) => {
      if (error.response.status === 401) {
        console.log(error.response.data.message)
        commit('loginFailure', {
          status: true,
          message: error.response.data.message
        })
      }
    })
  },
  goToRoute ({ commit }, name) {
    console.log(name)
    axios.get(`http://localhost:8080/login-callback/${name}`).then((res) => {
      console.log(res)
      console.log(jwtDecode(res.data.token))
      const userData = jwtDecode(res.data.token)
      const userObject = {
        public_id: userData.public_id,
        email: userData.email,
        name: userData.name,
        roles: userData.roles,
        first_name: userData.first_name,
        last_name: userData.last_name,
        type: userData.type
      }
      commit('authUser', {
        token: res.data.token,
        user: userObject
      })
      console.log(userObject)
      localStorage.setItem('token', res.data.token)
      localStorage.setItem('user', JSON.stringify(userObject))
      const roles = userData.roles
      if (roles.includes('admin') || roles.includes('finance')) {
        router.push('/projects')
      } else {
        router.push('/applications')
      }
    }).catch((error) => {
      if (error.response.status === 401) {
        console.log(error.response.data.message)
        commit('loginFailure', {
          status: true,
          message: error.response.data.message
        })
      }
    })
  },
  logout ({ commit }) {
    commit('clearAuthData')
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    router.replace('/')
  }
}

const mutations = {
  authUser (state, userData) {
    state.token = userData.token
    state.user = userData.user
  },
  loginFailure (state, error) {
    state.errorStatus = error.status
    state.errorMessage = error.message
  },
  clearAuthData (state) {
    state.token = null
    state.errorStatus = false
    state.errorMessage = null
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
