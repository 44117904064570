import { render, staticRenderFns } from "./GeneralPayments.vue?vue&type=template&id=cd329c30&scoped=true&"
import script from "./GeneralPayments.vue?vue&type=script&lang=js&"
export * from "./GeneralPayments.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd329c30",
  null
  
)

export default component.exports