<template>
  <div>
    <!-- <vue-friendly-iframe :src="'https://employee.simpsonhealthcare.com/login.php?username='+getEmail" width="100%" style="position: absolute; height: calc(100% - 55px); border: none; top: 54px;"></vue-friendly-iframe> -->
    <iframe :src="'https://employee.simpsonhealthcare.com/login.php?username='+getEmail" width="100%" style="position: absolute; height: calc(100% - 55px); border: none; top: 54px;"></iframe>
  </div>
</template>

<script>
export default {
  name: 'employee-portal',
  data () {
    return {
    }
  },
  computed: {
    getEmail: function () {
      return JSON.parse(localStorage.getItem('user')).email
    }
  },
  methods: {
  },
  mounted () {
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
</style>
