<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-dialog" role="document" style="margin: 0 auto;max-width: 100vw;">
        <div class="modal-content" style="height: 100vh;">
          <div class="modal-header" style="border-bottom: 0px !important; border-bottom: 1px solid #ccc;">
            <h5 class="modal-title">{{title}}</h5>
            <button class="close" v-on:click="close">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body" style="padding: 0px;">
              <form-builder :data="item" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import eventService from '@/services/event.service'
// import Editor from '@tinymce/tinymce-vue'
// import Datepicker from 'vuejs-datepicker'
import { forEach } from 'lodash'
import moment from 'moment'
import FormBuilder from '@/components/requests/form-builder/FormBuilder.vue'
export default {
  name: 'FormModal',
  components: {
    FormBuilder
  },
  props: ['title', 'item', 'type'],
  data () {
    return {
      projects: [],
      projectManagers: [],
      templates: [],
      start: '',
      end: '',
      cutoff: '',
      inputForm: {},
      formArray: [],
      required: true,
      // options: {
      //   menubar: false,
      //   plugins: [
      //     'advlist autolink lists link image charmap print preview anchor',
      //     'searchreplace visualblocks code fullscreen',
      //     'insertdatetime media table paste code help wordcount'
      //   ],
      //   toolbar: 'bold italic strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist | link'
      // },
      formSubmitted: false,
      formValid: false,
      value: []
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    isInvalid (name) {
      if (this.formSubmitted) {
        if (name === null || name === '') {
          return true
        } else {
          return false
        }
      }
    },
    isValid (name) {
      if (this.formSubmitted) {
        return name !== null
      }
    },
    validation (name) {
      if (this.formSubmitted) {
        if (this.inputForm[name] === null || this.inputForm[name].length === 0) {
          return false
        } else {
          return true
        }
      } else {
        return null
      }
    },
    getProjects: function () {
      eventService.getProjects().then((response) => {
        const res = response
        const items = []
        forEach(res, (item) => {
          items.push(item.name)
        })
        this.projects = items
      })
    },
    getEventTemplates: function () {
      eventService.getEventTemplates().then((response) => {
        console.log(response)
        this.templates = response
      })
    },
    getProjectManagers: function () {
      eventService.getProjectManagers().then((response) => {
        this.projectManagers = response.users
      })
    },
    checkItem: function (item) {
      console.log(this.inputForm[item])
      if (this.inputForm[item] !== '' || this.inputForm[item] !== 0) {
        const index = this.formArray.indexOf(item)
        this.formArray.splice(index, 1)
      }
      console.log('Check', this.formArray)
    },
    validateEvent: function () {
      this.formSubmitted = true
      console.log(this.formArray)
      if (this.formArray.length === 0) {
        this.formValid = true
      } else {
        this.formValid = false
      }
      console.log(this.formValid)
    },
    saveEvent: function () {
      this.validateEvent()
      if (this.formValid) {
        this.inputForm.start_date = this.inputForm.start_date ? this.formatDate(this.inputForm.start_date) : null
        this.inputForm.end_date = this.inputForm.end_date ? this.formatDate(this.inputForm.end_date) : null
        this.inputForm.cutoff_date = this.inputForm.cutoff_date ? this.formatDate(this.inputForm.cutoff_date) : null
        this.inputForm.event_name = this.inputForm.event_name ? this.inputForm.event_name : null
        this.inputForm.project = this.inputForm.project ? this.inputForm.project : null
        this.inputForm.project_manager = this.inputForm.project_manager ? this.inputForm.project_manager : null
        this.inputForm.registration_limit = this.inputForm.registration_limit ? this.inputForm.registration_limit : null
        // this.inputForm.notes = this.inputForm.notes ? this.inputForm.notes : null
        this.inputForm.template = this.inputForm.template ? this.inputForm.template : null
        this.inputForm.type = this.type
        console.log(this.inputForm)
        eventService.saveEvent(this.inputForm).then((response) => {
          console.log(response)
          if (response.id) {
            this.inputForm.id = response.id
            this.inputForm.status = true
            this.inputForm.type = 'add'
          } else {
            this.inputForm.type = 'update'
          }
          this.$emit('save', this.inputForm)
        })
      }
    },
    formatDate: function (date) {
      return moment(date).format('YYYY-MM-DD')
    },
    formatStringDate: function (date) {
      const _date = new Date()
      _date.setYear(date.slice(0, 4))
      _date.setMonth(parseInt(date.slice(5, 7)) - 1)
      _date.setDate(date.slice(8, 10))
      return _date
    }
  },
  mounted () {
    // if (this.item.id) {
    //   this.inputForm = this.item
    //   console.log(this.inputForm)
    // } else {
    //   this.inputForm = {
    //     event_name: null,
    //     project: null,
    //     project_manager: null,
    //     start_date: null,
    //     end_date: null,
    //     template: null
    //   }
    //   this.formArray = ['event_name', 'project', 'project_manager', 'start_date', 'end_date']
    //   if (this.type === 'Template') {
    //     this.formArray.unshift('template')
    //   }
    //   console.log(this.formArray)
    // }
    // this.getProjects()
    // this.getProjectManagers()
    // this.getEventTemplates()
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.vdp-datepicker input.form-control {
  background-color:#FFF;
}
</style>
<style scoped>
.invalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 12.8px;
  color: #dc3545;
}

.invalid .multiselect__tags {
  border-color: #dc3545!important;
}

.valid .multiselect__tags {
  border-color: #28a745!important;
}

.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.document-editor {
    border: 1px solid var(--ck-color-base-border);
    border-radius: var(--ck-border-radius);
}

.close:focus {
  outline: none;
}

.mce-notification {
  display: none !important;
}

.file-select > .select-button {
  margin: 10px;
  cursor: pointer;
  font-size: 25px;
}

.file-select > input[type="file"] {
  display: none;
}
</style>
