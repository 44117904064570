<template>
  <div class="col-8">
    <h4>Event URL</h4>
    <div class="form-row" style="margin-top: 15px;">
      <div class="col">
        <input type="text" class="form-control" v-model="wordgenerator" data-max-words="3" placeholder="Enter max 3 words to generate URL">
      </div>
      <div class="col">
        <button class="btn btn-primary" @click="saveURL">Generate and Save URL</button>
      </div>
    </div>
    <div class="card" style="margin-top: 15px;">
      <div class="card-body">
        <h5>Actual URL</h5>
        <div style="margin-bottom: 8px;">
          <a :href="`https://webreg.simpsonhealthcare.com/${url}`" target="_blank" style="font-size: 20px; font-weight: bold; text-decoration:none;">https://webreg.simpsonhealthcare.com/{{url}}</a>
        </div>
      </div>
    </div>
    <div class="card" style="margin-top: 15px;">
      <div class="card-body">
        <h5>Public URL</h5>
        <div style="margin-top: 8px;">
          <a :href="`https://smpsnhlth.co/${url}`" target="_blank" style="font-size: 20px; font-weight: bold; text-decoration:none;">https://smpsnhlth.co/{{url}}</a>
        </div>
      </div>
    </div>
    <!-- <div style="margin-top: 25px;" v-if="urlgenerated">
      <button class="btn btn-lg btn-success" @click="saveURL">Save Generated URL</button>
    </div> -->
  </div>
</template>

<script>
import eventService from '@/services/event.service'
export default {
  name: 'eventURL',
  props: ['eventData'],
  data () {
    return {
      wordgenerator: '',
      url: '',
      randomURL: '',
      chars: '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
      urlgenerated: false,
      link: {},
      employee: {}
    }
  },
  computed: {
  },
  watch: {
    wordgenerator: function () {
      document.querySelectorAll('input[data-max-words]').forEach(input => {
        const maxWords = parseInt(input.getAttribute('data-max-words') || 0)
        input.addEventListener('keydown', e => {
          const target = e.currentTarget
          const words = target.value.split(/\s+/).length
          if (!target.getAttribute('data-announce')) {
            words >= maxWords && e.keyCode === 32 && e.preventDefault()
          }
        })
      })
    }
  },
  methods: {
    getDetails: function () {
      const eventId = this.$route.params.id
      eventService.getEventURL(eventId).then((response) => {
        console.log(response)
        this.link = response
        this.url = response.event_url
      })
    },
    generateURL: function () {
      this.url = this.wordgenerator.replace(/\s+/g, '-').toLowerCase()
      this.randomURL = this.randomString(6)
      this.wordgenerator = ''
      this.urlgenerated = true
    },
    randomString: function (length) {
      var result = ''
      for (var i = length; i > 0; --i) result += this.chars[Math.round(Math.random() * (this.chars.length - 1))]
      return result
    },
    saveURL: function () {
      this.generateURL()
      this.link.event_url = this.url
      this.link.event_id = this.$route.params.id
      console.log(this.link)
      eventService.saveLink(this.link).then((response) => {
        console.log(response)
        if (response.id) {
          this.link.id = response.id
        }
        this.urlgenerated = true
      })
    }
  },
  mounted () {
    this.getDetails()
    // if (this.eventData.event_url) {
    //   this.url = this.eventData.event_url
    // }
  }
}
</script>

<style scoped>

</style>
