function fieldSettings (el, elIndex) {
  console.log(el)
  if (el.type === 'input') {
    return {
      type: el.type,
      inputType: el.inputType,
      id: elIndex,
      columnSize: el.columnSize,
      label: true,
      labelText: el.name,
      placeholder: false,
      placeholderText: '',
      required: false,
      classAttr: '',
      className: '',
      classId: '',
      minLength: '2',
      maxLength: '50',
      vModel: '',
      error: 'This field is required'
    }
  } else if (el.type === 'us-address') {
    return {
      type: el.type,
      id: elIndex,
      customFields: [
        { name: 'Address 1', required: false, inputType: 'text', columnSize: 'col-6', vModel: 'address_1', error: 'This field is required' },
        { name: 'Address 2', required: false, inputType: 'text', columnSize: 'col-6', vModel: 'address_2', error: 'This field is required' },
        { name: 'City', required: false, inputType: 'text', columnSize: 'col-6', vModel: 'city', error: 'This field is required' },
        {
          name: 'State',
          required: false,
          inputType: 'text',
          columnSize: 'col-6',
          values: [
            'Alabama',
            'Alaska',
            'American Samoa',
            'Arizona',
            'Arkansas',
            'California',
            'Colorado',
            'Connecticut',
            'Delaware',
            'District of Columbia',
            'Federated States of Micronesia',
            'Florida',
            'Georgia',
            'Guam',
            'Hawaii',
            'Idaho',
            'Illinois',
            'Indiana',
            'Iowa',
            'Kansas',
            'Kentucky',
            'Louisiana',
            'Maine',
            'Marshall Islands',
            'Maryland',
            'Massachusetts',
            'Michigan',
            'Minnesota',
            'Mississippi',
            'Missouri',
            'Montana',
            'Nebraska',
            'Nevada',
            'New Hampshire',
            'New Jersey',
            'New Mexico',
            'New York',
            'North Carolina',
            'North Dakota',
            'Northern Mariana Islands',
            'Ohio',
            'Oklahoma',
            'Oregon',
            'Palau',
            'Pennsylvania',
            'Puerto Rico',
            'Rhode Island',
            'South Carolina',
            'South Dakota',
            'Tennessee',
            'Texas',
            'Utah',
            'Vermont',
            'Virgin Island',
            'Virginia',
            'Washington',
            'West Virginia',
            'Wisconsin',
            'Wyoming'
          ],
          vModel: 'state',
          error: 'This field is required'
        },
        { name: 'Zip', required: false, inputType: 'text', columnSize: 'col-6', vModel: 'zip', error: 'This field is required' }],
      columnSize: el.columnSize,
      labelText: 'US Address'
    }
  } else if (el.type === 'contact') {
    return {
      type: el.type,
      id: elIndex,
      customFields: [
        { name: 'First Name', required: false, inputType: 'text', columnSize: 'col-6', vModel: 'first_name', error: 'This field is required' },
        { name: 'Last Name', required: false, inputType: 'text', columnSize: 'col-6', vModel: 'last_name', error: 'This field is required' },
        { name: 'Degree(s)', required: false, inputType: 'text', columnSize: 'col-6', vModel: 'degree', error: 'This field is required' },
        { name: 'Email', required: false, inputType: 'text', columnSize: 'col-12', vModel: 'email', error: 'This field is required' },
        { name: 'Cell Number', required: false, inputType: 'tel', columnSize: 'col-6', vModel: 'cell', error: 'This field is required' },
        { name: 'Business Phone Number', required: false, inputType: 'tel', columnSize: 'col-6', vModel: 'phone', error: 'This field is required' }
      ],
      columnSize: el.columnSize,
      labelText: 'Contact'
    }
  } else if (el.type === 'general') {
    return {
      type: el.type,
      id: elIndex,
      customFields: [
        { name: 'Full Name (as appears on DL/Passport)', required: false, inputType: 'text', columnSize: 'col-6', vModel: 'full_name', error: 'This field is required' },
        { name: 'Date of Birth (per TSA requirements)', required: false, inputType: 'birth-date', columnSize: 'col-6', vModel: 'date_of_birth', error: 'This field is required', placeholderText: 'Date of Birth (MM/DD/YYYY)' },
        { name: 'Gender', required: false, inputType: 'radio', columnSize: 'col-6', vModel: 'gender', error: 'This field is required', choices: ['Male', 'Female'] },
        { name: 'Method of Travel', required: false, inputType: 'radio', columnSize: 'col-6', vModel: 'method_of_travel', error: 'This field is required', choices: ['Airplane', 'Automobile', 'Train'] }
      ],
      columnSize: el.columnSize,
      labelText: 'General'
    }
  } else if (el.type === 'hotel') {
    return {
      type: el.type,
      id: elIndex,
      customFields: [
        { name: 'Need Hotel?', required: false, inputType: 'radio', columnSize: 'col-12', vModel: 'need_hotel', error: 'This field is required', choices: ['Yes', 'No'] },
        { name: 'Check-in Date', required: false, inputType: 'date', columnSize: 'col-6', vModel: 'check_in_date', error: 'This field is required', placeholderText: 'Check-In Date' },
        { name: 'Check-out Date', required: false, inputType: 'date', columnSize: 'col-6', vModel: 'check_out_date', error: 'This field is required', placeholderText: 'Check-Out Date' },
        { name: 'Accommodation Type', required: false, inputType: 'radio', columnSize: 'col-12', vModel: 'accommodation_type', error: 'This field is required', choices: ['King', 'Two Beds'] }
      ],
      columnSize: el.columnSize,
      labelText: 'Hotel'
    }
  } else if (el.type === 'flights') {
    return {
      type: el.type,
      id: elIndex,
      customFields: [
        { name: 'Departure Date', required: false, inputType: 'date', columnSize: 'col-6', vModel: 'departure_date', error: 'This field is required', placeholderText: 'Departure Date' },
        { name: 'Departure Time', required: false, inputType: 'time', columnSize: 'col-6', vModel: 'departure_time', error: 'This field is required', placeholderText: 'Departure Time' },
        { name: 'Departure City', required: false, inputType: 'text', columnSize: 'col-12', vModel: 'departure_city', error: 'This field is required', clearfix: true },
        { name: 'Return Date', required: false, inputType: 'date', columnSize: 'col-6', vModel: 'return_date', error: 'This field is required', placeholderText: 'Return Date' },
        { name: 'Return Time', required: false, inputType: 'time', columnSize: 'col-6', vModel: 'return_time', error: 'This field is required', placeholderText: 'Return Time' },
        { name: 'Return City', required: false, inputType: 'text', columnSize: 'col-12', vModel: 'return_city', error: 'This field is required', clearfix: true },
        { name: 'Preferred Airline', required: false, inputType: 'text', columnSize: 'col-6', vModel: 'preferred_airline', error: 'This field is required' },
        { name: 'Frequent Flyer Number', required: false, inputType: 'text', columnSize: 'col-6', vModel: 'frequent_flyer_number', error: 'This field is required' },
        { name: 'Seating Preference', required: false, inputType: 'radio', columnSize: 'col-12', vModel: 'seating_preference', error: 'This field is required', choices: ['Window', 'Aisle', 'No Preference'] },
        { name: 'TSA or Known Traveler Number', required: false, inputType: 'text', columnSize: 'col-6', vModel: 'tsa_known_traveler_number', error: 'This field is required' }
      ],
      columnSize: el.columnSize,
      labelText: 'Flights'
    }
  } else if (el.type === 'meals') {
    return {
      type: el.type,
      id: elIndex,
      customFields: [
        { name: 'Will you be attending XXX night dinner?', required: false, inputType: 'radio', columnSize: 'col-12', vModel: 'attending_dinner', error: 'This field is required', choices: ['Yes', 'No'] },
        { name: 'Dietary Requests', required: false, inputType: 'textarea', columnSize: 'col-12', vModel: 'dietary_requests', error: 'This field is required' }
      ],
      columnSize: el.columnSize,
      labelText: 'Meals'
    }
  } else if (el.type === 'image') {
    return {
      type: el.type,
      id: elIndex,
      columnSize: el.columnSize,
      imageWidth: el.width,
      imageFile: '468x60',
      imageSource: 'https://via.placeholder.com/600x130?text=Header+Image',
      imageAltText: '',
      labelText: 'Header Image'
    }
  } else if (el.type === 'word') {
    return {
      type: el.type,
      id: elIndex,
      columnSize: el.columnSize,
      textValue: 'Enter instructions/cover letter here'
    }
  } else if (el.type === 'textarea') {
    return {
      type: el.type,
      id: elIndex,
      columnSize: el.columnSize,
      label: true,
      labelText: el.name,
      placeholder: false,
      placeholderText: '',
      required: false,
      classAttr: '',
      className: '',
      classId: '',
      minLength: '20',
      maxLength: '1000',
      vModel: '',
      error: 'This field is required'
    }
  } else if (el.type === 'select') {
    return {
      type: el.type,
      inputType: el.inputType,
      id: elIndex,
      columnSize: el.columnSize,
      label: true,
      labelText: el.name,
      placeholder: false,
      placeholderText: '',
      required: false,
      classAttr: '',
      className: '',
      classId: '',
      choices: ['Choice 1', 'Choice 2'],
      other: 'No',
      vModel: '',
      error: 'This field is required'
    }
  } else if (el.type === 'checkbox') {
    return {
      type: el.type,
      id: elIndex,
      columnSize: el.columnSize,
      label: true,
      labelText: el.name,
      required: false,
      classAttr: '',
      className: '',
      classId: '',
      choices: ['Choice 1', 'Choice 2'],
      displayTypes: ['standard', 'inline'],
      display: el.display,
      limit: 'No',
      limitAllowed: '',
      maxSelect: '',
      other: 'No',
      vModel: '',
      error: 'This field is required'
    }
  } else if (el.type === 'radio') {
    return {
      type: el.type,
      id: elIndex,
      columnSize: el.columnSize,
      label: true,
      labelText: el.name,
      required: false,
      classAttr: '',
      className: '',
      classId: '',
      choices: ['Choice 1', 'Choice 2'],
      displayTypes: ['standard', 'inline'],
      display: el.display,
      other: 'No',
      vModel: '',
      error: 'This field is required'
    }
  } else if (el.type === 'switch') {
    return {
      type: el.type,
      id: elIndex,
      columnSize: el.columnSize,
      label: true,
      labelText: el.name,
      classAttr: '',
      className: '',
      classId: '',
      vModel: ''
    }
  } else if (el.type === 'button') {
    return {
      type: el.type,
      id: elIndex,
      buttonText: 'Submit',
      buttonSize: 'sm',
      buttonType: 'primary',
      columnSize: el.columnSize
    }
  } else if (el.type === 'clearfix') {
    return {
      type: 'clearfix',
      columnSize: el.columnSize,
      id: elIndex
    }
  } else if (el.type === 'date') {
    return {
      type: el.type,
      inputType: el.inputType,
      id: elIndex,
      columnSize: el.columnSize,
      label: true,
      labelText: el.name,
      placeholder: false,
      placeholderText: '',
      required: false,
      classAttr: '',
      className: '',
      classId: '',
      vModel: '',
      error: 'This field is required'
    }
  } else if (el.type === 'time') {
    return {
      type: el.type,
      inputType: el.inputType,
      id: elIndex,
      columnSize: el.columnSize,
      label: true,
      labelText: el.name,
      placeholder: false,
      placeholderText: '',
      required: false,
      classAttr: '',
      className: '',
      classId: '',
      vModel: '',
      error: 'This field is required'
    }
  }
}

export default fieldSettings
