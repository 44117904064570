<template>
<div>
    <div style="background: #fff; position: fixed; z-index: 50; padding: 20px; width: 100%; text-align:center;  box-shadow:rgba(0, 0, 0, 0.2) 2px 4px 20px;">
        <div class="cleafix float-left" style="padding-top: 10px; padding-left: 10px; text-align:left;">
            <h3 style="margin-top:0px;">Employees</h3>
        </div>
        <div class="float-right" style="padding-right: 10px; text-align:right;">
            <div class="btn-group">
                <a class="btn btn-primary btn" style="text-transform: uppercase; font-size: 14px; font-weight: bold; padding: 10px 15px; color:#fff;"
                data-toggle="modal" data-target="#userModal" data-backdrop="static" data-keyboard="false"><span class="fa fa-plus"></span> Add Employee</a>
            </div>
        </div>
    </div>
    <div style="padding: 25px; position:fixed; top: 135px; bottom: 0; width: 100%; overflow-y:auto;">
      <div class="container">
        <div class="row">
          <div class="col">
            <table class="table">
              <thead>
                <tr>
                  <td>Employee</td>
                  <td>Position</td>
                  <td>Department</td>
                  <td>Roles</td>
                  <td>Status</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in activeEmployees" :key="item.id">
                  <td>
                    <div class="d-flex bd-highlight">
                      <div class="p-1">
                        <img :src="item.img" width="55" height="55" class="rounded-circle"/>
                      </div>
                      <h6 class="p-2 m-1">
                        {{item.name}}<br />
                        <small class="text-muted">{{item.email}}</small>
                      </h6>
                    </div>
                  </td>
                  <td>{{item.position}}</td>
                  <td>{{item.department}}</td>
                  <td><span class="badge mr-2 badge-secondary" :class="{'badge-danger': value == 'admin', 'badge-info': value == 'supervisor' }" v-for="(value,index) in item.roles" :key="index">{{value}}</span></td>
                  <td><span class="badge badge-success">Active</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- <div class="container-fluid">
        <div class="row">
          <div class="col-md-3 d-none d-md-block bg-light tabbar">
            <div class="border-bottom">
              <ul class="nav nav-pills mb-1 d-flex justify-content-center" id="pills-tab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="pills-active-employees-tab" data-toggle="pill" href="#pills-active-employees" role="tab" aria-controls="pills-active-employees" aria-selected="true">Active Employees</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="pills-inactive-employees-tab" data-toggle="pill" href="#pills-inactive-employees" role="tab" aria-controls="pills-inactive-employees" aria-selected="false">Inactive Employees</a>
                </li>
              </ul>
              <div class="d-flex mr-3 ml-3 mb-3">
                <input type="text" class="form-control" v-model="userSearch" placeholder="Search Employee..."/>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <nav class="col-md-3 d-none d-md-block bg-light sidebar">
            <div class="sidebar-sticky">
              <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-active-employees" role="tabpanel" aria-labelledby="pills-active-employees-tab">
                  <ul class="nav flex-column">
                    <li class="nav-item" v-for="item in activeEmployees" :key="item.id">
                        <a class="nav-link" style="cursor:pointer;" v-bind:class="{ 'employee-active': selected == item.id }" v-on:click="showEmployee(item.id)">
                          <div class="d-flex bd-highlight">
                            <div class="p-1">
                              <img :src="item.img" width="55" height="55" class="rounded-circle"/>
                            </div>
                            <h6 class="p-2 m-1">
                              {{item.name}}<br />
                              <small class="text-muted">{{item.email}}</small>
                            </h6>
                          </div>
                        </a>
                    </li>
                  </ul>
                </div>
                <div class="tab-pane fade" id="pills-inactive-employees" role="tabpanel" aria-labelledby="pills-inactive-employees-tab">
                  <ul class="nav flex-column">
                    <li class="nav-item" v-for="item in inactiveEmployees" :key="item.id" style="border-bottom: 1px solid #ddd;">
                        <a class="nav-link" style="cursor:pointer;" v-on:click="showEmployee(item.id)">
                          <div class="d-flex bd-highlight">
                            <div class="p-1">
                              <img :src="item.img" width="55" height="55" class="rounded-circle"/>
                            </div>
                            <h6 class="p-2">
                              {{item.name}}<br />
                              <small class="text-muted">{{item.email}}</small>
                            </h6>
                          </div>
                        </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
          <main v-if="userSelected" role="main" class="col-md-8 ml-sm-auto col-lg-9 px-0 ml-0" style="position: fixed; top: 145px; left: 0px; right: 0px; bottom: 0px; padding-bottom: 25px;">

            <div class="bg-light">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="true">Profile</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="access-tab" data-toggle="tab" href="#access" role="tab" aria-controls="access" aria-selected="false">Access</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="status-tab" data-toggle="tab" href="#status" role="tab" aria-controls="status" aria-selected="false">Status</a>
                </li>
              </ul>
            </div>

            <div class="tab-content" id="myTabContent" style="padding: 25px; background: #fff; position: relative; height: 100%; z-index: 0; overflow-y: auto;">
              <div class="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab" style="">
                <div class="d-flex justify-content-between">
                  <h3>
                    {{user.first_name}} {{user.last_name}}<br />
                    <small class="text-muted">{{user.position}}</small>
                  </h3>
                  <div class="d-flex align-items-center">
                    <button class="btn btn-primary" v-on:click="editUser(user);">Edit Profile</button>
                  </div>
                </div>

                <div style="padding-top: 25px;">
                  <h5>Email <br />
                  <small class="text-muted">{{user.email}}</small>
                  </h5>
                </div>

                <div style="padding-top: 25px;">
                  <div class="row">
                    <div class="col-md-6">
                      <h5>Phone <br />
                        <small class="text-muted">{{user.phone}}</small>
                      </h5>
                    </div>
                    <div class="col-md-6">
                      <h5>Extension <br />
                        <small class="text-muted">{{user.extension}}</small>
                      </h5>
                    </div>
                  </div>
                </div>

                <div style="padding-top: 25px;">
                  <div class="row">
                    <div class="col-md-6">
                      <h5>Department <br />
                        <small class="text-muted">{{user.department}}</small>
                      </h5>
                    </div>
                    <div class="col-md-6">
                      <h5>Supervisor <br />
                        <small class="text-muted">{{user.supervisor}}</small>
                      </h5>
                    </div>
                  </div>
                </div>

                <div style="padding-top: 25px;">
                  <div class="row">
                    <div class="col-md-6">
                      <h5>Employment Type <br />
                        <span class="badge badge-pill badge-primary">{{user.employment_type}}</span>
                      </h5>
                    </div>
                    <div class="col-md-6">
                      <h5>Multi-Department Head (for timesheet only) <br />
                        <span class="badge badge-pill badge-success">{{user.timesheet_department}}</span>
                      </h5>
                    </div>
                  </div>
                </div>

              </div>
              <div class="tab-pane fade" id="access" role="tabpanel" aria-labelledby="access-tab">

              </div>
              <div class="tab-pane fade" id="status" role="tabpanel" aria-labelledby="status-tab">
                <div v-if="user.is_active" style="padding-bottom: 15px;">
                  <p>To deactive this user enter a reason for deactivation and then click on the button below.</p>
                  <div class="row">
                    <div class="col-md-6" style="padding-bottom: 25px;">
                      <textarea class="form-control" v-model="deactivateReason" placeholder="Reason for deactivation"></textarea>
                    </div>
                  </div>
                  <button type="button" class="btn btn-danger btn-lg">Deactive {{user.first_name}} {{user.last_name}}</button>

                </div>
                <div v-if="!user.is_active" style="padding-bttom: 15px;">
                  <p>To active/re-active this user please select date for activation and click on the button below</p>
                  <p>Activation date is for timesheet purposes only. The date should be the user's first day of employment/re-employment.</p>
                  <div class="row">
                    <div class="col-md-3" style="padding-bottom: 25px;">
                      <date-picker v-model="date" :config="options" placeholder="Date"></date-picker>
                    </div>
                  </div>
                   <button type="button" class="btn btn-success btn-lg">Activate {{user.first_name}} {{user.last_name}}</button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div> -->
    </div>
    <!-- User MODAL -->
    <div class="modal" tabindex="-1" role="dialog" id="userModal">
        <div class="modal-dialog" role="document" style="max-width: 675px;">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add User</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form novalidate>
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <label for="firstName">First Name</label>
                          <input type="text" class="form-control" id="firstName" placeholder="First Name">
                        </div>
                        <div class="form-group col-md-6">
                          <label for="lastName">Last Name</label>
                          <input type="text" class="form-control" id="lastName" placeholder="Last Name">
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-12">
                          <label for="position">Position</label>
                          <input type="text" class="form-control" id="position" placeholder="Position">
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <label for="email">Email</label>
                          <input type="text" class="form-control" id="Email" placeholder="Email">
                        </div>
                        <div class="form-group col-md-6">
                          <label for="department">Department</label>
                          <multiselect :options="departmentOptions" placeholder="Select department..."></multiselect>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <label for="phone">Phone</label>
                          <input type="text" class="form-control" id="phone" placeholder="Phone">
                        </div>
                        <div class="form-group col-md-6">
                          <label for="extension">Extension</label>
                          <input type="text" class="form-control" id="extension" placeholder="Extension">
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <label for="usertype">User type</label>
                          <multiselect :options="userTypeOptions" placeholder="Select type..."></multiselect>
                        </div>
                        <div class="form-group col-md-6">
                          <label for="supervisor">Supervisor</label>
                          <multiselect :options="supervisorOptions" placeholder="Select supervisor..."></multiselect>
                        </div>
                      </div>
                      <div class="form-row">

                        <div class="form-group col-md-6">
                          <label>Timesheet Multi-Department Head</label>
                          <multiselect :options="departmentOptions" :multuple="true" placeholder="Select departments..."></multiselect>
                        </div>
                      </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save employee</button>
                </div>
            </div>
        </div>
    </div>
    <!-- User MODAL -->
</div>
</template>
<script>
import JQuery from 'jquery'
import EmployeeService from '@/services/employee.service'
import { forEach } from 'lodash'

export default {
  name: 'Employees',
  data () {
    return {
      employees: [],
      userSearch: '',
      user: {},
      userSelected: false,
      date: null,
      options: {
        format: 'MM/DD/YYYY',
        useCurrent: false
      },
      selected: '',
      roleItems: [],
      deactivateReason: '',
      userTypeOptions: ['Contractor', 'Employee', 'Temp'],
      departmentOptions: ['CEO', 'Client Services', 'Editorial', 'Finance', 'Graphics', 'Marketing', 'Medical', 'Human Resources', 'Software Engineering + Technology'],
      supervisorOptions: []
    }
  },
  computed: {
    activeEmployees: function () {
      return this.employees.filter((employee) => {
        return employee.is_active === true
      }).filter((employee) => {
        return employee.name.toLowerCase().match(this.userSearch)
      })
    },
    inactiveEmployees: function () {
      return this.employees.filter((employee) => {
        return employee.is_active === false
      }).filter((employee) => {
        return employee.name.toLowerCase().match(this.userSearch)
      })
    }
  },
  methods: {
    getAllUsers: function () {
      EmployeeService.getAllEmployees().then((response) => {
        const [users] = [response.users]
        const allUsers = []
        forEach(users, (item) => {
          item.img = `https://imgs.simpsonhealthcare.com/simpson/employees/${item.name.replace(/\s+/g, '-').toLowerCase()}.gif`
          if (item.is_active) {
            allUsers.push(item.name)
          }
        })
        this.employees = users
        this.supervisorOptions = allUsers
      })
    },
    toTitleCase: function (str) {
      return str.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ')
    },
    showEmployee: function (id) {
      this.userSelected = true
      this.selected = id
      EmployeeService.getEmployee(id).then((response) => {
        this.user = response
      })
    },
    showRoles: function (roles) {
      this.roleItems = roles.split(',')
    },
    editUser: function (item) {
      let $ = JQuery
      $('#userModal').modal({
        backdrop: 'static',
        keyboard: false,
        show: true
      })
    }
  },
  mounted () {
    this.getAllUsers()
  }
}
</script>

<style scoped>
/** Avatar Circle **/
.circle {
  background-color: #ccc;
  border-radius: 50%;
  height: 36px;
  text-align: center;
  width: 36px;
}
.initials {
  font-size: calc(36px / 2);
  /* 50% of parent */
  line-height: 1;
  position: relative;
  top: calc(36px / 4);
  /* 25% of parent */
}

/*
 * Sidebar
 */

 .tabbar {
  position: fixed;
  top: 145px;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar {
  position: fixed;
  top: 270px;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  bottom: 0;
  height: 100%;
  padding-top: 0px;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #fff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

/*
 * Content
 */

[role="main"] {
  padding-top: 133px; /* Space for fixed navbar */
}

@media (min-width: 768px) {
  [role="main"] {
    padding-top: 0px; /* Space for fixed navbar */
  }
}

.flex-column.nav-link {
    cursor:pointer;
    padding: 0.5rem 1rem 0 1rem;
    border-bottom: 1px solid #ccc;
}

.nav-pills {
  padding: 10px 0px;
}

.employee-active {
  background-color: #006AB6;
  color: #fff;
}

.employee-active h6,
.employee-active .text-muted {
  color: #fff !important;
}

/* Rounded sliders */

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 18px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #009370;
}

input:focus+.slider {
    box-shadow: 0 0 1px #009370;
}

input:checked+.slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
