<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Event <span class="text-primary">{{event.event_name}}</span>
            </h5>
            <button class="close" v-on:click="close">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body" style="padding: 25px;" ref="regInfo">
            <section class="registrant-section">
              <div class="d-flex justify-content-between" style="padding: 15px;">
                <div class="card bg-primary text-white text-center p-3">
                  <div class="mb-0" style="font-size: 35px; font-weight: 700;">{{registrant.reg_name}}</div>
                  <footer class="text-white">Registrant</footer>
                </div>
                <div class="card bg-primary text-white text-center p-3">
                  <div class="mb-0" style="font-size: 35px; font-weight: 700;">{{registrant.reg_date | formatDate}}</div>
                  <footer class="text-white">Date Registered</footer>
                </div>
                <div class="card text-center p-3" :class="getBackgroundColor(registrant.reg_status)" style="width: 250px;">
                  <div class="mb-0 p-2" style="font-size: 35px; font-weight: 700;">
                    <multiselect :options="registrantStatus" :show-labels="false" placeholder="Select Email Type" @select="updateStatus" v-model="registrant.reg_status"></multiselect>
                  </div>
                  <footer class="text-white">Status</footer>
                </div>
              </div>
              <div style="padding: 15px;">
                  <div style="background-color: #f4f5f7; padding: 10px; border-radius: 5px;">
                    <h4 style="margin-bottom: 0px;">Emails</h4>
                  </div>
                  <table class="table table-striped" style="background: #fff;">
                    <tr>
                      <th>Email Name</th>
                      <th>Schedule</th>
                      <th>Sent</th>
                      <th></th>
                    </tr>
                    <tr v-for="item in emailResponses" :key="item.id">
                      <td>{{item.email_name}}</td>
                      <td>{{item.schedule}}</td>
                      <td>{{getSentStatus(item.id)}}</td>
                      <td>
                        <a href="javascript:void(0);" class="text-primary" style="font-weight: 700;" @click="sendRegistrantEmailManually(item)">Send Manually Now</a>
                      </td>
                    </tr>
                  </table>
                  <!-- <table class="table table-striped">
                    <tr>
                      <th>Email</th>
                      <th>Schedule</th>
                      <th>Sent</th>
                      <th></th>
                    </tr>
                    <tr>
                      <td>Registration Received</td>
                      <td>Automatically Upon Registration</td>
                      <td>Sent 11/06/2019</td>
                      <td><a href="javascript:void(0);" class="text-primary" style="font-weight: 700;">Send Manually Now</a></td>
                    </tr>
                  </table> -->
              </div>
              <div style="padding: 15px;">
                <GenerateFormEdit :registrantId="registrantId"/>
                <!-- <div style="padding: 25px;">
                  <div class="p-3 row">
                    <GenerateFormEdit :registrantId="registrantId"/>
                  </div>
                   <div class="p-3 row" style="height: 400px; overflow-y: scroll;">
                    <div v-for="(item, index) in fields" :key="index" v-bind:class="{'col-12': item.columnSize == 12, 'col-6': item.columnSize == 6 }">
                      <div class="form-group">
                        <label>{{item.name}}</label>
                        <div v-if="!editInfo" style="font-weight: 600;">
                          {{item.value}}
                        </div>
                        <input v-else :type="item.inputType" class="form-control" :placeholder="item.name" :value="item.value">
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </section>
            <aside class="registrant-notes">
              <div class="sidebar-sticky">
                <h4>Notes</h4>
                <div v-for="item in registrantNotes" :key="item.id" style="padding-top: 15px; border-bottom: 1px dashed #ccc;">
                  <span style="font-size: 18px; font-weight: 600;">{{item.created_at}}</span>
                  <p>{{item.note}}</p>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import eventService from '@/services/event.service'
import GenerateFormEdit from '@/components/events/form-builder/GenerateFormEdit.vue'
import moment from 'moment'
import { forEach } from 'lodash'
export default {
  name: 'RegistrantModal',
  components: {
    GenerateFormEdit
  },
  props: ['registrantId', 'event'],
  data () {
    return {
      registrant: {},
      registrantNotes: {},
      emailResponses: [],
      registrantStatus: ['Registered', 'Wait List', 'Missing Materials', 'Confirmed', 'Cancelled', 'Error'],
      regStatus: 'Registered',
      // fields: [
      //   { name: 'First Name', vModel: 'first_name', required: false, inputType: 'text', columnSize: 'col-6', value: 'John' },
      //   { name: 'Last Name', vModel: 'last_name', required: false, inputType: 'text', columnSize: 'col-6', value: 'Doe' },
      //   { name: 'Degree(s)', vModel: 'degree', required: false, inputType: 'text', columnSize: 'col-6', value: 'MD' },
      //   { name: 'Email', vModel: 'email', required: false, inputType: 'text', columnSize: 'col-12', value: 'jdoe@test.com' },
      //   { name: 'Cell Number', vModel: 'cell', required: false, inputType: 'text', columnSize: 'col-6', value: '+1 645-566-3454' },
      //   { name: 'Business Phone Number', vModel: 'phone', required: false, inputType: 'text', columnSize: 'col-6', value: '+1 234-345-2342' }
      // ],
      fields: [],
      editInfo: false
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    getService: function () {
      eventService.getRegistrant(this.registrantId).then((response) => {
        console.log(response)
        this.registrant = response
      })

      eventService.getEmailResponses(this.event.id).then((response) => {
        console.log(response)
        this.emailResponses = response
      })
    },
    getRegistrantNotes: function () {
      eventService.getRegistrantNotes(this.registrantId).then((response) => {
        console.log(response)
        this.registrantNotes = response
      })
    },
    getSentStatus: function (id) {
      let status = ''
      forEach(this.registrantNotes, function (item) {
        if (item.email_response_id === id) {
          status = 'Sent ' + item.created_at
        }
      })
      return status
    },
    getBackgroundColor: function (status) {
      return {
        'bg-primary': status === 'Registered',
        'bg-success': status === 'Confirmed',
        'bg-danger': status === 'Error',
        'bg-warning': status === 'Cancelled',
        'bg-info': status === 'Missing Materials',
        'bg-secondary': status === 'Wait List'
      }
    },
    updateStatus: function (status) {
      let loader = this.$loading.show({
        container: this.$refs.regInfo,
        loader: 'dots'
      })

      this.registrant.reg_status = status
      this.registrant.reg_form = JSON.stringify(this.registrant.reg_form)
      this.registrant.reg_date = moment(this.registrant.reg_date).format('YYYY-MM-DD')
      eventService.saveRegistrant(this.registrant).then((response) => {
        console.log(response)
        this.registrant.reg_form = JSON.parse(this.registrant.reg_form)
        if (status === 'Confirmed') {
          this.getRegistrantNotes()
          this.getSentStatus()
        }
        loader.hide()
      })
    },
    sendRegistrantEmailManually: function (item) {
      let loader = this.$loading.show({
        container: this.$refs.regInfo,
        loader: 'dots'
      })
      console.log(item)
      eventService.sendRegistrantEmailManually(this.registrantId, item.id).then((response) => {
        console.log(response)
        this.getRegistrantNotes()
        console.log('Email Sent Manually')
        loader.hide()
      })
    }
  },
  mounted () {
    this.getService()
    this.getRegistrantNotes()
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
      }
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-dialog {
    max-width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
}

.modal-dialog .modal-content {
    min-height: 100vh;
}

.close:focus {
  outline: none;
}

.registrant-section {
    position: fixed;
    top: 64px;
    right: 350px;
    left: 0;
    height: 100vh;
    padding-bottom: 64px;
    overflow-y: auto;
}

/*
 * Sidebar
 */
.registrant-notes {
  position: fixed;
  top: 64px;
  bottom: 0;
  width: 350px;
  right: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}
.sidebar-sticky {
  position: relative;
  top: 0;
  bottom: 0;
  height: 100%;
  padding: 15px;
  background: #f4f5f7;
  border-left: 1px solid #ccc;
  scrollbar-width: none;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}
</style>
