<template>
  <div>
    <div class="px-3">
      <div class="row">
        <div class="col-2 p-1">
          <div class="accordion">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1">
                <span v-b-toggle.accordion-1>Seqirus 5153 IAC Website Updates</span>
              </b-card-header>
              <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                 <b-list-group>
                  <b-list-group-item @click="showPage('http://localhost/~abeamin/hyqvia.html')" style="cursor:pointer;">www.influenza-defense.org_home.html</b-list-group-item>
                </b-list-group>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
        <div class="col-8 p-1">
          <div style="width: 100%; border: 1px solid #ccc;">
            <div class="p-2 d-flex justify-content-between" style="background-color: #f1f1f1;">
              <input type="text" class="form-control" placeholder="Enter url" style="width: 65%;" v-model="webpage" disabled>
              <div class="d-flex justify-content-between">
                <button class="btn btn-primary mr-2" @click="editPage">Edit Page</button>
                <button class="btn btn-success mr-2" @click="savePage">Save Page</button>
              </div>
            </div>
            <!-- <object id="htmlView" ref="htmlView" type="text/html" :data="url" width="100%" style="height: calc(100vh - 160px);"></object> -->
            <iframe id="htmlView" :src="url" frameborder="0" width="100%" style="height: calc(100vh - 160px);"></iframe>
          </div>
        </div>
        <div class="col-2 p-1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as htmlToImage from 'html-to-image'
export default {
  name: 'graphics',
  components: {
  },
  data () {
    return {
      webpage: '',
      url: '',
      editable: false
    }
  },
  methods: {
    showPage: function (page) {
      this.webpage = page
      this.url = page
      this.makeEditable()
    },
    editPage: function () {
      this.url = this.webpage
      this.makeEditable()
      // var iframeDocument = iframe.value.contentDocument.body
      // console.log(iframeDocument)
      // iframeDocument.setAttribute('contenteditable', true)
    },
    savePage: function () {
      const link = new URL(this.url)
      const path = link.pathname.split('/').pop()
      let versionNum = path.split('-')[0].split('').pop()

      let iframe = document.getElementById('htmlView')
      var html = iframe.contentDocument.documentElement.outerHTML

      let body = iframe.contentDocument.body
      body.setAttribute('contenteditable', false)
      // let html = preview.value.contentDocument.documentElement.outerHTML
      const blob = new Blob([html], { type: 'text/html' })
      const a = document.createElement('a')
      versionNum = versionNum + 1
      a.download = `v${versionNum}-www.influenza-defense.org_home.html`
      a.href = URL.createObjectURL(blob)
      a.click()
    },
    exportToPDF: function () {
      htmlToImage.toPng(document.getElementById('htmlView').contentDocument.body).then(function (dataUrl) {
        var link = document.createElement('a')
        link.download = 'my-image-name.png'
        link.href = dataUrl
        link.click()
      })
    },
    makeEditable: function () {
      let iframe = document.getElementById('htmlView')
      var iframeDocument = iframe.contentDocument.body
      console.log(iframeDocument)
      iframeDocument.setAttribute('contenteditable', true)
    }
  }
}
</script>

<style scoped>

</style>
