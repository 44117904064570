<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-dialog" role="document" style="max-width: 750px;">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: 0px !important;">
            <h5 class="modal-title">{{title}}</h5>
            <button class="close" @click="close">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body" style="padding: 0px;">
            <div class="card">
              <div class="p-3">
                <div v-if="formError" class="alert alert-danger alert-dismissible fade show" role="alert">
                  <strong>Error!</strong> Description field is required!
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="formError = false">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label>Bibliometric Type</label>
                    <input type="text" class="form-control" v-model="biblio_type" disabled>
                  </div>
                  <div class="form-group col-md-6">
                    <label>Search</label>
                    <input type="serach" class="form-control" v-model="author" placeholder="Enter author to search" @keyup.enter="searchPublications(author)">
                  </div>
                </div>
              </div>
              <div class="p-0" style="height:400px; overflow-y: auto;" ref="pubCard">
                <table class="table">
                  <tbody>
                    <tr v-for="(pub, index) in pubArray" :key="index">
                      <td>
                        <div class="form-check">
                          <input class="form-check-input mt-3" style="margin-left: -.75rem;" type="checkbox" id="blankCheckbox" :value="pub.pub" v-model="selectedPubs">
                        </div>
                      </td>
                      <td class="pb-2" v-html="pub.pub"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">Close</button>
            <button type="button" class="btn btn-success" @click="addPublications('Published')">Publish</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
export default {
  name: 'PublicationModal',
  components: {},
  props: ['title', 'type', 'kolId', 'biblio', 'editMode'],
  data () {
    return {
      biblio_type: this.type,
      author: '',
      pubArray: [],
      editor: false,
      description: '',
      biblioId: '',
      formError: false,
      selectedPubs: []
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    async searchPublications (val) {
      // console.log(val)
      let loader = this.$loading.show({
        container: this.$refs.pubCard,
        loader: 'dots'
      })
      axios.get(`https://www.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&api_key=8bea255ccc5144401674b1e3c77c5ff08408&term=${val}&retmax=50&retmode=json`).then(res => {
        let ids = res.data.esearchresult.idlist
        let idString = ids.toString()
        axios.get(`https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esummary.fcgi?db=pubmed&id='${idString}&retmode=json`).then(res2 => {
          let idList = idString.split(',')
          let result = res2.data.result
          let searchArray = []
          idList.forEach((id) => {
            // Publication Year
            if (result[id]) {
              let pubYear = moment(result[id].sortpubdate).format('YYYY')
              let pubObj = {
                pub: `<a href="https://pubmed.ncbi.nlm.nih.gov/${result[id].uid}/" target="_blank" style="color: #000; text-decoration: none;">${result[id].sortfirstauthor}, et al. ${result[id].title} <em>${result[id].source}</em>. ${pubYear};${result[id].volume}(${result[id].issue})</a>`
              }
              searchArray.push(pubObj)
            }
          })
          // Sort by publication year desc
          let limitArray = searchArray.sort(function (a, b) {
            return new Date(b.sortpubdate) - new Date(a.sortpubdate)
          })
          // Limit results to 4 most recent publications
          // this.pubArray = limitArray.slice(0, 4)
          this.pubArray = limitArray
          console.log(this.pubArray)
          loader.hide()
        }).catch(error => {
          console.log(error)
        })
      }).catch(error => {
        console.log(error)
      })
    },
    addPublications: function (type) {
      let biblioData = {
        id: Math.floor(100000 + Math.random() * 900000),
        kol_id: this.kolId,
        type: this.type,
        description: this.selectedPubs,
        publish_type: type,
        pub_id: '',
        editMode: false
      }
      this.$emit('saved', biblioData)
    }
  },
  created () {
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.multiselect.form-control {
  padding: 0;
}

.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.document-editor {
    border: 1px solid var(--ck-color-base-border);
    border-radius: var(--ck-border-radius);
}

.close:focus {
  outline: none;
}

.mce-notification {
  display: none !important;
}

.file-select > .select-button {
  margin: 10px;
  cursor: pointer;
  font-size: 25px;
}

.file-select > input[type="file"] {
  display: none;
}
</style>
