import { http } from '@/config/base-url'
import authHeader from '@/config/auth-header'

export default {
  getConventions () {
    return http.get('v2/conventions/locations', {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },
  getConvention (id) {
    return http.get(`v2/conventions/${id}`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },
  getProjects () {
    return http.get('v2/conventions/projects', {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },
  saveData (item) {
    if (item._rev) {
      return http.put(`v2/conventions/update/${item._id}`, item, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    } else {
      return http.post(`v2/conventions/new`, item, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    }
  },
  saveClientData (item) {
    if (item.id) {
      return http.put(`v2/conventions/client-data/${item.id}`, item, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    } else {
      return http.post(`v2/conventions/client-data`, item, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    }
  },
  getClientData (id) {
    return http.get(`v2/conventions/client-data/${id}`, {
      headers: authHeader()
    }).then((res) => {
      return res.data
    }).catch((error) => {
      console.log(error)
    })
  },
  getHeatmapTables (id) {
    return http.get(`v2/conventions/heatmap-tables/${id}`, {
      headers: authHeader()
    }).then((res) => {
      return res.data
    }).catch((error) => {
      console.log(error)
    })
  },
  getHeatmapTableData (id) {
    return http.get(`v2/conventions/heatmap-table-data/${id}`, {
      headers: authHeader()
    }).then((res) => {
      return res.data
    }).catch((error) => {
      console.log(error)
    })
  },
  saveHeatmapTableData (item) {
    return http.put(`v2/conventions/heatmap-table-data`, item, {
      headers: authHeader()
    }).then((res) => {
      return res.data
    }).catch((error) => {
      console.log(error)
    })
  }
}
