import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth.module'
import events from './events.module'
import forms from './forms.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    events,
    forms
  }
})
