<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-dialog" role="document" style="max-width: 750px;">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: 0px !important;">
            <h5 class="modal-title">{{title}}</h5>
            <button class="close" @click="close">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body" style="padding: 0px;">
            <div class="card">
              <div class="p-3">
                <div v-if="formError" class="alert alert-danger alert-dismissible fade show" role="alert">
                  <strong>Error!</strong> Description field is required!
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="formError = false">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label>Bibliometric Type</label>
                    <input type="text" class="form-control" v-model="biblio_type" disabled>
                  </div>
                  <div class="form-group col-md-6">
                    <label>Search</label>
                    <input type="serach" class="form-control" v-model="author" placeholder="Enter author to search" @keyup.enter="searchClinicalTrials(author)">
                  </div>
                </div>
              </div>
              <div class="p-0" style="height:400px; overflow-y: auto;" ref="trialCard">
                <table class="table">
                  <tbody>
                    <tr v-for="(trial, index) in trialsArray" :key="index">
                      <td>
                        <div class="form-check">
                          <input class="form-check-input mt-3" style="margin-left: -.75rem;" type="checkbox" id="blankCheckbox" :value="trial.trial" v-model="selectedTrials">
                        </div>
                      </td>
                      <td class="pb-2" v-html="trial.trial"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">Close</button>
            <button type="button" class="btn btn-success" @click="addClinicalTrials('Published')">Publish</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
export default {
  name: 'ClinicalTrialModal',
  components: {},
  props: ['title', 'type', 'kolId', 'biblio', 'editMode'],
  data () {
    return {
      biblio_type: this.type,
      author: '',
      trialsArray: [],
      editor: false,
      description: '',
      biblioId: '',
      formError: false,
      selectedTrials: []
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    async searchClinicalTrials (val) {
      // console.log(val)
      let loader = this.$loading.show({
        container: this.$refs.trialCard,
        loader: 'dots'
      })
      // let str = val
      // str = str.replace(/\s+/g, '+')
      // let query = str

      axios.get(`https://clinicaltrials.gov/api/query/full_studies?expr=${val}&min_rnk=1&max_rnk=100&fmt=json`).then(res => {
        let studies = res.data.FullStudiesResponse.FullStudies
        let nameSplit = val.split(' ')
        // console.log(studies)
        studies.forEach((study) => {
          // console.log(study.Study.ProtocolSection)
          // Identification Module
          // let identify = study.Study.ProtocolSection.IdentificationModule
          let NCTId = study.Study.ProtocolSection.IdentificationModule.NCTId
          let BriefTitle = study.Study.ProtocolSection.IdentificationModule.BriefTitle
          let submitDate = study.Study.ProtocolSection.StatusModule.StudyFirstSubmitDate
          let StudyFirstSubmitDate = moment(submitDate).format('YYYY')

          // Contacts Module
          // let contacts = study.Study.ProtocolSection.ContactsLocationsModule
          let central = study.Study.ProtocolSection.ContactsLocationsModule.CentralContactList
          let overall = study.Study.ProtocolSection.ContactsLocationsModule.OverallOfficialList
          let locations = study.Study.ProtocolSection.ContactsLocationsModule.LocationList
          let ContactRole
          // let ContactName
          // this.result.push(contacts)
          // console.log(contacts)

          // Capitlaize Author Name
          let firstCap, lastCap
          let first, last
          // let middle

          if (nameSplit.length === 2) {
            firstCap = nameSplit[0].charAt(0).toUpperCase() + nameSplit[0].slice(1)
            first = firstCap
            lastCap = nameSplit[1].charAt(0).toUpperCase() + nameSplit[1].slice(1)
            last = lastCap
          } else if (nameSplit.length === 3) {
            firstCap = nameSplit[0].charAt(0).toUpperCase() + nameSplit[0].slice(1)
            first = firstCap
            // middleCap = nameSplit[1].charAt(0).toUpperCase() + nameSplit[1].slice(1)
            // middle = middleCap
            lastCap = nameSplit[2].charAt(0).toUpperCase() + nameSplit[2].slice(1)
            last = lastCap
          }

          // Central Contact List
          if (central) {
            central.CentralContact.forEach((centContact) => {
              // ContactName = centContact.CentralContactName
              ContactRole = centContact.CentralContactRole
            })
          }
          // Overall Official List
          if (overall) {
            overall.OverallOfficial.forEach((overContact) => {
              if (overContact.OverallOfficialName.includes(first && last)) {
                // ContactName = overContact.OverallOfficialName
                ContactRole = overContact.OverallOfficialRole
              } else {
                // Locations Contact List
                if (!locations) {
                  // No Locations Exist
                } else {
                  locations.Location.forEach((list) => {
                    if (list.LocationContactList) {
                      list.LocationContactList.LocationContact.forEach((kol) => {
                        if (kol.LocationContactName.includes(first && last)) {
                          // ContactName = kol.LocationContactName
                          ContactRole = kol.LocationContactRole
                        }
                      })
                    } else {
                      if (list.LocationFacility.includes(first && last)) {
                        // ContactName = list.LocationFacility
                        ContactRole = 'N/A'
                      }
                    }
                  })
                } // !locations
              }
            })
          }
          let trialsObj = {
            trial: `${ContactRole}, ${StudyFirstSubmitDate}. ${BriefTitle}. ClinicalTrials.gov Identifier: ${NCTId}`
          }
          // console.log(trialsObj)
          this.trialsArray.push(trialsObj)
          loader.hide()
          // console.log(this.trialsArray)
        }, nameSplit)
      }, val).catch(error => {
        console.log(error)
      })
    },
    addClinicalTrials: function (type) {
      let biblioData = {
        id: Math.floor(100000 + Math.random() * 900000),
        kol_id: this.kolId,
        type: this.type,
        description: this.selectedTrials,
        publish_type: type,
        pub_id: '',
        editMode: false
      }
      this.$emit('saved', biblioData)
    }
  },
  created () {
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.multiselect.form-control {
  padding: 0;
}

.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.document-editor {
    border: 1px solid var(--ck-color-base-border);
    border-radius: var(--ck-border-radius);
}

.close:focus {
  outline: none;
}

.mce-notification {
  display: none !important;
}

.file-select > .select-button {
  margin: 10px;
  cursor: pointer;
  font-size: 25px;
}

.file-select > input[type="file"] {
  display: none;
}
</style>
