<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-dialog" role="document" style="max-width: 750px;">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: 0px !important;">
            <h5 class="modal-title">{{title}}</h5>
            <button class="close" v-on:click="close">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body" style="padding: 0px;">
            <form novalidate>
              <div class="col form-group">
                <label>Project</label>
                <multiselect v-model="project_name" :options="projectOptions" label="name" track-by="name" placeholder="Select project..." @select="showModule"></multiselect>
              </div>
              <div v-if="isCongress">
                <div class="row" style="padding-left: 15px; padding-right: 15px;">
                  <div class="col form-group">
                    <label>Convention Name</label>
                    <input type="text" v-model="project.name" class="form-control" id="name" placeholder="Convention Name e.g. ATS 2015">
                  </div>
                  <div class="col form-group">
                    <label for="timezone">Timezone</label>
                    <multiselect v-model="project.timezone" :options="timezoneOptions" placeholder="Select timezone..."></multiselect>
                  </div>
                </div>
                <div class="row" style="padding-left: 15px; padding-right: 15px;">
                  <div class="col form-group">
                    <label>Start Date</label>
                    <date-picker v-model="project.start_date" :config="options" placeholder="Start Date"></date-picker>
                  </div>
                  <div class="col form-group">
                    <label>End Date</label><br />
                    <date-picker v-model="project.end_date" :config="options" placeholder="End Date"></date-picker>
                  </div>
                </div>
                <div class="col form-group">
                  <label>Convention Location</label>
                  <multiselect v-model="project.location" :options="locationOptions" label="_id" track-by="_id" placeholder="Select convention location..."></multiselect>
                </div>
                <p style="padding-left: 15px;">Choose which modules you need for this convention</p>
                <div class="row" style="padding-left: 15px; padding-right: 15px;">
                  <div class="col form-group">
                    <label>Interactions</label><br />
                    <label class="switch">
                      <input type="checkbox" id="im" v-model="project.im.has_interactions">
                      <div class="slider round"></div>
                    </label>
                  </div>
                  <div class="col form-group">
                    <label>Scientific Intelligence</label><br />
                    <label class="switch">
                      <input type="checkbox" id="ci" v-model="project.si.has_scientific_intelligence">
                      <div class="slider round"></div>
                    </label>
                  </div>
                </div>
                <div v-show="project.si.has_scientific_intelligence">
                  <div class="col form-group">
                    <label>Do you need abstracts for the scientific intelligence module?</label><br />
                    <label class="switch">
                      <input type="checkbox" id="abstract" v-model="project.si.has_abstracts">
                      <div class="slider round"></div>
                    </label>
                  </div>
                  <div class="col form-group">
                    <label>Do you need the ability to take pictures for the scientific intelligence module?</label><br />
                    <label class="switch">
                      <input type="checkbox" id="si-images" v-model="project.si.has_images">
                      <div class="slider round"></div>
                    </label>
                  </div>
                </div>
              </div>
            </form>
            <div class="p-3 d-flex justify-content-end">
              <div class="p-1 d-flex">
                <button class="btn btn-primary mr-3" @click="saveProject">Save</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ConventionService from '@/services/convention.service'
import ProjectService from '@/services/project.service'
import moment from 'moment'
export default {
  name: 'Add3xProjectModal',
  props: ['title', 'projectItem'],
  data () {
    return {
      project_name: null,
      project: {
        name: null,
        start_date: null,
        end_date: null,
        location: null,
        project_id: null,
        formatted_date: null,
        im: {
          has_interactions: false
        },
        timezone: null,
        si: {
          has_scientific_intelligence: false,
          has_abstracts: false,
          has_images: false
        },
        client: null,
        is_active: true
      },
      projectOptions: [],
      locationOptions: [],
      timezoneOptions: ['EST', 'CST', 'MST', 'PST'],
      options: {
        format: 'MM/DD/YYYY',
        useCurrent: false
      },
      isProfile: false,
      isCongress: false
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    get3xProjects () {
      ProjectService.getProjectModules().then((response) => {
        console.log(response)
        this.projectOptions = response
      })
    },
    getConventionList () {
      ConventionService.getConventions().then((response) => {
        console.log(response)
        this.locationOptions = response
      })
    },
    mysqlDate (date) {
      let checkDate
      if (date) {
        checkDate = new Date(date)
      } else {
        checkDate = new Date()
      }
      return checkDate.toISOString().split('T')[0]
    },
    formatDateString (date) {
      const d = new Date(date)
      let month = '' + (d.getMonth() + 1)
      let day = '' + (d.getDate())
      const year = d.getFullYear()

      if (month.length < 2) { month = '0' + month }
      if (day.length < 2) { day = '0' + day }

      return [month, day, year].join('/')
    },
    saveProject: function () {
      let project = {}
      if (this.project_name.modules === 'Congress') {
        project = this.project
        project.start_date = this.mysqlDate(project.start_date)
        project.end_date = this.mysqlDate(project.end_date)
        project.formatted_date = moment(project.start_date).format('dddd, MMM Do YYYY') + '-' + moment(project.end_date).format('dddd, MMM Do YYYY')
        project.type = 'convention'
        project.client = this.project_name.client
        project.project_id = this.project_name.project_id
        project.project = this.project_name.name
        const location = project.location
        location.name = location._id
        delete location._id
        delete location.type
        delete location._rev
        project.location = location
        project._id = project.project_id + '_' + project.client.toLowerCase() + '_' + project.name.toLowerCase().replace(/ /g, '_')
      } else {
        console.log(this.project_name)
        project.client = this.project_name.client
        project.project_id = this.project_name.project_id
        project.project = this.project_name.name
        let n = 2
        let a = this.project_name.name.split(' ')
        let name = a.slice(n).join(' ')
        let finalName = name.replace(/ /g, '_')
        project.type = this.project_name.modules
        project.img = 'https://imgs.simpsonhealthcare.com/3x/kols.png'
        project._id = project.project_id + '_' + project.client.toLowerCase() + '_' + finalName.toLowerCase().replace(/ /g, '_')
      }
      console.log(project)
      ConventionService.saveData(project).then((response) => {
        console.log(response)
        if (response.id) {
          project._id = response.data._id
          project._rev = response.data._rev
          project.save_type = 'add'
        } else {
          project.save_type = 'update'
        }
        this.$emit('save', project)
      })
    },
    getProject: function () {
      if (this.projectItem.name) {
        this.project = this.projectItem
        this.project.start_date = moment(this.projectItem.start_date).format('MM/DD/YYYY')
        this.project.end_date = moment(this.projectItem.end_date).format('MM/DD/YYYY')
        const location = this.projectItem.location
        location._id = location.name
        const project = {
          project_id: this.projectItem.project_id,
          client: this.projectItem.client,
          name: this.projectItem.project
        }
        this.project_name = project
      }
    },
    showModule: function (selected) {
      console.log(selected)
      if (selected.modules.includes('Congress')) {
        this.isCongress = true
      } else {
        this.isCongress = false
      }
    }
  },
  mounted () {
    this.getConventionList()
    this.get3xProjects()
    this.getProject()
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>

.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.document-editor {
    border: 1px solid var(--ck-color-base-border);
    border-radius: var(--ck-border-radius);
}

.close:focus {
  outline: none;
}

/* Rounded sliders */

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 18px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #009370;
}

input:focus+.slider {
    box-shadow: 0 0 1px #009370;
}

input:checked+.slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
