<template>
  <div>
    <section class="header">
      <ul class="nav nav-tabs d-flex justify-content-end" role="tablist" style="margin-top: 8px; padding-bottom: 8px;">
        <li class="nav-item">
          <a class="nav-link btn btn-info" style="color: #fff; margin-left: 15px;" herf="javascript:void();" @click="resetForm">Clear Form</a>
        </li>
        <li class="nav-item">
          <a class="nav-link btn btn-success" style="color: #fff; margin-left: 15px;" herf="javascript:void();" @click.prevent="saveEventForm">Publish Form</a>
        </li>
      </ul>
    </section>
    <section class="sidebar">
      <div class="sidebar-sticky">
        <div class="stdFields">
          <draggable v-model="stdElements" :group="{ name: 'elements', pull: 'clone', put: false }" :sort="false" :clone="onClone">
            <li v-for="item in stdElements" :key="item.type" class="dragElement-wrapper" v-bind:class="{'dragElement-wrapper-header': item.type == 'image' || item.type == 'word'}">
              <div class="drag-element">
                <div><i :class="item.icon" style="font-size: 25px;"></i></div>
                <span style="font-size: 12px;">{{item.name}}</span>
              </div>
            </li>
          </draggable>
        </div>
      </div>
    </section>
    <section class="px-0 ml-0 notes-section">
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item" v-for="(tab, index) in tabs" :key="index">
            <a class="nav-link" v-bind:class="{ active: tab.isActive }" href="javascript:void(0);" :id="tab.name" data-toggle="tab" role="tab" aria-selected="true" @click.stop.prevent="setActive(tab)">{{ tab.name }}</a>
        </li>
      </ul>
      <div class="tab-content">
        <div role="tabpanel" class="tab-pane fade" v-bind:class="{ 'show active': tabs[0].isActive }" id="builder">
          <div class="alert alert-warning" role="alert">
            <strong>Warning!</strong> If available Excel Column Name field <strong>MUST</strong> be filled out or the form will not work.
          </div>
          <draggable class="p-3 row" style="border: 1px solid #ccc; margin: 0px 25px; background-color: #fff; bottom: 25px; top: 310px; position:fixed; left: 400px; right: 350px; overflow-y:scroll;" :list="newElements" :group="{ name: 'elements' }">
            <div v-for="(el, index) in newElements" :key="index" v-bind:class="{'col-12': el.columnSize == 12, 'col-6': el.columnSize == 6 }">
              <FormBuilderItems :el="el" v-on:delete="deleteItem" v-on:edit="editItem" :index="index" :selected="selected" :selectedIndex="selectedIndex"/>
            </div>
          </draggable>
        </div>
        <div role="tabpanel" class="tab-pane fade" v-bind:class="{ 'show active': tabs[1].isActive }" id="preview">
          <div class="alert alert-info" role="alert">
            <font-awesome-icon icon="info-circle" style="font-size: 20px; margin-right: 15px;"/>This is for preview only. Form does not work.
          </div>
          <GenerateForm :jsonForm="jsonForm"/>
        </div>
      </div>
    </section>
    <section class="sidebar-right">
      <div class="sidebar-sticky-right">
        <FormItemEdit :item="this.item" v-show="active"/>
      </div>
    </section>
  </div>
</template>

<script>
import stdJSON from './fields.json'
import draggable from 'vuedraggable'
import FormItemEdit from './FormItemEdit.vue'
import GenerateForm from './GenerateForm.vue'
import FormBuilderItems from './FormBuilderItems.vue'
import fieldSettings from './clonedItems'
import eventService from '@/services/event.service'
import { maxBy } from 'lodash'
export default {
  components: {
    draggable,
    FormItemEdit,
    GenerateForm,
    FormBuilderItems
  },
  props: ['eventData'],
  data () {
    return {
      stdElements: stdJSON,
      newElements: [],
      jsonForm: [],
      active: false,
      selected: false,
      selectedIndex: -1,
      elIndex: 0,
      item: {},
      livehtml: '',
      code: '',
      tabs: [{
        name: 'Builder',
        id: 0,
        isActive: true
      },
      {
        name: 'Preview Layout',
        id: 1,
        isActive: false
      }],
      activeTab: {},
      formId: ''
    }
  },
  ready: function () {
    this.setActive(this.tabs[0])
  },
  computed: {
  },
  methods: {
    setActive: function (tab) {
      var self = this
      tab.isActive = true
      this.activeTab = tab
      this.tabs.forEach(function (tab) {
        if (tab.id !== self.activeTab.id) {
          tab.isActive = false
        }
      })
      if (tab.id === 1) {
        this.item = {}
        this.selectedIndex = -1
        this.selected = false
        this.active = false
        this.generateForm()
      }
    },
    deleteItem (index) {
      this.selectedIndex = -1
      this.selected = false
      this.newElements.splice(index, 1)
      this.active = false
    },
    editItem (val, index) {
      this.selected = false
      this.item = val
      this.active = true
      this.selectedIndex = index
      this.selected = true
    },
    resetForm () {
      this.newElements = []
      this.active = false
      this.selectedIndex = -1
      this.selected = false
    },
    saveEventForm () {
      const item = {}
      // Fix duplication in database
      if (this.formId) {
        item.id = this.formId
      }
      item.event_id = this.$route.params.id

      item.form_elements = JSON.stringify(this.newElements)
      console.log(item)
      eventService.saveEventForm(item).then((response) => {
        console.log(response)
      })
    },
    onClone (el) {
      this.elIndex++
      return fieldSettings(el, this.elIndex)
    },
    generateForm () {
      const json = JSON.parse(JSON.stringify(this.newElements))
      this.jsonForm = json
    },
    loadEventForm () {
      const eventId = this.$route.params.id
      eventService.getEventForm(eventId).then((response) => {
        console.log(response)
        this.formId = response.id
        if (response.form_elements) {
          this.newElements = JSON.parse(response.form_elements)
          // this.elIndex = JSON.parse(response.form_elements).length
          this.elIndex = this.getFormElementsId(response.form_elements)
        }
      })
    },
    getFormElementsId (elements) {
      const items = JSON.parse(elements)
      console.log(items)
      const max = maxBy(items, 'id').id
      return max
    }
  },
  mounted () {
    this.loadEventForm()
  }

}
</script>

<style scoped>

.header {
  position:fixed;
  background: #fff;
  top: 135px;
  left: 200px;
  right: 0px;
  padding-right: 20px;
  margin-top: 6px;
}

h3 {
  display: inline-block;
  padding-left: 120px;
  padding-top: 5px;
}
/*
 * Sidebar
 */
.sidebar {
  position: fixed;
  top: 200px;
  bottom: 0;
  width: 200px;
  left: 200px;
  z-index: 100; /* Behind the navbar */
  padding: 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}
.sidebar-right {
  position: fixed;
  top: 200px;
  bottom: 0;
  width: 350px;
  right: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}
.sidebar-sticky {
  position: relative;
  top: 0;
  bottom: 0;
  height: 100%;
  padding-top: 10px;
  padding-left: 10px;
  background: #2f303e;
  border-right: 1px solid #ccc;
  scrollbar-width: none;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar-sticky-right {
  position: relative;
  top: 0;
  bottom: 0;
  height: 100%;
  background: #fff;
  border-left: 1px solid #ccc;
  scrollbar-width: none;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.notes-section {
  position: fixed;
  top: 200px;
  left: 400px;
  right: 350px;
  bottom: 0px;
  padding-top: 0px;
  padding-bottom: 25px;
  background-color: #f4f5f7;
  overflow-y:auto;
}
::-webkit-scrollbar {
  display:none;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}
.sidebar .nav-link {
  font-weight: 500;
  color: #333;
  padding: 0px 16px;
}
.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}
.sidebar .nav-link.active {
  color: #fff;
}
.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}
.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}
.sidebar .nav-link {
  padding-top: 5px;
  padding-bottom: 5px;
}
.sidebar .nav-link.active {
  color: #FFF;
  background: #006AB6;
}

.dragElement-wrapper {
  /* width: 95px; */
  width: 90px;
  display: inline-block;
  margin-bottom: 2px;
}

.dragElement-wrapper-header {
  width: 96%;
}

.dragElement-wrapper .drag-element {
  display: block;
  width: 98%;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  background-color: #1d1d28;
  color: #fff;
}

.dragElement-wrapper .drag-element i {
  margin-right: 5px;
}

.element {
  background-color: #f3f3f3;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.element-selected {
  background-color: #DCFEDC;
}
</style>
