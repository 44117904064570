import eventService from '@/services/event.service'

const state = {
  events: []
}

const actions = {
  getEvents ({ commit }) {
    eventService.getEvents().then((response) => {
      commit('eventsData', response)
    })
  },
  getMyEvents ({ commit, state }) {
    if (state.events.length === 0) {
      eventService.getEvents().then((response) => {
        const events = response.filter(item => item.project_manager === JSON.parse(localStorage.getItem('user')).name)
        commit('eventsData', events)
      })
    } else {
      const my = state.events.filter(item => item.project_manager === JSON.parse(localStorage.getItem('user')).name)
      commit('eventsData', my)
    }
  }
}

const mutations = {
  eventsData (state, data) {
    state.events = data
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
