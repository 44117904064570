<template>
  <div>
    <div class="d-flex justify-content-between" style="background: #fff; position: fixed; z-index: 50; padding: 20px 25px; width: 100%; text-align:center; box-shadow:rgba(0, 0, 0, 0.2) 2px 4px 20px;">
      <div class="col-md-2" style="padding-left: 0px; margin-top: 5px;">
        <multiselect :options="projectTypeOptions" :show-labels="false" placeholder="Select Specialty..." v-model="projectType"></multiselect>
      </div>
      <div class="w-25">
        <input type="text" class="form-control form-control-lg" v-model="kolSearch" placeholder="Search KOL..."/>
      </div>
      <div>
        <div class="btn-group">
          <a class="btn btn-primary btn" style="text-transform: uppercase; font-size: 14px; font-weight: bold; padding: 10px 15px; color:#fff;"
        data-toggle="modal" data-target="#projectModal" data-backdrop="static" data-keyboard="false"><font-awesome-icon icon="plus"/> Add KOL</a>
        </div>
      </div>
    </div>
    <div style="padding: 0px 25px; padding-top: 25px; position:fixed; top: 140px; bottom: 0; width: 100%; overflow-y:auto;">
      <div class="row">
        <div v-for="n in 12" :key="n" class="col-sm-6 col-md-4 col-lg-3" style="padding-top: 15px;">
          <article class="info-box" style="width: 100%; text-align:left; box-shadow:rgba(0, 0, 0, 0.2) 2px 4px 20px;">
            <div class="card mb-3 w-100">
              <div class="card-body">
                <div class="d-flex justify-content-between mb-2 pb-3">
                  <div class="text-uppercase">
                    <span class="flag-icon flag-icon-us">NPI: 1881788610 {{n}}</span>
                  </div>
                  <font-awesome-icon icon="star" style="font-size: 22px; color: goldenrod;"/>
                </div>
                <div class="kol-profile text-center">
                    <div class="profile-image" style="background-image: url('https://employee.simpsonhealthcare.com/takeda/4534-104/kols/7.jpg')"></div>
                </div>
                <h5 class="card-title text-center pt-3 mb-0">Charlotte Cunningham-Rundles</h5>
                <h6 class="text-center text-black-50">Pulmonologist</h6>
              </div>
              <div class="card-footer">
                <button type="button" class="btn btn-primary btn btn-block" @click="viewProfile">View Profile</button>
              </div>
            </div>
          </article>
        </div>
      </div>
      <div class="d-flex justify-content-center p-3">
        <button class="btn btn-primary">Show more</button>
      </div>
    </div>
    <!-- <div v-for="item in myfilteredList" :key="item.id" class="col-sm-6 col-md-4 col-lg-3" style="padding-top: 15px;">
            <article v-if="!item.is_archived" class="info-box" style="width: 100%; text-align:left; box-shadow:rgba(0, 0, 0, 0.2) 2px 4px 20px;">
              <div class="card mb-3 w-100">
                <div class="card-body">
                  <div class="d-flex justify-content-between mb-3 h-50">
                    <div class="text-uppercase">
                      <span class="badge" v-bind:class="getBadgeType(item.project_status)">{{item.project_status}}</span>
                    </div>
                    <span v-if="!filterUserProjects(item.user_projects)" @click="starProject(item)" class="far fa-star" style="font-size: 22px;"></span>
                    <font-awesome-icon v-if="filterUserProjects(item.user_projects)" @click="unStarProject(item)" icon="star" style="font-size: 22px; color: goldenrod;"/>
                  </div>
                  <h5 class="card-title" style="height: 100px;">{{item.name}}</h5>
                </div>
                <div class="card-footer" v-if="hasAccess">
                  <div class="d-flex justify-content-between">
                    <span class="badge badge-dark text-uppercase" style="cursor:pointer;" @click="archiveProject(item)">Archive</span>
                    <span class="badge badge-primary text-uppercase" style="cursor:pointer;" data-toggle="modal" data-target="#projectModal" data-backdrop="static" data-keyboard="false" @click="editProject(item)">Edit</span>
                  </div>
                </div>
              </div>
            </article>
        </div> -->
  </div>
</template>

<script>
// import { mapState } from 'vuex'
// import { forEach } from 'lodash'
import SettingService from '@/services/setting.service'
export default {
  name: 'kols',
  components: {
  },
  data () {
    return {
      projectTypeOptions: [],
      kolSearch: '',
      projectType: 'All Specialties'
    }
  },
  methods: {
    viewProfile: function () {
      this.$router.push({ name: 'kolprofile', params: { id: '123456' } })
    }
  },
  created () {
    SettingService.getActiveProjectStatus().then((response) => {
      console.log(response)
      this.projectTypeOptions = response.project_type
      this.projectTypeOptions.unshift('All Specialties')
    })
  }
}
</script>

<style scoped>
.profile-image {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  background-size: cover;
  display: inline-block;
}

.btype:nth-child(odd) {
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
}

.btype:nth-child(even) {
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
}

.btype:nth-child(4n) {
    border-right: 1px solid #ccc;
}
</style>
