<template>
  <div class="px-0" style="margin-left: -15px; margin-right:-15px;">
    <div class="card w-100" style="border: none; border-bottom: 1px solid rgba(0, 0, 0, .1);">
      <div class="d-flex justify-content-between align-items-center p-3">
         <h4>{{type}}</h4>
      </div>
    </div>
    <div class="col-md-2 bg-light" style="position:fixed; bottom:0; top:125px; -webkit-box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1); box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);">
      <ul class="nav flex-column pt-4 pb-4 px-3">
        <li v-for="(item, index) in clientData" :key="index" class="nav-item py-2 pr-3" style="width: 100%;">
          <div class="d-flex justify-content-between">
            <h6 class="mb-0 font-weight-normal" style="cursor:pointer;" @click="viewClientData(item)">{{item.section_header}}</h6>
          </div>
        </li>
      </ul>
    </div>
    <main role="main" class="col-md-7 ml-sm-auto col-lg-7 ml-0" style="position: fixed; top: 125px; left: 0px; right: 0px; bottom: 0px; background-color: #f4f5f7; overflow-y:auto;">
      <Component :is="showComponent" @edit="editForm" @save="saveForm" @cancel="cancelForm" :type="formType" :formId="formId" :formData="formData" />
    </main>
  </div>
</template>
<script>
import ConventionService from '@/services/convention.service'
import ClientData from '@/components/3x/ClientData.vue'
export default {
  props: ['type', 'kolId', 'projectId'],
  components: {
    ClientData
  },
  data () {
    return {
      isDraft: false,
      showModal: false,
      items: [],
      biblio: {},
      clientData: [],
      title: '',
      editMode: false,
      showDropdown: false,
      showComponent: '',
      formType: '',
      formData: [],
      formId: ''
    }
  },
  methods: {
    getBibliometrics: function (id, projectId, type) {
      console.log(id, projectId, type)
      ConventionService.getClientData(projectId).then((response) => {
        console.log(response)
        this.clientData = response
      })
    },
    viewClientData: function (type) {
      this.showComponent = 'ClientData'
      this.formType = type
      this.formId = type.id
      this.formData = type
    }
  },
  created () {
    this.getBibliometrics(this.kolId, this.projectId, this.type)
  }
}
</script>
<style scoped>
.file-select > .select-button {
  margin: 10px;
  cursor: pointer;
  font-size: 25px;
}

.file-select > input[type="file"] {
  display: none;
}
</style>
