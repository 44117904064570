import * as Msal from 'msal'

export default class AuthService {
  constructor () {
    let msalConfig = {
      auth: {
        clientId: 'ba4e71c1-a767-482a-ab69-189bdf23cb6d',
        authority: 'https://login.microsoftonline.com/bc5b6b42-00da-4b67-a317-97a6a2a7cc2a',
        redirectUri: window.location.origin + '/aad-callback',
        navigatToLoginRequestUrl: false,
        postLogoutRedirectUri: window.location.origin
      }
    }
    this.requestObj = {
      scopes: ['user.read', 'mail.send']
    }
    this.app = new Msal.UserAgentApplication(msalConfig)
    this.app.handleRedirectCallback(this.authRedirectCallBack)
  }

  login () {
    return this.app.loginPopup(this.requestObj).then(loginResponse => {
      let user = this.showWelcomeMessage()
      if (user) {
        return user
      } else {
        return null
      }
    })
  }

  showWelcomeMessage () {
    return this.app.getAccount().userName
  }

  logout () {
    this.app.logout()
  };

  authRedirectCallBack (error) {
    if (error) {
      console.log(error)
    }
  }

  getToken () {
    return this.app.acquireTokenSilent(this.requestObj).then(accessToken => {
      return accessToken
    }).catch(error => {
      console.error(error)
      return this.app.acquireTokenPopup(this.requestObj).then(accessToken => {
        return accessToken
      }).catch(err => {
        console.error(err)
      })
    })
  }

  isLoggedIn () {
    if (this.app.getAccount()) {
      return true
    } else {
      return false
    }
  }

  getUser () {
    return this.app.getAccount()
  }

  acquireToken () {
    var tokenRequest = {
      scopes: ['user.read', 'mail.send']
    }
    return this.app.acquireTokenSilent(tokenRequest)
      .then(response => { return response })
      .catch(err => {
        if (err.name === 'InteractionRequiredAuthError') {
          return this.app.acquireTokenPopup(tokenRequest).then(response => {
            return response
          }).catch(err => {
            console.log(err)
          })
        }
      })
  }
}
