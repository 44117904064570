<template>
  <div>
    <div class="d-flex justify-content-between" style="background: #fff; position: fixed; z-index: 50; padding: 20px 25px; width: 100%; text-align:center; box-shadow:rgba(0, 0, 0, 0.2) 2px 4px 20px;">
      <div v-if="hasAccess" class="col-md-2" style="padding-left: 0px; margin-top: 5px;">
        <multiselect :options="projectTypeOptions" :show-labels="false" placeholder="Select project type..." v-model="projectType"></multiselect>
      </div>
      <div class="w-25">
        <input type="text" class="form-control form-control-lg" v-model="projectSearch" placeholder="Search Project..."/>
      </div>
      <div v-if="hasAccess">
        <div class="btn-group">
          <router-link to="/archived" class="btn btn-warning btn" style="text-transform: uppercase; font-size: 14px; font-weight: bold; padding: 10px 15px; color:#fff;"
          data-toggle="modal" data-target="#userModal" data-backdrop="static" data-keyboard="false"><font-awesome-icon icon="archive"/> Archived Projects</router-link>
          <a class="btn btn-primary btn" style="text-transform: uppercase; font-size: 14px; font-weight: bold; padding: 10px 15px; color:#fff;"
        data-toggle="modal" data-target="#projectModal" data-backdrop="static" data-keyboard="false" @click="addProject"><font-awesome-icon icon="plus"/> Add Project</a>
        </div>
      </div>
    </div>
    <div style="padding: 0px 25px; padding-top: 25px; position:fixed; top: 140px; bottom: 0; width: 100%; overflow-y:auto;">
      <div class="row">
        <div v-for="item in myfilteredList" :key="item.id" class="col-sm-6 col-md-4 col-lg-3" style="padding-top: 15px;">
            <article v-if="!item.is_archived" class="info-box" style="width: 100%; text-align:left; box-shadow:rgba(0, 0, 0, 0.2) 2px 4px 20px;">
              <div class="card mb-3 w-100">
                <div class="card-body">
                  <div class="d-flex justify-content-between mb-3 h-50">
                    <div class="text-uppercase">
                      <span class="badge" v-bind:class="getBadgeType(item.project_status)">{{item.project_status}}</span>
                    </div>
                    <span v-if="!filterUserProjects(item.user_projects)" @click="starProject(item)" class="far fa-star" style="font-size: 22px;"></span>
                    <font-awesome-icon v-if="filterUserProjects(item.user_projects)" @click="unStarProject(item)" icon="star" style="font-size: 22px; color: goldenrod;"/>
                  </div>
                  <h5 class="card-title" style="height: 100px;">{{item.name}}</h5>
                </div>
                <div class="card-footer" v-if="hasAccess">
                  <div class="d-flex justify-content-between">
                    <span class="badge badge-dark text-uppercase" style="cursor:pointer;" @click="archiveProject(item)">Archive</span>
                    <span class="badge badge-primary text-uppercase" style="cursor:pointer;" data-toggle="modal" data-target="#projectModal" data-backdrop="static" data-keyboard="false" @click="editProject(item)">Edit</span>
                  </div>
                </div>
              </div>
            </article>
        </div>
      </div>
      <div class="d-flex justify-content-center p-3">
        <button class="btn btn-primary" v-if="hasMoreItemsToShow" @click="showMoreItems()">Show more</button>
      </div>
    </div>
    <!-- Project MODAL -->
    <div>
      <div class="modal" tabindex="-1" role="dialog" id="projectModal">
        <div class="modal-dialog" role="document" style="max-width: 675px;">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{projectTitle}} Project</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
                  <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form novalidate>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="client">Client</label>
                    <multiselect :options="clients" :show-labels="false" placeholder="Select client" v-model="inputForm.client" @select="getClient"></multiselect>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="projectid">Project #</label>
                    <input type="text" class="form-control" placeholder="Project #" v-model="inputForm.project_id" :disabled="editMode">
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group" :class="{'col-md-12': !editMode, 'col-md-9': editMode}">
                    <label for="projectname">Project Name</label>
                    <input type="text" class="form-control" id="projectname" placeholder="Project Name" v-model="inputForm.name">
                  </div>
                  <div class="form-group col-md-3 w-100" style="padding-top: 30px;" v-show="editMode">
                  <button class="btn btn-primary" @click.prevent="renameProject()">Rename Project</button>
                  </div>
                </div>
                <div class="form-row" v-show="editProjectName">
                  <div class="form-group col-md-12">
                    <label for="projectname">New Project Name</label>
                    <input type="text" class="form-control" placeholder="New Project Name" v-model="newprojectname">
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6 form-group">
                    <label>Project Status</label>
                    <multiselect :options="projectStatus" :show-labels="false" placeholder="Select project status" v-model="inputForm.project_status"></multiselect>
                  </div>
                </div>
                <label>3ConneX Modules</label><br />
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="congress">Congress</label><br />
                    <label class="switch">
                        <input type="checkbox" value="Congress" id="congress" v-model="modules">
                        <div class="slider round"></div>
                    </label>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="ee">External Experts</label><br />
                    <label class="switch">
                        <input type="checkbox" value="External Experts" id="ee" v-model="modules">
                        <div class="slider round"></div>
                    </label>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="heatmap">Heatmap</label><br />
                    <label class="switch">
                        <input type="checkbox" value="Heatmap" id="heatmap" v-model="modules">
                        <div class="slider round"></div>
                    </label>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close">Close</button>
              <button type="button" class="btn btn-primary" @click="saveProject(inputForm)">Save project</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- User MODAL -->
  </div>
</template>

<script>
import JQuery from 'jquery'
// import moment from 'moment'
import ProjectService from '@/services/project.service'
import SettingService from '@/services/setting.service'
import { chain, filter, includes, find } from 'lodash'

export default {
  name: 'Projects',
  data () {
    return {
      allItems: [],
      myItems: [],
      finalItems: [],
      pagesShown: 1,
      pageSize: 24,
      projectSearch: '',
      projectTypeOptions: [],
      projectStatus: [],
      clients: [],
      inputForm: {},
      modules: [],
      projectType: 'All Projects',
      editMode: false,
      editProjectName: false,
      newprojectname: '',
      showModal: false,
      projectTitle: 'Add',
      user: JSON.parse(localStorage.getItem('user'))
    }
  },
  computed: {
    myfilteredList: function () {
      const arr = this.finalItems.filter((item) => {
        if (this.projectType !== 'All Projects') {
          return item.project_status === this.projectType
        } else {
          return item
        }
      }).filter((item) => {
        return item.name.toLowerCase().match(this.projectSearch.toLowerCase())
      })
      return this.pageSize ? arr.slice(0, this.pageSize * this.pagesShown) : arr
    },
    hasMoreItemsToShow: function () {
      const arr = this.finalItems.filter((item) => {
        if (this.projectType !== 'All Projects') {
          return item.project_status === this.projectType
        } else {
          return item
        }
      }).filter((item) => {
        return item.name.toLowerCase().match(this.projectSearch)
      })

      return this.pagesShown < arr.length / this.pageSize
    },
    hasAccess: function () {
      const roles = this.user.roles
      if (includes(roles, 'admin') || includes(roles, 'finance')) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    getAllProjects: function () {
      this.busy = true
      ProjectService.getAllProjects().then((response) => {
        const myProjects = this.filterMyProjects(response)
        const allProjects = this.filterAllProjects(response)
        let myItems = []
        let allItems = []
        allItems = chain(allProjects).sortBy('name').value()
        myItems = chain(myProjects).sortBy('name').value()
        const finalItems = myItems.concat(allItems)
        this.finalItems = finalItems
        console.log(this.finalItems)
      })

      SettingService.getActiveProjectStatus().then((response) => {
        console.log(response)
        this.projectStatus = response.project_status
        this.projectTypeOptions = response.project_type
        this.projectTypeOptions.unshift('All Projects')
      })

      SettingService.getActiveClients().then((response) => {
        console.log(response)
        this.clients = response.clients
      })
    },
    getClient: function (action) {
      this.inputForm.name = action + ' '
    },
    filterAllProjects: function (items) {
      const useremail = JSON.parse(localStorage.getItem('user')).email
      return filter(items, (item) => {
        if (item.user_projects) {
          return item.user_projects.indexOf(useremail) === -1
        } else {
          return item
        }
      })
    },
    filterMyProjects: function (items) {
      const useremail = JSON.parse(localStorage.getItem('user')).email
      return filter(items, (item) => {
        if (item.user_projects) {
          return item.user_projects.indexOf(useremail) !== -1
        }
      })
    },
    filterUserProjects: function (item) {
      const useremail = JSON.parse(localStorage.getItem('user')).email
      if (includes(item, useremail)) {
        return true
      } else {
        return false
      }
    },
    archiveProject: function (item) {
      console.log(item)
      item.is_archived = true
      if (item.marketing_status) {
        item.marketing_status = 'Marketing Archived'
      }
      item.project_status = 'Archived'

      ProjectService.saveMarketingData(item).then((response) => {
        console.log(response)
      })
    },
    showMoreItems: function () {
      this.pagesShown = this.pagesShown + 1
    },
    starProject: function (item) {
      const useremail = JSON.parse(localStorage.getItem('user')).email
      const project = find(this.finalItems, item)
      ProjectService.addToMyProjects(project).then((response) => {
        project.user_projects.push(useremail)
        this.getAllProjects()
      })
    },
    unStarProject: function (item) {
      const useremail = JSON.parse(localStorage.getItem('user')).email
      const project = find(this.finalItems, item)
      ProjectService.removeFromMyProjects(project).then((response) => {
        const index = project.user_projects.indexOf(useremail)
        project.user_projects.splice(index, 1)
        this.getAllProjects()
      })
    },
    getBadgeType: function (type) {
      return {
        'badge-info': (type === 'Internal'),
        'badge-success': (type === 'In Progress'),
        'badge-warning': (type === 'Marketing'),
        'badge-primary': (includes(['Completed', 'Need PO', 'Not Kicked', 'On Hold'], type))
      }
    },
    saveProject: function () {
      console.log(this.inputForm)
      this.inputForm.modules = this.modules
      if (this.editMode && this.editProjectName !== '') {
        this.inputForm.new_projectname = this.newprojectname
      }
      if (this.inputForm.project_status === 'Marketing') {
        this.inputForm.marketing_status = null
      } else {
        this.inputForm.marketing_status = null
      }
      ProjectService.saveData(this.inputForm).then((response) => {
        console.log(response)
        if (this.editMode && this.editProjectName) {
          this.inputForm.name = this.newprojectname
        }
        this.editMode = false
        this.editProjectName = false
        this.newprojectname = ''
        JQuery('#projectModal').modal('hide')
        this.getAllProjects()
      })
    },
    editProject: function (item) {
      this.inputForm = item
      if (item.modules === null) {
        this.modules = []
      } else {
        this.modules = item.modules
      }
      this.editMode = true
      this.projectTitle = 'Edit'
      console.log(this.inputForm)
    },
    addProject: function () {
      this.modules = []
      this.inputForm = {}
      this.editMode = false
      this.projectTitle = 'Add'
    },
    close: function () {
      this.inputForm = {}
      this.showModal = false
    },
    renameProject: function () {
      this.editProjectName = true
    }
  },
  mounted () {
    this.getAllProjects()
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
/* Rounded sliders */

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 18px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #009370;
}

input:focus+.slider {
    box-shadow: 0 0 1px #009370;
}

input:checked+.slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
