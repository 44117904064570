<template>
  <div class="d-flex justify-content-center px-5" style="padding:45px 25px 25px 25px;">
    <!-- <Description :kid="this.kid" :type="type" /> -->
    <div class="card w-75">
      <div class="card-header d-flex justify-content-between align-items-center">
        <div>
          <h4>{{type}}</h4>
          <p class="text-muted mb-0" style="font-size: 0.85em;">Last updated: 7/1/2020 2:57 PM by Simpson Employee</p>
        </div>
        <button v-if="!editMode" class="btn btn-sm btn-warning" @click="editInfo">Edit</button>
        <div v-else class="btn-group">
          <button class="btn btn-sm btn-success" @click="save">Save</button>
          <button class="btn btn-sm btn-default" @click="cancel">Cancel</button>
        </div>
      </div>
      <div class="card-body p-5">
        <div class="row">
          <div class="col">
            <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">Gender</label>
            <p v-if="!editMode" style="font-size: 16px;">{{inputForm.gender}}</p>
            <div v-else class="pb-2">
              <input type="text" class="form-control w-25" placeholder="Gender" v-model="inputForm.gender">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">First Name</label>
            <p v-if="!editMode" style="font-size: 16px;">{{inputForm.first_name}}</p>
            <div v-else class="pb-2">
              <input type="text" class="form-control" placeholder="First Name" v-model="inputForm.first_name">
            </div>
          </div>
          <div class="col">
            <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">Middle Name</label>
            <p v-if="!editMode" style="font-size: 16px;">{{checkInput(inputForm.middle_name)}}</p>
            <div v-else class="pb-2">
              <input type="text" class="form-control" placeholder="Middle Name" v-model="inputForm.middle_name">
            </div>
          </div>
          <div class="col">
            <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">Last Name</label>
            <p v-if="!editMode" style="font-size: 16px;">{{inputForm.last_name}}</p>
            <div v-else class="pb-2">
              <input type="text" class="form-control" placeholder="Last Name" v-model="inputForm.last_name">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">Degree</label>
            <p v-if="!editMode" style="font-size: 16px;">{{inputForm.degree}}</p>
            <div v-else class="pb-2">
              <input type="text" class="form-control" placeholder="Degree" v-model="inputForm.degree">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">Primary Email</label>
            <p v-if="!editMode" style="font-size: 16px;">{{inputForm.primary_email}}</p>
            <div v-else class="pb-2">
              <input type="text" class="form-control" placeholder="Primary Email" v-model="inputForm.primary_email">
            </div>
          </div>
          <div class="col">
            <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">Secondary Email</label>
            <p v-if="!editMode" style="font-size: 16px;">{{checkInput(inputForm.secondary_email)}}</p>
            <div v-else class="pb-2">
              <input type="text" class="form-control" placeholder="Secondary Email" v-model="inputForm.secondary_email">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">Primary Phone</label>
            <p v-if="!editMode" style="font-size: 16px;">{{inputForm.primary_phone}}</p>
            <div v-else class="pb-2">
              <input type="text" class="form-control" placeholder="Primary Phone" v-model="inputForm.primary_phone">
            </div>
          </div>
          <div class="col">
            <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">Secondary Phone</label>
            <p v-if="!editMode" style="font-size: 16px;">{{checkInput(inputForm.secondary_phone)}}</p>
            <div v-else class="pb-2">
              <input type="text" class="form-control" placeholder="Secondary Phone" v-model="inputForm.secondary_phone">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">Mobile</label>
            <p v-if="!editMode" style="font-size: 16px;">{{checkInput(inputForm.mobile)}}</p>
            <div v-else class="pb-2">
              <input type="text" class="form-control w-50" placeholder="Mobile" v-model="inputForm.mobile">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">Primary Address</label>
            <p v-if="!editMode" style="font-size: 16px;">
              {{inputForm.primary_address.address_1}}<br>
              <span v-if="inputForm.primary_address.address_2">{{inputForm.primary_address.address_2}}<br></span>
              <span v-if="inputForm.primary_address.address_3">{{inputForm.primary_address.address_3}}<br></span>
              {{inputForm.primary_address.city}}, {{inputForm.primary_address.state}} {{inputForm.primary_address.zip}}<br>
              {{inputForm.primary_address.country}}
            </p>
            <div v-else class="pb-2">
              <div class="row form-group">
                <div class="col">
                  <input type="text" class="form-control" placeholder="Address 1" v-model="inputForm.primary_address.address_1">
                </div>
                <div class="col">
                  <input type="text" class="form-control" placeholder="Address 2" v-model="inputForm.primary_address.address_2">
                </div>
                <div class="col">
                  <input type="text" class="form-control" placeholder="Address 3" v-model="inputForm.primary_address.address_3">
                </div>
              </div>
              <div class="row form-group">
                <div class="col">
                  <input type="text" class="form-control" placeholder="City" v-model="inputForm.primary_address.city">
                </div>
                <div class="col">
                  <input type="text" class="form-control" placeholder="State" v-model="inputForm.primary_address.state">
                </div>
                <div class="col">
                  <input type="text" class="form-control" placeholder="Zip" v-model="inputForm.primary_address.zip">
                </div>
              </div>
              <div class="form-group">
                <input type="text" class="form-control w-25" placeholder="Country" v-model="inputForm.primary_address.country">
              </div>
            </div>
            <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">Map Coordinates</label>
            <p style="font-size: 16px;">Lat: 32.234<br>Lng: 32.234</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['type', 'kolId'],
  components: {},
  data () {
    return {
      isDraft: false,
      showModal: false,
      items: [],
      biblio: {},
      title: '',
      editMode: false,
      inputForm: {
        gender: 'Female',
        first_name: 'Charlotte',
        middle_name: null,
        last_name: 'Cunningham-Rundles',
        degree: 'MD',
        primary_email: 'test@demo.com',
        secondary_email: null,
        primary_phone: '+1 (345) 456-4545',
        secondary_phone: '+1 (345) 456-4545',
        mobile: null,
        primary_address: {
          address_1: '230 Shore Rd',
          address_2: null,
          city: 'Old Lyme',
          state: 'CT',
          zip: '06173',
          country: 'United States'
        },
        map_coordinate: {
          lat: '32.234',
          lng: '32.234'
        }
      }
    }
  },
  methods: {
    getBibliometrics: function (id, type) {
      console.log(id, type)
    },
    editInfo: function () {
      this.editMode = true
    },
    save: function () {
      this.editMode = false
    },
    cancel: function () {
      this.editMode = false
    },
    checkInput: function (item) {
      if (item) {
        return item
      } else {
        return 'None'
      }
    }
  },
  created () {
    this.getBibliometrics(this.kolId, this.type)
  }
}
</script>
