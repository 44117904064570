import { http } from '@/config/base-url'
import authHeader from '@/config/auth-header'

export default {
  getAllEmployees () {
    return http.get('v2/users/employees', {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },
  getActiveEmployees () {
    return http.get('v2/users/employees/active', {
      headers: authHeader()
    }).then((res) => {
      const items = res.data.users
      return items
    }).catch((error) => {
      console.log(error)
    })
  },
  getEmployee (id) {
    return http.get(`v2/users/employees/${id}`, {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },
  getAllTemps () {
    return http.get('v2/users/temps', {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },
  saveTemp (item) {
    if (item.id) {
      return http.put(`v2/users/temps/${item.id}`, item, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    } else {
      return http.post(`v2/users/temps`, item, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    }
  }
}
