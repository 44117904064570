<template>
<div>
  <b-tabs>
    <b-tab :title="tabtitles[0]" class="tab-content-container" style="overflow:auto; height: calc(100vh - 135px);">
      <p>Showing only questions with choices. Open ended questions can be seen in the excel</p>
      <div v-for="(item, index) in summaryResults" :key="index" ref="questionCard">
        <div class="result-item" v-if="item.question_type == 'radio'">
          <div class="clearfix">
            <div class="circle float-left">
              <span class="initials">Q.{{index+1}}</span>
            </div>
            <ul class="list-group float-left" style="margin-left: 25px; width: 90%;">
                <li class="list-group-item result-box d-flex justify-content-between align-items-center" style="cursor:pointer;" v-on:click="showResultDetails(index)">
                  <h6>{{item.question}}<br><small class="text-uppercase">Choices</small></h6>
                  <font-awesome-icon :icon="!showDetails || answerEdit != index ? 'chevron-down' : 'chevron-up'"/>
                  <!-- <font-awesome-icon icon="chevron-up" v-if="showDetails"/> -->
                </li>
                <li class="list-group-item d-flex" style="padding-top: 35px;" v-if="showDetails && answerEdit == index">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="d-flex justify-content-center" style="padding-top: 30px;">
                          <donut-chart v-bind:initial-values="getAnswerValues(item.answer_values)"></donut-chart>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <table class="table table-borderless" style="font-size: 14px;">
                          <tr>
                            <th></th>
                            <th></th>
                            <th style="text-align:center;"><font-awesome-icon icon="user"/></th>
                            <th style="text-align:center;"><font-awesome-icon icon="percentage"/></th>
                          </tr>
                          <tr v-for="(answer, answerIndex) in item.answers" :key="answerIndex">
                            <td>
                              <div v-if="item.type != 'screener'" class="circle-dot" v-bind:class="{'sd': answer.answer == 1, 'd': answer.answer == 2, 'nad': answer.answer == 3, 'a': answer.answer == 4, 'sa': answer.answer == 5}"></div>
                              <div v-else class="circle-dot" v-bind:class="{'sd': answer.color == 1, 'd': answer.color == 2, 'nad': answer.color == 3, 'a': answer.color == 4, 'sa': answer.color == 5}"></div>
                            </td>
                            <td>
                              <div v-if="item.type == 'screener'">
                                {{answer.answer}}
                              </div>
                              <div v-else>
                                <strong>{{answer.answer}}</strong> - {{answer.answer_text}}
                              </div>

                            </td>
                            <td style="text-align:center;">{{answer.num_answered}}</td>
                            <td style="text-align:center;">{{answer.percentage}}%</td>
                          </tr>
                          <!-- <tr>
                            <td><div class="circle-dot d"></div></td>
                            <td><strong>2</strong> - Disagree</td>
                            <td style="text-align:center;">82</td>
                            <td style="text-align:center;">18%</td>
                          </tr>
                          <tr>
                            <td><div class="circle-dot nad"></div></td>
                            <td><strong>3</strong> - Neither agree nor disagree</td>
                            <td style="text-align:center;">15</td>
                            <td style="text-align:center;">14%</td>
                          </tr>
                          <tr>
                            <td><div class="circle-dot a"></div></td>
                            <td><strong>4</strong> - Agree</td>
                            <td style="text-align:center;">11</td>
                            <td style="text-align:center;">7%</td>
                          </tr>
                          <tr>
                            <td><div class="circle-dot sa"></div></td>
                            <td><strong>5</strong> - Strongly Agree</td>
                            <td style="text-align:center;">5</td>
                            <td style="text-align:center;">2%</td>
                          </tr> -->
                        </table>
                      </div>
                    </div>
                  </div>
                </li>
            </ul>
          </div>
        </div>
      </div>
    </b-tab>
    <b-tab :title="tabtitles[1]" class="tab-content-container">
      <div style="overflow:auto; height: calc(100vh - 155px);" ref="participantCard">
        <div v-for="(item, index) in participantResults.users" :key="index" style="padding-bottom: 25px;">
      <ul class="list-group">
            <li class="list-group-item d-flex justify-content-between align-items-center" style="cursor:pointer;" v-on:click="showParticipantDetails(index)">
              <h6>{{item.user}}<br><small class="text-uppercase">Submitted {{item.date_submitted}}</small></h6>
              <font-awesome-icon :icon="!participantDetails || participantEdit != index ? 'chevron-down' : 'chevron-up'"/>
              <!-- <font-awesome-icon icon="chevron-down" v-if="!showDetails" />
              <font-awesome-icon icon="chevron-up" v-if="showDetails" /> -->
            </li>
            <li class="list-group-item" style="padding-top: 35px;" v-if="participantDetails && participantEdit == index">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="screener-tab" data-toggle="tab" href="#screener" role="tab" aria-controls="screener" aria-selected="true">Screener Questions</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="behavior-tab" data-toggle="tab" href="#behavior" role="tab" aria-controls="behavior" aria-selected="false">Behavior Stage</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="risk-tab" data-toggle="tab" href="#risk" role="tab" aria-controls="risk" aria-selected="false">Risk Belief</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="consequence-tab" data-toggle="tab" href="#consequence" role="tab" aria-controls="consequence" aria-selected="false">Consequence Belief</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="barriers-tab" data-toggle="tab" href="#barriers" role="tab" aria-controls="barriers" aria-selected="false">Barriers Belief</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="benefit-tab" data-toggle="tab" href="#benefit" role="tab" aria-controls="benefit" aria-selected="false">Benefit Belief</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="archetype-tab" data-toggle="tab" href="#archetypes" role="tab" aria-controls="archetypes" aria-selected="false">Archetypes</a>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="screener" role="tabpanel" aria-labelledby="screener-tab" style="padding-top: 25px;">
                  <h5>Screener Questions</h5>
                  <table class="table">
                    <thead class="thead-light">
                      <tr>
                        <th style="width: 80%;">Question</th>
                        <th style="text-align:center;">Answer</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="screener in item.results.screener" :key="screener.question_id">
                        <td>{{screener.question}}</td>
                        <td style="text-align:center;">{{screener.answer}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="tab-pane fade" id="behavior" role="tabpanel" aria-labelledby="behavior-tab" style="padding-top: 25px;">
                  <h5>Behavior Stage<br><small style="font-size: 12px;">1 (strongly disagree) - 5 (strongly agree)</small></h5>
                  <table class="table">
                    <thead class="thead-light">
                      <tr>
                        <th style="width: 90%;">Question</th>
                        <th style="text-align:center;">Answer</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="behavior in item.results.behavior" :key="behavior.question_id" v-bind:class="{'bg-success text-white': behavior.answer == item.behavior_stage}">
                        <td>{{behavior.question}}</td>
                        <td style="text-align:center;">{{behavior.answer}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="tab-pane fade" id="risk" role="tabpanel" aria-labelledby="risk-tab" style="padding-top: 25px;">
                  <h5>Risk Belief<br><small style="font-size: 12px;">1 (strongly disagree) - 5 (strongly agree)</small></h5>
                  <table class="table">
                    <thead class="thead-light">
                      <tr>
                        <th style="width: 90%;">Question</th>
                        <th style="text-align:center;">Answer</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="risk in item.results.risk" :key="risk.question_id">
                        <td>{{risk.question}}</td>
                        <td style="text-align:center;">{{risk.answer}}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr style="font-weight: bold;">
                        <td style="text-align:right;">Score</td>
                        <td class="text-success" style="text-align:center;">{{item.risk_belief}}</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div class="tab-pane fade" id="consequence" role="tabpanel" aria-labelledby="consequence-tab" style="padding-top: 25px;">
                  <h5>Consequence Belief<br><small style="font-size: 12px;">1 (strongly disagree) - 5 (strongly agree)</small></h5>
                  <table class="table">
                    <thead class="thead-light">
                      <tr>
                        <th style="width: 90%;">Question</th>
                        <th style="text-align:center;">Answer</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="consequence in item.results.consequence" :key="consequence.question_id">
                        <td>{{consequence.question}}</td>
                        <td style="text-align:center;">{{consequence.answer}}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr style="font-weight: bold;">
                        <td style="text-align:right;">Score</td>
                        <td class="text-success" style="text-align:center;">{{item.consequence_belief}}</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div class="tab-pane fade" id="barriers" role="tabpanel" aria-labelledby="barriers-tab" style="padding-top: 25px;">
                  <h5>Barriers Belief<br><small style="font-size: 12px;">1 (strongly disagree) - 5 (strongly agree)</small></h5>
                  <table class="table">
                    <thead class="thead-light">
                      <tr>
                        <th style="width: 90%;">Question</th>
                        <th style="text-align:center;">Answer</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="barrier in item.results.barrier" :key="barrier.question_id">
                        <td>{{barrier.question}}</td>
                        <td style="text-align:center;">{{barrier.answer}}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr style="font-weight: bold;">
                        <td style="text-align:right;">Score</td>
                        <td class="text-success" style="text-align:center;">{{item.barriers_belief}}</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div class="tab-pane fade" id="benefit" role="tabpanel" aria-labelledby="benefit-tab" style="padding-top: 25px;">
                  <h5>Benefit Belief<br><small style="font-size: 12px;">1 (strongly disagree) - 5 (strongly agree)</small></h5>
                  <table class="table">
                    <thead class="thead-light">
                      <tr>
                        <th style="width: 90%;">Question</th>
                        <th style="text-align:center;">Answer</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="benefit in item.results.benefit" :key="benefit.question_id">
                        <td>{{benefit.question}}</td>
                        <td style="text-align:center;">{{benefit.answer}}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr style="font-weight: bold;">
                        <td style="text-align:right;">Score</td>
                        <td class="text-success" style="text-align:center;">{{item.benefit_belief}}</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div class="tab-pane fade" id="archetypes" role="tabpanel" aria-labelledby="archetype-tab" style="padding-top: 25px;">

                    <div v-for="(result, key) in item.results.archetype" :key="key" class="card" style="margin-bottom: 25px;">
                      <h5 class="card-header text-capitalize" v-bind:class="getDominatArchetype(key, item.dominate_archetype)">{{key}}<br><small style="font-size: 12px;">1 (strongly disagree) - 5 (strongly agree)</small></h5>
                      <div class="card-body" style="padding:0;">
                        <table class="table">
                          <thead class="thead-light">
                            <tr>
                              <th style="width: 90%;">Question</th>
                              <th style="text-align:center;">Answer</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="value in result" :key="value.question_id">
                              <td>{{value.question}}</td>
                              <td style="text-align:center;">{{value.answer}}</td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr style="font-weight: bold;">
                              <td style="text-align:right;">Score</td>
                              <td class="text-success" style="text-align:center;">{{getArchetypeScore(key, item.archetype)}}</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                </div>
              </div>
            </li>
          </ul>
          </div>
          </div>
    </b-tab>
  </b-tabs>
</div>
</template>

<script>
// import JQuery from 'jquery';
import moment from 'moment'
import BeliefMapService from '@/services/beliefmap.service'
import donutChart from '@/components/beliefmap/charts/donutChart.vue'
import * as _ from 'lodash'

export default {
  name: 'SurveyResultsView',
  components: {
    donutChart
  },
  props: ['surveyId'],
  data () {
    return {
      tabtitles: ['Summary results', 'Participant results'],
      values: [125, 82, 15, 11, 5],
      showDetails: false,
      summaryResults: [],
      participantResults: [],
      summaryIndex: 0,
      colorArray: [],
      answerEdit: -1,
      participantDetails: false,
      participantEdit: -1
    }
  },
  methods: {
    showResultDetails: function (index) {
      this.showDetails = !this.showDetails
      this.answerEdit = index
    },
    showParticipantDetails: function (index) {
      this.participantDetails = !this.participantDetails
      this.participantEdit = index
    },
    getQuestionAnswers: function () {
      let loader = this.$loading.show({
        container: this.$refs.questionCard,
        loader: 'dots'
      })
      BeliefMapService.getSurveySummaryResults(this.surveyId).then((response) => {
        this.summaryResults = response
        loader.hide()
      })
    },
    getParticipantResults: function () {
      let loader = this.$loading.show({
        container: this.$refs.participantCard,
        loader: 'dots'
      })
      BeliefMapService.getSurveyParticipantResults(this.surveyId).then((response) => {
        _.forEach(response.users, (item) => {
          const finalItems = _.chain(item.results)
            .groupBy('type')
            .value()
          item.results = finalItems
        })

        _.forEach(response.users, (aItem) => {
          const aItems = _.chain(aItem.results.archetype)
            .groupBy('category_type')
            .value()

          aItem.results.archetype = aItems
        })

        this.participantResults = response
        console.log(this.participantResults)
        // const finalItems = _.chain(response.)
        //     .sortBy('task_date')
        //     .groupBy('task_date')
        //     .toPairs()
        //     .map(item => zipObject(['date', 'tasks'], item))
        //     .value();
        loader.hide()
      })
    },
    getArchetypeScore: function (archetype, scores) {
      let score = 0
      _.forEach(scores, (item) => {
        if (item.category === archetype) {
          score = item.total
        }
      })
      return score
    },
    getAnswerValues: function (values) {
      let answerArray = []
      values.forEach(el => {
        answerArray.push(el.a)
      })
      // console.log(answerArray);
      // colors: ["#6495ED", "goldenrod", "#cd5c5c", "thistle", "lightgray"],
      return answerArray
    },
    getDominatArchetype: function (key, archetype) {
      console.log(key)
      return {
        'bg-success text-white': archetype.includes(key)
      }
    },
    getHumanDate: function (date) {
      return moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY h:mm A')
    }
  },
  computed: {
  },
  mounted () {
    this.getQuestionAnswers()
    this.getParticipantResults()
  }
}
</script>

<style scoped>
.tab-content-container {
  padding-top: 15px;
}

a {
  color: #006AB6;
}

  /** Avatar Circle **/
.circle {
  background-color: #ccc;
  border-radius: 50%;
  height: 45px;
  text-align: center;
  width: 45px;
  margin-top: 13px;
}
.initials {
  font-size: 14px;
  /* 50% of parent */
  line-height: 1;
  position: relative;
  top: 10px;
  /* 25% of parent */
}

/** Circle Dot **/
.circle-dot {
  background-color: #000;
  border-radius: 50%;
  height: 10px;
  text-align: center;
  width: 10px;
  margin-top: 6px;
}

.sd {
  background-color: #006BB6;
}

.d {
  background-color: #B3C50E;
}

.nad {
  background-color: #333333;
}

.a {
  background-color: #E56A54;
}

.sa {
  background-color: #FEBE10;
}

.result-item {
  padding-bottom: 25px;
}

.result-box:after, .result-box:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.result-box:after {
  border-color: rgba(136, 183, 213, 0);
  border-right-color: #fff;
  border-width: 12px;
  margin-top: -12px;
}
.result-box:before {
  border-color: rgba(194, 225, 245, 0);
  border-right-color: #ccc;
  border-width: 13px;
  margin-top: -13px;
}

</style>
