<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-2" style="margin-bottom: 30px;">
        <div class="card bg-secondary text-white text-center p-3">
          <div class="mb-0" style="font-size: 35px; font-weight: 700;">{{this.analytics.total ? this.analytics.total: 0}}</div>
          <footer class="text-white">Total</footer>
        </div>
      </div>
      <div class="col-md-2" style="margin-bottom: 30px;">
        <div class="card bg-success text-white text-center p-3">
          <div class="mb-0" style="font-size: 35px; font-weight: 700;">{{this.analytics.confirmed ? this.analytics.confirmed : 0}}</div>
          <footer class="text-white">Confirmed</footer>
        </div>
      </div>
      <div class="col-md-2" style="margin-bottom: 30px;">
        <div class="card bg-primary text-white text-center p-3">
          <div class="mb-0" style="font-size: 35px; font-weight: 700;">{{this.analytics.registered ? this.analytics.registered : 0}}</div>
          <footer class="text-white">Registered</footer>
        </div>
      </div>
      <div class="col-md-2" style="margin-bottom: 30px;">
        <div class="card bg-warning text-white text-center p-3">
          <div class="mb-0" style="font-size: 35px; font-weight: 700;">{{this.analytics.cancelled ? this.analytics.cancelled : 0}}</div>
          <footer class="text-white">Cancelled</footer>
        </div>
      </div>
      <div class="col-md-2" style="margin-bottom: 30px;">
        <div class="card bg-danger text-white text-center p-3">
          <div class="mb-0" style="font-size: 35px; font-weight: 700;">{{this.analytics.error ? this.analytics.error : 0}}</div>
          <footer class="text-white">Error</footer>
        </div>
      </div>
    </div>
    <div class="card" style="height: 480px; width:100%;">
      <div class="card-header" style="border-bottom: 0px; background: #fff;">
        <div class="d-flex justify-content-between">
          <div class="form-group mx-sm-6 mb-0">
            <input type="text" class="form-control" v-model="search" placeholder="Search">
          </div>
          <div class="p-0">
            <div class="d-flex justify-content-between">
              <a class="btn btn-outline-primary mr-2" :href="downloadRegistrants()"><font-awesome-icon icon="cloud-download-alt"></font-awesome-icon> Download Excel</a>
              <button class="btn btn-outline-success" @click="addRegistrant">
                <font-awesome-icon icon="plus"></font-awesome-icon>
                Add Registrant
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body" style="padding:0px; border-left: 0px; border-right: 0px; border-bottom: 0px;">
        <table class="table table-striped table-bordered" style="margin-bottom: 0px; border-left: 0px; border-right: 0px;">
          <thead class="thead-light">
            <tr>
              <th width="60%">Name</th>
              <th width="20%">Date Registered</th>
              <th width="10%">Status</th>
              <th width="10%"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in sortedItems" :key="item.id">
              <td>{{item.reg_name}}</td>
              <td>{{item.reg_date | formatDate}}</td>
              <td><span class="badge" :class="getBadgeColor(item.reg_status)" style="font-size: 14px;">{{item.reg_status}}</span></td>
              <td>
                <div class="d-flex justify-content-between" style="font-size: 18px; width: 125px;">
                  <span class="badge badge-info" style="cursor:pointer;" @click="viewRegistrant(item.id)">View</span>
                  <span class="badge badge-danger" style="cursor:pointer;" @click="deleteRegistrant(item)">Delete</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-show="registrants.length == 0">
            <tr>
              <td colspan="4" align="center">No Registrants Available</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer" style="background: #fff; border-top: 0px;">
        <div class="d-flex justify-content-between">
          <div style="padding-top: 8px;">Showing {{getCurrentRows()}} of {{itemsLength}} rows</div>
          <nav>
            <ul class="pagination" style="margin-bottom: 0px;">
              <li class="page-item" v-bind:class="{ disabled: currentPage === 1 }"><button class="page-link" @click="prevPage">Previous</button></li>
              <!-- <li class="page-item" v-for="(n, index) in totalPages" :key="index" v-bind:class="{ active: currentPage === n}"><button class="page-link" @click="goToPage(n)">{{n}}</button></li> -->
              <li class="page-item" v-bind:class="{ disabled: currentPage === totalPages }"><button class="page-link" @click="nextPage">Next</button></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div v-if="showRegistrantModal">
      <registrant-modal @close="closeRegistrantModal" :event="event" :registrantId="registrant"/>
    </div>
    <div v-if="showAddRegistrantModal">
      <add-registrant-modal @close="closeAddRegistrantModal" :event="event" title="Add Registrant"/>
    </div>
  </div>
</template>

<script>
import eventService from '@/services/event.service'
import RegistrantModal from '@/components/events/modals/RegistrantModal.vue'
import AddRegistrantModal from '@/components/events/modals/AddRegistrantModal.vue'
import moment from 'moment'
import { groupBy, forEach, findIndex } from 'lodash'

const API = 'https://simpsonapi.com/v2'
export default {
  name: 'registrants',
  components: {
    RegistrantModal,
    AddRegistrantModal
  },
  props: ['eventData'],
  data () {
    return {
      view: '',
      showEmailResponseModal: false,
      emailModalTitle: '',
      showRegistrantModal: false,
      showAddRegistrantModal: false,
      registrantName: '',
      eventName: '',
      event: {},
      registrants: [],
      analytics: {},
      pageSize: 6,
      currentPage: 1,
      totalPages: 0,
      isCurrentPage: false,
      itemsLength: 0,
      search: '',
      registrant: ''
    }
  },
  methods: {
    /** Registrants */
    viewRegistrant: function (registrant) {
      this.registrant = registrant
      this.showRegistrantModal = true
    },
    deleteRegistrant: function (registrant) {
      console.log(registrant)
      eventService.deleteRegistrant(registrant.id).then((response) => {
        console.log(response)
        // this.registrants.splice(index, 1)
        this.registrants.splice(findIndex(this.registrants, function (item) {
          return item.id === registrant.id
        }), 1)
      })
    },
    closeRegistrantModal: function () {
      this.showRegistrantModal = false
      this.analytics = {}
      this.getRegistrants(this.$route.params.id)
    },
    closeAddRegistrantModal: function () {
      this.showAddRegistrantModal = false
      this.getRegistrants(this.$route.params.id)
    },
    getRegistrants: function (eventId) {
      eventService.getEventRegistrants(eventId).then((response) => {
        console.log(response)
        this.registrants = response
        this.itemsLength = this.registrants.length
        console.log(this.toatalPages)
        this.analytics.total = this.registrants.length
        const groups = groupBy(this.registrants, 'reg_status')
        console.log(groups)
        const items = {}
        forEach(groups, function (value, key) {
          items[key.toLowerCase()] = value.length
        })
        this.analytics = { ...this.analytics, ...items }
        console.log(this.analytics)
      })
    },
    addRegistrant: function () {
      this.showAddRegistrantModal = true
    },
    nextPage: function () {
      if ((this.currentPage * this.pageSize) < this.registrants.length) this.currentPage++
    },
    prevPage: function () {
      if (this.currentPage > 1) this.currentPage--
    },
    goToPage: function (n) {
      this.currentPage = n
    },
    getCurrentRows: function () {
      let lastentry = 0
      let firstentry = 0
      if (this.currentPage === this.totalPages) {
        const lastentryTemp = this.currentPage * this.pageSize
        firstentry = (lastentryTemp - this.pageSize) + 1
        lastentry = this.itemsLength
      } else {
        lastentry = this.currentPage * this.pageSize
        firstentry = (lastentry - this.pageSize) + 1
      }

      return firstentry + ' to ' + lastentry
    },
    downloadRegistrants: function () {
      return `${API}/events/download-registrants/${this.$route.params.id}`
    },
    getTotalPages: function () {
      if (this.registrants.length <= this.pageSize) {
        this.totalPages = 1
      } else {
        console.log(this.registrants.length)
        this.totalPages = Math.ceil(this.registrants.length / this.pageSize)
        console.log(this.totalPages)
      }
      this.getCurrentRows()
      this.itemsLength = this.registrants.length
    },
    getBadgeColor: function (status) {
      return {
        'badge-primary': status === 'Registered',
        'badge-success': status === 'Confirmed',
        'badge-danger': status === 'Error',
        'badge-warning': status === 'Cancelled',
        'badge-info': status === 'Missing Materials',
        'badge-secondary': status === 'Wait List'
      }
    }
  },
  computed: {
    sortedItems: function () {
      const surveyData = []
      forEach(this.registrants, (item) => {
        if (item.reg_name.toLowerCase().includes(this.search.toLowerCase())) {
          surveyData.push(item)
        }
      })
      return surveyData.filter((row, index) => {
        const start = (this.currentPage - 1) * this.pageSize
        const end = this.currentPage * this.pageSize

        if (index >= start && index < end) return true
      })
    }
  },
  watch: {
    registrants: function () {
      this.getTotalPages()
    }
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
      }
    }
  },
  mounted () {
    eventService.getEvent(this.$route.params.id).then((response) => {
      console.log(response)
      this.event = response
    })
    this.getRegistrants(this.$route.params.id)
  },
  created () {
  }
}
</script>

<style scoped>
h3 {
  display: inline-block;
  padding-left: 120px;
  padding-top: 5px;
}
/*
 * Sidebar
 */
.sidebar {
  position: fixed;
  top: 142px;
  bottom: 0;
  width: 200px;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}
.sidebar-sticky {
  position: relative;
  top: 0;
  bottom: 0;
  height: 100%;
  padding-top: 0px;
  background: #fff;
  border-right: 1px solid #ccc;
  scrollbar-width: none;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}
.notes-section {
  position: fixed;
  top: 145px;
  left: 200px;
  right: 0px;
  bottom: 0px;
  padding-top: 0px;
  padding-bottom: 25px;
  background-color: #f4f5f7;
  overflow-y:auto;
}
::-webkit-scrollbar {
  display:none;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}
.sidebar .nav-link {
  font-weight: 500;
  color: #333;
  padding: 0px 16px;
}
.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}
.sidebar .nav-link.active {
  color: #fff;
}
.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}
.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}
.sidebar .nav-link {
  padding-top: 5px;
  padding-bottom: 5px;
}
.sidebar .nav-link.active {
  color: #FFF;
  background: #006AB6;
}

.nav-link {
  cursor:pointer;
}
.btn:focus,.btn:active, .page-link {
   outline: none !important;
   box-shadow: none;
}
</style>
