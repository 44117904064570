import formService from '@/services/form.service'

const state = {
  forms: []
}

const actions = {
  getForms ({ commit }) {
    formService.getForms().then((response) => {
      commit('formsData', response)
    })
  },
  getMyForms ({ commit, state }) {
    if (state.forms.length === 0) {
      formService.getForms().then((response) => {
        const forms = response.filter(item => item.project_manager === JSON.parse(localStorage.getItem('user')).name)
        commit('formsData', forms)
      })
    } else {
      const my = state.forms.filter(item => item.project_manager === JSON.parse(localStorage.getItem('user')).name)
      commit('formsData', my)
    }
  }
}

const mutations = {
  formsData (state, data) {
    state.forms = data
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
