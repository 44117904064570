<template>
  <div>
    <div style="background: #fff; position: fixed; z-index: 50; padding: 25px 20px; width: 100%; text-align:center;  box-shadow:rgba(0, 0, 0, 0.2) 2px 4px 20px;">
      <div class="d-flex justify-content-between">
        <div class="col-2">
          <multiselect :options="statusOptions" :show-labels="false" placeholder="Select status..." v-model="statusType"></multiselect>
        </div>
        <button class="btn btn-primary" @click="addProject">
          <font-awesome-icon icon="plus"/> Project
        </button>
      </div>
    </div>
    <div style="padding: 25px; position:fixed; top: 135px; bottom: 0; width: 100%; overflow-y:auto;">
      <div class="row">
        <div v-for="(item, index) in myfilteredList" :key="index" class="col-3" style="padding-top: 15px; padding-bottom: 15px;">
          <article v-if="item.type == 'convention'" style="width: 100%; text-align:left; box-shadow:rgba(0, 0, 0, 0.2) 2px 4px 20px;">
            <div class="d-flex justify-content-between" style="padding: 10px; font-size: 20px;">
              <div>{{item.client}}</div>
              <div>{{item.project_id}}</div>
            </div>
            <div v-on:click="goToCongress(item)" style="cursor: pointer;">
              <img :src="item.location.image" class="img-fluid"/>
            </div>
            <h4 style="margin-top: -55px; color: #fff; padding: 15px;">{{item.name}}</h4>
            <div style="padding: 15px 15px 0px 15px;">
              <div style="height: 42px;">
                <h5 style="margin-top: 0px; font-family: 'Helvetica Neue'; font-size: 17px;">{{item.location.name}}</h5>
              </div>
              <font-awesome-icon icon="calendar-alt" style="font-size: 13px;"/>&nbsp;<small style="font-family: 'Helvetica Neue'"> {{item.formatted_date}}</small><br/>
              <font-awesome-icon icon="map-marker-alt" style="font-size: 13px;"/>&nbsp;&nbsp;<small style="font-family: 'Helvetica Neue'">{{item.location.address}}, {{item.location.city}}, {{item.location.state}} {{item.location.zip}}</small>
            </div>
            <div class="clearfix" style="padding: 10px 15px;">
              <div class="float-left">
                <change-status style="padding-top: 5px;" :item="item" :key-index="index" v-on:status-changed="saveItemStatus(item, index)"></change-status>
              </div>
              <div class="float-right" v-if="item.is_active">
                <a @click="editProject(item)" style="cursor: pointer;"><span class="badge badge-primary">Edit</span></a>
              </div>
            </div>
          </article>
          <article v-if="item.type.includes('External Experts')" class="info-box" style="width: 100%; text-align:left;box-shadow:rgba(0, 0, 0, 0.2) 2px 4px 20px;">
            <div class="clearfix" style="padding: 10px; font-size: 20px;">
              <div class="float-left">{{item.client}}</div>
              <div class="float-right">{{item.project_id}}</div>
            </div>
            <div class="text-center" v-on:click="goToProfile(item)" style="cursor: pointer; height: 234px;">
              <img :src="item.img" height="234"/>
            </div>
            <div style="padding: 15px 15px 0px 15px;">
              <div style="height: 42px;">
                <h5 style="margin-top: 0px; font-family: 'Helvetica Neue'; font-size: 17px;">{{item.project}}</h5>
              </div>
            </div>
            <div class="clearfix" style="padding: 10px 15px;">
              <div class="float-left">
                <change-status style="padding-top: 5px;" :item="item" :key-index="index" v-on:status-changed="saveItemStatus(item, index)"></change-status>
              </div>
              <div class="float-right" v-if="item.is_active">
                <a @click="editProject(item)" style="cursor: pointer;"><span class="badge badge-primary">Edit</span></a>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
    <div v-if="show3xProjectModal">
      <add-3x-project-modal @close="close3xProjectModal" @save="saveProject" :projectItem="project" :title="projectModalTitle"/>
    </div>
  </div>
</template>

<script>
// import moment from 'moment'
import ConventionService from '@/services/convention.service'
import ChangeStatus from '@/components/ChangeStatus.vue'
import Add3xProjectModal from '@/components/modals/Add3xProjectModal.vue'
import { orderBy, forEach } from 'lodash'
export default {
  name: 'Projects',
  components: {
    'Add3xProjectModal': Add3xProjectModal,
    'ChangeStatus': ChangeStatus
  },
  data () {
    return {
      projects: [],
      projectModalTitle: '',
      show3xProjectModal: false,
      statusOptions: ['Active', 'Inactive'],
      statusType: 'Active',
      project: {}
    }
  },
  computed: {
    myfilteredList: function () {
      const arr = this.projects.filter((item) => {
        if (this.statusType === 'Active') {
          return item.is_active === true
        } else {
          return item.is_active === false
        }
      })
      return orderBy(arr, ['client', 'project_id'], ['asc', 'desc'])
    }
  },
  methods: {
    getProjects () {
      ConventionService.getProjects().then((response) => {
        console.log(response)
        this.projects = response
      })
    },
    saveItemStatus: function (item, index) {
      ConventionService.saveData(item).then((response) => {
        console.log(response)
      })
    },
    addProject () {
      this.projectModalTitle = 'Add Project'
      this.show3xProjectModal = true
      this.project = {}
    },
    close3xProjectModal () {
      this.show3xProjectModal = false
    },
    saveProject (item) {
      this.show3xProjectModal = false
      if (item.save_type === 'add') {
        delete item.save_type
        this.projects.push(item)
      } else {
        forEach(this.projects, (items) => {
          if (items._id === item._id) {
            delete item.save_type
            items = item
          }
        })
      }
    },
    editProject (item) {
      this.projectModalTitle = 'Edit Project'
      this.show3xProjectModal = true
      this.project = item
    },
    goToCongress (item) {
      this.$router.push({ name: 'convention', params: { id: item.project_id, cid: item._id } })
    },
    goToProfile: function (item) {
      this.$router.push({ name: 'profiles', params: { id: item.project_id, pid: item._id } })
    }
  },
  mounted () {
    this.getProjects()
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>

</style>
