<template>
<svg height="80%" width="80%" viewBox="0 0 200 200" class="donut-chart">
  <g v-for="(value, index) in sortedValues" v-bind:key="index">
    <circle :cx="cx" :cy="cy" :r="radius" :stroke="colors[index]" :stroke-width="strokeWidth" :stroke-dasharray="adjustedCircumference" :stroke-dashoffset="calculateStrokeDashOffset(value, circumference)" fill="transparent" :transform="returnCircleTransformValue(index)" />
    <text v-if="segmentBigEnough(value)" style="fill:white" font-size="0.55em" text-anchor="middle" dy="3px" :x="chartData[index].textX" :y="chartData[index].textY">{{ percentageString(value) }}</text>
  </g>
</svg>
</template>


<script>
export default {
  name: 'donutChart',
  props: ['initialValues'],
  data () {
    return {
		angleOffset: -90,
		chartData: [],
		colors: ['#006BB6', '#B3C50E', '#333333', '#E56A54', '#FEBE10', '#B1E4E3', '#201751'],
		cx: 80,
		cy: 80,
		radius: 60,
		sortedValues: [],
		strokeWidth: 30,
    }
  },
  computed: {
   	adjustedCircumference () {
		// return this.circumference - 2;
		return this.circumference
	},
	circumference () {
		return 2 * Math.PI * this.radius
	},
	dataTotal () {
		return this.sortedValues.reduce((acc, val) => acc + val)
	},
	calculateChartData () {
		this.sortedValues.forEach((dataVal, index) => {
			const { x, y } = this.calculateTextCoords(dataVal, this.angleOffset)
			// start at -90deg so that the largest segment is perpendicular to top
			const data = {
				degrees: this.angleOffset,
				textX: x,
				textY: y
			};
			this.chartData.push(data)
			this.angleOffset = this.dataPercentage(dataVal) * 360 + this.angleOffset
		})
	},
	sortInitialValues () {
		//return this.sortedValues = this.initialValues.sort((a,b) => b-a);
		return this.sortedValues = this.initialValues
	}
  },
  methods: {
    calculateStrokeDashOffset (dataVal, circumference) {
		const strokeDiff = this.dataPercentage(dataVal) * circumference
		return circumference - strokeDiff
	},
	calculateTextCoords (dataVal, angleOffset) {
		// t must be radians
		// x(t) = r cos(t) + j
		// y(t) = r sin(t) + j

		const angle = (this.dataPercentage(dataVal) * 360) / 2 + angleOffset
		const radians = this.degreesToRadians(angle)
		const textCoords = {
			x: this.radius * Math.cos(radians) + this.cx,
			y: this.radius * Math.sin(radians) + this.cy
		}
		return textCoords
	},
	degreesToRadians (angle) {
		return angle * (Math.PI / 180)
	},
	dataPercentage (dataVal) {
		return dataVal / this.dataTotal
	},
	percentageString (dataVal) {
		return `${Math.round(this.dataPercentage(dataVal) * 100)}%`
	},
	returnCircleTransformValue (index) {
		return `rotate(${this.chartData[index].degrees}, ${this.cx}, ${this.cy})`
	},
	segmentBigEnough (dataVal) {
		return Math.round(this.dataPercentage(dataVal) * 100) > 2
	}
  },
  mounted () {
    this.sortInitialValues
    this.calculateChartData
  }
}
</script>
