<template>
  <div style="padding: 25px;">
    <div class="row">
      <!-- <div class="col-3" style="padding-bottom: 30px;">
        <div class="card shadow p-3 mb-5 bg-white rounded">
          <div class="card-body">
            <h5 class="card-title">Mobile Applications for iOS/Android</h5>
            <p class="card-text">Applications for iOS and Android. Shows applications in Simpson Mobile App Store.</p>
            <router-link :to="{ path: '/settings/mobile-apps'}" class="btn btn-primary">Mobile Apps</router-link>
          </div>
        </div>
      </div> -->
      <div class="col-3" style="padding-bottom: 30px;">
        <div class="card shadow p-3 mb-5 bg-white rounded">
          <div class="card-body">
            <h5 class="card-title">3ConneX Contractor/Temp Access</h5>
            <p class="card-text">Contractor/Temp access to 3Connex profiling backend</p>
            <router-link :to="{ path: '/settings/contractor-access'}" class="btn btn-primary">Contractor Access</router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- <nav class="col-md-2 d-none d-md-block bg-light sidebar">
      <div class="sidebar-sticky">
        <div style="padding: 25px 25px 15px 25px;">
          <h3 style="font-size:1.7rem;">Settings</h3>
        </div>
        <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link" style="cursor:pointer;" v-on:click="showView('mobile-applications')">
              <div class="d-flex bd-highlight">
                <h6 class="p-2 m-0 text-uppercase">
                  Mobile Applications
                </h6>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </nav>
    <main role="main" class="col-md-8 ml-sm-auto col-lg-10 px-0 ml-0" style="position: fixed; top: 55px; left: 0px; right: 0px; bottom: 0px; padding-top: 30px; padding-bottom: 25px; background-color: #f4f5f7; overflow-y:auto;">
      <div style="padding: 0px 35px;" class="col-md-12">
        <div v-if="view === 'mobile-applications'">
          <app-card :data="data" :view="view" title="Mobile Applications" search-placeholder-text="Search Applications"></app-card>
        </div>
      </div>
    </main> -->
  </div>
</template>

<script>
// import SettingService from '@/services/setting.service'
// import AppCard from '@/components/AppCard.vue'
export default {
  name: 'Settings',
  // components: {
  //   'AppCard': AppCard
  // },
  data () {
    return {
    }
  },
  methods: { },
  mounted () { }
}
</script>

<style scoped>
/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 54px;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  bottom: 0;
  height: 100%;
  padding-top: 0px;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
  padding: 0px 16px;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #fff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

</style>
