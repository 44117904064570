<template>
  <div>
    <nav class="col-md-2 d-none d-md-block bg-light sidebar">
      <div class="sidebar-sticky">
        <div style="padding: 0px 25px 15px 25px; cursor: pointer;" v-on:click="goBack">
          <font-awesome-icon icon="arrow-left" class="mr-2"/> Back to BeliefMap Surveys
        </div>
        <div style="padding: 0px 25px 30px 25px;">
          <h3 style="font-size:1.7rem;">{{surveyName}}</h3>
        </div>
        <ul class="nav flex-column">
          <li class="nav-item" v-if="published">
            <div class="d-flex bd-highlight">
              <div class="col form-group" style="margin-left: 10px;">
                <label class="switch">
                  <input type="checkbox" v-model="surveyActive" @change="toggleSurveyStatus">
                  <div class="slider round"></div>
                  <div style="padding-left: 60px; margin-top: -4px;">
                    <label :class="{ 'badge badge-success': surveyActive, 'badge badge-danger': !surveyActive }">{{getSurveyStatus()}}</label>
                  </div>
                </label>
              </div>
            </div>
          </li>
          <li class="nav-item" v-if="published">
            <a class="nav-link" :class="{'active': this.view == 'dashboard'}" style="cursor:pointer;" v-on:click="showView('dashboard')">
              <div class="d-flex bd-highlight">
                <h6 class="p-2 m-1 text-uppercase">
                  Dashboard
                </h6>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{'active': this.view == 'questions'}" style="cursor:pointer;" v-on:click="showView('questions')">
              <div class="d-flex bd-highlight">
                <h6 class="p-2 m-1 text-uppercase">
                  Questions
                </h6>
              </div>
            </a>
          </li>
          <li class="nav-item" v-if="published">
            <a class="nav-link" :class="{'active': this.view == 'results'}" style="cursor:pointer;" v-on:click="showView('results')">
              <div class="d-flex bd-highlight">
                <h6 class="p-2 m-1 text-uppercase">
                  Results
                </h6>
              </div>
            </a>
          </li>
          <li class="nav-item" v-if="published">
            <a class="nav-link" style="cursor:pointer;" :href="'https://assets.simpsonhealthcare.com/beliefmap/downloadSurveyResults.php?id='+surveyId">
              <div class="d-flex bd-highlight">
                <h6 class="p-2 m-1 text-uppercase">
                  Excel Download
                </h6>
              </div>
            </a>
          </li>
        </ul>
        <ul class="nav nav-bottom align-items-end ml-auto">
          <li class="nav-item">
            <div class="d-flex justify-content-center" v-cloak>
              <button  v-if="!published" class="btn btn-success text-uppercase" style="width: 200px;" v-on:click="publishSurvey">Publish Survey</button>
              <button  v-else class="btn btn-primary text-uppercase" style="width: 200px;" disabled>Survey Published</button>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <main role="main" class="col-md-8 ml-sm-auto col-lg-10 px-0 ml-0" style="position: fixed; top: 55px; left: 0px; right: 0px; bottom: 0px; padding-top: 30px; padding-bottom: 25px; background-color: #f4f5f7;">
      <div style="padding: 0px 35px;">
        <survey-dashboard-view :survey-id="surveyId" :public-id="publicId" :status="surveyActive" v-if="view === 'dashboard'"></survey-dashboard-view>
        <survey-results-view :survey-id="surveyId" v-if="view === 'results'"></survey-results-view>
        <survey-questions-view :survey-id="surveyId" :published="published" v-if="view === 'questions'"></survey-questions-view>
      </div>
    </main>
  </div>
</template>

<script>
// import JQuery from 'jquery'
// import moment from 'moment'
import BeliefMapService from '@/services/beliefmap.service'
import SurveyDashboardView from '@/components/beliefmap/SurveyDashboardView.vue'
import SurveyResultsView from '@/components/beliefmap/SurveyResultsView.vue'
import SurveyQuestionsView from '@/components/beliefmap/SurveyQuestionsView.vue'

export default {
  name: 'SurveyDetailsPage',
  components: {
    'SurveyDashboardView': SurveyDashboardView,
    'SurveyResultsView': SurveyResultsView,
    'SurveyQuestionsView': SurveyQuestionsView
  },
  data () {
    return {
      surveyId: this.$route.params.id,
      survey: {},
      view: '',
      published: false,
      surveyName: '',
      publicId: null,
      surveyActive: false
    }
  },
  methods: {
    showView (view) {
      this.view = view
    },
    goBack: function () {
      this.$router.go(-1)
    },
    getSurvey () {
      BeliefMapService.getSurvey(this.surveyId).then((response) => {
        console.log(response)
        this.survey = response
        this.publicId = response.public_id
        this.surveyName = response.name
        if (response.is_published) {
          this.published = response.is_published
          this.surveyActive = response.status
          this.view = 'dashboard'
        } else {
          this.view = 'questions'
        }
      })
    },
    publishSurvey: function () {
      BeliefMapService.publishSurvey(this.surveyId).then((response) => {
        console.log(response)
        this.published = true
      })
    },
    getSurveyStatus: function () {
      if (this.surveyActive) {
        return 'Active'
      } else {
        return 'Inactive'
      }
    },
    toggleSurveyStatus () {
      this.survey.status = !this.survey.status
      console.log(this.survey)
      BeliefMapService.saveSurvey(this.survey).then((response) => {
        console.log(response)
        this.surveyActive = this.survey.status
        console.log(this.surveyActive)
      })
    }
  },
  created () {
    this.getSurvey()
  }
}
</script>

<style scoped>

[v-cloak] {
  display: none;
}
/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 54px;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 28px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

/*
 * Content
 */

[role="main"] {
  padding-top: 133px; /* Space for fixed navbar */
}

@media (min-width: 768px) {
  [role="main"] {
    padding-top: 0px; /* Space for fixed navbar */
  }
}

.nav-link {
    cursor:pointer;
}

/* Rounded sliders */

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 18px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #009370;
}

input:focus+.slider {
    box-shadow: 0 0 1px #009370;
}

input:checked+.slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.nav-bottom > li
{
  position: absolute;
  bottom: 60px;
  left: 0;
  width: 100%;
}
</style>
