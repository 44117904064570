<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-dialog" role="document" style="max-width: 750px;">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: 0px !important;">
            <h5 class="modal-title">{{title}}</h5>
            <button class="close" @click="close">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body" style="padding: 0px;">
            <div class="card p-3">
              <div v-if="formError" class="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>Error!</strong> Description field is required!
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="formError = false">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6" v-if="biblio.id">
                  <label>ID</label>
                  <input type="text" class="form-control" v-model="biblioId" disabled>
                </div>
                <div class="form-group col-md-6">
                  <label>Bibliometric Type</label>
                  <input type="text" class="form-control" v-model="biblio_type" disabled>
                </div>
              </div>
              <div class="form-group">
                <label>Description</label>
                <editor id="editor" api-key="xmy55lj0kciq6f2f0cfrnrnj4qjbs8e5qge7gjk8rr1j4692" v-model="description" style="height: 300px; border:none;" :init="options"></editor>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">Close</button>
            <div v-if="editMode">
              <button v-if="biblio.publish_type != 'Published'" type="button" class="btn btn-warning mr-2" @click="checkForm('Draft')">Save Draft</button>
              <button type="button" class="btn btn-success" @click="checkForm('Published')">Publish</button>
            </div>
            <div v-else>
              <button type="button" class="btn btn-warning mr-2" @click="checkForm('Draft')">Save as Draft</button>
              <button type="button" class="btn btn-success" @click="checkForm('Published')">Publish</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
export default {
  name: 'BibliometricModal',
  components: {
    'editor': Editor
  },
  props: ['title', 'type', 'kolId', 'biblio', 'editMode'],
  data () {
    return {
      biblio_type: this.type,
      options: {
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar: 'bold italic strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist | link'
      },
      editor: false,
      description: '',
      biblioId: '',
      formError: false
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    checkForm: function (type) {
      if (this.description === '') {
        this.formError = true
      } else {
        this.formError = false
        this.saveInfo(type)
      }
    },
    saveInfo: function (type) {
      console.log(type)
      this.formError = false

      if (this.editMode && this.biblio) {
        this.biblio.description = this.description
        this.biblio.editMode = true
        if (type === 'Published') {
          this.biblio.publish_type = 'Published'
        }
        this.$emit('saved', this.biblio)
      } else {
        let biblioData = {
          id: Math.floor(100000 + Math.random() * 900000),
          kol_id: this.kolId,
          type: this.type,
          description: this.description,
          publish_type: type,
          pub_id: '',
          editMode: false
        }
        this.$emit('saved', biblioData)
      }
      // this.$http.post(`http://localhost/~abeamin/profiles/api/index.php/add_bibliometrics`, biblioData).then(res => {
      //   biblioData.id = res.id
      //   this.$emit('saved', biblioData)
      // })
      // .catch(error => {
      //   console.log(error)
      // })
    }
  },
  created () {
    if (this.biblio.id) {
      this.biblioId = this.biblio.id
      this.type = this.biblio.type
      this.description = this.biblio.description
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.multiselect.form-control {
  padding: 0;
}

.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.document-editor {
    border: 1px solid var(--ck-color-base-border);
    border-radius: var(--ck-border-radius);
}

.close:focus {
  outline: none;
}

.mce-notification {
  display: none !important;
}

.file-select > .select-button {
  margin: 10px;
  cursor: pointer;
  font-size: 25px;
}

.file-select > input[type="file"] {
  display: none;
}
</style>
