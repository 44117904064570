<template>
<div style="padding-top: 25px; overflow:auto; height: calc(100vh - 155px);">
  <ul class="list-group">
    <li class="list-group-item"><input type="text" class="form-control shadow-none" v-model="question" :placeholder="placeholder" v-on:keyup.enter="addQuestion"></li>
    <li class="list-group-item clearfix" v-for="(item, index) in questions" :key="index">
      <div v-if="questionEdit != index" class="float-left" style="width: 92%;">
        {{item.question}}
      </div>
      <div v-else class="float-left" style="width: 92%;">
        <input type="text" class="form-control shadow-none" v-model="surveyQuestion.question" style="padding-top: 0px; padding-bottom: 0px; margin-top: -7px;">
      </div>
      <div v-if="questionEdit != index" class="float-right d-flex justify-content-end" style="width: 8%; margin-top: 5px;">
        <span class="mr-2 badge badge-info" style="cursor:pointer;" v-on:click="editQuestion(index)">Edit</span>
        <span v-if="!published" class="badge badge-danger" style="cursor:pointer;" v-on:click="deleteQuestion(item.question_id, index)">Delete</span>
      </div>
      <div v-else class="float-right d-flex justify-content-between" style="width: 8%; margin-top: 5px;">
        <span class="mr-2 badge badge-success" style="cursor:pointer;" @click.prevent="saveQuestion(surveyQuestion)">Save</span>
        <span class="badge badge-primary" style="cursor:pointer;" @click.prevent="questionEdit = -1; showQuestionDetails = false">Cancel</span>
      </div>
      <div v-if="showQuestionDetails && questionEdit == index" class="clearfix" style="margin-top: 50px; border-top: none; padding-bottom: 25px; width: 100%;">
        <div class="row">
          <div class="col-md-4">
            <select class="form-control" v-model="surveyQuestion.question_type">
              <option value="">Select Question Type...</option>
              <option value="radio">Radio</option>
              <option value="textbox">Textbox</option>
              <option value="multi-line">Multi-Line Textbox</option>
            </select>
          </div>
          <div class="col-md-8">
            <ul class="list-group">
              <li v-if="surveyQuestion.question_type == 'radio'" class="list-group-item" style="padding-top: 2px; padding-bottom: 2px;"><input type="text" class="form-control shadow-none" v-model="screener_answer" v-on:keyup.enter="addQuestionAnswer" placeholder="Add answer and press enter"></li>
              <li class="list-group-item clearfix" v-for="(item, index) in surveyQuestion.answers" :key="item">
                <div v-if="questionAnswerEdit != index" class="float-left" style="width: 82%;">
                  {{item}}
                </div>
                <div v-else class="float-left" style="width: 82%;">
                  <input type="text" class="form-control shadow-none" v-model="answer" style="padding-top: 0px; padding-bottom: 0px; margin-top: -7px;">
                </div>
                <div v-if="questionAnswerEdit != index" class="float-right d-flex justify-content-end" style="width: 12%; margin-top: 5px;">
                  <span class="mr-2 badge badge-info" style="cursor:pointer;" v-on:click="editQuestionAnswer(index)">Edit</span>
                  <span v-if="!published" class="badge badge-danger" style="cursor:pointer;" v-on:click="deleteAnswer(index)">Delete</span>
                </div>
                <div v-else class="float-right d-flex justify-content-between" style="width: 12%; margin-top: 5px;">
                  <span class="mr-2 badge badge-success" style="cursor:pointer;" @click.prevent="saveQuestionAnswer(answer, index)">Save</span>
                  <span class="badge badge-primary" style="cursor:pointer;" @click.prevent="questionAnswerEdit = -1;">Cancel</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </li>
    <!-- <li v-if="showQuestionDetails" class="list-group-item" style="border-top: none; padding-bottom: 25px;">
      <div class="row">
        <div class="col-md-4">
          <select class="form-control" v-model="surveyQuestion.question_type">
            <option value="">Select Question Type...</option>
            <option value="radio">Radio</option>
            <option value="checkbox">Checkbox</option>
            <option value="textbox">Textbox</option>
            <option value="multi-line">Multi-Line Textbox</option>
          </select>
        </div>
        <div class="col-md-8">
          <ul class="list-group">
            <li class="list-group-item" style="padding-top: 2px; padding-bottom: 2px;"><input type="text" class="form-control shadow-none" v-model="screener_answer" v-on:keyup.enter="addQuestionAnswer" placeholder="Add answer and press enter"></li>
            <li class="list-group-item clearfix" v-for="(item, index) in surveyQuestion.answers" :key="item">
              <div v-if="questionAnswerEdit != index" class="float-left" style="width: 82%;">
                {{item}}
              </div>
              <div v-else class="float-left" style="width: 82%;">
                <input type="text" class="form-control shadow-none" v-model="answer" style="padding-top: 0px; padding-bottom: 0px; margin-top: -7px;">
              </div>
              <div v-if="questionAnswerEdit != index" class="float-right d-flex justify-content-between" style="width: 12%; margin-top: 5px;">
                <span class="mr-2 badge badge-info" style="cursor:pointer;" v-on:click="editQuestionAnswer(index)">Edit</span>
                <span class="badge badge-danger" style="cursor:pointer;">Delete</span>
              </div>
              <div v-else class="float-right d-flex justify-content-between" style="width: 12%; margin-top: 5px;">
                <span class="mr-2 badge badge-success" style="cursor:pointer;" @click.prevent="saveQuestionAnswer(answer, index)">Save</span>
                <span class="badge badge-primary" style="cursor:pointer;" @click.prevent="questionAnswerEdit = -1;">Cancel</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </li> -->
  </ul>
</div>
</template>

<script>
// import moment from 'moment'
import BeliefMapService from '@/services/beliefmap.service'
// import * as _ from 'lodash'

export default {
  name: 'SurveyQuestions',
  props: ['id', 'placeholder', 'published', 'questions', 'type', 'categoryType', 'answerType'],
  data () {
    return {
      question: null,
      questionEdit: -1,
      questionAnswerEdit: -1,
      surveyQuestion: {},
      showQuestionDetails: false,
      screener_answer: null,
      answer: null
    }
  },
  methods: {
    addQuestion: function () {
      let newQuestion = {
        survey_id: this.id,
        type: this.type,
        category_type: this.categoryType,
        question: this.question,
        answer_type: this.answerType,
        question_type: 'radio',
        answers: ['1', '2', '3', '4', '5']
      }

      if (this.type === 'screener') {
        newQuestion.question_type = null
        newQuestion.answers = []
      }

      BeliefMapService.saveQuestion(newQuestion).then((response) => {
        newQuestion.question_id = response.id
        this.questions.push(newQuestion)
        this.question = null
      })
    },
    editQuestion: function (index) {
      this.surveyQuestion = this.questions[index]
      this.questionEdit = index
      if (this.type === 'screener') {
        if (this.surveyQuestion.answers === null) {
          this.surveyQuestion.answers = []
        }
        this.showQuestionDetails = !this.showQuestionDetails
      }
    },
    deleteQuestion: function (id, index) {
      BeliefMapService.deleteQuestion(id).then((response) => {
        console.log(response)
        this.questions.splice(index, 1)
      })
    },
    saveQuestion: function (item) {
      if (this.type === 'screener') {
        this.showQuestionDetails = !this.showQuestionDetails
      }
      this.questionEdit = -1
      BeliefMapService.saveQuestion(item)
    },
    addQuestionAnswer: function () {
      this.surveyQuestion.answers.push(this.screener_answer)
      this.screener_answer = null
    },
    editQuestionAnswer: function (index) {
      this.answer = this.surveyQuestion.answers[index]
      this.questionAnswerEdit = index
    },
    saveQuestionAnswer: function (item, index) {
      this.surveyQuestion.answers[index] = item
      this.questionAnswerEdit = -1
    },
    deleteAnswer: function (index) {
      this.surveyQuestion.answers.splice(index, 1)
    }
  }
}
</script>

<style scoped>
  input.form-control {
    border:none;
    padding-left: 0px;
  }
</style>
