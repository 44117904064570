<template>
  <div style="padding: 25px;">
    <nav class="col-md-2 d-none d-md-block bg-light sidebar">
        <div class="bg-light d-none d-md-block p-0" style="bottom: 0; top: 54px;">
          <div style="padding: 15px; border-right: 1px solid #ccc;">
            <input type="search" class="form-control" v-model="clientSearch" placeholder="Search Client">
          </div>
          <div class="sidebar-sticky" style="border-right: 1px solid #ccc;">
            <ul class="nav flex-column">
              <li class="nav-item" v-for="item in activeClients" :key="item">
                  <a class="nav-link" style="cursor:pointer;" v-bind:class="{ 'employee-active': selected == item }" v-on:click="showClient(item)">
                    <div class="d-flex bd-highlight">
                      <h5 class="p-2 m-1">
                        {{item}}<br />
                      </h5>
                    </div>
                  </a>
              </li>
            </ul>
          </div>
        </div>
    </nav>
    <main role="main" class="col-md-8 ml-sm-auto col-lg-10 px-0 ml-0" style="position: fixed; top: 55px; left: 0px; right: 0px; bottom: 0px; padding-top: 0px; padding-bottom: 25px; background-color: #f4f5f7; overflow-y:auto;">
      <div style="padding: 25px;" class="col-md-8 mx-auto">
        <div class="card" style="height: 513px;">
          <div class="card-header" style="border-bottom: 0px; background: #fff;">
            <div class="d-flex justify-content-sm-between">
              <div class="form-group mx-sm-6 mb-0">
                <input type="text" v-model="search" class="form-control shadow-none" placeholder="Search">
              </div>
              <div class="mb-0">
                <a class="btn btn-primary" style="color:#fff;" data-toggle="modal" data-backdrop="static" data-keyboard="false" v-on:click="addUser()">Add User</a>
              </div>
            </div>
          </div>
          <div class="card-body" style="padding:0px; border-left: 0px; border-right: 0px; border-bottom: 0px;">
            <table class="table table-striped table-bordered" style="margin-bottom: 0px; border-left: 0px; border-right: 0px;">
              <thead class="thead-light">
                <tr>
                  <th width="45%">Name</th>
                  <th width="40%">Email</th>
                  <th width="5%">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in sortedItems" :key="item.user_id">
                  <td>{{item.name}}</td>
                  <td>{{item.email}}</td>
                  <td style="text-align:center;">
                    <button type="button" class="btn btn-sm btn-outline-primary mr-3" v-on:click="editUser(item)">Edit</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer" style="background: #fff; border-top: 0px;">
            <div class="d-flex justify-content-between">
              <div style="padding-top: 8px;">Showing <span v-if="itemsLength > 0">{{getCurrentRows()}} of </span>{{itemsLength}} rows</div>
              <nav>
                <ul class="pagination" style="margin-bottom: 0px;">
                  <li class="page-item" v-bind:class="{ disabled: currentPage === 1 }"><button class="page-link" @click="prevPage">Previous</button></li>
                  <!-- <li class="page-item" v-for="(n, index) in totalPages" :key="index" v-bind:class="{ active: currentPage === n}"><button class="page-link" @click="goToPage(n)">{{n}}</button></li> -->
                  <li class="page-item" v-bind:class="{ disabled: currentPage === totalPages }"><button class="page-link" @click="nextPage">Next</button></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div v-if="showModal">
        <client-modal @close="closeModal" @showUsers="showClientUsers" :title="modalTitle" :client="client" :user="user"/>
    </div>
  </div>
</template>

<script>
// import moment from 'moment'
import SettingService from '@/services/setting.service'
import { forEach } from 'lodash'
import ClientModal from '@/components/modals/ClientModal'
// import DateRangePicker from 'vue2-daterange-picker'
// import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  name: 'Reports',
  components: {
    ClientModal
  },
  data () {
    return {
      departments: [],
      client: '',
      clients: [],
      companyClients: [],
      view: 'employee',
      customEmployees: [],
      selected: '',
      companySelected: '',
      details: false,
      departmentIndex: '',
      projectIndex: '',
      custom: {},
      pageSize: 6,
      currentPage: 1,
      totalPages: 0,
      isCurrentPage: false,
      itemsLength: 0,
      search: '',
      clientSearch: '',
      showModal: false,
      modalTitle: '',
      user: {}
    }
  },
  computed: {
    activeClients: function () {
      return this.clients.filter((client) => {
        return client.toLowerCase().match(this.clientSearch.toLowerCase())
      })
    },
    sortedItems: function () {
      return this.getRows(this.companyClients)
    }
  },
  methods: {
    showView: function (view) {
      this.view = view
    },
    getClients: function () {
      SettingService.getData('clients').then((response) => {
        console.log(response)
        this.clients = response
      })
    },
    showClient: function (item) {
      this.client = item
      this.selected = item
      SettingService.getClients(item).then((response) => {
        console.log(response)
        this.companyClients = response.users
        this.itemsLength = this.companyClients.length
      })
    },
    addUser: function () {
      this.modalTitle = 'Add User'
      this.showModal = true
      this.user.type = 'add'
    },
    editUser: function (item) {
      this.modalTitle = 'Edit User'
      this.user = item
      this.user.type = 'edit'
      this.showModal = true
    },
    showClientUsers: function (user) {
      if (user.type === 'add') {
        this.companyClients.push(user)
      } else {
        let itemIndex = null
        forEach(this.companyClients, (item, index) => {
          if (item.name === user.name) {
            itemIndex = index
          }
        })
        this.companyClients[itemIndex] = user
      }
      this.showModal = false
    },
    closeModal: function () {
      this.showModal = false
    },
    nextPage: function () {
      if ((this.currentPage * this.pageSize) < this.companyClients.length) {
        this.currentPage++
      }
    },
    prevPage: function () {
      if (this.currentPage > 1) {
        this.currentPage--
      }
    },
    goToPage: function (n) {
      this.currentPage = n
    },
    getCurrentRows: function () {
      let lastentry = 0
      let firstentry = 0
      if (this.currentPage === this.totalPages) {
        const lastentryTemp = this.currentPage * this.pageSize
        firstentry = (lastentryTemp - this.pageSize) + 1
        lastentry = this.itemsLength
      } else {
        lastentry = this.currentPage * this.pageSize
        firstentry = (lastentry - this.pageSize) + 1
      }
      return firstentry + ' to ' + lastentry
    },
    getRows: function (surveys) {
      let surveyData = []
      forEach(surveys, (item) => {
        if (item.name.toLowerCase().includes(this.search.toLowerCase())) {
          surveyData.push(item)
        }
      })
      return surveyData.filter((row, index) => {
        let start = (this.currentPage - 1) * this.pageSize
        let end = this.currentPage * this.pageSize
        if (surveyData.length <= this.pageSize) {
          this.totalPages = 1
        } else {
          this.totalPages = Math.round(surveyData.length / this.pageSize)
        }
        this.getCurrentRows()
        this.itemsLength = surveyData.length

        if (index >= start && index < end) {
          return true
        }
      })
    }
  },
  mounted () {
    this.getClients()
  }
}
</script>
<style>
.calendars-container {
  width: 75%;
}
</style>
<style scoped>
/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 54px;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  position: relative;
  top: 54px;
  bottom: 0;
  height: calc(100vh - 120px);
  padding-top: 0px;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
  padding: 0px 16px;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #fff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

.active {
  background: #009370;
}

.active .nav-link,
.active .nav-link:hover {
  color: #fff;
}

.employee-active {
  background-color: #006AB6;
  color: #fff;
}

.employee-active h5,
.employee-active .text-muted {
  color: #fff !important;
}

/* Rounded sliders */

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 18px;
    margin-left: 15px;
    margin-top: 4px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #009370;
}

input:focus+.slider {
    box-shadow: 0 0 1px #009370;
}

input:checked+.slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
