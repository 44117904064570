// import axios from 'axios'

export default class GraphService {
  constructor () {
    this.graphUrl = 'https://graph.microsoft.com/v1.0/'
  }
  getUserInfo (token) {
    const headers = new Headers({ Authorization: `Bearer ${token}` })
    const options = {
      headers
    }
    return fetch(`${this.graphUrl}/me`, options)
      .then(response => response.json())
      .catch(response => {
        throw new Error(response.text())
      })
  }

  // sendEmail(token) {
  //   // console.log(token)
  //   // const headers = new Headers({ Authorization: `Bearer ${token}` })
  //   const options =  { Authorization: `Bearer ${token}` }

  //   const mail = {
  //     subject: 'Microsoft Graph JavaScript Sample',
  //     toRecipients: [
  //       {
  //         emailAddress: {
  //           address: "support@simpsonhealthcare.com",
  //         }
  //       }
  //     ],
  //     body: {
  //       content: "<h1>MicrosoftGraph JavaScript Sample</h1>Check out https://github.com/microsoftgraph/msgraph-sdk-javascript",
  //       contentType: "html"
  //     }
  //   }

  //     axios.post(`${this.graphUrl}/me/sendMail`, {message: mail}, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     }).then((res) => {
  //       console.log(res);
  //     }).catch((error) => {
  //       console.log(error)
  //     })

  //     // return fetch(`${this.graphUrl}/me`, options)
  //     //   .then(response => response.json())
  //     //   .catch(response => {
  //     //     throw new Error(response.text());
  //     //   });
  //   }
}
