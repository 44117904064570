<template>
  <div class="d-flex justify-content-center px-5" style="padding:45px 25px 25px 25px;">
    <div class="card w-75">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h4>{{type}}</h4>
        <button class="btn btn-sm btn-primary">Add KOL</button>
      </div>
      <div class="card-body p-0">
        <table class="table mb-0">
          <tr v-if="items.length == 0">
            <td colspan="3" class="text-center">
              No Information Available
            </td>
          </tr>
          <tr>
            <th></th>
            <th class="text-center">First Name</th>
            <th class="text-center">Last Name</th>
            <th class="text-center">Email</th>
            <th class="text-center">Country</th>
            <th></th>
          </tr>
          <tr v-for="item in items" :key="item.id">
            <td class="text-center">
              <div class="profile-image" style="background-image: url('https://employee.simpsonhealthcare.com/takeda/4534-104/kols/7.jpg')"></div>
            </td>
            <td class="text-center" style="padding-top: 28px;">{{item.first_name}}</td>
            <td class="text-center" style="padding-top: 28px;">{{item.last_name}}</td>
            <td class="text-center" style="padding-top: 28px;">{{item.email}}</td>
            <td class="text-center" style="padding-top: 28px;">{{item.country}}</td>
            <td class="text-center" style="padding-top: 28px;">
              <div class="d-flex justify-content-between pt-1" v-if="!item.draft_id">
                <font-awesome-icon class="text-primary" icon="edit" style="font-size: 18px;" @click="viewProfile(item)" />
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['projectId', 'type'],
  components: {
  },
  data () {
    return {
      isDraft: false,
      showModal: false,
      items: [{
        id: '123456',
        first_name: 'Charlotte',
        last_name: 'Cunningham-Rundles',
        email: 'test@demo.com',
        country: 'United States',
        photo: 'https://employee.simpsonhealthcare.com/takeda/4534-104/kols/7.jpg'
      },
      {
        id: '234597',
        first_name: 'Charlotte',
        last_name: 'Cunningham-Rundles',
        email: 'test@demo.com',
        country: 'United States',
        photo: 'https://employee.simpsonhealthcare.com/takeda/4534-104/kols/7.jpg'
      }],
      biblio: {},
      title: '',
      editMode: false
    }
  },
  methods: {
    getKOL: function (id, type) {
      console.log(id, type)
    },
    viewProfile: function (item) {
      this.$router.push({ name: 'kol', params: { id: this.$route.params.id, pid: this.$route.params.pid, kolid: item.id } })
    }
  },
  created () {
    this.getKOL(this.projectId, this.type)
  }
}
</script>

<style scoped>
.profile-image {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background-size: cover;
  display: inline-block;
}
</style>
