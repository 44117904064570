<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-dialog" role="document" style="max-width: 750px;">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: 0px !important;">
            <h5 class="modal-title">{{title}}</h5>
            <button class="close" @click="close">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body" style="padding: 0px;">
            <div class="card p-3">
              <div v-if="formError" class="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>Error!</strong> Description field is required!
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="formError = false">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Client</label>
                  <multiselect :options="client" :show-labels="false" placeholder="Select Client..." v-model="inputForm.client"></multiselect>
                </div>
                <div class="form-group col-md-6">
                  <label>Year</label>
                  <multiselect :options="year" :show-labels="false" placeholder="Select Year..." v-model="inputForm.year"></multiselect>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Start Date</label>
                  <input type="text" class="form-control" v-model="inputForm.start_date" placeholder="Start Date">
                </div>
                <div class="form-group col-md-6">
                  <label>End Date</label>
                  <input type="serach" class="form-control" v-model="inputForm.end_date" placeholder="End Date">
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Acivity Type</label>
                  <multiselect :options="activityType" :show-labels="false" placeholder="Select Activity Type..." v-model="inputForm.activity_type"></multiselect>
                </div>
                <div class="form-group col-md-6">
                  <label>Congress</label>
                  <multiselect :options="congress" :show-labels="false" placeholder="Select Congress..." v-model="inputForm.congress"></multiselect>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Activity</label>
                  <input type="text" class="form-control" v-model="inputForm.activity" placeholder="Activity">
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Engagment Status</label>
                  <multiselect :options="engagementStatus" :show-labels="false" placeholder="Select Engagement Status..." v-model="inputForm.engagement_status"></multiselect>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success" @click="saveEngagement">Save</button>
            <button type="button" class="btn btn-secondary" @click="close">Close</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'EngagementPlanModal',
  props: ['title', 'kolId', 'data', 'editMode'],
  data () {
    return {
      description: '',
      biblioId: '',
      formError: false,
      client: ['AstraZeneca', 'Takeda', 'Janssen', 'Leo Pharma', 'Bayer'],
      year: ['2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'],
      activityType: ['1:1 Engagement', 'Content Committee', 'Consultant', 'Advisory Board', 'Congress Presentation', 'Speaker Bureau'],
      congress: ['AAAAI', 'WSAAI', 'ACAAI', 'ERS', 'General'],
      engagementStatus: ['Planned', 'Recommended', 'Completed'],
      inputForm: {}
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    checkForm: function (type) {
      if (this.description === '') {
        this.formError = true
      } else {
        this.formError = false
        this.saveInfo(type)
      }
    },
    saveEngagement: function () {
      console.log(this.inputForm)
      if (!this.inputForm.id) {
        this.inputForm.kol_id = this.kolId
        this.inputForm.id = Math.floor(100000 + Math.random() * 900000)
        this.inputForm.is_active = true
      }
      this.$emit('save', this.inputForm)
    }
  },
  created () {
    if (this.data.id) {
      this.inputForm = this.data
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.multiselect.form-control {
  padding: 0;
}

.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.document-editor {
    border: 1px solid var(--ck-color-base-border);
    border-radius: var(--ck-border-radius);
}

.close:focus {
  outline: none;
}

.mce-notification {
  display: none !important;
}

.file-select > .select-button {
  margin: 10px;
  cursor: pointer;
  font-size: 25px;
}

.file-select > input[type="file"] {
  display: none;
}
</style>
