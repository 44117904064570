<template>
  <div>
    <!-- Start of Header -->
    <div style="background: #fff; position: fixed; z-index: 50; padding: 20px; width: 100%; text-align:center;  box-shadow:rgba(0, 0, 0, 0.2) 2px 4px 20px;">
      <div class="cleafix float-left" style="padding-top: 10px; padding-left: 10px; text-align:left;">
        <div @click="$router.go(-1)" style="padding-top:5px; padding-left: 10px; float: left; text-align:left; padding-right:25px; cursor:pointer;">
            <span class="fa fa-chevron-left" style="font-size:20px;"></span>
            <span style="font-size: 20px; padding-left: 5px;">Back</span>
        </div>
        <div style="padding-left: 10px; float: left; text-align:left;">
          <h3 style="margin-top:0px;">
            3ConneX Contractor Access
          </h3>
        </div>
      </div>
      <div class="float-right" style="padding-top: 5px; padding-right: 10px; text-align:right;">
          <div class="btn-group">
              <button class="btn btn-primary btn" style="text-transform: uppercase; font-size: 14px; font-weight: bold; padding: 10px 15px; color:#fff;" @click="addContractor"><span class="fa fa-plus"></span> Add Contractor</button>
          </div>
      </div>
    </div> <!-- End of Header -->
    <div style="top: 150px; padding-top: 45px; position:fixed; width: 100%; bottom:0; overflow-y:scroll;">
      <div class="d-flex justify-content-center">
        <div class="card w-75 shadow p-3 mb-5 bg-white rounded" style="padding: 0px !important;">
          <div v-if="contractors.length == 0" class="card-header d-flex justify-content-center">
            <h4>No Contractors Available. Add Contractor to assign projects!</h4>
          </div>
          <ul class="list-group overflow-y:scroll;">
            <li class="list-group-item" v-for="user in contractors" :key="user.user_id">
              <div class="d-flex justify-content-between align-items-center">
                  <h5 style="width: 300px;">{{user.name}}<br><small class="text-muted">{{user.email}}</small></h5>
                  <div>{{user.company}}</div>
                  <div>{{user.phone}}</div>
                  <div class="d-flex justify-content-between">
                    <contractor-change-status :item="user" :key-index="user.user_id" v-on:status-changed="saveItemStatus(user, user.user_id)"></contractor-change-status>
                    <div class="text-primary" style="padding: 0px 15px; cursor:pointer;" @click="editContractor(user)"><font-awesome-icon icon="edit" style="font-size: 16px;"/></div>
                    <div style="padding-left: 20px; width: 50px; cursor:pointer;"><font-awesome-icon :icon="getIcon(user)" @click="getToggle(user)" style="font-size: 24px;"/></div>
                  </div>
              </div>
              <div v-if="showToggle && userIndex == user.user_id" style="padding-top: 25px;">
                <div class="w-50 d-flex justify-content-center">
                  <multiselect :options="options" :show-labels="false" :multiple="true" track-by="project" :reset-after="true" label="project" placeholder="Search 3x Projects" v-model="project" @select="onSelect"></multiselect>
                </div>
                <div class="row">
                  <div v-for="(item, index) in projects" :key="index" class="col-4">
                    <div class="card" style="margin-top: 25px;">
                      <div class="card-body">
                        <div class="d-flex justifiy-content-start">
                          <h5 style="margin-left: 15px;">{{item.project}}</h5>
                        </div>
                      </div>
                      <div class="card-footer">
                        <span class="badge badge-pill badge-danger" style="cursor:pointer" @click="deleteContractorAccess(item, index)">Delete</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="showContractorModal">
      <contractor-modal @close="closeContractorModal" :data="data"/>
    </div>
  </div>
</template>

<script>
// import SettingService from '@/services/setting.service'
import ContractorModal from '@/components/modals/ContractorModal.vue'
import ContractorChangeStatus from '@/components/ContractorChangeStatus.vue'
import axios from 'axios'
export default {
  name: 'contractor-access',
  components: {
    ContractorModal,
    ContractorChangeStatus
  },
  data () {
    return {
      options: [],
      project: [],
      projects: [],
      contractors: [],
      showToggle: false,
      showContractorModal: false,
      userIndex: null,
      data: {}
    }
  },
  computed: {
  },
  methods: {
    getIcon: function (item) {
      if (this.showToggle === false) {
        return 'chevron-right'
      } else if (this.showToggle === true && this.userIndex === item.user_id) {
        return 'chevron-down'
      } else {
        return 'chevron-right'
      }
    },
    getToggle: function (item) {
      this.showToggle = !this.showToggle
      if (this.showToggle === true) {
        this.userIndex = item.user_id
        this.getContractorProjects()
      }
    },
    getContractors: function () {
      axios.get('https://employee.simpsonhealthcare.com/api/index.php/contractors').then((response) => {
        this.contractors = response.data
      })
    },
    getContractorProjects: function () {
      axios.get(`https://employee.simpsonhealthcare.com/api/index.php/contractors/${this.userIndex}/projects`).then((response) => {
        this.projects = response.data
      })
    },
    get3xProjects: function () {
      axios.get('https://employee.simpsonhealthcare.com/api/index.php/3x-projects').then((response) => {
        this.options = response.data
      })
    },
    addContractor: function () {
      this.data = {}
      this.showContractorModal = true
    },
    editContractor: function (item) {
      item.type = 'update'
      this.data = item
      this.showContractorModal = true
    },
    onSelect: function (item) {
      item.user_id = this.userIndex
      axios.post('https://employee.simpsonhealthcare.com/api/index.php/contractors/projects', JSON.stringify(item)).then((response) => {
        if (response.data.type === 'success') {
          item.id = response.data.id
          this.projects.push(item)
        }
      })
    },
    deleteContractorAccess: function (item, index) {
      axios.get(`https://employee.simpsonhealthcare.com/api/index.php/contractors/projects/${item.id}`).then((response) => {
        if (response.data.type === 'success') {
          this.projects.splice(index, 1)
        }
      })
    },
    closeContractorModal: function () {
      this.showContractorModal = false
      this.getContractors()
    },
    saveItemStatus: function (item, index) {
      item.type = 'update'
      axios.post('https://employee.simpsonhealthcare.com/api/index.php/contractors/update/' + item.user_id, JSON.stringify(item)).then((response) => {
      })
    }
  },
  mounted () {
    this.getContractors()
    this.get3xProjects()
  }
}
</script>

<style scoped>
/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 54px;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  bottom: 0;
  height: 100%;
  padding-top: 0px;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
  padding: 0px 16px;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #fff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

</style>
