<template>
  <div>
    <div class="row">
      <div class="col-md-3 user-interaction-sidebar">
        <div class="btn-group" style="padding: 15px; width: 100%;">
          <input class="form-control" placeholder="Search">
        </div>
        <ul class="list-group" style="margin-bottom:0px;">
          <li class="list-group-item add-pointer" tyle="text-align:left; padding: 15px;" @click="loginInUser">
            <div class="d-flex">
              <div class="avatar" style="margin-top: 2px; margin-right: 10px;"><span class="avatar-text" style="color: #FFF;">AA</span></div>
              <div>
                <div>Abdullah Amin</div>
                <div style="margin-top: -5px;"><span style="font-size: 12px;">aamin@simpsonhealthcare.com</span></div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-md-9 iframe-offset d-flex justify-content-center">
          <div v-if="showIframe" style="box-shadow: 0 0 0 3px transparent; border: 1px solid #ccc; margin:25px; width: 375px; height: 600px;">
            <iframe id="mobile" :src="userLink" frameborder="0" height="100%" width="100%"></iframe>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserInteractions',
  data () {
    return {
      userLink: 'https://3connex.simpsonhealthcare.com/takeda-mobile',
      showIframe: false
    }
  },
  methods: {
    reload: function () {
      document.getElementById('mobile').contentWindow.location.reload()
    },
    loginInUser: function () {
      const email = 'aamin@simpsonhealthcare.com'
      this.userLink = `https://3connex.simpsonhealthcare.com/takeda-mobile/login/${email}`
      this.showIframe = true
    }
  },
  mounted () {
    this.reload()
  },
  updated () {
    this.reload()
  }
}
</script>
<style scoped>
/* User Interaction Sidebar */

.user-interaction-sidebar {
    position: fixed;
    top: 164px;
    bottom: 0;
    left: 0;
    width: 375px;
    z-index: 1000;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 0px;
    padding-right: 0px;
    /* Scrollable contents if viewport is shorter than content. */
    background-color: #f5f5f5;
    border-right: 1px solid #eee;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
}

.iframe-offset {
  position: fixed;
  left: 375px;
}

.add-pointer {
  cursor: pointer;
}

/*--------------------------------------------------------------
    Avatar Styles
--------------------------------------------------------------*/

.avatar {
    border-radius: 50%;
    background: #333;
    width: 35px;
    height: 35px;
    margin-top: -9px;
}

.avatar>.avatar-text {
    font-size: 16px;
    line-height: 35px;
    padding-left: 8px;
    font-weight: 300;
}

</style>
