<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-dialog" role="document" style="max-width: 750px;">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: 0px !important;">
            <h5 class="modal-title">{{title}}</h5>
            <button class="close" v-on:click="close">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body" style="padding: 0px;">
            <div class="card">
              <div style="padding: 15px; background: #f6f6f7;">
                <div v-if="formError" class="alert alert-danger alert-dismissible fade show" role="alert">
                  <strong>Submission Error!</strong> The following fields must not be empty: Email Type, Schedule, Email Name, From Address, and Subject
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="dismissAlert">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="row">
                  <div class="col-6" :class="{ 'invalid': isInvalid, 'valid': isValid }">
                    <multiselect :options="emailType" :show-labels="false" :value="value" @input="onChange" :allow-empty="false" @close="onTouch" placeholder="Select Email Type" v-model="inputForm.email_type"></multiselect>
                    <label class="invalid" v-show="isInvalid">Must have at least one value</label>
                  </div>
                  <div class="col-6" :class="{ 'invalid': isInvalid, 'valid': isValid }">
                    <multiselect :options="schedule" :show-labels="false" :value="value" @input="onChange" :allow-empty="false" @close="onTouch" placeholder="Select Schedule" v-model="inputForm.schedule"></multiselect>
                    <label class="invalid" v-show="isInvalid">Must have at least one value</label>
                  </div>
                  <div class="col-6" v-if="inputForm.schedule == 'Automatically X Days Before Event' || inputForm.schedule == 'Automatically X Days After Event'">
                    <div style="padding: 15px 0px 0px 0px;">
                      <input type="text" class="form-control shadow-none text-primary" placeholder="Num of Days" v-model="inputForm.num_days">
                    </div>
                  </div>
                </div>
              </div>
              <div style="padding: 0px 15px 0px 15px; background: #f6f6f7;">
                <table class="table table-striped" style="margin-bottom: 0px;">
                  <tr>
                    <td style="padding: 5px; border-top: none;">
                      <input type="text" class="form-control shadow-none" style="border:none; padding-left: 0px; font-weight: 700; background:transparent;" placeholder="Email Name" v-model="inputForm.email_name">
                    </td>
                  </tr>
                  <!-- <tr>
                    <td style="padding: 5px;">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text" style="border: none; background:transparent; padding-left: 0px;">From:</span>
                        </div>
                        <input type="text" class="form-control shadow-none text-primary" style="border:none; background:transparent;" v-model="inputForm.from_address">
                        <div class="input-group-append">
                          <span @click="toggleCc" class="input-group-text" style="border: none; background:transparent; padding-left: 0px; cursor: pointer;">Cc</span>
                          <span @click="toggleBcc" class="input-group-text" style="border: none; background:transparent; padding-left: 0px; cursor: pointer;">Bcc</span>
                        </div>
                      </div>
                    </td>
                  </tr> -->
                  <tr>
                    <td style="padding: 5px;">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text" style="border: none; background:transparent; padding-left: 0px;">From:</span>
                        </div>
                        <DropdownList v-model="inputForm.from_address" :email="inputForm.from_address" @on-item-selected="getData($event)" @on-item-reset="dropdownSelection = {}" />
                        <div class="input-group-append">
                          <span @click="toggleCc" class="input-group-text" style="border: none; background:transparent; padding-left: 0px; cursor: pointer;">Cc</span>
                          <span @click="toggleBcc" class="input-group-text" style="border: none; background:transparent; padding-left: 0px; cursor: pointer;">Bcc</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="cc == true">
                    <td style="padding: 5px;">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text" style="border: none; background:transparent; padding-left: 0px;">Cc:</span>
                        </div>
                        <input type="text" class="form-control shadow-none text-primary" style="border:none; background:transparent;" v-model="inputForm.cc_address">
                      </div>
                    </td>
                  </tr>
                  <tr v-if="bcc == true">
                    <td style="padding: 5px;">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text" style="border: none; background:transparent; padding-left: 0px;">Bcc:</span>
                        </div>
                        <input type="text" class="form-control shadow-none text-primary" style="border:none; background:transparent;" v-model="inputForm.bcc_address">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style="padding: 5px;">
                      <input type="text" class="form-control shadow-none" style="border:none; padding-left: 0px; font-weight: 700; background:transparent;" placeholder="Subject" v-model="inputForm.subject">
                    </td>
                  </tr>
                </table>
              </div>
              <div v-if="attachments.length !== 0" style="padding: 10px;" class="d-flex flex-wrap">
                <div v-for="(item,index) in attachments" :key="index" class="d-flex" style="padding:10px; border: 1px solid #CCC; border-radius: 5px; margin-right: 10px; margin-bottom: 10px;">
                  <img :src="getImageURL(item.extension)" width="50">
                  <div style="padding-left: 2px;">
                      <h6>{{item.file_name}}<br>
                      <small>{{item.file_size}}</small>
                      </h6>
                  </div>
                  <div style="margin-top: -12px; margin-left: 15px; font-size: 16px; cursor:pointer;">
                      <font-awesome-icon icon="times" class="text-danger" @click="removeAttachment(index, item)"></font-awesome-icon>
                  </div>
                </div>
              </div>
              <div style="background: #fff;">
                <editor id="editor" api-key="xmy55lj0kciq6f2f0cfrnrnj4qjbs8e5qge7gjk8rr1j4692" style="height: 300px; border:none;" :init="options" :initial-value="initial" v-model="inputForm.email_body"></editor>
                <div class="p-3 d-flex justify-content-between">
                  <div class="p-1 d-flex">
                    <button type="submit" class="btn btn-primary mr-3" @click="saveEmailResponse">Save Email Response</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close">Cancel</button>
                  </div>
                  <div class="d-flex justify-content-between">
                    <multiselect v-if="inputForm.email_type == 'Registration Confirmation'" :options="calendarInvite" :show-labels="false" label="subject" track-by="id" :allow-empty="true" placeholder="Select Calendar Invite" v-model="inputForm.calendar_invite"></multiselect>
                    <div class="file-select" v-if="inputForm.id">
                      <font-awesome-icon icon="paperclip" class="text-primary select-button" @click="attachFile"></font-awesome-icon>
                      <input type="file" ref="file" @change="handleFileChange">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import eventService from '@/services/event.service'
import DropdownList from '@/components/events/DropdownList.vue'
// import VueTagsInput from '@johmun/vue-tags-input'
// import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
// import { forEach } from 'lodash'
export default {
  name: 'EmailResponseModal',
  components: {
    'editor': Editor,
    DropdownList
    // VueBootstrapTypeahead
    // VueTagsInput
  },
  props: ['eventId', 'title', 'inputForm'],
  data () {
    return {
      emailType: ['Registration Reply', 'Contract Reminder', 'Registration Confirmation', 'Event Reminder', 'Other', 'Thank You'],
      schedule: ['Automatically Upon Registration', 'Automatically Upon Validation', 'Automatically Upon Registration Confirmation', 'Automatically X Days Before Event', 'Automatically X Days After Event', 'Manual'],
      options: {
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar: 'bold italic strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist | link'
      },
      attachments: [],
      cc: false,
      bcc: false,
      tag: '',
      tags: [],
      autocompleteItems: [],
      dropdownSelection: {},
      editor: false,
      initial: '',
      formError: false,
      isTouched: false,
      value: [],
      signature: '',
      calendarInvite: []
      // initial: `<p style="font-size: 13pt;"></p><p style="font-size: 13pt;"><strong>${JSON.parse(localStorage.getItem('user')).name}</strong> | ${JSON.parse(localStorage.getItem('muser')).jobTitle}</p><p style="font-size: 13pt;">${JSON.parse(localStorage.getItem('muser')).businessPhones[0]} | direct + fax</p><p style="font-size: 13pt;">Simpson Healthcare<br>230 Shore Road | Old Lyme, CT 06371<br><a href="https://www.simpsonhealthcare.com">www.simpsonhealthcare.com</a></p><p><img src="https://imgs.simpsonhealthcare.com/simpson/BPTW-2020.png" width="200"/></p><p style="font-size: 11pt;">Please consider the environment before printing…</p><p style="font-size: 11pt;">Confidentiality Notice: This message is private and confidential and may contain confidential, proprietary, or legally privileged information. If you have received this message in error or you are not the intended recipient, please notify us immediately and delete and remove the e-mail and any attachments from your system. Any unauthorized use or disclosure of the contents of this message or any attachments is not permitted and may be unlawful.</p>`
    }
  },
  computed: {
    filteredItems () {
      return this.autocompleteItems.filter(i => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1
      })
    },
    isInvalid () {
      return this.isTouched && this.value.length === 0
    },
    isValid () {
      return this.isTouched && this.value.length > 0
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    onTouch () {
      this.isTouched = true
    },
    onChange (value) {
      console.log(value)
      this.value = value
    },
    dismissAlert: function () {
      this.formError = false
    },
    attachFile: function () {
      this.$refs.file.click()
      console.log('clicked')
    },
    handleFileChange: function (e) {
      const file = e.target.files[0]
      console.log('File', file)
      const item = {}
      item.file = file
      item.file_name = file.name
      item.file_size = this.formatBytes(file.size)
      var ext = file.name.substr(file.name.lastIndexOf('.') + 1)
      item.extension = ext
      if (this.inputForm.id) {
        var formData = new FormData()
        formData.append('id', this.inputForm.id)
        formData.append('file[]', item.file)
        eventService.uploadFile(formData).then((response) => {
          console.log(response)
          item.id = response.id
          this.attachments.push(item)
        })
      }
      console.log(this.attachments)
      // var formData = new FormData()
      // formData.append('file', file)
      // formData.append('file_name', file.name)
      // formData.append('file_size', this.formatBytes(file.size))
      // formData.append('file_extension', ext)
      // eventService.uploadFile(formData).then((response) => {
      //   console.log(response)
      // })
    },
    formatBytes: function (bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes'
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    },
    getImageURL: function (ext) {
      const images = require.context('@/assets/')
      let fileImage = ''
      try {
        fileImage = images(`./svg/${ext}.svg`)
      } catch (error) {
        fileImage = images('./svg/unknown.svg')
      }
      return fileImage
    },
    removeAttachment: function (index, item) {
      var formData = new FormData()
      formData.append('id', item.id)
      formData.append('file_name', item.file_name)
      eventService.removeEmailAttachment(formData).then((response) => {
        this.attachments.splice(index, 1)
      })
    },
    saveEmailResponse: function (event) {
      // console.log(this.inputForm)
      // if (this.inputForm.email_type === null || this.inputForm.schedule === null || this.inputForm.email_name === null || this.inputForm.subject === null || this.inputForm.from_address === null || this.inputForm.email_type === '' || this.inputForm.schedule === '' || this.inputForm.email_name === '' || this.inputForm.subject === '' || this.inputForm.from_address === '') {
      //   this.formError = true
      //   event.preventDefault()
      //   event.stopPropagation()
      // } else {
      this.formError = false
      this.inputForm.cc_address = this.inputForm.cc_address !== '' ? this.inputForm.cc_address : null
      this.inputForm.bcc_address = this.inputForm.bcc_address !== '' ? this.inputForm.bcc_address : null
      this.inputForm.num_days = this.inputForm.num_days !== '' ? this.inputForm.num_days : null
      if (this.inputForm.calendar_invite) {
        this.inputForm.calendar_invite = this.inputForm.calendar_invite === '' ? null : JSON.stringify(this.inputForm.calendar_invite)
      }
      eventService.saveEmailResponse(this.inputForm).then((response) => {
        if (response.id) {
          this.inputForm.id = response.id
        }
        // if (this.attachments.length !== 0) {
        //   console.log(this.attachments)
        //   var formData = new FormData()
        //   formData.append('id', response.id)
        //   forEach(this.attachments, (item) => {
        //     formData.append('file[]', item.file)
        //   })
        //   eventService.uploadFile(formData).then((response) => {
        //     console.log(response)
        //     this.inputForm.attachments = this.attachments
        //   })
        // }
      })
      this.$emit('saved', this.inputForm)
      // }
    },
    toggleCc: function () {
      this.cc = !this.cc
    },
    toggleBcc: function () {
      this.bcc = !this.bcc
    },
    getEmployees: function () {
      eventService.getActiveEmployees().then((response) => {
        this.autocompleteItems = response
      })
    },
    getCalendarInvites: function () {
      eventService.getCalendarInvites(this.eventId).then((response) => {
        this.calendarInvite = response
      })
    },
    getData: function (event) {
      this.dropdownSelection = event
      this.inputForm.from_address = event.email
      // this.inputForm.email_body = window.parent.tinymce.get('editor').setContent(`<p style="font-size: 13pt;"></p><p style="font-size: 13pt;"><strong>${event.name}</strong> | ${event.position}</p><p style="font-size: 13pt;">${event.phone} | direct + fax</p><p style="font-size: 13pt;">Simpson Healthcare<br>230 Shore Road | Old Lyme, CT 06371<br><a href="https://www.simpsonhealthcare.com">www.simpsonhealthcare.com</a></p><p><img src="https://imgs.simpsonhealthcare.com/simpson/BPTW-2020.png" width="200"/></p><p style="font-size: 11pt;">Please consider the environment before printing…</p><p style="font-size: 11pt;">Confidentiality Notice: This message is private and confidential and may contain confidential, proprietary, or legally privileged information. If you have received this message in error or you are not the intended recipient, please notify us immediately and delete and remove the e-mail and any attachments from your system. Any unauthorized use or disclosure of the contents of this message or any attachments is not permitted and may be unlawful.</p>`)
      this.inputForm.email_signature = `<p style="font-size: 12pt; font-family: Arial;"></p><p style="font-size: 12pt; font-family: Arial;"><strong>${event.name}</strong> | ${event.position}</p><p style="font-size: 12pt; font-family: Arial;">${event.phone} | direct + fax</p><p style="font-size: 12pt; font-family: Arial;">Simpson Healthcare<br>230 Shore Road | Old Lyme, CT 06371<br><a href="https://www.simpsonhealthcare.com">www.simpsonhealthcare.com</a></p><p><img src="https://imgs.simpsonhealthcare.com/simpson/BPTW-2020.png" width="200"/></p><p style="font-size: 9pt; font-family: Arial;">Please consider the environment before printing…</p><p style="font-size: 9pt; font-family: Arial;">Confidentiality Notice: This message is private and confidential and may contain confidential, proprietary, or legally privileged information. If you have received this message in error or you are not the intended recipient, please notify us immediately and delete and remove the e-mail and any attachments from your system. Any unauthorized use or disclosure of the contents of this message or any attachments is not permitted and may be unlawful.</p>`
    }
  },
  mounted () {
    console.log(this.inputForm)
    if (this.inputForm.cc_address !== null) {
      this.cc = true
    }
    if (this.inputForm.bcc_address !== null) {
      this.bcc = true
    }
    if (this.inputForm.attachments.length !== 0) {
      this.attachments = this.inputForm.attachments
    }
    if (this.inputForm.calendar_invite) {
      this.inputForm.calendar_invite = JSON.parse(this.inputForm.calendar_invite)
    }
    this.getEmployees()
    this.getCalendarInvites()
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.invalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 90%;
  color: #dc3545;
}

.invalid .multiselect__tags {
  border-color: #dc3545!important;
}

.valid .multiselect__tags {
  border-color: #28a745!important;
}

.vue-tags-input {
  background-color: transparent !important;
  max-width: 660px !important;
}

.vue-tags-input .ti-new-tag-input {
  background: transparent;
  line-height: 1.4;
  font-size: 16px;
}

.vue-tags-input .ti-input {
  border: none;
}

.vue-tags-input .ti-tag {
  position: relative;
  background: #007bff;
  color: #ffffff;
  padding: 4px 6px;
  margin: 4px 2px 2px 2px;
}
</style>
<style scoped>
.multiselect.form-control {
  padding: 0;
}

.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.document-editor {
    border: 1px solid var(--ck-color-base-border);
    border-radius: var(--ck-border-radius);
}

.close:focus {
  outline: none;
}

.mce-notification {
  display: none !important;
}

.file-select > .select-button {
  margin: 10px;
  cursor: pointer;
  font-size: 25px;
}

.file-select > input[type="file"] {
  display: none;
}
</style>
