<template>
  <div>
    <form ref="requestForm" style="padding: 15px;" class="needs-validation" novalidate>
      <div class="p-3 row">
        <div v-for="item in jsonForm" :key="item.id" v-bind:class="{'col-12': item.columnSize == 12, 'col-6': item.columnSize == 6 }">
          <!---------- Input ---------->
          <div class="form-group" v-if="item.type == 'input'">
            <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
            <input :type="item.inputType" class="form-control" :class="checkFormField(item)" @change="checkItem(item)" :placeholder="item.placeholderText" v-model="inputForm[item.vModel]">
            <div class="invalid-feedback">{{item.error}}</div>
          </div>
          <!---------- Textarea ---------->
          <div class="form-group" v-if="item.type == 'textarea'">
            <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
            <textarea cols="30" rows="2" class="form-control" :class="checkFormField(item)" @change="checkItem(item)" :placeholder="item.placeholderText" v-model="inputForm[item.vModel]"></textarea>
            <div class="invalid-feedback">{{item.error}}</div>
          </div>
          <!---------- Dropdown ---------->
          <div v-if="item.type == 'select'">
            <div class="form-group">
              <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
              <!-- <select class="form-control" v-model="inputForm[item.vModel]" :class="checkFormField(item)" @change="checkItem(item)">
                <option value="">{{item.placeholderText}}</option>
                <option v-for="(choice, index) in item.choices" :key="index">
                  {{ choice }}
                </option>
              </select> -->
              <multiselect v-model="inputForm[item.vModel]" :options="item.choices" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" :placeholder="item.placeholderText" :class="checkFormField(item)" @select="checkItem(item)"></multiselect>
              <div class="invalid-feedback">{{item.error}}</div>
            </div>
            <!-- Other -->
            <div v-if="item.other === 'Yes'" class="form-group">
              <textarea :name="['other-']+item.id" :id="['other-']+item.classNme" cols="30" rows="1" class="form-control" v-model="inputForm[item.vModel]"></textarea>
            </div>
          </div>
          <!---------- Project Dropdown ---------->
          <div v-if="item.type == 'project-select'">
            <div class="form-group">
              <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
              <multiselect ref="multiselect" v-model="inputForm[item.vModel]" :options="projects" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" :placeholder="item.placeholderText" :class="checkFormField(item)" @select="checkItem(item)"></multiselect>
              <div class="invalid-feedback">{{item.error}}</div>
            </div>
            <!-- Other -->
            <div v-if="item.other === 'Yes'" class="form-group">
              <textarea :name="['other-']+item.id" :id="['other-']+item.classNme" cols="30" rows="1" class="form-control" v-model="inputForm[item.vModel]"></textarea>
            </div>
          </div>
          <!---------- Employee Dropdown ---------->
          <div v-if="item.type == 'employee-select'">
            <div class="form-group">
              <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
              <multiselect v-model="inputForm[item.vModel]" :options="projectManagers" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" :placeholder="item.placeholderText" :class="checkFormField(item)" @select="checkItem(item)"></multiselect>
              <div class="invalid-feedback">{{item.error}}</div>
            </div>
            <!-- Other -->
            <div v-if="item.other === 'Yes'" class="form-group">
              <textarea :name="['other-']+item.id" :id="['other-']+item.classNme" cols="30" rows="1" class="form-control" v-model="inputForm[item.vModel]"></textarea>
            </div>
          </div>
          <!---------- Checkbox ---------->
          <div v-if="item.type == 'checkbox'">
            <div class="form-group" v-if="item.display === 'standard'">
              <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label> <span class="text-info" v-if="item.limit === 'Yes'"> (Select up to {{item.limitAllowed}})</span>
              <span class="text-info" v-else> (Select all that apply)</span>
              <div class="clearfix"></div>
              <div class="form-check" v-for="(choice, index) in item.choices" :key="index">
                <input class="form-check-input" type="checkbox" :value="choice" v-model="inputForm[item.vModel]" :class="checkFormField(item)" @change="checkItem(item)">
                <label class="form-check-label">
                  {{ choice }}
                </label>
                <div v-if="item.choices.length == index + 1" class="invalid-feedback">{{item.error}}</div>
              </div>
            </div>

            <div class="form-group" v-else>
              <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label> <span class="text-info" v-if="item.limit === 'Yes'"> (Select up to {{item.limitAllowed}})</span>
              <div class="clearfix"></div>
              <div class="form-check form-check-inline" v-for="(choice, index) in item.choices" :key="index">
                <input class="form-check-input" type="checkbox" :value="choice" v-model="inputForm[item.vModel]" :class="checkFormField(item)" @change="checkItem(item)">
                <label class="form-check-label">
                  {{ choice }}
                </label>
              </div>
              <div style="margin-left: 0px;" class="invalid-feedback">{{item.error}}</div>
            </div>

            <!-- Other -->
            <div v-if="item.other === 'Yes'" class="form-group">
              <textarea :name="['other-']+item.id" :id="['other-']+item.className" cols="30" rows="1" class="form-control" v-model="inputForm[item.vModel]"></textarea>
            </div>
          </div>
          <!---------- Radio ---------->
          <div v-if="item.type == 'radio'">
            <div class="form-group" v-if="item.display === 'standard'">
              <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
              <div class="clearfix"></div>
              <div class="form-check" v-for="(choice, index) in item.choices" :key="index">
                <input class="form-check-input" type="radio" :value="choice" v-model="inputForm[item.vModel]" :class="checkFormField(item)" @change="checkItem(item)">
                <label class="form-check-label">
                  {{ choice }}
                </label>
                <div v-if="item.choices.length == index + 1" class="invalid-feedback">{{item.error}}</div>
              </div>
            </div>

            <div class="form-group" v-else>
              <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
              <div class="clearfix"></div>
              <div class="form-check form-check-inline" v-for="(choice, index) in item.choices" :key="index">
                <input class="form-check-input" type="radio" :value="choice" v-model="inputForm[item.vModel]" :class="checkFormField(item)" @change="checkItem(item)">
                <label class="form-check-label">
                  {{ choice }}
                </label>
                <div v-if="item.choices.length == index + 1" class="invalid-feedback">{{item.error}}</div>
              </div>
            </div>

            <!-- Other -->
            <div v-if="item.other === 'Yes'" class="form-group">
              <textarea :name="['other-']+item.id" :id="['other-']+item.className" cols="30" rows="1" class="form-control" v-model="inputForm[item.vModel]"></textarea>
            </div>
          </div>
          <!---------- Switch ---------->
          <div v-if="item.type == 'switch'">
            <div class="form-group">
              <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label><br />
              <label class="switch">
                <input type="checkbox" v-model="inputForm[item.vModel]" :class="checkFormField(item)" @change="checkItem(item)">
                <div class="slider round"></div>
                <div style="margin-top: 20px; width: 200px;" class="invalid-feedback">{{item.error}}</div>
              </label>
            </div>

          </div>
          <!---------- Date ---------->
          <div v-if="item.type == 'date'">
            <div class="form-group">
              <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
              <datepicker format="MM/dd/yyyy" :bootstrap-styling="true" :clear-button="true" :class="checkFormField(item)" @selected="checkItem(item)" :placeholder="item.placeholderText" v-model="inputForm[item.vModel]"></datepicker>
              <div class="invalid-feedback">{{item.error}}</div>
            </div>
          </div>
          <!---------- Time ---------->
          <div v-if="item.type == 'time'">
            <div class="form-group">
              <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label><br>
              <vue-timepicker format="hh:mm A" input-class="form-control time-background" input-width="100%" :class="checkFormField(item)" @close="checkItem(item)" :placeholder="item.placeholderText" v-model="inputForm[item.vModel]"></vue-timepicker>
              <div class="invalid-feedback">{{item.error}}</div>
            </div>
          </div>
          <!---------- Clearfix ---------->
          <div v-if="item.type == 'clearfix'">
            <div class="clearfix"></div>
          </div>

          <!---------- US Address ---------->
          <div v-if="item.type == 'us-address'">
            <div class="row">
              <div class="form-group col-6" v-for="(item, index) in item.customFields" :key="index">
                <span class="text-danger" v-show="item.required">* </span><label>{{item.name}}</label>
                <select v-if="item.name == 'State'" class="form-control" :class="checkFormField(item)" @change="checkItem(item)" v-model="inputForm[item.vModel]">
                  <option value="">Select {{item.name}}</option>
                  <option v-for="(choice, index) in item.values" :key="index">
                    {{ choice }}
                  </option>
                </select>
                <input v-else :type="item.inputType" class="form-control" :class="checkFormField(item)" @change="checkItem(item)" :placeholder="item.name" v-model="inputForm[item.vModel]">
                <div class="invalid-feedback">{{item.error}}</div>
              </div>
            </div>
          </div>

          <!---------- Contact ---------->
          <div v-if="item.type == 'contact'">
            <div class="row">
              <div class="form-group" v-bind:class="{'col-12': item.columnSize == 'col-12', 'col-6': item.columnSize == 'col-6'}" v-for="(item, index) in item.customFields" :key="index">
                <span class="text-danger" v-show="item.required">* </span><label>{{item.name}}</label>
                <input :type="item.inputType" class="form-control" :class="checkFormField(item)" @change="checkItem(item)" :placeholder="item.name" v-model="inputForm[item.vModel]">
                <div class="invalid-feedback">{{item.error}}</div>
              </div>
            </div>
          </div>

          <!---------- Cover Letter ---------->
          <div v-if="item.type == 'word'" style="margin-bottom: 35px;">
            <div class="row">
              <div class="col-12">
                <div v-html="item.textValue"></div>
              </div>
            </div>
          </div>

          <!---------- Button ---------->
          <div v-if="item.type == 'button'" class="py-3 d-flex">
            <button class="btn" :class="[ 'btn-' + item.buttonSize, 'btn-' + item.buttonType ]" @click.stop.prevent="save">{{item.buttonText}}</button>
          </div>
        </div>
      </div>
    </form>
    <!-- <div class="p-3 d-flex" style="background: #f6f6f7; border-top: 1px solid #ccc;">
      <button class="btn btn-primary mr-3" @click.stop.prevent="save">Save Registrant</button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close">Cancel</button>
    </div> -->
  </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import { forEach } from 'lodash'
import formService from '@/services/form.service'
import eventService from '@/services/event.service'
export default {
  name: 'GeneratedFormItems',
  components: {
    Datepicker,
    VueTimepicker
  },
  props: ['formId'],
  data () {
    return {
      jsonForm: {},
      inputForm: {},
      valid: false,
      inputBlured: false,
      formChecked: false,
      formArray: [],
      formData: {},
      submitForm: {},
      projects: [],
      projectManagers: []
    }
  },
  watch: {
    formId: function (val) {
      this.formArray = []
      this.jsonForm = {}
      this.inputForm = {}
      this.valid = false
      this.formChecked = false
      this.getData(val)
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    save: function () {
      this.validate()
      if (this.valid) {
        this.submitForm.forms_id = this.formId
        this.submitForm.category = this.formData.category
        this.submitForm.request_form = JSON.stringify(this.inputForm)
        console.log(this.inputForm)
        formService.submitRequest(this.submitForm).then((response) => {
          console.log(response)
          this.$refs.requestForm.reset()
          this.formArray = []
          this.resetData(this.inputForm)
          this.valid = false
          this.formChecked = false

        })
      }
    },
    validate: function () {
      this.inputBlured = true
      console.log('Validate', this.formArray)
      if (this.formArray.length === 0) {
        this.valid = true
      }
    },
    checkFormField: function (item) {
      if ((this.inputForm[item.vModel] === '' || this.inputForm[item.vModel].length === 0) && item.required) {
        return {
          'is-invalid': (this.inputForm[item.vModel] === '' || this.inputForm[item.vModel].length === 0) && this.inputBlured && item.required
        }
      } else {
        return {
          'is-valid': this.inputForm[item.vModel] !== '' || this.inputForm[item.vModel].length !== 0
        }
      }
    },
    checkItem: function (item) {
      console.log(item)
      console.log(this.formArray)
      console.log(this.inputForm[item.vModel])
      if ((this.inputForm[item.vModel] !== '' || this.inputForm[item.vModel] !== 0 || this.inputForm[item.vModel].length !== 0) && item.required) {
        const index = this.formArray.indexOf(item.vModel)
        this.formArray.splice(index, 1)
      }
      console.log('Check', this.formArray)
    },
    getProjects: function () {
      eventService.getProjects().then((response) => {
        const res = response
        const items = []
        forEach(res, (item) => {
          items.push(item.name)
        })
        this.projects = items
        console.log(this.projects)
      })
    },
    getProjectManagers: function () {
      eventService.getProjectManagers().then((response) => {
        this.projectManagers = response.users
      })
    },
    resetData: function(data) {
      forEach(data, (item, key) => {
        if (Array.isArray(item)) {
          data[key] = []
        } else {
          data[key] = ''
        }
      })
      this.inputForm = data
    },
    getData: function (id) {
      formService.getForm(id).then((response) => {
        this.formData = response
        const items = JSON.parse(response.form_elements)
        this.jsonForm = items
        console.log('Items', items)
        const data = {}
        forEach(items, field => {
          if (field.customFields) {
            field.customFields.forEach(subfield => {
              if (subfield.type === 'checkbox') {
                data[subfield.vModel] = []
              } else {
                data[subfield.vModel] = ''
              }
              if (subfield.required) {
                this.formArray.push(subfield.vModel)
              }
            })
          } else {
            if (field.vModel) {
              if (field.type === 'checkbox') {
                data[field.vModel] = []
              } else {
                data[field.vModel] = ''
              }
              if (field.required) {
                this.formArray.push(field.vModel)
              }
            }
          }
        })
        this.inputForm = data
        console.log('Fields', data)
        console.log('FormArray', this.formArray)
      })
    }
  },
  mounted() {
    this.getProjects()
    this.getProjectManagers()
  }
}
</script>
<style scoped>
/*--------------------------------------------------
  Switch
--------------------------------------------------*/
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 18px;
}

.switch input { display: none }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #009370;
}

input:focus+.slider {
  box-shadow: 0 0 1px #009370;
}

input:checked+.slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

.slider.round { border-radius: 34px }

.slider.round:before { border-radius: 50% }

/* input[type="text"], textarea {
outline: none;
box-shadow:none !important;
border:1px solid #ccc;
} */

.remove-border {
  border-left: 0px !important;
}
.input-group-prepend {
  padding: 10px;
  background-color: #fff;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.invalid .multiselect__tags {
  border-color: #dc3545!important;
}

.valid .multiselect__tags {
  border-color: #28a745!important;
}
</style>
