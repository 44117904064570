import axios from 'axios'

export const http = axios.create({ baseURL: process.env.VUE_APP_API_BASE_URL })

export const authHeader = localStorage.getItem('token') ? { 'x-access-token': `${localStorage.getItem('token')}` } : {}
// export const authHeader =  function() {
//     const token = localStorage.getItem('token');

//     if (token) {
//         return { 'x-access-token': `${token}` };
//     }
//     return {};
// }
