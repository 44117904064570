<template>
  <div>
    <div class="d-flex justify-content-start" style="background: #fff; position: fixed; padding: 20px 25px; width: 100%; text-align:center; box-shadow:rgba(0, 0, 0, 0.2) 2px 4px 20px;">
      <button class="btn btn-secondary" style="text-transform: uppercase; padding: 10px 15px; color:#fff; cursor:pointer;" @click="goBack">Back</button>
      <h3>{{event.event_name}}</h3>
    </div>
    <div style="padding-top: 88px;">
      <div>
        <section class="sidebar">
          <div class="sidebar-sticky">
            <ul class="nav flex-column" style="padding-top: 25px;">
              <li class="nav-item" @click="showMain">
                <router-link class="nav-link p-2 m-0 text-uppercase" active-class="active-main" :to="{ name: 'eventDetail'}">Dashboard</router-link>
              </li>
              <li class="nav-item" @click="showMain">
                <router-link class="nav-link p-2 m-0 text-uppercase" active-class="active" :to="{ name: 'form-builder'}">Form Builder</router-link>
              </li>
              <li class="nav-item" @click="showMain">
                <router-link class="nav-link p-2 m-0 text-uppercase" active-class="active" :to="{ name: 'email-responses'}">Email Responses</router-link>
              </li>
              <li class="nav-item" @click="showMain">
                <router-link class="nav-link p-2 m-0 text-uppercase" active-class="active" :to="{ name: 'registrants'}">Registrants</router-link>
              </li>
              <li class="nav-item" @click="showMain">
                <router-link class="nav-link p-2 m-0 text-uppercase" active-class="active" :to="{ name: 'event-url'}">Event URL</router-link>
              </li>
              <li class="nav-item" @click="showMain">
                <router-link class="nav-link p-2 m-0 text-uppercase" active-class="active" :to="{ name: 'vendor-access'}">Vendor Access</router-link>
              </li>
            </ul>
          </div>
        </section>
        <section class="px-0 ml-0 notes-section">
          <div style="padding: 35px;" class="col-md-12">
            <div v-if="main == true" class="d-flex justify-content-center">
              <div class="card shadow p-0 m-5 bg-white rounded w-50">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">Event</label>
                      <p style="font-size: 16px;">{{event.event_name}}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">Project</label>
                      <p style="font-size: 16px;">{{event.project}}</p>
                    </div>
                    <div class="col">
                      <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">Project Manager</label>
                      <p style="font-size: 16px;">{{event.project_manager}}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">Start Date</label>
                      <p style="font-size: 16px;">{{event.start_date | formatDate}}</p>
                    </div>
                    <div class="col">
                      <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">End Date</label>
                      <p style="font-size: 16px;">{{event.end_date | formatDate}}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">Cutoff Date</label>
                      <p style="font-size: 16px;">{{event.cutoff_date | formatDate}}</p>
                    </div>
                    <div class="col">
                      <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">Registration Limit</label>
                      <p style="font-size: 16px;">{{event.registration_limit}}</p>
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="col">
                      <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">Notes</label>
                      <p style="font-size: 16px;" v-html="event.notes"></p>
                    </div>
                  </div> -->
                </div>
                <div class="card-footer">
                  <div class="d-flex justify-content-end">
                    <button class="btn btn-primary" @click="createTemplate"><font-awesome-icon icon="plus"/> Create Template from Event</button>
                  </div>
                </div>
              </div>
            </div>
            <router-view v-if="main == false"></router-view>
          </div>
        </section>
      </div>
    </div>
    <div v-if="showEventTemplateModal">
      <event-template-modal @close="closeEventTemplateModal" :item="event" :title="title"/>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import eventService from '@/services/event.service'
import EventTemplateModal from '@/components/events/modals/EventTemplateModal.vue'
export default {
  components: {
    EventTemplateModal
  },
  data () {
    return {
      event: {},
      main: true,
      showEventTemplateModal: false,
      titile: ''
    }
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        console.log(value)
        return moment(String(value)).format('MM/DD/YYYY')
      } else {
        return 'No date'
      }
    }
  },
  methods: {
    goBack: function () {
      this.$router.push({ name: 'events' })
    },
    getDetails: function () {
      const eventId = this.$route.params.id
      eventService.getEvent(eventId).then((response) => {
        console.log(response)
        if (response.registration_limit === null || response.registration_limit === 0) {
          response.registration_limit = 'No registration limit'
        }

        // if (response.notes === null || response.notes === '') {
        //   response.notes = 'No notes'
        // }
        this.event = response
      })
    },
    showMain: function () {
      console.log(this.$route.path)
      if (this.$route.path !== `/events/${this.$route.params.id}`) {
        this.main = false
      }

      if (this.$route.path === `/events/${this.$route.params.id}`) {
        this.main = true
      }
    },
    createTemplate: function () {
      this.title = 'Create Template'
      this.showEventTemplateModal = true
    },
    closeEventTemplateModal: function () {
      this.showEventTemplateModal = false
    }
  },
  created () {
    this.getDetails()
    this.showMain()
  }
}
</script>

<style scoped>
h3 {
  display: inline-block;
  padding-left: 120px;
  padding-top: 5px;
}
/*
 * Sidebar
 */
.sidebar {
  position: fixed;
  top: 142px;
  bottom: 0;
  width: 200px;
  left: 0;
  z-index: 0; /* Behind the navbar */
  padding: 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}
.sidebar-sticky {
  position: relative;
  top: 0;
  bottom: 0;
  height: 100%;
  padding-top: 0px;
  background: #fff;
  border-right: 1px solid #ccc;
  scrollbar-width: none;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}
.notes-section {
  position: fixed;
  top: 145px;
  left: 200px;
  right: 0px;
  bottom: 0px;
  padding-top: 0px;
  padding-bottom: 25px;
  background-color: #f4f5f7;
  overflow-y:auto;
}
::-webkit-scrollbar {
  display:none;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}
.sidebar .nav-link {
  font-weight: 500;
  color: #333;
  padding: 0px 16px;
}
.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}
.sidebar .nav-link.active {
  color: #fff;
}
.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}
.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}
.sidebar .nav-link {
  padding-top: 5px;
  padding-bottom: 5px;
}
.sidebar .nav-link.active {
  color: #FFF;
  background: #006AB6;
}

.sidebar .nav-link.active-main {
  color: #FFF;
  background: #f0ad4e;
}

</style>
