<template>
  <div>
    <div style="padding: 15px 15px 35px 15px; width: 100%; text-align:center;">
      <div ng-show="items.length > 0" style="padding-bottom: 25px;">
          <div style="padding-top: 10px; padding-left: 10px; float: left; text-align:left;">
              <select class="form-control" name="show-filter" ng-model="filterItem.store" ng-options="item.name for item in filterOptions.stores"></select>
          </div>
          <div style="padding-top: 10px; padding-right: 15px; float:right; text-align:right;">
              <input type="text" class="form-control" ng-model="searchQuery" placeholder="Search User" style="width: 400px; font-size: 18px; height: 42px;">
          </div>
          <div class="clearfix"></div>
      </div>
      <section ng-repeat="entry in items | toArray:false | filter: searchQuery | filter: customFilter track by $index" class="col-md-4" style="padding-bottom: 30px;">
          <div style="border: 1px solid #ccc; background:#fff; padding: 15px; height: 110px; box-shadow: 0px 0px 15px #ccc; border-radius: 5px; text-align:left;">
              <div class="pull-left avatar" style="margin-top: 2px; margin-right: 10px;"><span class="avatar-text" style="color: #FFF;">{{ entry.first_name[0]}}{{entry.last_name[0]}}</span></div>
              <h4 class="pull-left" style="margin: 0px;">{{ entry.name }}<br/><small>{{ entry.email }}</small></h4>
              <div class="clearfix"></div>
              <div style="padding: 15px 0px;" class="pull-right">
                  <span class="tag tag-danger" style="cursor:pointer;" ng-click="deleteItem(entry)">Delete</span>
              </div>
              <div class="clearfix"></div>
          </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserInteractions',
  data () {
    return {
      userLink: 'https://3connex.simpsonhealthcare.com/takeda-mobile',
      showIframe: false
    }
  },
  methods: {
    reload: function () {
      document.getElementById('mobile').contentWindow.location.reload()
    },
    loginInUser: function () {
      const email = 'aamin@simpsonhealthcare.com'
      this.userLink = `https://3connex.simpsonhealthcare.com/takeda-mobile/login/${email}`
      this.showIframe = true
    }
  },
  mounted () {
    this.reload()
  },
  updated () {
    this.reload()
  }
}
</script>
<style scoped>
/* User Interaction Sidebar */

.user-interaction-sidebar {
    position: fixed;
    top: 164px;
    bottom: 0;
    left: 0;
    width: 375px;
    z-index: 1000;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 0px;
    padding-right: 0px;
    /* Scrollable contents if viewport is shorter than content. */
    background-color: #f5f5f5;
    border-right: 1px solid #eee;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
}

.iframe-offset {
  position: fixed;
  left: 375px;
}

.add-pointer {
  cursor: pointer;
}

/*--------------------------------------------------------------
    Avatar Styles
--------------------------------------------------------------*/

.avatar {
    border-radius: 50%;
    background: #333;
    width: 35px;
    height: 35px;
    margin-top: -9px;
}

.avatar>.avatar-text {
    font-size: 16px;
    line-height: 35px;
    padding-left: 8px;
    font-weight: 300;
}

</style>
