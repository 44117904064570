<template>
  <div class="d-flex justify-content-center px-5" style="padding:45px 25px 25px 25px;">
    <!-- <Description :kid="this.kid" :type="type" /> -->
    <div class="card w-75">
      <div class="card-header d-flex justify-content-between align-items-center">
        <div>
          <h4>{{type}}</h4>
          <p class="text-muted mb-0" style="font-size: 0.85em;">Last updated: 7/1/2020 2:57 PM by Simpson Employee</p>
        </div>
        <button v-if="items.length == 0" class="btn btn-sm btn-primary" @click="addInfo">Add Info</button>
      </div>
      <div class="card-body p-0">
        <table class="table mb-0">
          <tr v-if="items.length == 0">
            <td colspan="3" class="text-center">
              No Information Available
            </td>
          </tr>
          <tr v-for="item in items" :key="item.id">
            <td width="80%">
              <div v-html="item.description"></div>
            </td>
            <td><span class="badge badge-success" :class="{'badge-success': item.publish_type === 'Published' , 'badge-warning': item.publish_type === 'Draft' }">{{item.publish_type}}</span></td>
            <td>
              <div class="d-flex justify-content-between pt-1" v-if="!item.draft_id">
                <font-awesome-icon class="text-primary" icon="edit" style="font-size: 18px;" @click="editBiblio(item)" />
                <font-awesome-icon v-if="item.publish_type !== 'Draft'" class="text-warning" icon="pencil-ruler" style="font-size: 18px;" @click="addDraft(item)" />
              </div>
            </td>
          </tr>

          <!-- <tr>
            <td>
              <ul>
                <li>Fellowship, Allergy and Immunology, Penn State Hershey Medical Center, 2010</li>
                <li>MD, University of Puerto Rico, 2003</li>
                <li>Residency, Internal Medicine, University District Hospital, 2007</li>
                <li>Test</li>
              </ul>
            </td>
            <td><span class="badge badge-success">Published</span></td>
            <td>
              <div class="d-flex justify-content-between pt-1" v-if="!isDraft">
                <font-awesome-icon class="text-primary" icon="edit" style="font-size: 18px;" />
                <font-awesome-icon class="text-warning" icon="pencil-ruler" style="font-size: 18px;" @click="addDraft" />
              </div>
            </td>
          </tr> -->
          <!-- <tr v-if="isDraft">
            <td>
              <ul>
                <li>Fellowship, Allergy and Immunology, Penn State Hershey Medical Center, 2010</li>
                <li>MD, University of Puerto Rico, 2003</li>
                <li>Residency, Internal Medicine, University District Hospital, 2007</li>
                <li>Test</li>
              </ul>
            </td>
            <td><span class="badge badge-warning">Draft</span></td>
            <td>
              <div class="d-flex justify-content-between pt-1">
                <font-awesome-icon class="text-primary" icon="edit" style="font-size: 18px;" />
              </div>
            </td>
          </tr> -->
        </table>
      </div>
    </div>
    <div v-if="showModal">
      <bibliometric-modal @close="closeInfoModal" @saved="saveBiblio" :title="title" :biblio="biblio" :editMode="editMode" :type="type" :kolId="kolId"/>
    </div>
  </div>
</template>
<script>
import BibliometricModal from '@/components/profiles/BibliometricModal.vue'
export default {
  props: ['type', 'kolId'],
  components: {
    BibliometricModal
  },
  data () {
    return {
      isDraft: false,
      showModal: false,
      items: [],
      biblio: {},
      title: '',
      editMode: false
    }
  },
  methods: {
    getBibliometrics: function (id, type) {
      console.log(id, type)
    },
    addInfo: function () {
      this.title = 'Add Info'
      this.editMode = false
      this.showModal = true
    },
    closeInfoModal: function () {
      this.showModal = false
    },
    addDraft: function (item) {
      console.log(item)
      let clonedItem = { ...item }

      clonedItem.publish_type = 'Draft'
      clonedItem.pub_id = item.id
      clonedItem.id = Math.floor(100000 + Math.random() * 900000)
      let object = this.items.find(x => x.id === item.id)
      let index = this.items.indexOf(object)
      this.items[index].draft_id = clonedItem.id
      this.items.push(clonedItem)
      this.isDraft = true
      console.log(clonedItem)
      console.log(this.items)
    },
    editBiblio: function (item) {
      this.title = 'Edit Info'
      this.editMode = true
      console.log(item)
      this.biblio = item
      this.showModal = true
    },
    saveBiblio: function (data) {
      console.log(data)
      console.log('Items', this.items)
      if (data.editMode) {
        let object = this.items.find(x => x.id === data.id)
        let index = this.items.indexOf(object)
        if (data.publish_type === 'Published') {
          this.isDraft = false
          if (data.pub_id) {
            let pub = this.items.find(x => x.id === data.pub_id)
            let pubIndex = this.items.indexOf(pub)
            this.items[pubIndex].description = data.description
            this.items.splice(index, 1)
            delete this.items[pubIndex].draft_id
          }
        } else {
          this.items[index].description = data.description
        }
      } else {
        this.items.push(data)
      }
      this.showModal = false
    }
  },
  created () {
    this.getBibliometrics(this.kolId, this.type)
  }
}
</script>
