<template>
  <div>
    <div style="background: #fff; position: fixed; z-index: 50; padding: 0px 20px; width: 100%; text-align:center;  box-shadow:rgba(0, 0, 0, 0.2) 2px 4px 20px;">
      <div class="d-flex justify-content-between">
        <div>
          <div @click="$router.go(-1)" style="padding-top:20px; padding-left: 10px; float: left; text-align:left; padding-right:25px; cursor:pointer;">
              <span class="fa fa-chevron-left" style="font-size:20px;"></span>
              <span style="font-size: 20px; padding-left: 5px;">Back</span>
          </div>
          <div style="padding-top: 16px; padding-bottom: 16px; padding-left: 10px; float: left; text-align:left;">
            <h3 style="margin-top:3px; font-size: 23px;">
              {{convention.project}}
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2 bg-light" style="position:fixed; bottom:0px; top:125px; border-right: 1px solid #f1f1f1; border-top: 1px solid #f1f1f1; -webkit-box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1); box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);">
      <ul class="nav flex-column pt-4 pb-1">
        <li class="nav-item">
          <h3 class="p-2">Settings</h3>
        </li>
        <li class="nav-item">
          <a class="nav-link px-0" style="cursor:pointer;" v-on:click="toggleClientData">
            <div class="d-flex bd-highlight justify-content-between">
              <h6 class="p-2 m-0 text-uppercase">Client Forms</h6>
              <font-awesome-icon v-if="showdropdown == false" icon="chevron-right" style="margin-top:8px;"/>
              <font-awesome-icon v-if="showdropdown == true" icon="chevron-down" style="margin-top:8px;"/>
            </div>
          </a>
          <ul class="nav pl-2" v-if="showdropdown">
            <li class="nav-item py-2 pr-3" style="width: 100%;">
              <div class="d-flex justify-content-center">
                <button class="btn btn-sm btn-primary shadow-none" @click="showFormBuilder"><font-awesome-icon icon="plus"/> Add Client Form</button>
              </div>
            </li>
            <li v-for="(item, index) in clientData" :key="index" class="nav-item py-2 pr-3" style="width: 100%;">
              <div class="d-flex justify-content-between">
                <h6 class="mb-0 font-weight-normal" style="cursor:pointer;" @click="viewClientData(item)">{{item.section_header}}</h6>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <main role="main" class="col-md-7 ml-sm-auto col-lg-10 ml-0" style="position: fixed; padding-left: 0px; padding-right: 0px; bottom:0px; border-top: 1px solid #f1f1f1; top: 125px; left: 0px; right: 0px; background-color: #f4f5f7; overflow-y:auto;">
      <Component :is="showComponent" @edit="editForm" @save="saveForm" @cancel="cancelForm" :type="type" :formId="formId" :formData="formData" />
    </main>
  </div>
</template>
<script>
// import { http } from '@/config/base-url'
import ConventionService from '@/services/convention.service'
import FormBuilder from '@/components/3x/form-builder/FormBuilder.vue'
import ClientData from '@/components/3x/ClientData.vue'
export default {
  props: ['project'],
  components: {
    FormBuilder,
    ClientData
  },
  data () {
    return {
      isDraft: false,
      showModal: false,
      items: [],
      biblio: {},
      title: '',
      editMode: false,
      showDropdown: false,
      showComponent: '',
      type: '',
      convention: {},
      showdropdown: true,
      clientData: [],
      formId: '',
      formData: {}
    }
  },
  methods: {
    showFormBuilder: function () {
      this.showComponent = 'FormBuilder'
      this.formData = {}
    },
    getConvention: function () {
      ConventionService.getConvention(this.$route.params.pid).then((response) => {
        console.log(response)
        this.convention = response
      })
    },
    getClientData: function () {
      console.log(this.$route.params)
      ConventionService.getClientData(this.$route.params.id).then((response) => {
        console.log(response)
        this.clientData = response
      })
    },
    toggleClientData: function () {
      this.showdropdown = !this.showdropdown
    },
    viewClientData: function (type) {
      this.showComponent = 'ClientData'
      this.type = type
      this.formId = type.id
      this.formData = type
    },
    editForm: function (item) {
      console.log(item)
      this.formData = item
      this.showComponent = 'FormBuilder'
    },
    saveForm: function (item) {
      if (item.type === 'edit') {
        this.showComponent = 'ClientData'
        this.type = item
        this.formId = item.id
        this.formData = item
      } else {
        this.showComponent = ''
        this.getClientData()
      }
    },
    cancelForm: function (item) {
      if (item.type === 'cancel' || item.type === 'new') {
        this.showComponent = ''
      } else {
        this.showComponent = 'ClientData'
        this.type = item
        this.formId = item.id
        this.formData = item
      }
    },
    projectSettings: function () {
      this.$router.push({ name: 'settings', params: { id: 'pid' } })
    }
  },
  mounted () {
    this.getConvention()
    this.getClientData()
  },
  created () {
  }
}
</script>
<style scoped>
.file-select > .select-button {
  margin: 10px;
  cursor: pointer;
  font-size: 25px;
}

.file-select > input[type="file"] {
  display: none;
}
</style>
