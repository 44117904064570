<template>
  <div>
    <div class="d-flex justify-content-between" style="background: #fff; position: fixed; z-index: 50; padding: 20px 25px; width: 100%; text-align:center; box-shadow:rgba(0, 0, 0, 0.2) 2px 4px 20px;">
      <div class="btn-group">
        <a class="btn btn-secondary" v-bind:class="{ active: isMyEvents}" style="text-transform: uppercase; padding: 10px 15px; color:#fff; cursor:pointer;" @click="myEvents">My Events</a>
        <a class="btn btn-secondary" v-bind:class="{ active: isAllEvents}" style="text-transform: uppercase; padding: 10px 15px; color:#fff; cursor:pointer;" @click="allEvents">All Events</a>
      </div>
      <div class="w-25">
        <input type="text" class="form-control form-control-lg" v-model="eventSearch" placeholder="Search Events..."/>
      </div>
      <div class="btn-group">
        <a class="btn btn-primary btn" style="text-transform: uppercase; font-weight: bold; padding: 10px 15px; color:#fff; cursor: pointer;" @click="addEvent">Add New Event</a>
        <a v-if="hasTemplates" class="btn btn-info btn" style="text-transform: uppercase; font-weight: bold; padding: 10px 15px; color:#fff; cursor: pointer;" @click="addEventFromTemplate">Add Event From Template</a>
      </div>
    </div>
    <!-- Events -->
    <div style="padding-top: 88px;">
      <div style="position: fixed; top: 142px; width: 100%;">
        <table class="table" style="background-color: #fff; margin-bottom: 0px;">
          <thead>
            <tr>
              <th width="30%">Project</th>
              <th width="10%">Project Manager</th>
              <th width="30%">Event</th>
              <th width="10%">Start Date</th>
              <th width="10%">Status</th>
              <th width="10%"></th>
            </tr>
          </thead>
        </table>
        <div style="position: fixed; top: 192px; left: 0px; right: 0px; bottom: 0px; padding-top: 0px; padding-bottom: 0px; background-color: #FFF; overflow-y:auto;">
          <table class="table table-striped">
            <tbody>
              <tr v-for="(item, index) in filteredEvents" :key="index">
                <td width="30%" style="padding-top: 24px;">{{item.project}}</td>
                <td width="10%" style="padding-top: 24px;">{{item.project_manager}}</td>
                <td width="30%" style="padding-top: 24px;">{{item.event_name}}</td>
                <td width="10%" style="padding-top: 24px;">{{item.start_date | formatDate}}</td>
                <td width="10%" style="padding-top: 24px;">
                  <change-status :item="item" :key-index="index" v-on:status-changed="saveItemStatus(item, index)"></change-status>
                </td>
                <td width="10%" style="padding-top: 24px;">
                  <div class="btn-group">
                    <button class="btn btn-primary btn-sm" @click="viewEvent(item.id)">View</button>
                    <button class="btn btn-primary btn-sm" @click="editEvent(item)">Edit</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-center p-3">
            <!-- <button class="btn btn-primary" v-if="hasMoreItemsToShow" @click="showMoreItems()">Show more</button> -->
          </div>
        </div>
      </div>
    </div>
    <div v-if="showEventModal">
      <event-modal @close="closeEventModal" @save="saveEvent" :item="eventItem" :title="eventTitle" :type="type"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import EventModal from '@/components/events/modals/EventModal.vue'
import ChangeStatus from '@/components/events/helpers/ChangeStatus.vue'
import eventService from '@/services/event.service'
import moment from 'moment'
import { forEach } from 'lodash'
export default {
  name: 'events',
  components: {
    EventModal,
    ChangeStatus
  },
  data () {
    return {
      eventSearch: '',
      isMyEvents: false,
      isAllEvents: false,
      showEventModal: false,
      eventTitle: '',
      eventItem: {},
      type: '',
      hasTemplates: false
    }
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        console.log(value)
        return moment(String(value)).format('MM/DD/YYYY')
      }
    }
  },
  computed: {
    ...mapState({ events: state => state.events.events }),
    filteredEvents: function () {
      return this.events
        .filter((event) => {
          return event.event_name.toLowerCase().match(this.eventSearch) ||
            event.project_manager.toLowerCase().match(this.eventSearch) ||
            event.project.toLowerCase().match(this.eventSearch)
        })
    }
  },
  methods: {
    myEvents: function () {
      this.isMyEvents = !this.isMyEvents
      this.isAllEvents = false
      this.$store.dispatch('events/getMyEvents')
    },
    allEvents: function () {
      this.isAllEvents = !this.isAllEvents
      this.isMyEvents = false
      this.$store.dispatch('events/getEvents')
    },
    viewEvent: function (id) {
      this.$router.push({ name: 'eventDetail', params: { id: id } })
    },
    addEvent: function () {
      this.showEventModal = true
      this.eventTitle = 'Add New Event'
      this.type = 'Event'
    },
    addEventFromTemplate: function () {
      this.showEventModal = true
      this.eventTitle = 'Add New Event'
      this.type = 'Template'
    },
    editEvent: function (item) {
      this.showEventModal = true
      this.eventTitle = 'Edit Event'
      this.eventItem = item
    },
    closeEventModal: function () {
      this.showEventModal = false
      this.eventItem = {}
    },
    saveEvent: function (item) {
      this.showEventModal = false
      if (item.type === 'add') {
        this.events.push(item)
      } else {
        forEach(this.events, (items) => {
          if (items.id === item.id) {
            items = item
          }
        })
        this.eventItem = {}
      }
    },
    saveItemStatus: function (item, index) {
      eventService.saveEvent(item).then((response) => {
        console.log(response)
      })
    }
  },
  created () {
    this.isMyEvents = true
    this.$store.dispatch('events/getMyEvents')
    eventService.getEventTemplates().then((response) => {
      console.log(response)
      if (response.length === 0) {
        this.hasTemplates = false
      } else {
        this.hasTemplates = true
      }
    })
  }
}
</script>

<style scoped>

</style>
