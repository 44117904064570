<template>
  <div>
    <!---------- Single Input: Text and E-mail ---------->
    <div v-if="el.type == 'input'" class="element col-12" v-bind:class="{'element-selected': selected == true && selectedIndex == index }">
      <span class="element-tools d-flex justify-content-end">
        <ColumnSize :column="el.columnSize" />
        <button @click.prevent="editItem(el, index)" class="btn btn-sm text-primary">Edit</button>
        <button @click.prevent="deleteItem(index)" class="btn btn-sm text-danger">&#x2715;</button>
      </span>
      <div class="form-group">
        <span class="text-danger" v-show="el.required">* </span><label>{{el.labelText}}</label>
        <input :type="el.inputType" class="form-control" :placeholder="el.placeholderText">
      </div>
    </div>

    <!---------- Textarea ---------->
    <div v-if="el.type == 'textarea'" class="element col-12" v-bind:class="{'element-selected': selected == true && selectedIndex == index }">
      <span class="element-tools d-flex justify-content-end">
        <ColumnSize :column="el.columnSize" />
        <button @click.prevent="editItem(el, index)" class="btn btn-sm text-primary">Edit</button>
        <button @click.prevent="deleteItem(index)" class="btn btn-sm text-danger">&#x2715;</button>
      </span>
      <div class="form-group">
        <span class="text-danger" v-show="el.required">* </span><label>{{el.labelText}}</label>
        <textarea cols="30" rows="2" class="form-control" :placeholder="el.placeholderText"></textarea>
      </div>
    </div>

    <!---------- Dropdown ---------->
    <div v-if="el.type == 'select'" class="element col-12" v-bind:class="{'element-selected': selected == true && selectedIndex == index }">
      <span class="element-tools d-flex justify-content-end">
        <ColumnSize :column="el.columnSize" />
        <button @click.prevent="editItem(el, index)" class="btn btn-sm text-primary">Edit</button>
        <button @click.prevent="deleteItem(index)" class="btn btn-sm text-danger">&#x2715;</button>
      </span>
      <div class="form-group">
        <span class="text-danger" v-show="el.required">* </span><label>{{el.labelText}}</label>
        <select class="form-control">
          <option value="">{{el.placeholderText}}</option>
          <option v-for="(choice, index) in el.choices" :key="index">
            {{ choice }}
          </option>
        </select>
      </div>
      <!-- Other -->
      <div v-if="el.other === 'Yes'" class="form-group">
        <textarea :name="['other-']+el.id" :id="['other-']+el.classNme" cols="30" rows="1" class="form-control"></textarea>
      </div>
    </div>

    <!---------- Checkbox ---------->
    <div v-if="el.type == 'checkbox'" class="element col-12" v-bind:class="{'element-selected': selected == true && selectedIndex == index }">
      <span class="element-tools d-flex justify-content-end">
        <ColumnSize :column="el.columnSize" />
        <button @click.prevent="editItem(el, index)" class="btn btn-sm text-primary">Edit</button>
        <button @click.prevent="deleteItem(index)" class="btn btn-sm text-danger">&#x2715;</button>
      </span>
      <div class="form-group" v-if="el.display === 'standard'">
        <span class="text-danger" v-show="el.required">* </span><label>{{el.labelText}}</label> <span class="text-info" v-if="el.limit === 'Yes'"> (Select up to {{el.limitAllowed}})</span>
        <span class="text-info" v-else> (Select all that apply)</span>
        <div class="clearfix"></div>
        <div class="form-check" v-for="(choice, index) in el.choices" :key="index">
          <input class="form-check-input" type="checkbox">
          <label class="form-check-label" for="defaultCheck1">
            {{ choice }}
          </label>
        </div>
      </div>

      <div class="form-group" v-else>
        <span class="text-danger" v-show="el.required">* </span><label>{{el.labelText}}</label> <span class="text-info" v-if="el.limit === 'Yes'"> (Select up to {{el.limitAllowed}})</span>
        <span class="text-info" v-else> (Select all that apply)</span>
        <div class="clearfix"></div>
        <div class="form-check form-check-inline" v-for="(choice, index) in el.choices" :key="index">
          <input class="form-check-input" type="checkbox">
          <label class="form-check-label" for="defaultCheck1">
            {{ choice }}
          </label>
        </div>
      </div>

      <!-- Other -->
      <div v-if="el.other === 'Yes'" class="form-group">
        <textarea :name="['other-']+el.id" :id="['other-']+el.className" cols="30" rows="1" class="form-control"></textarea>
      </div>
    </div>

    <!---------- Radio ---------->
    <div v-if="el.type == 'radio'" class="element col-12" v-bind:class="{'element-selected': selected == true && selectedIndex == index }">
      <span class="element-tools d-flex justify-content-end">
        <ColumnSize :column="el.columnSize" />
        <button @click.prevent="editItem(el, index)" class="btn btn-sm text-primary">Edit</button>
        <button @click.prevent="deleteItem(index)" class="btn btn-sm text-danger">&#x2715;</button>
      </span>
      <div class="form-group" v-if="el.display === 'standard'">
        <span class="text-danger" v-show="el.required">* </span><label>{{el.labelText}}</label>
        <div class="clearfix"></div>
        <div class="form-check" v-for="(choice, index) in el.choices" :key="index">
          <input class="form-check-input" type="radio">
          <label class="form-check-label" for="defaultCheck1">
            {{ choice }}
          </label>
        </div>
      </div>

      <div class="form-group" v-else>
        <span class="text-danger" v-show="el.required">* </span><label>{{el.labelText}}</label>
        <div class="clearfix"></div>
        <div class="form-check form-check-inline" v-for="(choice, index) in el.choices" :key="index">
          <input class="form-check-input" type="radio">
          <label class="form-check-label" for="defaultCheck1">
            {{ choice }}
          </label>
        </div>
      </div>

      <!-- Other -->
      <div v-if="el.other === 'Yes'" class="form-group">
        <textarea :name="['other-']+el.id" :id="['other-']+el.className" cols="30" rows="1" class="form-control"></textarea>
      </div>
    </div>

    <!---------- Switch ---------->
    <div v-if="el.type == 'switch'" class="element col-12" v-bind:class="{'element-selected': selected == true && selectedIndex == index }">
      <span class="element-tools d-flex justify-content-end">
        <ColumnSize :column="el.columnSize" />
        <button @click.prevent="editItem(el, index)" class="btn btn-sm text-primary">Edit</button>
        <button @click.prevent="deleteItem(index)" class="btn btn-sm text-danger">&#x2715;</button>
      </span>
      <div class="form-group">
        <label>{{el.labelText}}</label><br />
        <label class="switch">
          <input type="checkbox">
          <div class="slider round"></div>
        </label>
      </div>
    </div>

    <!---------- Date ---------->
    <div v-if="el.type == 'date'" class="element col-12" v-bind:class="{'element-selected': selected == true && selectedIndex == index }">
      <span class="element-tools d-flex justify-content-end">
        <ColumnSize :column="el.columnSize" />
        <button @click.prevent="editItem(el, index)" class="btn btn-sm text-primary">Edit</button>
        <button @click.prevent="deleteItem(index)" class="btn btn-sm text-danger">&#x2715;</button>
      </span>
      <div class="form-group">
        <span class="text-danger" v-show="el.required">* </span><label>{{el.labelText}}</label>
        <b-form-datepicker today-button reset-button close-button locale="en" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" :placeholder="el.placeholderText"></b-form-datepicker>
        <!-- <datepicker format="MM/dd/yyyy" :bootstrap-styling="true" :clear-button="true" :placeholder="el.placeholderText"></datepicker> -->
      </div>
    </div>

    <!---------- Time ---------->
    <div v-if="el.type == 'time'" class="element col-12" v-bind:class="{'element-selected': selected == true && selectedIndex == index }">
      <span class="element-tools d-flex justify-content-end">
        <ColumnSize :column="el.columnSize" />
        <button @click.prevent="editItem(el, index)" class="btn btn-sm text-primary">Edit</button>
        <button @click.prevent="deleteItem(index)" class="btn btn-sm text-danger">&#x2715;</button>
      </span>
      <div class="form-group">
        <span class="text-danger" v-show="el.required">* </span><label>{{el.labelText}}</label><br>
        <b-form-timepicker :placeholder="el.placeholderText" now-button reset-button></b-form-timepicker>
        <!-- <vue-timepicker format="hh:mm A" input-class="form-control time-background" input-width="100%" :placeholder="el.placeholderText"></vue-timepicker> -->
      </div>
    </div>

    <!---------- Button ---------->
    <div v-if="el.type == 'button'" class="element col-12" v-bind:class="{'element-selected': selected == true && selectedIndex == index }">
      <span class="element-tools d-flex justify-content-end">
        <ColumnSize :column="el.columnSize" />
        <button @click.prevent="editItem(el, index)" class="btn btn-sm text-primary">Edit</button>
        <button @click.prevent="deleteItem(index)" class="btn btn-sm text-danger">&#x2715;</button>
      </span>
      <div class="form-group">
        <button class="btn" :class="[ 'btn-' + el.buttonSize, 'btn-' + el.buttonType ]">{{el.buttonText}}</button>
      </div>
    </div>

    <!---------- Clearfix ---------->
    <div v-if="el.type == 'clearfix'" class="element col-12" v-bind:class="{'element-selected': selected == true && selectedIndex == index }">
      <span class="element-tools d-flex justify-content-between">
        <div style="padding-top: 8px;">
          <label style="font-weight: 500;">Clearfix</label>
        </div>
        <button @click.prevent="deleteItem(index)" class="btn btn-sm text-danger">&#x2715;</button>
      </span>
      <div class="clearfix"></div>
    </div>

    <!---------- US Address ---------->
    <div v-if="el.type == 'us-address'" class="element col-12" v-bind:class="{'element-selected': selected == true && selectedIndex == index }">
      <span class="element-tools d-flex justify-content-end">
        <button @click.prevent="editItem(el, index)" class="btn btn-sm text-primary">Edit</button>
        <button @click.prevent="deleteItem(index)" class="btn btn-sm text-danger">&#x2715;</button>
      </span>
      <div class="row">
        <div class="form-group col-6" v-for="(item, index) in el.customFields" :key="index">
          <span class="text-danger" v-show="item.required">* </span><label>{{item.name}}</label>
          <select v-if="item.name == 'State'" class="form-control">
            <option value="">Select {{item.name}}</option>
            <option v-for="(choice, index) in item.values" :key="index">
              {{ choice }}
            </option>
          </select>
          <input v-else :type="item.inputType" class="form-control" :placeholder="item.name">
        </div>
      </div>
    </div>

    <!---------- Contact ---------->
    <div v-if="el.type == 'contact'" class="element col-12" v-bind:class="{'element-selected': selected == true && selectedIndex == index }">
      <span class="element-tools d-flex justify-content-end">
        <button @click.prevent="editItem(el, index)" class="btn btn-sm text-primary">Edit</button>
        <button @click.prevent="deleteItem(index)" class="btn btn-sm text-danger">&#x2715;</button>
      </span>
      <div class="row pb-3">
        <div class="col-12">
          <h5>{{titleCase(el.type)}}</h5>
        </div>
      </div>
      <div class="row">
        <div class="form-group" v-bind:class="{'col-12': item.columnSize == 'col-12', 'col-6': item.columnSize == 'col-6'}" v-for="(item, index) in el.customFields" :key="index">
          <span class="text-danger" v-show="item.required">* </span><label>{{item.name}}</label>
          <input :type="item.inputType" class="form-control" :placeholder="item.name">
        </div>
      </div>
    </div>

    <!---------- General ---------->
    <div v-if="el.type == 'general'" class="element col-12" v-bind:class="{'element-selected': selected == true && selectedIndex == index }">
      <span class="element-tools d-flex justify-content-end">
        <button @click.prevent="editItem(el, index)" class="btn btn-sm text-primary">Edit</button>
        <button @click.prevent="deleteItem(index)" class="btn btn-sm text-danger">&#x2715;</button>
      </span>
      <div class="row pb-3">
        <div class="col-12">
          <h5>{{titleCase(el.type)}}</h5>
        </div>
      </div>
      <div class="row">
        <div class="form-group" v-bind:class="{'col-12': item.columnSize == 'col-12', 'col-6': item.columnSize == 'col-6'}" v-for="(item, index) in el.customFields" :key="index">
          <span class="text-danger" v-show="item.required">* </span><label>{{item.name}}</label>
          <div v-if="item.inputType == 'radio'">
            <div class="clearfix"></div>
            <div class="form-check form-check-inline" v-for="(choice, index) in item.choices" :key="index">
              <input class="form-check-input" type="radio">
              <label class="form-check-label" for="defaultCheck1">
                {{ choice }}
              </label>
            </div>
          </div>
          <input v-else-if="item.inputType == 'birth-date'" v-mask="'99/99/9999'" class="form-control" :placeholder="item.placeholderText" />
          <input v-else :type="item.inputType" class="form-control" :placeholder="item.name">
        </div>
      </div>
    </div>

    <!---------- Hotel ---------->
    <div v-if="el.type == 'hotel'" class="element col-12" v-bind:class="{'element-selected': selected == true && selectedIndex == index }">
      <span class="element-tools d-flex justify-content-end">
        <button @click.prevent="editItem(el, index)" class="btn btn-sm text-primary">Edit</button>
        <button @click.prevent="deleteItem(index)" class="btn btn-sm text-danger">&#x2715;</button>
      </span>
      <div class="row pb-3">
        <div class="col-12">
          <h5>{{titleCase(el.type)}}</h5>
        </div>
      </div>
      <div class="row">
        <div class="form-group" v-bind:class="{'col-12': item.columnSize == 'col-12', 'col-6': item.columnSize == 'col-6'}" v-for="(item, index) in el.customFields" :key="index">
          <span class="text-danger" v-show="item.required">* </span><label>{{item.name}}</label>
          <div v-if="item.inputType == 'radio'">
            <div class="clearfix"></div>
            <div class="form-check form-check-inline" v-for="(choice, index) in item.choices" :key="index">
              <input class="form-check-input" type="radio">
              <label class="form-check-label" for="defaultCheck1">
                {{ choice }}
              </label>
            </div>
          </div>
          <b-form-datepicker v-else-if="item.inputType == 'date'" today-button reset-button close-button locale="en" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" :placeholder="item.placeholderText"></b-form-datepicker>
          <!-- <datepicker v-else-if="item.inputType == 'date'" format="MM/dd/yyyy" :bootstrap-styling="true" :clear-button="true" :placeholder="item.placeholderText"></datepicker> -->
          <input v-else :type="item.inputType" class="form-control" :placeholder="item.name">
        </div>
      </div>
    </div>

    <!---------- Flights ---------->
    <div v-if="el.type == 'flights'" class="element col-12" v-bind:class="{'element-selected': selected == true && selectedIndex == index }">
      <span class="element-tools d-flex justify-content-end">
        <button @click.prevent="editItem(el, index)" class="btn btn-sm text-primary">Edit</button>
        <button @click.prevent="deleteItem(index)" class="btn btn-sm text-danger">&#x2715;</button>
      </span>
      <div class="row pb-3">
        <div class="col-12">
          <h5>{{titleCase(el.type)}}</h5>
        </div>
      </div>
      <div class="row">
        <div class="form-group" v-bind:class="{'col-12': item.columnSize == 'col-12', 'col-6': item.columnSize == 'col-6'}" v-for="(item, index) in el.customFields" :key="index">
          <span class="text-danger" v-show="item.required">* </span><label>{{item.name}}</label>
          <div v-if="item.inputType == 'radio'">
            <div class="clearfix"></div>
            <div class="form-check form-check-inline" v-for="(choice, index) in item.choices" :key="index">
              <input class="form-check-input" type="radio">
              <label class="form-check-label" for="defaultCheck1">
                {{ choice }}
              </label>
            </div>
          </div>
          <b-form-datepicker v-else-if="item.inputType == 'date'" today-button reset-button close-button locale="en" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" :placeholder="item.placeholderText"></b-form-datepicker>
          <b-form-timepicker v-else-if="item.inputType == 'time'" :placeholder="el.placeholderText" now-button reset-button></b-form-timepicker>
          <!-- <datepicker v-else-if="item.inputType == 'date'" format="MM/dd/yyyy" :bootstrap-styling="true" :clear-button="true" :placeholder="item.placeholderText"></datepicker>
          <vue-timepicker v-else-if="item.inputType == 'time'" format="hh:mm A" input-class="form-control time-background" input-width="100%" :placeholder="item.placeholderText"></vue-timepicker> -->
          <input v-else-if="item.clearfix == true" :type="item.inputType" class="form-control w-50-clearfix" :placeholder="item.name">
          <input v-else :type="item.inputType" class="form-control" :placeholder="item.name">
        </div>
      </div>
    </div>

    <!---------- Meals ---------->
    <div v-if="el.type == 'meals'" class="element col-12" v-bind:class="{'element-selected': selected == true && selectedIndex == index }">
      <span class="element-tools d-flex justify-content-end">
        <button @click.prevent="editItem(el, index)" class="btn btn-sm text-primary">Edit</button>
        <button @click.prevent="deleteItem(index)" class="btn btn-sm text-danger">&#x2715;</button>
      </span>
      <div class="row pb-3">
        <div class="col-12">
          <h5>{{titleCase(el.type)}}</h5>
        </div>
      </div>
      <div class="row">
        <div class="form-group" v-bind:class="{'col-12': item.columnSize == 'col-12', 'col-6': item.columnSize == 'col-6'}" v-for="(item, index) in el.customFields" :key="index">
          <span class="text-danger" v-show="item.required">* </span><label>{{item.name}}</label>
          <div v-if="item.inputType == 'radio'">
            <div class="clearfix"></div>
            <div class="form-check form-check-inline" v-for="(choice, index) in item.choices" :key="index">
              <input class="form-check-input" type="radio">
              <label class="form-check-label" for="defaultCheck1">
                {{ choice }}
              </label>
            </div>
          </div>
          <b-form-datepicker v-else-if="item.inputType == 'date'" today-button reset-button close-button locale="en" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" :placeholder="item.placeholderText"></b-form-datepicker>
          <b-form-timepicker v-else-if="item.inputType == 'time'" :placeholder="el.placeholderText" now-button reset-button></b-form-timepicker>
          <!-- <datepicker v-else-if="item.inputType == 'date'" format="MM/dd/yyyy" :bootstrap-styling="true" :clear-button="true" :placeholder="item.placeholderText"></datepicker>
          <vue-timepicker v-else-if="item.inputType == 'time'" format="hh:mm A" input-class="form-control time-background" input-width="100%" :placeholder="item.placeholderText"></vue-timepicker> -->
          <textarea v-else-if="item.inputType == 'textarea'" cols="30" rows="2" class="form-control" :placeholder="item.placeholderText"></textarea>
          <input v-else :type="item.inputType" class="form-control" :placeholder="item.name">
        </div>
      </div>
    </div>

    <!---------- Header Image ---------->
    <div v-if="el.type == 'image'" class="element col-12" v-bind:class="{'element-selected': selected == true && selectedIndex == index }">
      <span class="element-tools d-flex justify-content-end">
        <button @click.prevent="editItem(el, index)" class="btn btn-sm text-primary">Edit</button>
        <button @click.prevent="deleteItem(index)" class="btn btn-sm text-danger">&#x2715;</button>
      </span>
      <div class="row">
        <div class="col-12">
          <img :src="el.imageSource" :alt="el.imageAltText" :width="el.imageWidth" class="img-fluid">
        </div>
      </div>
    </div>

    <!---------- Introduction / Cover Letter ---------->
    <div v-if="el.type == 'word'" class="element col-12" v-bind:class="{'element-selected': selected == true && selectedIndex == index }">
      <span class="element-tools d-flex justify-content-end">
        <button @click.prevent="deleteItem(index)" class="btn btn-sm text-danger">&#x2715;</button>
      </span>
      <div class="row">
        <div class="col-12">
          <tinymce-editor api-key="xmy55lj0kciq6f2f0cfrnrnj4qjbs8e5qge7gjk8rr1j4692" style="height: 250px; border:none;" :init="options" v-model="el.textValue"></tinymce-editor>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import ColumnSize from './ColumnSize.vue'
import Editor from '@tinymce/tinymce-vue'
// import Datepicker from 'vuejs-datepicker'
// import VueTimepicker from 'vue2-timepicker'
export default {
  name: 'FormBuilderItems',
  components: {
    ColumnSize,
    // Datepicker,
    // VueTimepicker,
    'tinymce-editor': Editor
  },
  props: ['el', 'index', 'selected', 'selectedIndex'],
  data () {
    return {
      options: {
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar: 'undo | bold italic strikethrough | forecolor backcolor formatselect | alignleft aligncenter alignright alignjustify | bullist numlist | link | code'
      }
    }
  },
  methods: {
    deleteItem (index) {
      this.$emit('delete', index)
    },
    editItem (val, index) {
      this.$emit('edit', val, index)
    },
    titleCase: function (str) {
      str = str.toLowerCase().split(' ')
      for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
      }
      return str.join(' ')
    }
  }
}
</script>
<style>
.time-background {
  background-color: #fff !important;
}
</style>
<style scoped>
.w-50-clearfix {
  width: calc(50% - 15px) !important;
}
.element {
  background-color: #f3f3f3;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.element-selected {
  background-color: #DCFEDC;
}

/*--------------------------------------------------
  Switch
--------------------------------------------------*/
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 18px;
}

.switch input { display: none }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #009370;
}

input:focus+.slider {
  box-shadow: 0 0 1px #009370;
}

input:checked+.slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

.slider.round { border-radius: 34px }

.slider.round:before { border-radius: 50% }

input[type="text"], textarea {
outline: none;
box-shadow:none !important;
border:1px solid #ccc;
}

.remove-border {
  border-left: 0px !important;
}
.input-group-prepend {
  padding: 10px;
  background-color: #fff;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

</style>
