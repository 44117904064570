<template>
  <div>
    <div style="background: #fff; position: fixed; z-index: 50; padding: 0px 20px; width: 100%; text-align:center;  box-shadow:rgba(0, 0, 0, 0.2) 2px 4px 20px;">
      <div @click="$router.go(-1)" style="padding-top:20px; padding-left: 10px; float: left; text-align:left; padding-right:25px; cursor:pointer;">
          <span class="fa fa-chevron-left" style="font-size:20px;"></span>
          <span style="font-size: 20px; padding-left: 5px;">Back</span>
      </div>
      <div style="padding-top: 10px; padding-left: 10px; float: left; text-align:left;">
        <h3 style="margin-top:0px; font-size: 23px;">
          {{convention.project}}
          <br>
          <small style="color: #777; font-size:16px;">{{convention.name}} • {{location.name}} • {{location.address}}, {{location.city}}, {{location.state}} {{location.zip}}</small>
        </h3>
      </div>
    </div>
    <main style="padding: 0px 0px 25px 0px; position:fixed; top: 155px; bottom: 0; width: 100%; overflow-y:auto;">
      <nav id="interactions-menu">
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <a class="nav-item nav-link active" id="nav-users-tab" data-toggle="tab" href="#nav-users" role="tab" aria-controls="nav-users" aria-selected="true">
            Users
          </a>
          <a class="nav-item nav-link" id="nav-objectives-tab" data-toggle="tab" href="#nav-objectives" role="tab" aria-controls="nav-objectives" aria-selected="false">
            Objectives
          </a>
          <a class="nav-item nav-link" id="nav-locations-tab" data-toggle="tab" href="#nav-locations" role="tab" aria-controls="nav-locations" aria-selected="false">
            Meeting Locations
          </a>
          <a class="nav-item nav-link" id="nav-interactions-tab" data-toggle="tab" href="#nav-interactions" role="tab" aria-controls="nav-interactions" aria-selected="false">
            Interactions/Events
          </a>
          <a class="nav-item nav-link" id="nav-reports-tab" data-toggle="tab" href="#nav-reports" role="tab" aria-controls="nav-reports" aria-selected="false">
            Reports
          </a>
          <a class="nav-item nav-link" id="nav-support-tab" data-toggle="tab" href="#nav-support" role="tab" aria-controls="nav-support" aria-selected="false">
            Support
          </a>
          <a class="nav-item nav-link" id="nav-settings-tab" data-toggle="tab" href="#nav-settings" role="tab" aria-controls="nav-settings" aria-selected="false">
            Settings
          </a>
          <a class="nav-item nav-link" id="nav-user-interactions-tab" data-toggle="tab" href="#nav-user-interactions" role="tab" aria-controls="nav-user-interactions" aria-selected="false">
            User Interactions <sup>BETA</sup>
          </a>
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-users" role="tabpanel" aria-labelledby="nav-users-tab">
          <users></users>
        </div>
        <div class="tab-pane fade" id="nav-objectives" role="tabpanel" aria-labelledby="nav-objectives-tab">
          <!-- <Objectives :cid="this.cid" /> -->
        </div>
        <div class="tab-pane fade" id="nav-locations" role="tabpanel" aria-labelledby="nav-locations-tab">
          <!-- <Locations :cid="this.cid" /> -->
        </div>
        <div class="tab-pane fade" id="nav-interactions" role="tabpanel" aria-labelledby="nav-interactions-tab">
          <!-- <Interactions :cid="this.cid" /> -->
        </div>
        <div class="tab-pane fade" id="nav-reports" role="tabpanel" aria-labelledby="nav-reports-tab">
          <!-- <Reports :cid="this.cid" /> -->
        </div>
        <div class="tab-pane fade" id="nav-support" role="tabpanel" aria-labelledby="nav-support-tab">
          <!-- <Support :cid="this.cid" /> -->
        </div>
        <div class="tab-pane fade" id="nav-settings" role="tabpanel" aria-labelledby="nav-settings-tab">
          <!-- <Settings :cid="this.cid" /> -->
        </div>
        <div class="tab-pane fade" id="nav-user-interactions" role="tabpanel" aria-labelledby="nav-user-interactions-tab">
          <user-interactions></user-interactions>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import ConventionService from '@/services/convention.service'
import UserInteractions from '@/components/3x/interactions/tabs/UserInteractions.vue'
import Users from '@/components/3x/interactions/tabs/Users.vue'
export default {
  components: {
    Users,
    UserInteractions
  },
  name: 'Convention',
  data () {
    return {
      convention: {},
      location: {}
    }
  },
  methods: {
    getConvention: function () {
      ConventionService.getConvention(this.$route.params.cid).then((response) => {
        console.log(response)
        this.convention = response
        this.location = response.location
      })
    }
  },
  mounted () {
    this.getConvention()
  }
}
</script>

<style scoped>
  .nav-tabs > a { color: #337ab7; font-size: 14px; }

  .input.search-query { max-width: 400px }

</style>
