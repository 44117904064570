<template>
  <div class="d-flex justify-content-center px-5" style="padding:45px 25px 25px 25px;">
    <!-- <Description :kid="this.kid" :type="type" /> -->
    <div class="card w-75">
      <div class="card-header d-flex justify-content-between align-items-center">
        <div>
          <h4>{{type}}</h4>
          <p class="text-muted mb-0" style="font-size: 0.85em;">Last updated: 7/1/2020 2:57 PM by Simpson Employee</p>
        </div>
        <div class="col-3">
          <multiselect :options="paymentDataYears" :show-labels="false" track-by="year" label="year" placeholder="Select Year..." v-model="year" @select="changeYear"></multiselect>
        </div>
      </div>
      <div class="card-body p-3">
        <div v-if="hasData == true">
          <div class="d-flex justify-content-between">
            <div class="p-2">
              <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">Payment Type</label><br>
              General Payments
            </div>
            <div class="p-2 col-4">
              <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">Company</label><br>
              <multiselect :options="company_menu" :show-labels="false" placeholder="Select Company..." v-model="options.company" @select="searchCompany"></multiselect>
            </div>
            <div class="p-2 col-4">
              <label style="font-size: 12px; margin-bottom:0px; font-weight: bold;">Nature of Payment</label><br>
              <multiselect :options="nature_of_menu" :show-labels="false" placeholder="Select Nature of Payment..." v-model="options.nature_of" @select="searchNature"></multiselect>
            </div>
          </div>
          <!-- <div class="col-6 text-center pt-4">
            <h5 class="payment-title">General Payments</h5>
            <p class="payment-total">${{ general_payments_total | usd_format }}</p>
            ({{ number_of_payments }} payments)
          </div> -->
          <div class="col-12 pt-3 px-0">
            <GeneralPayments
              :id="this.profileId"
              :sent_options="this.options"
              :year="this.year"
              ref="generalComponent"
            />
          </div>
        </div>
        <div v-else>
          <div class="d-flex justify-content-center pt-2 px-0">
            No Information Available
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GeneralPayments from '@/components/profiles/open-payments/GeneralPayments.vue'
import axios from 'axios'
export default {
  props: ['type', 'kolId', 'name'],
  components: {
    GeneralPayments
  },
  data () {
    return {
      isDraft: false,
      showModal: false,
      items: [],
      biblio: {},
      title: '',
      editMode: false,
      physician: {},
      physicianArray: [],
      paymentDataYears: [
        {
          year: '2019',
          query: 'p2ve-2ws5'
        },
        {
          year: '2018',
          query: 'hpct-jqg5'
        },
        {
          year: '2017',
          query: 'sq2r-g2ww'
        },
        {
          year: '2016',
          query: 'b9d9-i3f4'
        },
        {
          year: '2015',
          query: 'axsx-c8us'
        }
      ],
      year: {
        year: '2019',
        query: 'p2ve-2ws5'
      },
      options: {
        company: 'All Companies',
        nature_of: 'All Nature of Payments'
      },
      company_menu: [],
      nature_of_menu: [],
      general_payments_total: '',
      number_of_payments: '',
      profileId: '',
      hasData: false
    }
  },
  methods: {
    getBibliometrics: function (id, type, name) {
      console.log(id, type, name)
      let nameArray = name.split(' ')
      this.searchName(nameArray)
    },
    changeYear: function (selection) {
      console.log(selection)
      console.log(this.year)
      this.year = selection
      console.log(this.year)
      this.searchProfile(this.profileId)
    },
    searchName: function (nameArray) {
      axios.get(`https://openpaymentsdata.cms.gov/resource/${this.year.query}.json?physician_first_name=${nameArray[0]}&physician_last_name=${nameArray[1]}`, {
        headers: {
          'X-App-Token': 'YhHPfcbvU5yPjTVFQzRraM41w'
        }
      }).then(res => {
        console.log(res)
        let id = res.data.shift()
        // this.physician_id = id.physician_profile_id
        console.log(id)
        this.profileId = id.physician_profile_id
        this.searchProfile(id.physician_profile_id)
      }).catch(error => {
        console.log(error)
      })
    },
    searchProfile (id) {
      axios.get(`https://openpaymentsdata.cms.gov/resource/${this.year.query}.json?physician_profile_id=${id}`, {
        headers: {
          'X-App-Token': 'YhHPfcbvU5yPjTVFQzRraM41w'
        }
      }).then(res => {
        console.log(res)
        if (res.data.length > 0) {
          this.hasData = true
          // console.log(res.data)
          // this.profile = res.data
          // Physician Information
          let info = res.data.shift()
          this.physician.first_name = info.physician_first_name
          this.physician.last_name = info.physician_last_name
          this.physician.address_1 = info.recipient_primary_business_street_address_line1
          this.physician.address_2 = info.recipient_primary_business_street_address_line2
          this.physician.city = info.recipient_city
          this.physician.state = info.recipient_state
          this.physician.zipcode = info.recipient_zip_code
          this.physician.specialty = info.physician_specialty

          console.log(res.data)
          console.log(this.physician)

          this.getFilteredMenusOnCreate(id)
          this.getGeneralPaymentTotal(id)
          this.$refs.generalComponent.getGeneralPayments(this.year)
        } else {
          this.hasData = false
        }
      }).catch(error => {
        console.log(error)
      })
    },
    getFilteredMenusOnCreate: function (id) {
      axios.get(`https://openpaymentsdata.cms.gov/resource/${this.year.query}.json?physician_profile_id=${id}`, {
        headers: {
          'X-App-Token': 'YhHPfcbvU5yPjTVFQzRraM41w'
        }
      }).then(res => {
        // Companies to create menu
        let companyList = []

        // Nature of Payments to create menu
        let natureList = []

        res.data.forEach((item) => {
          // Push company name into array to build company filter menu
          companyList.push(item.applicable_manufacturer_or_applicable_gpo_making_payment_name)

          // Push nature of payment into array to build nature filter menu
          natureList.push(item.nature_of_payment_or_transfer_of_value)
        })

        // Get unique Company names from duplicates for company menu dropdown
        let filteredCompany = companyList.filter(function (item, pos) {
          return companyList.indexOf(item) === pos
        })

        // Get unique Nature of Payment from duplicates for nature of menu dropdown
        let filteredNature = natureList.filter(function (item, pos) {
          return natureList.indexOf(item) === pos
        })

        console.log('Companies', filteredCompany.sort())
        console.log('Nature of Payments', filteredNature.sort())
        // Sort array for company filter menu
        this.company_menu = filteredCompany.sort()
        this.company_menu.unshift('All Companies')

        // Sort array for nature of payment filter menu
        this.nature_of_menu = filteredNature.sort()
        this.nature_of_menu.unshift('All Nature of Payments')
      }).catch(error => {
        console.log(error)
      })
    },
    getGeneralPaymentTotal (id) {
      axios.get(`https://openpaymentsdata.cms.gov/resource/${this.year.query}.json?physician_profile_id=${id}`, {
        headers: {
          'X-App-Token': 'YhHPfcbvU5yPjTVFQzRraM41w'
        }
      }).then(res => {
        let generalTotal = []
        let paymentsArray = []

        res.data.forEach((item) => {
          // Get sum total of all payment amounts
          generalTotal.push(parseFloat(item.total_amount_of_payment_usdollars))
          paymentsArray.push(item.number_of_payments_included_in_total_amount)
        })
        // ---------- Sum of all General Payments by Year
        // Get total of all payments
        let sum = 0

        for (let i = 0; i < generalTotal.length; i++) {
          let x = generalTotal[i]
          let total = x
          sum += total
        }

        this.general_payments_total = sum

        // Get total of all yearly payments
        let paymentsSum = 0
        let arrayOfNumbers = paymentsArray.map(Number)

        for (let i = 0; i < arrayOfNumbers.length; i++) {
          let x = arrayOfNumbers[i]
          let paymentsTotal = x
          paymentsSum += paymentsTotal
        }
        this.number_of_payments = paymentsSum

        console.log(this.payments)
      }).catch(error => {
        console.log(error)
      })
    },
    searchCompany: function (selection) {
      console.log(selection)
      const company = selection
      if (company === 'All Companies') {
        this.$refs.generalComponent.getGeneralPayments(this.year)
      } else {
        this.$refs.generalComponent.getGeneralPayments(this.year, company)
      }
    },

    searchNature: function (selection) {
      console.log(selection)
      const nature = selection
      if (nature === 'All Nature of Payments') {
        this.$refs.generalComponent.getGeneralPayments(this.year)
      } else {
        this.$refs.generalComponent.getGeneralPayments(this.year, null, nature)
      }
    }
  },
  created () {
    this.getBibliometrics(this.kolId, this.type, this.name)
  }
}
</script>
