import { http } from '@/config/base-url'
import authHeader from '@/config/auth-header'

export default {
  getAllProjectReprots () {
    return http.get('v2/projects/all-projects', {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },
  getAllProjects () {
    return http.get('v2/projects/active-projects', {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },
  getArchivedProjects () {
    return http.get('v2/projects/archived-projects', {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },
  getAllMarketingProjects () {
    return http.get('v2/projects/marketing-projects', {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },
  getProjectModules () {
    return http.get('v2/projects/modules', {
      headers: authHeader()
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  },
  addToMyProjects (project) {
    return http.put('v2/projects/add-user', project, {
      headers: authHeader()
    }).then(res => res.data).catch((error) => {
      console.log(error)
    })
  },
  removeFromMyProjects (project) {
    return http.put('v2/projects/remove-user', project, {
      headers: authHeader()
    }).then(res => res.data).catch((error) => {
      console.log(error)
    })
  },
  saveData (item) {
    if (item.id) {
      return http.put(`v2/projects/update/${item.id}`, item, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    } else {
      return http.post(`v2/projects/new`, item, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    }
  },
  saveMarketingData (item) {
    if (item.id) {
      return http.put(`v2/projects/marketing/update/${item.id}`, item, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    } else {
      return http.post(`v2/projects/marketing/new`, item, {
        headers: authHeader()
      }).then((res) => {
        return res.data
      }).catch((error) => {
        console.log(error)
      })
    }
  }
}
