<template>
<div>
    <div style="background: #fff; position: fixed; z-index: 50; padding: 20px; width: 100%; text-align:center;  box-shadow:rgba(0, 0, 0, 0.2) 2px 4px 20px;">
        <div class="cleafix float-left" style="padding-top: 10px; padding-left: 10px; text-align:left;">
            <h3 style="margin-top:0px;">Temps</h3>
        </div>
        <div class="float-right" style="padding-right: 10px; text-align:right;">
            <div class="btn-group">
                <a class="btn btn-primary btn" style="cursor:pointer; text-transform: uppercase; font-size: 14px; font-weight: bold; padding: 10px 15px; color:#fff;" @click="addTemp"><span class="fa fa-plus"></span> Add Temp</a>
            </div>
        </div>
    </div>
    <div style="padding: 25px; position:fixed; top: 145px; bottom: 0; width: 100%; overflow-y:auto;">
      <div class="container">
        <div class="row">
            <ul class="nav">
              <li class="col-4" v-for="item in temps" :key="item.id">
                  <a class="nav-link" style="cursor:pointer;" v-on:click="showTemp(item)">
                    <div class="d-flex justify-content-start">
                      <div class="text-center">
                        <img :src="item.img" width="75" height="75" class="rounded-circle img-thumbnail"/>
                        <h6 class="p-2 m-1">
                          {{item.name}}<br />
                          <small class="text-muted">{{item.email}}</small>
                        </h6>
                      </div>
                    </div>
                  </a>
              </li>
            </ul>
        </div>
      </div>
    </div>
    <!-- Temp MODAL -->
    <div class="modal" tabindex="-1" role="dialog" id="userModal">
        <div class="modal-dialog" role="document" style="max-width: 675px;">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add Temp</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form novalidate>
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <label for="firstName">First Name</label>
                          <input type="text" class="form-control" id="firstName" v-model="inputForm.first_name" placeholder="First Name">
                        </div>
                        <div class="form-group col-md-6">
                          <label for="lastName">Last Name</label>
                          <input type="text" class="form-control" id="lastName" v-model="inputForm.last_name" placeholder="Last Name">
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <label for="email">Email</label>
                          <input type="text" class="form-control" id="Email" v-model="inputForm.email" placeholder="Email">
                        </div>
                        <div class="form-group col-md-6">
                          <label for="department">Department</label>
                          <multiselect :options="departmentOptions" v-model="inputForm.department" placeholder="Select department..."></multiselect>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <label for="username">Username</label>
                          <input type="text" class="form-control" id="username" v-model="inputForm.username" placeholder="Username">
                        </div>
                        <div class="form-group col-md-6">
                          <label for="password">Password</label>
                          <input type="text" class="form-control" id="password" v-model="inputForm.password" disabled>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <label for="supervisor">Supervisor</label>
                          <multiselect :options="supervisorOptions" v-model="inputForm.supervisor" placeholder="Select supervisor..."></multiselect>
                        </div>
                        <div class="form-group col-md-6">
                          <label for="regcode">AWS Registration Code</label>
                          <input type="text" class="form-control" id="regcode" v-model="inputForm.registration_code" disabled>
                        </div>
                      </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button v-if="modalType == 'edit'" class="btn btn-danger" @click="disableTemp">Disable</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" @click="saveTemp">Save</button>
                </div>
            </div>
        </div>
    </div>
    <!-- User MODAL -->
</div>
</template>
<script>
import JQuery from 'jquery'
import EmployeeService from '@/services/employee.service'
import { forEach } from 'lodash'

export default {
  name: 'Temps',
  data () {
    return {
      employees: [],
      temps: [],
      userSearch: '',
      user: {},
      userSelected: false,
      date: null,
      inputForm: {
        registration_code: 'SLiad+K7UP4L',
        password: 'Sh0re230!'
      },
      options: {
        format: 'MM/DD/YYYY',
        useCurrent: false
      },
      selected: '',
      deactivateReason: '',
      departmentOptions: ['CEO', 'Client Services', 'Editorial', 'Finance', 'Graphics', 'Marketing', 'Medical', 'Human Resources', 'Software Engineering + Technology'],
      supervisorOptions: [],
      modalType: 'add'
    }
  },
  computed: {
    activeEmployees: function () {
      return this.employees.filter((employee) => {
        return employee.is_active === true
      }).filter((employee) => {
        return employee.name.toLowerCase().match(this.userSearch)
      })
    },
    inactiveEmployees: function () {
      return this.employees.filter((employee) => {
        return employee.is_active === false
      }).filter((employee) => {
        return employee.name.toLowerCase().match(this.userSearch)
      })
    }
  },
  methods: {
    getAllUsers: function () {
      EmployeeService.getAllEmployees().then((response) => {
        const [users] = [response.users]
        const allUsers = []
        forEach(users, (item) => {
          item.img = `https://imgs.simpsonhealthcare.com/simpson/employees/${item.name.replace(/\s+/g, '-').toLowerCase()}.gif`
          if (item.is_active) {
            allUsers.push(item.name)
          }
        })
        this.employees = users
        this.supervisorOptions = allUsers
      })
    },
    getAllTemps: function () {
      EmployeeService.getAllTemps().then((response) => {
        const [users] = [response.users]
        forEach(users, (item) => {
          item.img = `https://imgs.simpsonhealthcare.com/simpson/employees/simpson-star.gif`
        })
        this.temps = users
      })
    },
    disableTemp: function () {
      this.inputForm.is_active = false
      EmployeeService.saveTemp(this.inputForm).then((response) => {
        console.log(response)
        let $ = JQuery
        $('#userModal').modal('hide')
      })
    },
    showTemp: function (item) {
      this.modalType = 'edit'
      let $ = JQuery
      $('#userModal').modal({
        backdrop: 'static',
        keyboard: false,
        show: true
      })
      this.inputForm = item
      this.inputForm.password = 'Sh0re230!'
    },
    saveTemp: function () {
      console.log(this.inputForm)
      EmployeeService.saveTemp(this.inputForm).then((response) => {
        console.log(response)
        let $ = JQuery
        $('#userModal').modal('hide')
        this.getAllTemps()
      })
    },
    addTemp: function () {
      this.modalType = 'add'
      this.inputForm = {
        registration_code: 'SLiad+K7UP4L',
        password: 'Sh0re230!'
      }
      let $ = JQuery
      $('#userModal').modal({
        backdrop: 'static',
        keyboard: false,
        show: true
      })
    }
  },
  mounted () {
    this.getAllUsers()
    this.getAllTemps()
  }
}
</script>

<style scoped>
/** Avatar Circle **/
.circle {
  background-color: #ccc;
  border-radius: 50%;
  height: 36px;
  text-align: center;
  width: 36px;
}
.initials {
  font-size: calc(36px / 2);
  /* 50% of parent */
  line-height: 1;
  position: relative;
  top: calc(36px / 4);
  /* 25% of parent */
}

/*
 * Content
 */

[role="main"] {
  padding-top: 133px; /* Space for fixed navbar */
}

@media (min-width: 768px) {
  [role="main"] {
    padding-top: 0px; /* Space for fixed navbar */
  }
}

.flex-column.nav-link {
    cursor:pointer;
    padding: 0.5rem 1rem 0 1rem;
    border-bottom: 1px solid #ccc;
}

.nav-pills {
  padding: 10px 0px;
}

.employee-active {
  background-color: #006AB6;
  color: #fff;
}

.employee-active h6,
.employee-active .text-muted {
  color: #fff !important;
}

/* Rounded sliders */

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 18px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #009370;
}

input:focus+.slider {
    box-shadow: 0 0 1px #009370;
}

input:checked+.slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
